import { DismissableInlineAlert } from '@daangn/sprout-components-inline-alert';

import { CHALLENGE_PROMOTION_LANDKIT_LINK } from '@/components/group/Challenge/ChallengePromotion/components/PromotionBanner/ChallengeListPromotionBanner';
import { openLink } from '@/utils/link';

import useGroupDetailGroupChallengeCertifyPromotionBannerClosed from '../../hooks/useGroupDetailGroupChallengeCertifyPromotionBannerClosed';
import * as s from './index.css';

type Props = {
  groupId: number;
};

// 2025.01 챌린지 인증 프로모션 이벤트 > 모임 상세 배너
const GroupDetailGroupChallengeCertifyPromotionBanner = ({ groupId }: Props) => {
  const [_, setGroupChallengeCertifyPromotionBannerClosed] =
    useGroupDetailGroupChallengeCertifyPromotionBannerClosed(groupId.toString());

  const handleClose = () => {
    setGroupChallengeCertifyPromotionBannerClosed(true);
  };
  const handleClick = (event) => {
    const contentDiv = event.currentTarget.querySelector('[data-part="content"]');
    if (!contentDiv || !contentDiv.contains(event.target)) return;

    setGroupChallengeCertifyPromotionBannerClosed(true);

    openLink(CHALLENGE_PROMOTION_LANDKIT_LINK, true);
  };

  return (
    <div onClick={handleClick} className={s.Banner}>
      <DismissableInlineAlert
        variant="info"
        title="이벤트"
        description="새해 목표 챌린지에 인증글 남기고, 매일 보상금 받아가세요!💰"
        onClose={handleClose}
      />
    </div>
  );
};

export default GroupDetailGroupChallengeCertifyPromotionBanner;
