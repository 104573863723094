import { GroupMemberRoleEnum } from '@community-group/api/lib/group/models';
import { BoxButton, useDialog } from '@community-group/components';
import { useState } from 'react';

import { useGetGroup1on1TalkTargetUri } from '@/api/hooks/useGetGroup1on1TalkTargetUri';
import { useGetMoneyTransferTicket } from '@/api/hooks/useGetMoneyTransferTicket';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useVisibilityEvent } from '@/hooks/useVisibilityEvent';
import { trackEvent } from '@/utils/analytics';
import { openHref } from '@/utils/link';

type Props = {
  groupId: string;
  targetUserId: string;
  requestUserRole: GroupMemberRoleEnum;
  requestUserDirectChatEnabledStatus: string;
};

export const GroupDirectChatStatusOnEnabledButton = ({
  groupId,
  targetUserId,
  requestUserRole,
  requestUserDirectChatEnabledStatus,
}: Props) => {
  const [isChatLoading, setIsChatLoading] = useState(false);
  const [isTransferLoading, setIsTransferLoading] = useState(false);
  const [talkTargetUri, setTalkTargetUri] = useState<string>('');
  const [ticketId, setTicketId] = useState<string>('');

  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate } = useGetGroup1on1TalkTargetUri({
    onError: handleErrorWithToast,
  });

  const handleChatClick = () => {
    if (isChatLoading) return;
    setIsChatLoading(true);

    mutate(
      {
        id: groupId.toString() ?? '0',
        memberId: targetUserId.toString(),
      },
      {
        onSuccess: ({ data }) => {
          trackEvent({
            event: 'click_1on1_chat_button',
            params: {
              groupId,
              domain: 'group',
              role: requestUserRole,
              directChatEnabledStatus: requestUserDirectChatEnabledStatus,
            },
            sample: true,
          });
          openHref(data?.talkTargetUri);
        },
        onSettled: () => {
          setIsChatLoading(false);
        },
      }
    );
  };

  const { fetch: checkMoneyTransferStatus } = useGetMoneyTransferTicket();

  useVisibilityEvent({
    enabled: isTransferLoading && !!talkTargetUri,
    callback: async (visibilityState) => {
      if (visibilityState === 'visible') {
        const chatChannelId = talkTargetUri.split('channels/')?.[1] ?? '';

        try {
          if (chatChannelId) {
            const { transferStatus = 'wait' } = await checkMoneyTransferStatus(ticketId);

            if (transferStatus === 'success') {
              trackEvent({
                event: 'click_pay_transfer_direct_success',
                params: {
                  groupId,
                  type: 'profile_detail',
                  directChatEnabledStatus: 'enabled',
                },
              });
              openHref(talkTargetUri);

              setTalkTargetUri('');
              setTicketId('');
            }

            setIsTransferLoading(false);
          }
        } catch {
          setIsTransferLoading(false);
        }
      }
    },
  });

  return (
    <>
      <BoxButton
        size="medium"
        variant="secondary"
        onClick={handleChatClick}
        UNSAFE_style={{ width: '100%' }}
        isLoading={isChatLoading}
      >
        채팅하기
      </BoxButton>
    </>
  );
};
