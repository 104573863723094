import { useEffect, useMemo } from 'react';

import { useStorage } from '@/hooks/useStorage';
import { formatDate } from '@/utils/date';

export const useChallengeCertifyButtonClick = (challengeId: string) => {
  const [storage, setStorage] = useStorage('challengeCertifyButtonClick', {
    date: '2025-01-12',
    count: 0,
  });

  const today = formatDate(new Date(), 'yyyy-MM-dd');

  const currentClickCount = useMemo(() => {
    const data = storage;
    if (!data || data.date !== today) {
      return 0;
    }
    return data.count;
  }, [storage, today]);

  // 날짜가 바뀌면 초기화
  useEffect(() => {
    const data = storage;
    if (data && data.date !== today) {
      setStorage({
        date: today,
        count: 0,
      });
    }
  }, [storage, setStorage, challengeId, today]);

  const incrementClickCount = () => {
    setStorage({
      date: today,
      count: currentClickCount + 1,
    });
  };

  return {
    clickCount: currentClickCount,
    incrementClickCount,
  };
};
