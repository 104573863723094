import { BoxButton, withAsyncBoundary } from '@community-group/components';
import { IconChevronRightFill } from '@daangn/react-monochrome-icon';

import { useReadGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { useReadGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import * as s from './GroupMeetupDetailCommentListMoreButton.css';

type Props = {
  groupId: string;
  meetupId: string;
  totalCommentCount: number;
};

const GroupMeetupDetailCommentListMoreButton = ({
  groupId,
  meetupId,
  totalCommentCount,
}: Props) => {
  const { data: groupMe } = useReadGroupMe({ groupId });
  const { group } = useReadGroupDetail(groupId);

  const { push } = useFlow();
  const handleClick = () => {
    trackEvent({
      event: 'click_group_meetup_comment_more',
      params: {
        role: groupMe.role,
      },
      sample: true,
    });

    push('CommentListPage', {
      groupId,
      relatedId: meetupId,
      relatedContentType: 'meetup',
      commentCount: totalCommentCount.toString(),
      groupName: group?.name,
    });
  };

  return (
    <div className={s.Wrapper}>
      <BoxButton
        width="100%"
        variant="secondary"
        size="medium"
        suffix={<IconChevronRightFill size={14} color="gray900" />}
        onClick={handleClick}
      >
        댓글 더보기
      </BoxButton>
    </div>
  );
};

export default withAsyncBoundary(GroupMeetupDetailCommentListMoreButton, {});
