import { groupClient } from '@community-group/api';
import { useInfiniteQuery, useSuspenseInfiniteQuery } from '@tanstack/react-query';
import { $Values } from 'utility-types';

import { GROUP_URL, ORDER_SEARCH } from '../base/group';
import { useFetchInstance } from './instance/useFetchInstance';

type Params = {
  query: string;
  category?: number | number[];
  order?: $Values<typeof ORDER_SEARCH>;
  referrer: string;
  queryId?: string;
  splitSearch?: boolean;
  minAge?: number;
  maxAge?: number;
  regionRange?: 'my' | 'adjacent' | 'range2' | 'range3' | 'unlimited';
  initialCursor?: string | undefined;
};

const getSearchGroupListKey = ({
  query,
  order,
  category,
  referrer,
  splitSearch,
  minAge,
  maxAge,
  regionRange,
}: Params) =>
  `${GROUP_URL}/search?query=${query}&category=${category}&order=${order}&referrer=${referrer}&splitSearch=${splitSearch}&minAge=${minAge}&maxAge=${maxAge}&regionRange=${regionRange}`;

export const useGetSearchGroupList = ({
  query,
  order,
  category,
  referrer,
  queryId,
  splitSearch,
  minAge,
  maxAge,
  regionRange,
  initialCursor,
}: Params) => {
  const fetchInstance = useFetchInstance();

  const getSearchGroupList = groupClient.api.SearchApi.apiV2GroupsSearchGet({
    axios: fetchInstance,
  });

  const getCategoryParams = () => {
    if (typeof category === 'number') return [Number(category)];

    return category;
  };

  const key = getSearchGroupListKey({
    query,
    order,
    category: getCategoryParams(),
    referrer,
    queryId,
    splitSearch,
    minAge,
    maxAge,
    regionRange,
  });

  const { fetchNextPage, hasNextPage, isFetchingNextPage, data, refetch } =
    useSuspenseInfiniteQuery({
      queryKey: [key],
      queryFn: ({ pageParam = initialCursor }) =>
        getSearchGroupList({
          query: decodeURIComponent(query),
          cursor: pageParam ? pageParam : undefined,
          limit: 20,
          order,
          category: getCategoryParams(),
          type: 'group',
          referrer,
          queryId,
          splitSearch,
          minAge,
          maxAge,
          regionRange,
        }),
      initialPageParam: initialCursor,
      getNextPageParam: ({ data }) => (data.hasNext ? data?.endCursor ?? undefined : undefined),
    });

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
  };
};
