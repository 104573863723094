import { groupClient } from '@community-group/api';
import { ProfileInfoExposureRangeEnum } from '@community-group/api/lib/group/models';

import { useFetchInstance } from '@/api/hooks/instance/useFetchInstance';
import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';
import { queryClient } from '@/shared/api/instance';

type Params = {
  reviewMessageId: number;
  reviewMessageExposureRange: ProfileInfoExposureRangeEnum;
};

export const usePutGroupProfileReviewMessage = () => {
  const fetchInstance = useFetchInstance();
  const putGroupProfileReviewMessage =
    groupClient.api.GroupProfileApi.apiV1GroupsProfileReviewMessageReviewMessageIdPut({
      axios: fetchInstance,
    });

  const fetchPutGroupProfileReviewMessage = ({
    reviewMessageId,
    reviewMessageExposureRange,
  }: Params) =>
    putGroupProfileReviewMessage({
      reviewMessageId,
      inlineObject: {
        reviewExposureRange: reviewMessageExposureRange,
      },
    });

  return useThrottleMutation(fetchPutGroupProfileReviewMessage, {
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: [groupClient.api.GroupProfileApi.getapiV1GroupsProfileGetPath()],
      });
    },
  });
};
