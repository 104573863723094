import { GroupCurrentUser, GroupDetailPresentation } from '@community-group/api/lib/group/models';
import { isHigherManager, isManager, isSuperHost } from '@community-group/components';
import { useMemo } from 'react';

import { useGetApplications } from '@/api/hooks/useGetApplications';
import { useGetChallengeBanner } from '@/api/hooks/useGetChallengeBanner';
import { useGetGroupFeedReviewWriteableMeetup } from '@/api/hooks/useGetGroupFeedReviewWriteableMeetup';
import { useGetRunningGrowthMission } from '@/api/hooks/useGetRunningGrowthMission';
import GroupLevelBanner from '@/components/common/GroupLevelBanner';
import ChallengeBanner from '@/components/group/Challenge/ChallengeBanner/components';
import GroupDetailRunningGrowthBanner from '@/components/group/Detail/components/RunningGrowth/GroupDetailRunningGrowthBanner/index';

import useMemberMissionList from '../../hooks/useMemberMissionList';
import GroupDetailMeetupReviewBanner from './GroupDetailMeetupReviewBanner';
import GroupDetailOnboardingBannerForMember from './GroupDetailOnboardingBannerForMember';
import GroupDetailWaitingMemberBanner from './GroupDetailWaitingMemberBanner';

type Props = {
  group: GroupDetailPresentation;
  currentUser: GroupCurrentUser;
};

const useMainBanners = ({ group, currentUser }: Props) => {
  const groupId = useMemo(() => group.id.toString(), [group.id]);

  const missionList = useMemberMissionList(groupId);

  const hasRemainMission = missionList.length !== 0;

  const { data: applications } = useGetApplications(groupId);
  const { data: reviewWriteable } = useGetGroupFeedReviewWriteableMeetup(groupId);
  const { runningGrowthData } = useGetRunningGrowthMission(groupId);
  const { data: challengeBanner } = useGetChallengeBanner({ groupId: Number(groupId) });

  const isShowApprovalBanner = useMemo(() => {
    if (!currentUser.permissions.applyApplicationMember) return false;
    if (group.management?.applicationCount === undefined) return false;
    if (group.management?.applicationCount <= 0) return false;

    return true;
  }, [group, currentUser]);

  const showRunningGrowthBanner =
    isSuperHost(currentUser.role) && runningGrowthData && runningGrowthData.length > 0;

  const memberCount = applications?.pages[0].data.applicationCount ?? 0;

  const showChallengeBanner = challengeBanner && challengeBanner.challenges.length > 0;

  const allDoneChallenge = challengeBanner?.challenges.every(
    (challenge) => challenge.todayCertified ?? false
  );

  // ** 일반 멤버인 경우
  const MemberBanners = useMemo(() => {
    const result: { id: string; element: React.ReactNode }[] = [];

    // 모임 일정 후기 배너 노출
    const meetupId = reviewWriteable?.meetupId?.toString();
    if (meetupId) {
      result.push({
        id: 'meetup-review-banner',
        element: (
          <GroupDetailMeetupReviewBanner
            group={group}
            currentUser={currentUser}
            meetupId={meetupId}
          />
        ),
      });
    }

    // 모임 챌린지 배너 노출
    if (showChallengeBanner) {
      result.push({
        id: 'challenge-banner',
        element: <ChallengeBanner challengeBanner={challengeBanner} />,
      });
    }

    // 공통 함수: 2주 이내 가입 여부 확인
    const isJoinedIn2Weeks = (joinedAt: string) =>
      new Date(joinedAt) > new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 14);

    if (isJoinedIn2Weeks(currentUser.joinedAt) && hasRemainMission) {
      // 멤버 온보딩 배너 노출
      result.push({
        id: 'member-onboarding-banner',
        element: <GroupDetailOnboardingBannerForMember groupId={groupId} />,
      });
    }

    // 모든 챌린지를 완료한 경우 challenge-banner가 존재하면 제일 뒤로 이동시킴
    // if (allDoneChallenge) {
    //   const challengeBannerIndex = result.findIndex((banner) => banner.id === 'challenge-banner');
    //   if (challengeBannerIndex !== -1) {
    //     result.push(result.splice(challengeBannerIndex, 1)[0]);
    //   }
    // }

    if (result.length === 0) return [];
    return result.filter((banner) => banner.element !== null);
  }, [
    challengeBanner,
    currentUser,
    group,
    groupId,
    hasRemainMission,
    reviewWriteable?.meetupId,
    showChallengeBanner,
  ]);

  // ** 모임장 or 운영진인 경우
  const HostAndManagerBanners = useMemo(() => {
    const result: { id: string; element: React.ReactNode }[] = [];

    // 가입 승인 대기중인 멤버가 있는 경우
    if (isShowApprovalBanner) {
      result.push({
        id: 'waiting-member-banner',
        element: (
          <GroupDetailWaitingMemberBanner
            group={group}
            currentUser={currentUser}
            memberCount={memberCount}
          />
        ),
      });
    }

    // 모임 일정 후기 배너 노출
    const meetupId = reviewWriteable?.meetupId?.toString();
    if (meetupId) {
      result.push({
        id: 'meetup-review-banner',
        element: (
          <GroupDetailMeetupReviewBanner
            group={group}
            currentUser={currentUser}
            meetupId={meetupId}
          />
        ),
      });
    }

    // 모임 챌린지 배너 노출
    if (showChallengeBanner) {
      result.push({
        id: 'challenge-banner',
        element: <ChallengeBanner challengeBanner={challengeBanner} />,
      });
    }

    // 러닝 그로스 미션 배너 노출
    if (showRunningGrowthBanner) {
      result.push({
        id: 'running-growth-banner',
        element: <GroupDetailRunningGrowthBanner />,
      });
    }

    result.push({
      id: 'group-level-banner',
      element: <GroupLevelBanner groupId={groupId} size="large" />,
    });

    // * 운영진인 경우
    if (isManager(currentUser.role)) {
      // 공통 함수: 2주 이내 가입 여부 확인
      const isJoinedIn2Weeks = (joinedAt: string) =>
        new Date(joinedAt) > new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 14);

      if (isJoinedIn2Weeks(currentUser.joinedAt) && hasRemainMission) {
        // 멤버 온보딩 배너 노출
        result.push({
          id: 'member-onboarding-banner',
          element: <GroupDetailOnboardingBannerForMember groupId={groupId} />,
        });
      }
    }

    // 모든 챌린지를 완료한 경우 challenge-banner가 존재하면 제일 뒤로 이동시킴
    // if (allDoneChallenge) {
    //   const challengeBannerIndex = result.findIndex((banner) => banner.id === 'challenge-banner');
    //   if (challengeBannerIndex !== -1) {
    //     result.push(result.splice(challengeBannerIndex, 1)[0]);
    //   }
    // }

    if (result.length === 0) return [];
    return result.filter((banner) => banner.element !== null);
  }, [
    challengeBanner,
    currentUser,
    group,
    groupId,
    hasRemainMission,
    isShowApprovalBanner,
    memberCount,
    reviewWriteable?.meetupId,
    showChallengeBanner,
    showRunningGrowthBanner,
  ]);

  return { banners: isHigherManager(currentUser.role) ? HostAndManagerBanners : MemberBanners };
};

export default useMainBanners;
