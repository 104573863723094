import { groupClient } from '@community-group/api';
import {
  GroupChallengeCreateRequest,
  GroupChallengeCreateResponse,
} from '@community-group/api/lib/group/models';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Params = {
  groupChallengeCreateRequest: GroupChallengeCreateRequest;
};
type Props = UseMutationOptions<AxiosResponse<GroupChallengeCreateResponse>, Error, Params>;

// 챌린지 생성 Hook, 모임이 없는 경우에는 groupId를 Null로 보내면 됨
export const usePostChallenge = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const postGroupChallenge = groupClient.api.GroupChallengeApi.apiV1ChallengesPost({
    axios: fetchInstance,
  });

  const fetchPostGroupChallenge = ({ groupChallengeCreateRequest }: Params) => {
    return postGroupChallenge({
      groupChallengeCreateRequest,
    });
  };

  return useThrottleMutation(fetchPostGroupChallenge, {
    onError,
    onSuccess: (data, variables, context) => {
      onSuccess?.(data, variables, context);
    },
  });
};
