import {
  BoxButton,
  isMember,
  Typography,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { IconPlusLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { useCallback, useMemo } from 'react';

import { MEETUP_PROGRESS_STATUS_FILTER, useGetMeetupList } from '@/api/hooks/useGetMeetupList';
import { LoadMoreGroupMeetupContainer } from '@/components/common/LoadMoreContainer';
import { convertFlattenMeetupLisFromAPI as convertFlattenMeetupListFromAPI } from '@/components/group/Detail/components/Meetup/utils/meetupList';
import { useReadGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import GroupDetailFeedMeetupList from '../GroupDetailFeedMeetupList';
import * as s from './FeedMeetupList.css';

type Props = {
  groupId: string;
  shouldSetSubNickname: boolean;
};

const FeedMeetupList = ({ groupId, shouldSetSubNickname }: Props) => {
  const { push } = useFlow();

  const { data: myInfo } = useReadGroupMe({ groupId, refetchOnWindowFocus: true });
  const { data: openedMeetupData, hasNextPage: hasOpenedMeetupNextPage } = useGetMeetupList(
    groupId,
    MEETUP_PROGRESS_STATUS_FILTER.OPENED,
    'meet_at_asc'
  );
  const openedMeetups = useMemo(
    () => convertFlattenMeetupListFromAPI(openedMeetupData),
    [openedMeetupData]
  );

  const { data: closedMeetupData, hasNextPage: hasClosedMeetupNextPage } = useGetMeetupList(
    groupId,
    MEETUP_PROGRESS_STATUS_FILTER.CLOSED,
    'meet_at_desc'
  );

  const closedMeetups = useMemo(
    () => convertFlattenMeetupListFromAPI(closedMeetupData),
    [closedMeetupData]
  );

  const isEmpty = useMemo(() => {
    if (hasOpenedMeetupNextPage) return false;
    if (openedMeetups.length > 0) return false;

    if (hasClosedMeetupNextPage) return false;
    if (closedMeetups.length > 0) return false;

    return true;
  }, [openedMeetups, hasOpenedMeetupNextPage, closedMeetups, hasClosedMeetupNextPage]);

  const handleWriteButton = () => {
    trackEvent({
      event: 'click_group_meetup_create',
      params: {
        name: 'meetup_section',
      },
    });

    return push('GroupMeetupNewPage', { groupId });
  };

  const MeetupWriteButton = useCallback(() => {
    const isNotGroupMember = !isMember(myInfo.role);

    if (isNotGroupMember) {
      return null;
    }

    return (
      <div className={s.ButtonWrapper}>
        <BoxButton
          size="large"
          variant="secondary"
          width="100%"
          prefix={<IconPlusLine size={16} color={vars.$scale.color.gray900} />}
          onClick={handleWriteButton}
        >
          일정 만들기
        </BoxButton>
      </div>
    );
  }, [myInfo]);

  if (isEmpty) {
    return (
      <>
        <MeetupWriteButton />
        <div className={s.EmptyListWrapper}>
          <Typography className={s.EmptyList} typography="bodyM1Regular" color="gray600">
            아직 일정이 없어요.
            <br />
            가장 먼저 일정을 만들어보세요.
          </Typography>
        </div>
      </>
    );
  }

  return (
    <>
      <MeetupWriteButton />
      <GroupDetailFeedMeetupList
        title="다가오는 일정"
        groupId={groupId}
        meetups={openedMeetups}
        shouldSetSubNickname={shouldSetSubNickname}
      />
      {hasOpenedMeetupNextPage ? (
        <LoadMoreGroupMeetupContainer
          selectedMeetupProgressStatusKey={'OPENED'}
          order={'meet_at_asc'}
        />
      ) : (
        <>
          <GroupDetailFeedMeetupList
            title="종료된 일정"
            groupId={groupId}
            meetups={closedMeetups}
            shouldSetSubNickname={shouldSetSubNickname}
            shouldReverse
          />
          {hasClosedMeetupNextPage && (
            <LoadMoreGroupMeetupContainer
              selectedMeetupProgressStatusKey={'CLOSED'}
              order={'meet_at_desc'}
            />
          )}
        </>
      )}
    </>
  );
};

export default withAsyncBoundary(FeedMeetupList, {
  pendingFallback: <ViewLoader />,
  rejectedFallback: <></>,
});
