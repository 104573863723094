import { groupClient } from '@community-group/api';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

import { curationKeywords } from '@/components/group/Curation/Page/GroupCurationWithKeyword/curationKeywords';

import { useFetchInstance } from './instance/useFetchInstance';

type Params = {
  topic?: Array<string>;
  priorityOrderParams?: string;
};

export const useGetCurationCollection = ({ topic, priorityOrderParams }: Params) => {
  const fetchInstance = useFetchInstance();
  const getCuration = groupClient.api.GroupCurationApi.apiV1GroupsCurationCollectionGet({
    axios: fetchInstance,
  });

  const { data, refetch, isInitialLoading } = useSuspenseQuery({
    queryKey: [groupClient.api.GroupCurationApi.getapiV1GroupsCurationCollectionGetPath([]), topic],
    queryFn: () => getCuration({ topics: topic ?? [] }),
    select: useCallback(
      (data) => {
        if (!data?.data?.collections) return [];
        const nonEmptyCollections = removeEmptyGroups(data.data.collections);
        const sortedTopicCollections = sortByTopicOrder(nonEmptyCollections, curationKeywords);

        if (priorityOrderParams === '전체') return sortedTopicCollections;
        const targetCollection = sortedTopicCollections.find(
          (collection) => collection.topic === priorityOrderParams
        );
        if (!targetCollection) return sortedTopicCollections;
        const index = sortedTopicCollections.indexOf(targetCollection);
        return [
          targetCollection,
          ...sortedTopicCollections.slice(0, index),
          ...sortedTopicCollections.slice(index + 1),
        ];
      },
      [priorityOrderParams]
    ),
    staleTime: 1000 * 60 * 5,
  });

  return { data: data, refetch, isLoading: isInitialLoading };
};

// 빈배열 제거
const removeEmptyGroups = (collections) => {
  return collections.filter((collection) => collection.groups?.length > 0);
};

// 큐레이션 토픽 순서대로 정렬
const sortByTopicOrder = (collections, curationKeywords) => {
  return collections.sort((a, b) => {
    const prevTopic = curationKeywords.find((keyword) => keyword.name === a.topic);
    const nextTopic = curationKeywords.find((keyword) => keyword.name === b.topic);
    if (!prevTopic || !nextTopic) return 0;
    return prevTopic.order - nextTopic.order;
  });
};
