import { groupClient } from '@community-group/api';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

export const getGroupFeedReviewWriteableMeetupPath = (groupId: number) =>
  groupClient.api.GroupFeedApi.getapiV1GroupsIdFeedReviewWriteableMeetupGetPath(groupId);

export const useGetGroupFeedReviewWriteableMeetup = (groupId: string) => {
  const fetchInstance = useFetchInstance();
  const get = groupClient.api.GroupFeedApi.apiV1GroupsIdFeedReviewWriteableMeetupGet({
    axios: fetchInstance,
  });

  const numberGroupId = Number(groupId);
  const path = getGroupFeedReviewWriteableMeetupPath(numberGroupId);
  const { data, refetch } = useSuspenseQuery({
    queryKey: [path],
    queryFn: () => get({ id: numberGroupId }),
  });
  const response = data?.data;

  return { data: response, refetch };
};
