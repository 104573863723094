import { groupClient } from '@community-group/api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useRead } from '@/shared/api/hooks/useRead';

export const getMyChallengeCalendarPath = (groupId?: number, challengeId?: number) =>
  groupClient.api.GroupChallengeMemberApi.getapiV1GroupsGroupIdChallengesChallengeIdCalendarGetPath(
    groupId ?? 0,
    challengeId ?? 0
  );

export const useGetMyChallengeCalendar = ({
  groupId,
  challengeId,
}: {
  groupId: number;
  challengeId: number;
}) => {
  const read = useRead(
    groupClient.api.GroupChallengeMemberApi.apiV1GroupsGroupIdChallengesChallengeIdCalendarGet
  );

  return useSuspenseQuery({
    queryKey: [getMyChallengeCalendarPath(groupId, challengeId)],
    queryFn: async () => read({ groupId: Number(groupId), challengeId: Number(challengeId) }),
    select: (data) => data?.data,
  });
};
