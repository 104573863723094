import { groupClient } from '@community-group/api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { getGroupPath } from '../base/group';
import { useFetchInstance } from './instance/useFetchInstance';

const getMembersInfoPath = (groupId: string) => `${getGroupPath(groupId)}/members/info`;

export const useGetMembersForMention = (groupId?: string, limit = 2000) => {
  const fetchInstance = useFetchInstance();
  const getMemberList = groupClient.api.GroupMemberApi.apiV1GroupsIdMembersInfoGet({
    axios: fetchInstance,
  });

  const {
    data,
    refetch,
    isLoading: isInitialLoading,
  } = useSuspenseQuery({
    queryKey: [getMembersInfoPath(groupId ?? '')],
    queryFn: () => getMemberList({ id: Number(groupId), limit }),
    refetchInterval: 10000,
  });

  return {
    data,
    refetch,
    isLoading: isInitialLoading,
  };
};
