import { ChallengeRankingUserInfo } from '@community-group/api/lib/group/models';
import {
  Avatar,
  isHigherManager,
  Spacing,
  Typography,
  VerticalSpacing,
} from '@community-group/components';
import { IconCrownFill } from '@daangn/react-monochrome-icon';
import { useMemo } from 'react';

import { useFlow } from '@/stackflow';

import * as s from './ParticipantsRankItem.css';

type Props = {
  isMe?: boolean;
  groupId: string;
} & ChallengeRankingUserInfo;

const Rank1GoldMedal =
  'https://assetstorage.krrt.io/1025317940251365954/4cfe7eba-6d1d-49af-be62-fb6508230a2a/width=66,height=66.png';
const Rank2SilverMedal =
  'https://assetstorage.krrt.io/1025317940251365954/4bddc3f0-fef3-4e8c-b4d4-fb092b0bbd24/width=66,height=66.png';
const Rank3BronzeMedal =
  'https://assetstorage.krrt.io/1025317940251365954/878b6102-6011-443d-8eb5-72a4fda70afb/width=66,height=66.png';

const ParticipantsItem = ({
  isMe = false,
  groupId,
  id,
  currentRank,
  nickname,
  profileImage,
  totalCertifiedProgress,
  subNickname,
  role,
}: Props) => {
  const { push } = useFlow();
  const percentage = (totalCertifiedProgress.current / totalCertifiedProgress.target) * 100;

  const RankSection = useMemo(() => {
    if (currentRank <= 3) {
      switch (currentRank) {
        case 1:
          return <img className={s.RankMedalImage} src={Rank1GoldMedal} />;
        case 2:
          return <img className={s.RankMedalImage} src={Rank2SilverMedal} />;
        case 3:
          return <img className={s.RankMedalImage} src={Rank3BronzeMedal} />;
      }
    }

    return (
      <Typography
        typography={isMe ? 'subtitle1Bold' : 'subtitle1Regular'}
        color={isMe ? 'gray900' : 'gray600'}
      >
        {currentRank}
      </Typography>
    );
  }, [isMe, currentRank]);

  const handleProfileClick = () => {
    push('GroupUserProfileDetailPage', {
      groupId,
      userId: id.toString(),
    });
  };

  return (
    <div className={s.Container({ isMe })} onClick={handleProfileClick}>
      <div className={s.Rank}>{RankSection}</div>
      <VerticalSpacing size={12} />
      <div className={s.AvatarWrapper}>
        <Avatar alt="profile" fallback={nickname} src={profileImage} size="small" />
      </div>
      <VerticalSpacing size={12} />
      <div>
        <div className={s.ProfileWrapper}>
          {isMe && (
            <div className={s.MeBadge}>
              <Typography typography="label5Bold" color="gray00">
                나
              </Typography>
            </div>
          )}
          <Typography
            typography="subtitle2Regular"
            style={{
              whiteSpace: 'nowrap',
            }}
          >
            {nickname}
          </Typography>
          {role && isHigherManager(role) && (
            <IconCrownFill
              size={14}
              color={role === 'superHost' ? '#f7be68' : '#30c795'}
              style={{
                minWidth: '.875rem',
              }}
            />
          )}
          {subNickname && (
            <Typography typography="subtitle2Regular" color="gray900" ellipsisAfterLines={1}>
              ({subNickname})
            </Typography>
          )}
        </div>
        <Spacing size={2} />
        <div className={s.UserInfoWrapper}>
          <div className={s.PercentageWrapper}>
            <div className={s.PercentageBar} style={{ width: `${percentage}%` }} />
          </div>
          <VerticalSpacing size={8} />
          <Typography typography="caption2Regular" color="gray600">
            {totalCertifiedProgress.current}/{totalCertifiedProgress.target}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default ParticipantsItem;
