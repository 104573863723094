import { groupClient } from '@community-group/api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

export const getChallengeBannerPath = (groupId: number) =>
  groupClient.api.GroupChallengeMemberApi.getapiV1GroupsGroupIdChallengesBannerGetPath(groupId);

export const useGetChallengeBanner = ({ groupId }: { groupId: number }) => {
  const fetchInstance = useFetchInstance();

  const getChallengeBanner =
    groupClient.api.GroupChallengeMemberApi.apiV1GroupsGroupIdChallengesBannerGet({
      axios: fetchInstance,
    });

  const { data, refetch } = useSuspenseQuery({
    queryKey: [getChallengeBannerPath(groupId)],
    queryFn: () => getChallengeBanner({ groupId }),
  });

  return { data: data.data, refetch };
};
