import {
  ChallengeStatusEnum,
  GroupMeetupProgressStatusPresentationStatus,
} from '@community-group/api/lib/group/models';
import { vars } from '@seed-design/design-token';
/**
 * @deprecated domain/GroupFeed/utils/getMeetupStatusTitleColor 사용해주세요.
 */
export const getMeetupStatusTitleColor = (
  status: GroupMeetupProgressStatusPresentationStatus | ChallengeStatusEnum
) => {
  switch (status) {
    case 'opened':
      return vars.$semantic.color.primary;
    case 'fullyRecruited':
      return vars.$scale.color.yellow400;
    case 'closed':
    case 'deleted':
    case 'canceled':
    case 'blocked':
    default:
      return vars.$scale.color.gray600;
  }
};
