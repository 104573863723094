import {
  GroupCurrentUser,
  UserProfileSnsTagPresentation,
} from '@community-group/api/lib/group/models';

import { openSnsLink } from '@/components/group/profile/GroupProfile/Detail/utils/openSnsLink';
import SnsTextButton from '@/domain/GroupProfile/components/SnsTextButton';
import { trackEvent } from '@/utils/analytics';

import * as s from './style.css';

type Props = {
  snsTags: UserProfileSnsTagPresentation[];
  currentUser: GroupCurrentUser;
};

const SnsTagOtherMemberSection = ({ snsTags, currentUser }: Props) => {
  return (
    <div className={s.Wrapper}>
      {snsTags.map((snsTag) => (
        <SnsTextButton
          key={snsTag.snsId}
          name={snsTag.snsId}
          iconType={snsTag.snsType}
          onClick={() => {
            trackEvent({
              event: 'click_move_external_sns',
              params: {
                snsTagId: snsTag.snsId,
                snsTagType: snsTag.snsType,
                currentUserState: currentUser.state,
              },
            });

            openSnsLink({
              snsId: snsTag.snsId,
              snsType: snsTag.snsType,
            });
          }}
        />
      ))}
    </div>
  );
};

export default SnsTagOtherMemberSection;
