import { axiosClient, groupClient } from '@community-group/api';
import { Activity, StackflowPluginHook, StackflowPluginPostEffectHook } from '@stackflow/core';
import { StackflowReactPlugin } from '@stackflow/react';
import Cookies from 'universal-cookie';

import { COOKIE } from '@/constants/keys';

export const checkVisitPlugin: () => StackflowReactPlugin = () => () => ({
  key: 'check-visit-plugin',
  onChanged: handleChange,
  onInit: handleInit,
});

const handleInit: StackflowPluginHook = ({ actions }) => {
  const stack = actions.getStack();
  const currentActivity = stack.activities.find(({ isActive }) => isActive);

  if (!currentActivity) return;

  checkGroupVisit(currentActivity);
};

const handleChange: StackflowPluginPostEffectHook<'%SOMETHING_CHANGED%'> = ({ actions }) => {
  const stack = actions.getStack();
  const currentActivity = stack.activities.find(({ isActive }) => isActive);

  if (!currentActivity) return;

  checkGroupVisit(currentActivity);
};

const checkGroupVisit = (activity: Activity) => {
  fetchGroupCheckVisit({
    groupId: activity.params?.groupId,
    pathName: activity.name,
  });
};

type Params = {
  groupId?: string;
  pathName?: string;
};
const fetchGroupCheckVisit = async ({ groupId, pathName }: Params, callback?: () => void) => {
  // TODO: fetchGroupCheckVisit가 잘 동작하는지 확인해볼 것
  if (import.meta.env.SSR || pathName === 'TokenSettingPage') return;

  const authToken = new Cookies().get(COOKIE.AUTH_TOKEN);
  const userId = new Cookies().get(COOKIE.USER_ID);
  const regionId = new Cookies().get(COOKIE.REGION_ID);
  const userAgent = new Cookies().get(COOKIE.USER_AGENT);

  if (!authToken || !userId || !regionId || !userAgent) return;

  const fetchInstance = axiosClient.initFetchInstance(
    {
      baseURL: globalThis.appConfig.apiEndPoints.community,
    },
    {
      'X-Auth-Token': authToken,
      'X-User-Id': userId.toString(),
      'X-Region-Id': regionId.toString(),
      'X-User-Agent': userAgent,
    }
  );

  const fetch = groupClient.api.GroupMemberApi.apiV1GroupsCheckVisitGet({ axios: fetchInstance });
  await fetch({ pathName, groupId });

  callback?.();
};
