import { groupClient } from '@community-group/api';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

export const getGroupFeedUpcomingMeetupCount = (groupId: number) =>
  groupClient.api.GroupFeedApi.getapiV1GroupsIdFeedUpcomingMeetupCountGetPath(groupId);

export const useGetGroupFeedUpcomingMeetupCount = (groupId: number) => {
  const fetchInstance = useFetchInstance();
  const get = groupClient.api.GroupFeedApi.apiV1GroupsIdFeedUpcomingMeetupCountGet({
    axios: fetchInstance,
  });

  const { data, refetch } = useSuspenseQuery({
    queryKey: [getGroupFeedUpcomingMeetupCount(groupId)],
    queryFn: () => get({ id: groupId }),
  });
  const response = data?.data;

  return { data: response, refetch };
};
