import { GroupMemberJoinedMeetupPresentation } from '@community-group/api/lib/group/models';
import { Render, Typography } from '@community-group/components';
import {
  IconCheckmarkLine,
  IconExclamationmarkCircleLine,
  IconXmarkLine,
} from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';

import * as s from './style.css';

type Props = Pick<
  GroupMemberJoinedMeetupPresentation,
  'userJoinedStatus' | 'isDeleted' | 'image' | 'title' | 'meetupTimeAtDisplayText'
>;
const DEFAULT_IMAGE =
  'https://assetstorage.krrt.io/1414875091366735739/c36dfa80-e9bf-4de5-9b4d-e2117008e84b/width=100,height=100.png';

const GroupMemberJoinedMeetupItem = ({
  userJoinedStatus,
  isDeleted,
  image,
  title,
  meetupTimeAtDisplayText,
}: Props) => {
  const showDefaultImage = isDeleted || !image?.url;
  return (
    <div className={s.Wrapper}>
      <img
        className={s.Image}
        src={showDefaultImage ? DEFAULT_IMAGE : image?.url}
        alt={'일정 게시글 이미지'}
      />
      <div className={s.Description}>
        <AttendanceTitle isDeleted={isDeleted} title={title} />
        <Typography typography="caption1Regular" color="gray600">
          {meetupTimeAtDisplayText}
        </Typography>
      </div>
      <Render condition={userJoinedStatus !== 'joined'}>
        <Attendance userJoinedStatus={userJoinedStatus} />
      </Render>
    </div>
  );
};

export default GroupMemberJoinedMeetupItem;

const AttendanceTitle = ({ isDeleted, title }: { isDeleted: boolean; title: string }) => {
  if (isDeleted) {
    return (
      <div className={s.DeletedMeetup}>
        <IconExclamationmarkCircleLine size={18} color={vars.$scale.color.gray600} />
        <Typography typography="bodyM1Regular" color="gray600">
          삭제된 일정
        </Typography>
      </div>
    );
  }
  return <Typography typography="bodyM1Regular">{title}</Typography>;
};

const Attendance = ({ userJoinedStatus }: { userJoinedStatus: Props['userJoinedStatus'] }) => {
  return (
    <div className={s.Attendance}>
      {userJoinedStatus === 'attendance' ? (
        <IconCheckmarkLine size={14} color={vars.$scale.color.gray900} />
      ) : (
        <IconXmarkLine size={14} color={vars.$semantic.color.danger} />
      )}
      <Typography
        typography="label3Regular"
        color={userJoinedStatus === 'attendance' ? 'gray900' : 'danger'}
      >
        {userJoinedStatus === 'attendance' ? '출석' : '불참'}
      </Typography>
    </div>
  );
};
