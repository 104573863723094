import { useGetNotifications } from '@/api/hooks/useGetNotifications';
import { usePatchActiveNotification } from '@/api/hooks/usePatchActiveNotification';
import { usePatchNotification } from '@/api/hooks/usePatchNotifications';
import { useReadGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useStorage } from '@/hooks/useStorage';
import { isNotMember } from '@/utils/role';

// 활동 알림 켜기 유도 BottomSheet
function useTurnOnNotificationBottomSheet(groupId: string, onSuccess?: () => void) {
  const { data: currentUserData } = useReadGroupMe({ groupId });
  const { data: notificationState } = useGetNotifications(groupId, currentUserData.role !== 'none');
  const [turnOnNotificationBottomSheet, setTurnOnNotificationBottomSheet] = useStorage(
    'turnOnNotificationBottomSheet',
    {
      closedBottomSheet: false,
    }
  );

  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate: mutateNotification } = usePatchNotification({
    onError: (error) => handleErrorWithToast(error),
    onSuccess: () => {
      onSuccess?.();
    },
  });

  const { mutate: putDaangnActivityNotification } = usePatchActiveNotification({
    onError: handleErrorWithToast,
    onSuccess: () => {
      setTurnOnNotificationBottomSheet({
        closedBottomSheet: false,
      });
      mutateNotification({
        id: Number(groupId),
        groupNotificationSettingForm: {
          daangnGroupNotificationState: 'all_on',
        },
      });
    },
  });

  // BottomSheet 오픈 조건
  const isRenderBottomSheet = () => {
    // 해당 그룹 멤버가 아닌 경우 오픈하지 않음
    if (isNotMember(currentUserData.role)) return false;

    // 조건 1
    // 직전 호이안 알림이 켜져 있었고, 현재 호이안 알림이 꺼져있는 경우 & BottomSheet를 닫은 적이 없을 때 알림 켜기 유도
    if (
      notificationState?.daangnGroupNotificationState === 'all_off' &&
      turnOnNotificationBottomSheet?.closedBottomSheet === false
    ) {
      setTurnOnNotificationBottomSheet({
        closedBottomSheet: true,
      });
      return true;
    }

    // 호이안 알림이 켜져있다면, prevDaangnNotificationState 를 업데이트 & BottomSheet가 다시 열리도록 closedBottomSheet False 반환
    if (notificationState?.daangnGroupNotificationState === 'all_on') {
      setTurnOnNotificationBottomSheet({
        closedBottomSheet: false,
      });
      return false;
    }
    return false;
  };

  return {
    turnOnNotificationBottomSheet,
    setTurnOnNotificationBottomSheet,
    isRenderBottomSheet,
    putDaangnActivityNotification,
  };
}

export default useTurnOnNotificationBottomSheet;
