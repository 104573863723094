import { groupClient } from '@community-group/api';
import { ApiV1GroupsGroupIdChallengesChallengeIdLeftPatchRequestParams } from '@community-group/api/lib/group/api/group-challenge-member-api';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useRead } from '@/shared/api/hooks/useRead';

type Props = UseMutationOptions<
  AxiosResponse<void>,
  Error,
  ApiV1GroupsGroupIdChallengesChallengeIdLeftPatchRequestParams
>;

export const usePatchGroupChallengeLeft = ({ onError, onSuccess, onMutate, onSettled }: Props) => {
  const patch = useRead(
    groupClient.api.GroupChallengeMemberApi.apiV1GroupsGroupIdChallengesChallengeIdLeftPatch
  );

  return useMutation({
    mutationFn: ({
      groupId,
      challengeId,
    }: ApiV1GroupsGroupIdChallengesChallengeIdLeftPatchRequestParams) => {
      return patch({
        groupId: Number(groupId),
        challengeId: Number(challengeId),
      });
    },
    onError,
    onSuccess,
    onMutate,
    onSettled,
  });
};
