import { Callout, CalloutDescription, CalloutTitle, Typography } from '@community-group/components';

import { openLink } from '@/utils/link';

import * as s from './FAQCallout.css';
import { CHALLENGE_PROMOTION_LANDKIT_LINK } from './PromotionBanner/ChallengeListPromotionBanner';

type Props = {
  itemIndex: number;
};

const FAQ = [
  {
    title: 'Q. 이벤트는 언제까지 진행되나요?',
    description: '2025년 2월 3일까지 진행되며, 예산 소진 시 조기종료될 수 있어요.',
  },
  {
    title: 'Q. 당근머니 지급 금액은 어떻게 정해지나요?',
    description: '내용이 풍부한지, 참여한 챌린지와 연관성이 있는지 등을 확인한 뒤 결정돼요.',
  },
  {
    title: 'Q. 당근머니는 항상 받을 수 있나요?',
    description: '당근머니는 1일당 최대 10회까지만 받을 수 있어요.',
  },
];

const FAQCallout = ({ itemIndex }: Props) => {
  const currentFAQ = FAQ[itemIndex];

  const handleMoreButtonClick = () => {
    openLink(CHALLENGE_PROMOTION_LANDKIT_LINK, true);
  };

  return (
    <Callout>
      <CalloutTitle>{currentFAQ.title}</CalloutTitle>
      <br />
      <CalloutDescription>
        <Typography typography="bodyL2Regular" color="gray700">
          {currentFAQ.description}
          <span className={s.MoreButton} onClick={handleMoreButtonClick}>
            자세히 보기
          </span>
        </Typography>
      </CalloutDescription>
    </Callout>
  );
};

export default FAQCallout;
