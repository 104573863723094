import { groupClient } from '@community-group/api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

import { GROUP_MEMBER_QUERY_KEY } from '../queries';

type Props = {
  groupId: string;
  userId: string;
};

export const useQueryGroupMemberGradeApplicationStatus = ({ groupId, userId }: Props) => {
  const read = useAsyncRead(
    groupClient.api.GroupMemberApi.apiV1GroupsIdMembersUserIdGradeApplicationStatusGet
  );
  return {
    queryKey: GROUP_MEMBER_QUERY_KEY.memberGradeApplicationStatus(groupId, userId),
    queryFn: () =>
      read({ id: Number(groupId), userId: Number(userId) }).then((res) => res.isPending),
  };
};

export const useReadGroupMemberGradeApplicationStatus = (props: Props) => {
  const query = useQueryGroupMemberGradeApplicationStatus(props);
  return useSuspenseQuery({ ...query });
};
