import {
  GroupDetailPresentation,
  GroupMemberRoleEnum,
} from '@community-group/api/lib/group/models';
import {
  ActionList,
  ActionListItem,
  ActionSheet,
  AsyncBoundary,
  useBottomSheet,
} from '@community-group/components';
import {
  IconDot3HorizontalChatbubbleLeftLine,
  IconDot3VerticalFill,
  IconHorizline3VerticalLine,
} from '@daangn/react-monochrome-icon';
import { useCallback, useMemo } from 'react';

import { IconShareRegular } from '@/components/common/Icons/shareRegular';
import { INTERNAL_USER_ID_LIST } from '@/constants/user';
import { useBridge } from '@/contexts/Bridge';
import { useUserConfig } from '@/contexts/UserConfig';
import { useReadGroupUnreadChatCount } from '@/domain/GroupDetailShortcutMenu/hooks/useReadGroupUnreadChatCount';
import { useHandleIsNotViewGroup } from '@/hooks/NotViewGroup/useHandleIsNotViewGroup';
import { useFlow, useStepFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';
import { getPermalink, openGroupReport } from '@/utils/link';

import * as s from './GroupDetailAppBarMenu.css';

type Props = {
  group: GroupDetailPresentation;
  currentUserRole: GroupMemberRoleEnum;
  iconColor: string;
};

const GroupDetailAppBarMenu = ({ group, currentUserRole, iconColor }: Props) => {
  const groupId = useMemo(() => group.id.toString(), [group.id]);
  const groupName = useMemo(() => group.name, [group.name]);
  const isMember = useMemo(() => currentUserRole !== 'none', [currentUserRole]);

  const { userConfig } = useUserConfig();
  const { push } = useFlow();

  const { bridge } = useBridge();
  const handleShareMenuClick = () => {
    trackEvent({
      event: 'click_share',
      params: {
        groupId,
        groupName,
        groupCategoryName: group.category.name,
        isGroupCategoryOn: group.isBoardManaged,
        role: currentUserRole,
        version: '2',
      },
      sample: true,
    });
    if (!group.permalink) return;

    bridge.share({
      share: {
        url: getPermalink(group.permalink.id),
        text: `${groupName} 모임 공유하기`,
      },
    });
  };

  const bottomSheet = useBottomSheet();

  const { shownHandleIsNotViewGroupButton, isNotViewGroup, toggleIsNotViewGroup } =
    useHandleIsNotViewGroup({
      groupId,
      groupName,
    });
  const handleMoreMenuClick = () => {
    trackEvent({
      event: 'click_more',
      params: {
        groupId,
        groupName,
        groupCategoryName: group.category.name,
        isGroupCategoryOn: group.isBoardManaged,
        role: currentUserRole,
        version: '2',
      },
      sample: true,
    });
    bottomSheet.open({
      element: (
        <ActionSheet
          bottomButton={{
            label: '닫기',
          }}
        >
          <ActionList>
            {shownHandleIsNotViewGroupButton && (
              <ActionListItem
                onClick={async () => {
                  await bottomSheet.closeAsync();
                  await toggleIsNotViewGroup();
                }}
              >
                {isNotViewGroup ? '이 모임의 글 다시 보기' : '이 모임의 글 보지 않기'}
              </ActionListItem>
            )}
            <ActionListItem
              onClick={() => {
                trackEvent({
                  event: 'click_report',
                  params: {
                    type: 'group',
                  },
                });
                bottomSheet.closeAsync();
                openGroupReport({
                  groupId: String(groupId),
                });
              }}
            >
              신고
            </ActionListItem>
          </ActionList>
        </ActionSheet>
      ),
    });
  };

  const activity = useFlow();
  const handleChatRoomMenuClick = () => {
    trackEvent({
      event: 'click_chat',
      params: {
        groupId,
        groupName,
        groupCategoryName: group.category.name,
        isGroupCategoryOn: group.isBoardManaged,
        role: currentUserRole,
        version: '2',
      },
      sample: true,
    });
    activity.push('GroupDetailChatRoomPage', { groupId });
  };

  const step = useStepFlow('GroupDetailPage');
  const handleSidebarMenuClick = () => {
    trackEvent({
      event: 'click_sidebar',
      params: {
        groupId,
        groupName,
        groupCategoryName: group.category.name,
        isGroupCategoryOn: group.isBoardManaged,
        role: currentUserRole,
        version: '2',
      },
      sample: true,
    });
    step.stepPush({
      groupId,
      openSidebar: 'true',
    });
  };

  const ChattingDefaultButton = useCallback(() => {
    return (
      <button className={s.MenuButton} onClick={handleChatRoomMenuClick}>
        <IconDot3HorizontalChatbubbleLeftLine size={24} color={iconColor} />
      </button>
    );
  }, [handleChatRoomMenuClick]);

  const renderInternalUserGhostBanButton = useCallback(() => {
    const isInternalUser =
      userConfig.userId && INTERNAL_USER_ID_LIST.includes(userConfig.userId.toString());

    if (!isInternalUser) return null;
    const handleReportMenuClick = () => {
      push('BottomSheet/RestrictGroupBottomSheet', {
        groupId: groupId.toString(),
      });
    };

    return <button className={s.InternalReportButton} onClick={handleReportMenuClick} />;
  }, [groupId, push, userConfig.userId]);

  if (!isMember) {
    return (
      <div className={s.ButtonContainer}>
        {renderInternalUserGhostBanButton()}
        <button className={s.MenuButton} onClick={handleShareMenuClick}>
          <IconShareRegular size={24} color={iconColor} />
        </button>
        <button className={s.MenuButton} onClick={handleMoreMenuClick}>
          <IconDot3VerticalFill size={24} color={iconColor} />
        </button>
      </div>
    );
  }

  return (
    <div className={s.ButtonContainer}>
      {renderInternalUserGhostBanButton()}
      <AsyncBoundary
        pendingFallback={<ChattingDefaultButton />}
        rejectedFallback={<ChattingDefaultButton />}
      >
        <ChattingButton groupId={groupId} onClick={handleChatRoomMenuClick} iconColor={iconColor} />
      </AsyncBoundary>
      <button className={s.MenuButton} onClick={handleSidebarMenuClick}>
        <IconHorizline3VerticalLine size={24} color={iconColor} />
      </button>
    </div>
  );
};

export default GroupDetailAppBarMenu;

type ChattingButtonProps = {
  groupId: string;
  onClick: () => void;
  iconColor: string;
};
const ChattingButton = ({ groupId, onClick, iconColor }: ChattingButtonProps) => {
  const { data: unreadChatCount } = useReadGroupUnreadChatCount({
    groupId,
    refetchInterval: 1000 * 60,
  });
  const hasNewTalk = useMemo(() => unreadChatCount > 0, [unreadChatCount]);

  return (
    <button className={s.MenuButton} onClick={onClick}>
      <span className={hasNewTalk ? s.RedDot : undefined}>
        <IconDot3HorizontalChatbubbleLeftLine size={24} color={iconColor} />
      </span>
    </button>
  );
};
