import { useEffect } from 'react';

import { useBridge } from '@/contexts/Bridge';

type Props = {
  pressHandler: () => void;
  enabled?: boolean;
};
export const useSubscribeAndroidBackButtonPress = ({ pressHandler, enabled }: Props) => {
  const { bridge } = useBridge();

  useEffect(() => {
    if (!bridge) return;

    (async () => {
      await bridge.configureAndroidBackButtonPressed({
        ANDROID_ONLY_backButtonEnabled: enabled ?? false,
      });
    })();

    const cleanup = bridge.subscribeAndroidBackButtonPressed({}, pressHandler);

    return () => {
      bridge.configureAndroidBackButtonPressed({
        ANDROID_ONLY_backButtonEnabled: true,
      });
      cleanup();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, pressHandler]);
};
