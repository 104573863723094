import { groupClient } from '@community-group/api';
import { GroupMeetupReviewCreateForm } from '@community-group/api/lib/group/models';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Params = {
  groupMeetupReviewCreateForm: GroupMeetupReviewCreateForm;
};

type Props = {
  groupId: number;
  meetupId: number;
} & UseMutationOptions<AxiosResponse<void>, Error, Params>;

export const usePostGroupMeetupReview = ({ groupId, meetupId, onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const PostGroupMeetupReview =
    groupClient.api.GroupMeetupReviewApi.apiV2GroupsGroupIdMeetupsMeetupIdReviewPost({
      axios: fetchInstance,
    });

  const fetchPostGroupMeetupReview = ({ groupMeetupReviewCreateForm }: Params) => {
    return PostGroupMeetupReview({
      groupId,
      meetupId,
      groupMeetupReviewCreateForm: groupMeetupReviewCreateForm,
    });
  };

  return useThrottleMutation(fetchPostGroupMeetupReview, {
    onError,
    onSuccess,
  });
};
