import { groupClient } from '@community-group/api';
import { GroupMainProfileModifyForm } from '@community-group/api/lib/group/models';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Params = {
  groupMainProfileModifyForm: GroupMainProfileModifyForm;
};
type Props = UseMutationOptions<AxiosResponse<void>, Error, Params>;

export const usePutGroupProfile = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const putGroupProfile = groupClient.api.GroupProfileApi.apiV1GroupsProfilePut({
    axios: fetchInstance,
  });

  const fetchputGroupProfile = ({ groupMainProfileModifyForm }: Params) => {
    return putGroupProfile({
      groupMainProfileModifyForm,
    });
  };

  return useThrottleMutation(fetchputGroupProfile, {
    onError,
    onSuccess,
  });
};
