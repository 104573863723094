import { ViewLoader, withAsyncBoundary } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { Suspense } from 'react';

import GroupProfileDeletedAccount from '@/features/GroupProfile/DeletedAccount';
import GroupProfileMemberJoinedMeetups from '@/features/GroupProfileMemberJoinedMeetups';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';

export type GroupProfileMemberJoinedMeetupPageParams = Pick<PageParams, 'groupId' | 'userId'>;

const GroupProfileMemberJoinedMeetupPage: ActivityComponentType<
  GroupProfileMemberJoinedMeetupPageParams
> = () => {
  const { groupId, userId } = usePathParams();
  return (
    <AppScreen appBar={{ title: '참여한 일정' }}>
      <Suspense fallback={<ViewLoader />}>
        <GroupProfileMemberJoinedMeetups groupId={groupId} userId={userId} />
      </Suspense>
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupProfileMemberJoinedMeetupPage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <GroupProfileDeletedAccount />
    </AppScreen>
  ),
});
