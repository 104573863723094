import { GroupMemberActivityResponse } from '@community-group/api/lib/group/models';
import { Divider, Typography } from '@community-group/components';

import * as s from './style.css';

type Props = {
  userActivityCount: GroupMemberActivityResponse['count'];
};
const JoinedMeetupDashBoard = ({ userActivityCount }: Props) => {
  return (
    <div className={s.MeetupBox}>
      <div className={s.MeetupBoard}>
        <div className={s.MeetupRow}>
          <MeetupBox title="일정 참여" value={userActivityCount.joinedMeetupCount} />
          <Divider direction="vertical" padding={3} />
          <MeetupBox title="출석 완료" value={userActivityCount.attendanceMeetupCount} />
          <Divider direction="vertical" padding={3} />
          <MeetupBox title="불참" value={userActivityCount.noAttendanceMeetupCount} />
        </div>
      </div>
    </div>
  );
};

export default JoinedMeetupDashBoard;
const MeetupBox = ({ title, value }: { title: string; value: number }) => {
  return (
    <div className={s.Meetup}>
      <Typography typography="caption1Regular" color="gray700">
        {title}
      </Typography>
      <Typography typography="subtitle2Bold" color="gray900">
        {value}
      </Typography>
    </div>
  );
};
