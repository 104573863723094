import { IconEnvelopeLine } from '@daangn/react-monochrome-icon';
import clsx from 'clsx';

import IconWrapper from '@/components/common/Icons';
import { useReadGroupUserDirectChatRequests } from '@/domain/GroupDirectChat/hooks/useReadGroupUserDirectChatRequests';
import { useFlow } from '@/stackflow';

import * as s from './style.css';

const MyRequestDirectChatPageAppBarRightButtonV2 = () => {
  const { data: requestGroupDirectChats } = useReadGroupUserDirectChatRequests();
  const hasRequestGroupDirectChats = (requestGroupDirectChats ?? []).length > 0;

  const { push } = useFlow();
  const handleClickRequestDirectChat = () => {
    push('MainProfileMyInfoRequestDirectChatsPage', {});
  };

  return (
    <IconWrapper>
      <div className={s.IconWrapper}>
        <div
          onClick={handleClickRequestDirectChat}
          className={clsx(hasRequestGroupDirectChats && [s.RedDot])}
        >
          <IconEnvelopeLine size={24} />
        </div>
      </div>
    </IconWrapper>
  );
};

export default MyRequestDirectChatPageAppBarRightButtonV2;
