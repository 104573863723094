import { ChallengeCalendarResponse } from '@community-group/api/lib/group/models';
import { Spacing, Typography } from '@community-group/components';
import dayjs from 'dayjs';

import { useGetMyChallengeCalendar } from '@/api/hooks/useGetMyChallengeCalendar';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

import ChallengeCalendarDayItem from './ChallengeCalendarDayItem';
import * as s from './MyChallengeCalendar.css';

const MyChallengeCalendar = () => {
  const { groupId, challengeId } = usePathParams();

  const { data } = useGetMyChallengeCalendar({
    groupId: Number(groupId),
    challengeId: Number(challengeId),
  });

  const { calendarDates, certifyIntervalTargetCount } = data as ChallengeCalendarResponse;

  // 첫 번째 날짜의 요일을 확인하여 필요한 빈 칸의 개수를 계산
  const firstDate = dayjs(calendarDates[0].date);
  const emptySpaces = firstDate.day(); // 0(일요일)부터 6(토요일)
  const emptySpacesArray = Array.from({ length: emptySpaces }, (_, index) => index);

  return (
    <div className={s.CalendarWrapper}>
      <div className={s.WeekdayHeader}>
        {['일', '월', '화', '수', '목', '금', '토'].map((day) => (
          <Typography key={day} typography="caption1Bold" color="gray600" textAlign="center">
            {day}
          </Typography>
        ))}
      </div>
      <Spacing size={10} />
      <div className={s.DaysGrid}>
        {emptySpacesArray && emptySpacesArray.map((_, index) => <div key={index} />)}
        {calendarDates.map((date) => (
          <ChallengeCalendarDayItem
            key={date.date}
            groupId={Number(groupId)}
            date={date}
            certifyIntervalTargetCount={certifyIntervalTargetCount}
          />
        ))}
      </div>
    </div>
  );
};

export default MyChallengeCalendar;
