import { GroupFeedSummary } from '@community-group/api/lib/group/models';
import { useMemo } from 'react';

import { useFlow } from '@/stackflow';

import ChallengeCertifyItem from './ChallengeCertifyItem';
import * as s from './ChallengeCertifyList.css';
import ChallengeCertifyMoreItem from './ChallengeCertifyMoreItem';

type Props = {
  challengeCertifyFeeds: GroupFeedSummary[];
  groupId: number;
  challengeId: number;
};

const ChallengeCertifyList = ({ challengeCertifyFeeds, groupId, challengeId }: Props) => {
  const { push } = useFlow();

  const renderItemList = challengeCertifyFeeds.slice(0, 8);

  const handleMoveToCertifyPost = (postId: number) => {
    push('GroupPostDetailPage', {
      groupId: groupId.toString(),
      postId: postId.toString(),
    });
  };

  const renderMoreButton = useMemo(() => {
    if (challengeCertifyFeeds.length > 8 && challengeCertifyFeeds?.[8]) {
      const handleMoveToCertifyFeeds = () => {
        push('ChallengeCertifyFeedsPage', {
          groupId: groupId.toString(),
          challengeId: challengeId.toString(),
        });
      };

      return (
        <ChallengeCertifyMoreItem
          certifyItem={challengeCertifyFeeds[8]}
          onClick={handleMoveToCertifyFeeds}
        />
      );
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [challengeCertifyFeeds, challengeId, groupId]);

  if (challengeCertifyFeeds.length === 0) return null;

  return (
    <div className={s.Container}>
      {renderItemList.map((item) => (
        <ChallengeCertifyItem key={item.id} certifyItem={item} onClick={handleMoveToCertifyPost} />
      ))}
      {renderMoreButton}
    </div>
  );
};

export default ChallengeCertifyList;
