import { groupClient } from '@community-group/api';
import {
  GroupAnotherMemberModifyForm,
  GroupMemberRoleEnum,
  GroupMemberSummaryPresentation,
} from '@community-group/api/lib/group/models';
import { AxiosInstance } from 'axios';

import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

interface Parmas {
  groupId: string;
  member: GroupMemberSummaryPresentation;
  role: GroupMemberRoleEnum;
}

interface Props {
  onSuccess?: () => void;
  onError?: (error: Error) => void;
}

export const usePatchGroupMembersRole = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();

  const patchGroupMembersRoleFp = ({ groupId, member, role }: Parmas) =>
    patchGroupMembersRole({
      groupId: Number(groupId),
      userId: member.id,
      requestBody: {
        profile: {
          role,
        },
      },
      fetchInstance,
    });

  return useThrottleMutation(patchGroupMembersRoleFp, {
    onError,
    onSuccess,
  });
};

type Params = {
  groupId: number;
  userId: number;
  requestBody: GroupAnotherMemberModifyForm;
  fetchInstance: AxiosInstance;
};
const patchGroupMembersRole = async ({ groupId, userId, requestBody, fetchInstance }: Params) => {
  const { data } = await groupClient.api.GroupMemberApi.apiV1GroupsIdMembersUserIdPatch({
    axios: fetchInstance,
  })({
    id: groupId,
    userId,
    groupAnotherMemberModifyForm: requestBody,
  });

  return data;
};
