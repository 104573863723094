import { GroupMemberRoleEnum } from '@community-group/api/lib/group/models';
import { IconCrownFill } from '@daangn/react-monochrome-icon';
import {
  Avatar as SproutAvatar,
  SeedAvatarProps,
  SeedAvatarSize,
} from '@daangn/sprout-components-avatar';

type Props = {
  src?: string;
  size?: SeedAvatarSize;
  onClick?: () => void;
  role?: GroupMemberRoleEnum;
} & Pick<SeedAvatarProps, Exclude<keyof SeedAvatarProps, 'src' | 'alt' | 'fallback'>>;

const BadgeIcon = ({ size = 'small', role = 'none' }: Props) => {
  const defaultSize = 20;
  const sizeMap = new Map<SeedAvatarSize, number>([
    ['xxsmall', 8],
    ['xsmall', 10],
    ['small', 16],
    ['medium', defaultSize],
    ['large', defaultSize],
    ['xlarge', defaultSize],
  ]);

  return (
    <IconCrownFill size={sizeMap.get(size)} color={role === 'superHost' ? '#f7be68' : '#30c795'} />
  );
};

/**
 * @description GroupAvatar 컴포넌트 내부의 운영진, 모임장 관련 왕관 모양은 더이상 사용되지 않아요.
 * GroupAvatar를 사용하는 곳에서 직접 왕관을 추가해야 돼요.
 */
const GroupAvatar = ({ src = '', size = 'small', onClick, role = 'none', ...props }: Props) => {
  return (
    <div onKeyDown={onClick} onClick={onClick} style={{ display: 'inline' }} role="button">
      <SproutAvatar
        src={src}
        size={size}
        fallback={
          <img
            src="https://dnvefa72aowie.cloudfront.net/origin/profile/profile_default.png"
            alt="profile_default_image"
          />
        }
        alt=""
        {...props}
      >
        {/* {isHigherManager(role) && (
          <AvatarBadge>
            <BadgeIcon size={size} role={role} />
          </AvatarBadge>
        )} */}
      </SproutAvatar>
    </div>
  );
};

export default GroupAvatar;
