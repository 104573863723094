import { groupClient } from '@community-group/api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { GROUP_CHAT_REQUEST_QUERY_KEY } from '@/domain/GroupDirectChat/queries';
import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

export const useQueryGroupUserDirectChatRequests = () => {
  const read = useAsyncRead(
    groupClient.api.GroupDirectChatApi.apiV1UserChatRequestGroupDirectChatsGet
  );
  return {
    queryKey: GROUP_CHAT_REQUEST_QUERY_KEY.base(),
    queryFn: () => read(),
  };
};

export const useReadGroupUserDirectChatRequests = () => {
  const query = useQueryGroupUserDirectChatRequests();
  return useSuspenseQuery({ ...query, select: (data) => data.requestGroupDirectChats });
};
