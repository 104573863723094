import { BoxButton, HorizontalSpacing, Render } from '@community-group/components';
import { IconChevronRightFill } from '@daangn/react-monochrome-icon';
import { useSuspenseQueries } from '@tanstack/react-query';

import GroupMemberJoinedMeetupItem from '@/domain/GroupMember/components/GroupMemberJoinedMeetupItem';
import { useQueryGroupMemberJoinedMeetupThumbnail } from '@/domain/GroupProfile/hooks/useReadGroupMemberJoinedMeetups';
import { useQueryGroupProfileUserActivities } from '@/domain/GroupProfile/hooks/useReadGroupProfileUserActivities';
import JoinedMeetupDashBoard from '@/features/GroupProfileMemberManage/JoinedMeetup/JoinedMeetupDashBoard';
import { useFlow } from '@/stackflow';

import * as s from './style.css';

type Props = {
  groupId: string;
  userId: string;
};
const GroupProfileMemberManageJoinedMeetup = ({ groupId, userId }: Props) => {
  const { push } = useFlow();
  const [{ data: userActivityData }, { data: joinedMeetupThumbnail }] = useSuspenseQueries({
    queries: [
      useQueryGroupProfileUserActivities({ groupId, userId }),
      useQueryGroupMemberJoinedMeetupThumbnail({ groupId, userId, limit: 4 }),
    ],
  });
  const userActivityCount = userActivityData.count;
  if (userActivityCount.joinedMeetupCount === 0) return null;
  return (
    <section>
      <div className={s.Title}>참여한 일정</div>
      <JoinedMeetupDashBoard userActivityCount={userActivityCount} />
      {joinedMeetupThumbnail.slice(0, 3).map((item) => (
        <GroupMemberJoinedMeetupItem key={item.id} {...item} />
      ))}
      <Render condition={userActivityCount.joinedMeetupCount > 3}>
        <div className={s.MoreButtonWrapper}>
          <BoxButton
            size="medium"
            width="100%"
            variant="secondary"
            suffix={<IconChevronRightFill />}
            onClick={() => push('GroupProfileMemberJoinedMeetupPage', { groupId, userId })}
          >
            전체보기
          </BoxButton>
        </div>
      </Render>
      <HorizontalSpacing size={16} />
    </section>
  );
};

export default GroupProfileMemberManageJoinedMeetup;
