import { useReadGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

const useCurrentGroupMe = (groupId?: string) => {
  const { groupId: pathGroupId } = usePathParams();
  const groupIdToUse = groupId || pathGroupId;
  const { data: currentUser } = useReadGroupMe({ groupId: groupIdToUse });

  return currentUser;
};

export default useCurrentGroupMe;
