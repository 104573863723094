import { useMemo } from 'react';

import { useGetParentingGroupKids } from '@/api/hooks/useGetParentingGroupKids';
import { useReadGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { isMember } from '@/utils/role';

import { useGetParentingGroupStorage } from './useGetParentingGroupStorage';

const useParentingGroupKidProfile = (groupId: string) => {
  const { data: currentUser } = useReadGroupMe({ groupId });
  const { isKidProfileEnabled } = useGetParentingGroupStorage(groupId);

  const shouldUseParentingGroupKidProfile = useMemo(
    () => isMember(currentUser.role) && isKidProfileEnabled,
    [currentUser.role, isKidProfileEnabled]
  );

  const { data: parentingGroup } = useGetParentingGroupKids(
    groupId,
    !shouldUseParentingGroupKidProfile // disabled
  );

  return useMemo(() => {
    const isKidsEmpty = (parentingGroup?.kids ?? []).length === 0;

    return shouldUseParentingGroupKidProfile && isKidsEmpty;
  }, [shouldUseParentingGroupKidProfile, parentingGroup?.kids]);
};

export default useParentingGroupKidProfile;
