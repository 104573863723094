import { HorizontalSpacing, Typography } from '@community-group/components';

import * as s from './style.css';

const GrowthColdStartJoinKarrotPayments = () => {
  return (
    <div className={s.Wrapper}>
      <Typography typography="title1Bold">
        당첨된 당근머니를 받으려면
        <br />
        당근페이 가입이 필요해요
      </Typography>
      <HorizontalSpacing size={8} />
      <Typography typography="bodyL1Regular">
        이제 가입만 하면 당첨된 당근머니를 받을 수 있어요.
      </Typography>
      <HorizontalSpacing size={24} />
      <div className={s.Img} style={{ height: '27.3125rem' }}>
        <img
          src={
            'https://asset-town.krrt.io/production/motion/b43d9ec8-7397-4235-91d3-1dc9b423fe5f/eaa375194e90fd0ecba8c9738775cf18c975ede1.png'
          }
          style={{ width: '11.25rem', height: '15.625rem' }}
        />
      </div>
    </div>
  );
};

export default GrowthColdStartJoinKarrotPayments;
