import { useMemo } from 'react';

import { useGetMembersForMention } from '@/api/hooks/useGetMembersForMention';
import { convertNicknameDisplay } from '@/utils/nickname';

export const useMembersForMentions = (groupId: string) => {
  const { data: memberListData } = useGetMembersForMention(groupId);

  const memberList = useMemo(() => memberListData?.data.members ?? [], [memberListData]);

  const membersForMentions = useMemo(
    () =>
      memberList?.map((member) => {
        return {
          id: member.id,
          display: `@${convertNicknameDisplay({
            nickname: member.nickname,
            subNickname: member.subNickname,
          })}`,
          imageSrc: member.profileImage,
        };
      }) || [],
    [memberList]
  );

  return membersForMentions;
};
