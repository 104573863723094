import { groupClient } from '@community-group/api';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useFetchInstance } from './instance/useFetchInstance';

type Params = {
  groupId: string;
  meetupId: string;
};

type Props = UseMutationOptions<AxiosResponse, Error, Params>;

export const useDeleteGroupMeetupLike = ({ onError, onSuccess, onMutate, onSettled }: Props) => {
  const fetchInstance = useFetchInstance();
  const deleteGroupMeetupLike =
    groupClient.api.MeetupApi.apiV1GroupsGroupIdMeetupsMeetupIdLikeDelete({ axios: fetchInstance });

  const deleteGroupMeetupLikeWrapperFp = ({ groupId, meetupId }: Params) => {
    return deleteGroupMeetupLike({ groupId: Number(groupId), meetupId: Number(meetupId) });
  };

  return useMutation({
    mutationFn: deleteGroupMeetupLikeWrapperFp,
    onError,
    onSuccess,
    onMutate,
    onSettled,
  });
};
