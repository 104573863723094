import { Spacing, Typography, ViewLoader, withAsyncBoundary } from '@community-group/components';
import {
  IconCheckmarkFlowerFill,
  IconCrownFill,
  IconLeafFill,
} from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { useMemo } from 'react';

import { useReadGroupMemberGradeRequirements } from '@/domain/GroupMember/hooks/useReadGroupMemberGradeRequirements';

import * as s from './style.css';

type Props = {
  groupId: string;
};

const activityMap = {
  visit_group: {
    label: '모임 방문',
    order: 1,
  },
  create_post: {
    label: '게시글 작성',
    order: 2,
  },
  create_comment: {
    label: '댓글 작성',
    order: 3,
  },
  join_meetup: {
    label: '일정 참여',
    order: 4,
  },
};

const MemberGradeSpecSection = ({ groupId }: Props) => {
  const { data: requirements } = useReadGroupMemberGradeRequirements(groupId);
  const requirementDescription = useMemo(() => {
    const requirementText = requirements
      .map((requirement) => ({
        ...requirement,
        ...activityMap[requirement.activity],
      }))
      .sort((a, b) => a.order - b.order)
      .filter((requirement) => requirement.goalCount > 0)
      .map((requirement) => `${requirement.label} <b>${requirement.goalCount}회</b>`)
      .join(', ');

    return `${requirementText}를 달성하면 등업을 신청할 수 있고, 모임장의 승인 후 등급이 올라가요.`;
  }, [requirements]);

  return (
    <div className={s.Container}>
      <Typography typography="title2Bold" color="gray900">
        전체 멤버 등급
      </Typography>
      <Spacing size={32} />
      <div>
        <div className={s.TitleWrapper}>
          <IconLeafFill size={16} color={vars.$scale.color.gray600} />
          <Typography typography="subtitle1Bold" color="gray700">
            준회원
          </Typography>
        </div>
        <Spacing size={12} />
        <Typography typography="bodyL1Regular" color="gray700">
          모임에 가입한 멤버예요.
        </Typography>
      </div>
      <Spacing size={32} />
      <div>
        <div className={s.TitleWrapper}>
          <IconCheckmarkFlowerFill size={16} color={vars.$scale.color.gray600} />
          <Typography typography="subtitle1Bold" color="gray700">
            정회원
          </Typography>
        </div>
        <Spacing size={12} />
        <Typography
          typography="bodyL1Regular"
          color="gray700"
          dangerouslySetInnerHTML={{ __html: requirementDescription }}
        />
      </div>
      <Spacing size={32} />
      <div>
        <div className={s.TitleWrapper}>
          <IconCrownFill size={16} color="#30C795" />
          <Typography typography="subtitle1Bold" color="gray700">
            운영진
          </Typography>
        </div>
        <Spacing size={12} />
        <Typography typography="bodyL1Regular" color="gray700">
          모임장을 도와 모임 운영을 돕는 멤버로 모임장이 직접 설정할 수 있어요.
        </Typography>
      </div>
    </div>
  );
};

export default withAsyncBoundary(MemberGradeSpecSection, {
  pendingFallback: <ViewLoader />,
  rejectedFallback: <></>,
});
