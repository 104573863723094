import { groupClient } from '@community-group/api';
import { model } from '@community-group/api/lib/group';
import { PostModifyForm, PostTypePresentation } from '@community-group/api/lib/group/models';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Props = UseMutationOptions<
  AxiosResponse<model.PostResponse>,
  Error,
  {
    id?: number;
    postId?: number;
    postModifyForm: PostModifyForm;
    postType?: PostTypePresentation;
  }
>;

export const usePutEditPost = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const putEditPost = groupClient.api.PostApi.apiV1GroupsIdPostsPostIdPut({ axios: fetchInstance });

  const putEditPostWrapperFp = ({
    id,
    postId,
    postModifyForm,
  }: {
    id?: number;
    postId?: number;
    postModifyForm: PostModifyForm;
  }) => {
    return putEditPost({
      id: Number(id),
      postId: Number(postId),
      postModifyForm,
    });
  };

  return useThrottleMutation(putEditPostWrapperFp, {
    onError,
    onSuccess,
  });
};
