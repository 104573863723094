import {
  ActionableCallout,
  CalloutDescription,
  CalloutTitle,
  Divider,
  getDateDiffFromNow,
  isHigherManager,
  isMember,
  Render,
  Typography,
} from '@community-group/components';
import { IconEyeFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { useSuspenseQueries } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { useQueryGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useHandleMeetupReviews } from '@/domain/GroupProfile/hooks/useHandleMeetupReviews';
import { useQueryGroupMemberMeetupReviews } from '@/domain/GroupProfile/hooks/useReadGroupMemberMeetupReviews';
import { useQueryGroupProfileUserActivities } from '@/domain/GroupProfile/hooks/useReadGroupProfileUserActivities';
import ActivityBox from '@/features/GroupProfile/UserActivities/ActivityBox';
import ReviewActionCallout from '@/features/GroupProfile/UserActivities/ReviewActionCallout';
import { useFlow } from '@/stackflow';

import * as s from './style.css';

type Props = {
  userId: string;
  groupId: string;
};

const GroupProfileUserActivities = ({ userId, groupId }: Props) => {
  const [{ data: me }, { data: userActivitiesData }, { data: userMeetupReviews }] =
    useSuspenseQueries({
      queries: [
        useQueryGroupMe({ groupId }),
        useQueryGroupProfileUserActivities({ groupId, userId }),
        useQueryGroupMemberMeetupReviews({ groupId, userId }),
      ],
    });

  const isMyProfile = String(me.id) === userId;
  const { meetupReviews, meetupReviewMessages } = userMeetupReviews;
  const { activities } = userActivitiesData;

  const { hasMeetupMemberReviews, thumbnailReviewMessage, reviewCount } = useHandleMeetupReviews(
    isMyProfile,
    meetupReviews,
    meetupReviewMessages
  );

  if (!isMember(me.role)) return null;

  return (
    <div className={s.Wrapper}>
      <Typography typography="title3Bold">모임 활동</Typography>
      <Render condition={isHigherManager(me.role)}>
        <HigherManagerCallout groupId={groupId} userId={userId} />
      </Render>
      <div className={s.ActivitiesWrapper}>
        <div className={s.ActivityBoxWrapper}>
          <ActivityBox title="가입일" value={dayjs(activities.joinedAt).format('YY.MM.DD')} />
          <Render condition={isMyProfile || isHigherManager(me.role)}>
            <Divider direction="vertical" />
            <ActivityBox
              title="최근 방문"
              value={`${getDateDiffFromNow(new Date(activities.lastVisitedAt)).text} 전`}
            />
          </Render>
          <Divider direction="vertical" />
          <ActivityBox title="모임 방문" value={String(activities.groupVisitCount)} />
        </div>
        <Render condition={hasMeetupMemberReviews}>
          <Divider padding={16} />
          <ReviewActionCallout
            reviewCount={reviewCount}
            content={thumbnailReviewMessage}
            userId={userId}
            groupId={groupId}
          />
        </Render>
      </div>
    </div>
  );
};

export default GroupProfileUserActivities;

const HigherManagerCallout = ({ groupId, userId }: { groupId: string; userId: string }) => {
  const { push } = useFlow();
  return (
    <ActionableCallout
      variant="info"
      UNSAFE_style={{ width: '100%' }}
      onClick={() => push('GroupProfileMemberManagePage', { groupId, userId })}
    >
      <CalloutTitle>멤버 관리</CalloutTitle>
      <CalloutDescription>
        <IconEyeFill size={12} color={vars.$scale.color.gray600} />
        <Typography typography="caption1Regular" color="gray600" style={{ marginLeft: '0.25rem' }}>
          모임장, 운영진
        </Typography>
      </CalloutDescription>
      <div style={{ width: '100%' }}>
        <Typography typography="caption1Regular" color="blue950">
          이 멤버의 자세한 활동 내역을 확인해보세요.
        </Typography>
      </div>
    </ActionableCallout>
  );
};
