import { groupClient } from '@community-group/api';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

type Params = {
  groupId: number;
};

export const getGroupLevelPath = (groupId) =>
  groupClient.api.GroupLevelApi.getapiV1GroupsGroupIdLevelGetPath(groupId);

/** @deprecated useReadGroupLevel 을 사용하세요 */
export const useGetGroupLevel = ({ groupId }: Params) => {
  const fetchInstance = useFetchInstance();

  const getGroupLevel = groupClient.api.GroupLevelApi.apiV1GroupsGroupIdLevelGet({
    axios: fetchInstance,
  });

  const { data: response, ...rest } = useSuspenseQuery({
    queryKey: [getGroupLevelPath(groupId)],
    queryFn: () => getGroupLevel({ groupId }),
    refetchInterval: 1000 * 60 * 5, // every 5min
    // 모임장이 모임 상세에 진입해 있을때 level이 갑자기 업데이트 되는 것을 방지하기 위해 refetch interval을 늘림
  });

  const data = response?.data;
  return {
    data,
    ...rest,
  };
};
