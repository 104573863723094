import { groupClient } from '@community-group/api';
import { ApiV1GroupsGroupIdChallengesChallengeIdChallengeCertifyPostFeedsGetRequestParams } from '@community-group/api/lib/group/api/group-challenge-api';
import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import { useRead } from '@/shared/api/hooks/useRead';

import { GROUP_CHALLENGE_QUERY_KEY } from '../queries';

export const useReadGroupChallengeCertifyFeeds = ({
  groupId,
  challengeId,
  cursor = undefined,
  limit = 20,
}: ApiV1GroupsGroupIdChallengesChallengeIdChallengeCertifyPostFeedsGetRequestParams) => {
  const read = useRead(
    groupClient.api.GroupChallengeApi
      .apiV1GroupsGroupIdChallengesChallengeIdChallengeCertifyPostFeedsGet
  );

  return useSuspenseInfiniteQuery({
    queryKey: GROUP_CHALLENGE_QUERY_KEY.feeds(groupId.toString(), challengeId.toString()),
    queryFn: async ({ pageParam = cursor }) => {
      const response = await read({
        groupId: Number(groupId),
        challengeId: Number(challengeId),
        cursor: pageParam,
        limit,
      });
      return {
        ...response,
        data: {
          items: response.data.items,
          hasNext: response.data.hasNext,
          endCursor: response.data.endCursor ?? '',
        },
      };
    },
    initialPageParam: cursor,
    getNextPageParam: (lastPage) => (lastPage.data.hasNext ? lastPage.data.endCursor : undefined),
    select: (data) => data.pages.flatMap((page) => page.data.items),
  });
};
