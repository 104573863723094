import { isMember } from '@community-group/components';
import { IconEnvelopeLine } from '@daangn/react-monochrome-icon';
import { useSuspenseQueries } from '@tanstack/react-query';
import { Suspense, useCallback, useMemo } from 'react';

import { useQueryGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useQueryGroupHostMembers } from '@/domain/GroupMember/hooks/useReadGroupHostMembers';
import { useQueryGroupProfileUserInfo } from '@/domain/GroupProfile/hooks/useReadGroupProfileUserInfo';
import GroupMemberProfileAppBarRightButton from '@/features/GroupProfile/AppScreen/RenderRight/GroupMemberProfileAppBarRightButton';
import MyRequestDirectChatPageAppBarRightButtonV2 from '@/features/GroupProfile/AppScreen/RenderRight/MyRequestDirectChatPageAppBarRightButtonV2';

type Props = {
  groupId: string;
  userId: string;
};
export const GroupProfileAppScreenRenderRight = ({ groupId, userId }: Props) => {
  const [{ data: profile }, { data: me }, { data: hostMembersData }] = useSuspenseQueries({
    queries: [
      useQueryGroupProfileUserInfo({ groupId, userId }),
      useQueryGroupMe({ groupId }),
      useQueryGroupHostMembers({ groupId, shownOnlyHost: true, limit: 30 }),
    ],
  });

  const isMyProfile = useMemo(() => profile.id === me.id, [profile.id, me.id]);

  const GroupProfileAppBarRightButtonList = useCallback(() => {
    if (isMyProfile)
      return (
        <Suspense fallback={<IconEnvelopeLine size={24} />}>
          <MyRequestDirectChatPageAppBarRightButtonV2 />
        </Suspense>
      );

    if (isMember(profile.role))
      return (
        <GroupMemberProfileAppBarRightButton
          groupId={groupId}
          profile={profile}
          currentUser={me}
          hostMemberList={hostMembersData.members}
        />
      );

    return <></>;
  }, [groupId, isMyProfile, profile, me, hostMembersData]);

  return <GroupProfileAppBarRightButtonList />;
};
