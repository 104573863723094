import { Typography } from '@community-group/components';

import * as s from './style.css';

const GroupProfileDeletedAccount = () => {
  return (
    <div className={s.DeletedAccountProfileWrapper}>
      <Typography typography="subtitle1Regular" color="gray600">
        앗! 죄송해요.
        <br />
        존재하지 않는 사용자예요.
      </Typography>
    </div>
  );
};

export default GroupProfileDeletedAccount;
