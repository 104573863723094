import { useMemo } from 'react';

import { useGetCheckOpenRegion } from './../api/hooks/useGetCheckOpenRegion';
import { useGetMe } from './../api/hooks/useGetMe';

export const useCurrentRegionCheckOpend = () => {
  const { data: userInfo, refetch: refetchUserInfo, isFetching: isFetchingUserInfo } = useGetMe();

  const {
    data,
    isFetching,
    refetch: refetchCheckOpenRegion,
  } = useGetCheckOpenRegion(userInfo.regionCheckIns.regionId);
  const regionCertified = useMemo(
    () => userInfo.regionCheckIns.certified,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFetching, userInfo.regionCheckIns.certified]
  );
  const refetch = () => {
    refetchUserInfo();
    refetchCheckOpenRegion();
  };

  return {
    data,
    userInfo,
    isOpenRegion: data?.data?.isOpenRegion,
    regionCertified,
    refetch,
    isFetching: isFetching || isFetchingUserInfo,
  };
};
