import { GroupCurrentUser } from '@community-group/api/lib/group/models';
import {
  GroupAvatar,
  HorizontalSpacing,
  Typography,
  VerticalSpacing,
} from '@community-group/components';
import { ReactNode, Suspense } from 'react';

import { GroupMainProfile } from '@/api/hooks/useGetGroupMainProfile';
import ActivityBox from '@/components/group/profile/GroupProfile/Detail/components/ActivityBox';
import { getUserProfileUrlInduceExperiment } from '@/utils/analytics/experiment/experiments/userProfileInduceExperiment';

import ProfileGradeBadge from '../ProfileGradeBadge';
import * as s from './Information.css';

export type InformationCoreProps = {
  profile?: Pick<
    GroupMainProfile,
    | 'id'
    | 'profileImage'
    | 'nickname'
    | 'subNickname'
    | 'temperature'
    | 'description'
    | 'role'
    | 'joinedAt'
    | 'userRegion'
    | 'userRegionCheckInCount'
    | 'verifiedUserInfoText'
    | 'state'
    | 'activities'
    | 'snsTags'
    | 'grade'
  > & {
    verified?: boolean;
  };
  isModal?: boolean;
  moreInformationRender?: ReactNode;
  currentUser?: Pick<GroupCurrentUser, 'id' | 'role' | 'grade' | 'state'> & { verified?: boolean };
  onClickProfileImage?: () => void;
  groupId?: string;
};

const InformationCore = ({
  profile,
  isModal,
  moreInformationRender,
  currentUser,
  onClickProfileImage,
  groupId,
}: InformationCoreProps) => {
  const profileImage = getUserProfileUrlInduceExperiment({
    groupId: groupId ? parseInt(groupId) : 0,
    userId: currentUser?.id ?? 0,
    defaultUrl: profile?.profileImage ?? '',
  });

  return (
    <div>
      <div className={s.top}>
        <div className={s.leftBox} onClick={onClickProfileImage}>
          <GroupAvatar size="large" src={profileImage} role={profile?.role}></GroupAvatar>
        </div>
        <div className={s.rightBox}>
          <div className={s.topContentWrapper}>
            <div
              className={s.titleWrapper}
              style={{ marginBottom: moreInformationRender ? '0.125rem' : '0' }}
            >
              <Typography className={s.name} typography="title3Bold" color="gray900">
                {profile?.nickname}
              </Typography>
              <VerticalSpacing size={4} />
              {groupId && profile?.grade && profile.grade.role !== 'none' && (
                <Suspense fallback={null}>
                  <ProfileGradeBadge groupId={groupId} grade={profile.grade} />
                </Suspense>
              )}
            </div>
            <HorizontalSpacing size={2} />
            <Typography color="gray900" typography="subtitle2Regular">
              {profile?.subNickname}
            </Typography>
          </div>
          {moreInformationRender}
        </div>
      </div>
      <HorizontalSpacing size={16} />
      {profile?.activities && (
        <>
          <div className={s.activityWrapper}>
            <ActivityBox title="모임 방문" count={profile?.activities.groupVisitCount} />
            <div className={s.divider} />
            <ActivityBox title="게시글" count={profile?.activities.entirePostCount} />
            <div className={s.divider} />
            <ActivityBox title="댓글" count={profile?.activities.entireCommentCount} />
            <div className={s.divider} />
            <ActivityBox title="일정 참여" count={profile?.activities.entireMeetupCount} />
          </div>
          <HorizontalSpacing size={16} />
        </>
      )}
      {profile?.description && (
        <>
          <Typography
            color="gray900"
            typography="bodyM1Regular"
            whiteSpace="pre-wrap"
            style={{
              lineHeight: '1.625rem',
              maxHeight: isModal ? '150px' : 'auto',
            }}
          >
            {profile?.description.trim()}
          </Typography>
          <HorizontalSpacing size={profile?.snsTags && profile.snsTags.length > 0 ? 8 : 16} />
        </>
      )}
    </div>
  );
};

export default InformationCore;
