import { vars } from '@seed-design/design-token';

interface Props {
  fill?: 'white' | 'dark' | 'gray';
  size?: number;
}

const IconFacebook = ({ size = 16, fill = 'dark' }: Props) => {
  const fillColor = {
    white: vars.$scale.color.gray00,
    gray: vars.$scale.color.gray600,
    dark: vars.$scale.color.gray900,
  }[fill];

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14.6666 8.04028C14.6666 4.33509 11.6829 1.33337 7.99998 1.33337C4.31702 1.33337 1.33331 4.33509 1.33331 8.04028C1.33331 11.3878 3.77183 14.1629 6.95702 14.6667V9.97783H5.2622V8.04028H6.95702V6.56178C6.95702 4.88058 7.95257 3.95354 9.47554 3.95354C10.2044 3.95354 10.9689 4.0847 10.9689 4.0847V5.73311H10.1274C9.30072 5.73311 9.03998 6.24879 9.03998 6.77938V8.0373H10.8889L10.5926 9.97485H9.03998V14.6607C12.2281 14.16 14.6666 11.3878 14.6666 8.04028Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default IconFacebook;
