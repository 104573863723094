import { Typography } from '@community-group/components';
import { useEffect } from 'react';

import { LottieURL } from '@/components/common/Lottie/LottieURL';

import * as s from './GrowthColdStartJoinSuccess.css';

/**
 *  25.01 챌린지 프로모션 그로스 이벤트입니다.
 *  이후 관련 내용을 삭제해주세요.
 */

type Props = {
  points?: number;
};
const GrowthColdStartJoinSuccess = ({ points }: Props) => {
  return (
    <div className={s.Wrapper}>
      <div className={s.Lottie}>
        <LottieURL
          loop={false}
          src="https://asset-town.krrt.io/production/motion/5f967149-d9c4-4bdd-bf06-dce254595201/569f30b5d87298ad240aa639e564b720390c7a54.json"
        />
      </div>
      <div className={s.TextBox}>
        <Typography typography="title1Bold" textAlign="center">
          당근페이 가입 완료
          {points ? (
            <>
              <br />
              <Typography typography="title1Bold" textAlign="center">
                {points.toLocaleString()}원 받았어요!
              </Typography>
            </>
          ) : null}
        </Typography>

        <Typography typography="bodyM1Regular" textAlign="center">
          당첨된 당근머니는
          <br />
          ‘나의 당근’ 탭에서 확인할 수 있어요.
        </Typography>
      </div>
    </div>
  );
};

export default GrowthColdStartJoinSuccess;
