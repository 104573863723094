import { ChallengeCalendarDatePresentation } from '@community-group/api/lib/group/models';
import { resizeImage, Typography } from '@community-group/components';
import { IconXmarkFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import dayjs from 'dayjs';

import { useFlow } from '@/stackflow';

import * as s from './ChallengeCalendarDayItem.css';

interface CalendarDayProps {
  groupId: number;
  date: ChallengeCalendarDatePresentation;
  certifyIntervalTargetCount: number;
}

const ChallengeCalendarDayItem = ({
  groupId,
  date,
  certifyIntervalTargetCount,
}: CalendarDayProps) => {
  const { push } = useFlow();
  const day = dayjs(date.date).format('D');
  const hasCertified = !!date.certifiedInfo;

  const textColor = hasCertified ? 'onPrimary' : 'gray700';
  const textTypography =
    hasCertified || date.dateType === 'today' ? 'subtitle2Bold' : 'subtitle2Regular';

  const isCertifyAllDaysInAWeek =
    certifyIntervalTargetCount === 7 || certifyIntervalTargetCount === 0;

  if (!hasCertified && isCertifyAllDaysInAWeek && date.dateType === 'past') {
    return <FailedChallengeCertifiedDay />;
  }

  const handleCalendarItemClick = () => {
    if (!date.certifiedInfo?.postId) return;

    push('GroupPostDetailPage', {
      groupId: groupId.toString(),
      postId: date.certifiedInfo.postId.toString(),
    });
  };

  const resizeImageUrl = date.certifiedInfo?.image?.url
    ? resizeImage(date.certifiedInfo.image.url, {
        size: `${date.certifiedInfo.image?.width}x${date.certifiedInfo.image?.height}`,
        quality: 'medium',
        type: 'inside',
      })
    : '';

  return (
    <div
      onClick={handleCalendarItemClick}
      className={s.CalendarDay({
        dateType: date.dateType,
      })}
      style={
        hasCertified
          ? {
              background: `linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), url(${resizeImageUrl}) lightgray 50% / cover no-repeat`,
            }
          : {}
      }
    >
      <Typography className={s.DayText} typography={textTypography} color={textColor}>
        {day}
      </Typography>
      {hasCertified && <div className={s.CertifiedImageBorder} />}
    </div>
  );
};

export default ChallengeCalendarDayItem;

// 실패 한 과거 챌린지 인증 날짜 아이템
const FailedChallengeCertifiedDay = () => {
  return (
    <div className={s.FailedChallengeCertifiedDay}>
      <IconXmarkFill size={14} color={vars.$scale.color.gray500} />
    </div>
  );
};
