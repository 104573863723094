import { groupClient } from '@community-group/api';
import { GroupMemberGradeRequirementUpdateForm } from '@community-group/api/lib/group/models';

import { useRead } from '@/shared/api/hooks/useRead';
import {
  useThrottleMutation,
  UseThrottleMutationOption,
} from '@/shared/api/hooks/useThrottleMutation';

type Params = {
  groupId: string;
} & GroupMemberGradeRequirementUpdateForm;

type Props = UseThrottleMutationOption;

export const usePutGroupMemberGradeRequirement = (props: Props) => {
  const put = useRead(groupClient.api.GroupMemberApi.apiV1GroupsIdMembersGradeRequirementsPut);
  const mutation = ({ groupId, ...form }: Params) =>
    put({
      id: Number(groupId),
      groupMemberGradeRequirementUpdateForm: form,
    });

  return useThrottleMutation(mutation, props);
};
