import { model } from '@community-group/api/lib/group';
import {
  GroupAvatar,
  isHigherManager,
  Spacing,
  Typography,
  VerticalSpacing,
} from '@community-group/components';
import { IconCrownFill } from '@daangn/react-monochrome-icon';
import React from 'react';

import * as s from './index.css';

type Props = {
  profileImage: string;
  nickname: string;
  onClick?: () => void;
  isDeletedAccount?: boolean;
  role: model.GroupMemberRoleEnum;
  children: React.ReactNode;
  subNickname?: string;
};

const ContentAuthorProfile = ({
  profileImage,
  nickname,
  onClick,
  isDeletedAccount,
  role,
  children,
  subNickname,
}: Props) => {
  return (
    <div className={s.wrapper} onClick={onClick} aria-hidden="true">
      <GroupAvatar src={profileImage} role={role} />
      <VerticalSpacing size={12} />
      <div className={s.container}>
        <div className={s.Nickname}>
          <Typography typography="caption1Bold" color={isDeletedAccount ? 'gray500' : 'gray900'}>
            {nickname}
          </Typography>
          {isHigherManager(role) && (
            <IconCrownFill size={14} color={role === 'superHost' ? '#f7be68' : '#30c795'} />
          )}
          {subNickname && (
            <Typography
              typography="caption1Bold"
              color={isDeletedAccount ? 'gray500' : 'gray900'}
              ellipsisAfterLines={1}
            >{`(${subNickname})`}</Typography>
          )}
        </div>
        <Spacing size={2} />
        <div>{children}</div>
      </div>
    </div>
  );
};

export default ContentAuthorProfile;
