import { vars } from '@seed-design/design-token';
import React, { PropsWithChildren } from 'react';

import { ColorValue, Typography, TypographyValue } from '../base';
import * as s from './style.css';

type Props = PropsWithChildren<{
  prefix?: string;
  typography?: TypographyValue;
  color?: ColorValue;
  bulletIndent?: string;
  gap?: string;
}>;

export const BulletItem = ({
  prefix = '∙',
  bulletIndent = '0.3rem',
  gap = '0.3rem',
  color = 'gray900',
  typography = 'caption1Regular',
  children,
}: Props) => (
  <div className={s.ListItem}>
    <Typography
      typography={typography}
      color={color}
      style={{ marginLeft: bulletIndent, marginRight: gap }}
    >
      {prefix}
    </Typography>
    <Typography typography={typography} color={color}>
      {children}
    </Typography>
  </div>
);

type BulletListProps = {
  children: React.ReactElement<typeof BulletItem>[] | React.ReactElement<typeof BulletItem>;
} & Props;

export const BulletList = ({
  color = 'gray900',
  typography = 'caption1Regular',
  bulletIndent = '0.3rem', // default값 추가
  gap = '0.3rem',
  children,
}: BulletListProps) => {
  const typo = vars.$semantic.typography[typography];

  return (
    <div style={{ color, ...typo }}>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            color,
            typography,
            bulletIndent,
            gap,
            ...child.props, // 개별 ListItem에서 설정한 props가 있다면 그것을 우선시
          });
        }
        return child;
      })}
    </div>
  );
};
