import { BoxButton } from '@community-group/components';
import { openPayHome } from '@karrotpay/account-sdk';

import { useBridge } from '@/contexts/Bridge';

import * as s from './style.css';

const GrowthColdStartReceivePrizeAccessoryBar = () => {
  const { bridge } = useBridge();

  const handleClickButton = () => {
    openPayHome({
      stage: globalThis.appConfig.appEnv,
      bridge: bridge as any,
      params: { from: 'community_group_challenge_promotion_2501' },
    });
  };

  return (
    <div
      className={s.Wrapper}
      style={{ paddingBottom: 'calc(0.5rem + env(safe-area-inset-bottom))' }}
    >
      <BoxButton width="100%" size="xlarge" onClick={handleClickButton}>
        받은 내역 보기
      </BoxButton>
    </div>
  );
};

export default GrowthColdStartReceivePrizeAccessoryBar;
