import { withAsyncBoundary } from '@community-group/components';
import { UseActionsOutputType } from '@stackflow/react';
import React from 'react';

import { useGetGroupMainProfile } from '@/api/hooks/useGetGroupMainProfile';
import { TypeActivities } from '@/stackflow';

import { GroupDirectPayChatStatusOnAwaitingApprovalActionListItem } from './DirectPayChatStatusActionListItem/GroupDirectPayChatStatusOnAwaitingApprovalActionListItem';
import { GroupDirectPayChatStatusOnEnabledActionListItem } from './DirectPayChatStatusActionListItem/GroupDirectPayChatStatusOnEnabledActionListItem';
import { GroupDirectPayChatStatusOnRequiredApprovalActionListItem } from './DirectPayChatStatusActionListItem/GroupDirectPayChatStatusOnRequiredApprovalActionListItem';

type Props = {
  groupId: number;
  targetUserId: number;
  actionFlowPush: UseActionsOutputType<TypeActivities>['push'];
};

const GroupPayChatActionListItem = ({ groupId, targetUserId, actionFlowPush }: Props) => {
  const { data } = useGetGroupMainProfile({
    userId: targetUserId,
    groupId,
  });

  const { directPayChatEnabledStatus, nickname, requestGroupDirectChatId } = data;

  const PayChatButtons = React.useCallback(() => {
    if (directPayChatEnabledStatus === 'required_approval') {
      return (
        <GroupDirectPayChatStatusOnRequiredApprovalActionListItem
          groupId={groupId}
          targetUserId={targetUserId}
          targetUserName={nickname}
          actionFlowPush={actionFlowPush}
        />
      );
    }
    if (directPayChatEnabledStatus === 'awaiting_approval' && !!requestGroupDirectChatId) {
      return <GroupDirectPayChatStatusOnAwaitingApprovalActionListItem groupId={groupId} />;
    }
    return (
      <GroupDirectPayChatStatusOnEnabledActionListItem
        groupId={groupId}
        targetUserId={targetUserId}
      />
    );
  }, [
    actionFlowPush,
    directPayChatEnabledStatus,
    groupId,
    nickname,
    requestGroupDirectChatId,
    targetUserId,
  ]);

  return PayChatButtons();
};

export default withAsyncBoundary(GroupPayChatActionListItem, {});
