import clsx from 'clsx';
import React from 'react';

import * as s from './ImageWithBorder.css';

type Props = {
  src: string;
  alt?: string;

  width?: number;
  height?: number;

  className?: string;

  border?: string;
  borderRadius?: string;
};

const ImageWithBorder = ({ src, alt, className, border, width, height, borderRadius }: Props) => {
  return (
    <div
      className={clsx(s.Container, className)}
      style={{ width, height, borderRadius, minWidth: width, minHeight: height }}
    >
      <div className={s.BorderContainer} style={{ border, borderRadius }} />
      <img className={s.Image} src={src} alt={alt} style={{ width, height, borderRadius }} />
    </div>
  );
};

export { ImageWithBorder };
