import { GroupFeedSummary, Image } from '@community-group/api/lib/group/models';
import {
  getDateDiffFromNow,
  ImageWithBorder,
  Spacing,
  Typography,
} from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { useMemo } from 'react';

import * as s from './ChallengeCertifyItem.css';

type Props = {
  certifyItem: GroupFeedSummary;
  onClick: (postId: number) => void;
};

const ChallengeCertifyItem = ({ certifyItem, onClick }: Props) => {
  const imageUrl = useMemo(() => {
    const images = certifyItem.medias.filter((media) => media.type === 'image');

    const imageObject = images[0]?.media as Image;

    return imageObject.medium;
  }, [certifyItem.medias]);

  const createdAtText =
    getDateDiffFromNow(new Date(Date.parse(certifyItem.createdAt))).text + ' 전';

  const regexForMarkup = /\[@(.*)\]\{.*\}/g;

  return (
    <div className={s.Container} onClick={() => onClick(certifyItem.id)}>
      <ImageWithBorder
        className={s.ImageWrapper}
        src={imageUrl}
        alt={certifyItem.challengeInfo?.name}
        borderRadius="0.5rem"
        border={`1px solid ${vars.$scale.color.grayAlpha50}`}
        width={118}
        height={118}
      />
      <Spacing size={8} />
      <div className={s.ProfileWrapper}>
        <ImageWithBorder
          src={certifyItem.author.profileImage}
          border={`1px solid ${vars.$scale.color.grayAlpha50}`}
          width={20}
          height={20}
          borderRadius="10000px"
        />
        <Spacing size={6} direction="vertical" />
        <Typography typography="caption1Regular" ellipsisAfterLines={1}>
          {certifyItem.author.nickname}
        </Typography>
      </div>
      <Spacing size={6} />
      <Typography typography="subtitle2Regular" ellipsisAfterLines={2}>
        {certifyItem.content.replaceAll(regexForMarkup, '@$1').replaceAll(/\n/g, ' ')}
      </Typography>
      <Spacing size={6} />
      <Typography typography="caption2Regular" color="gray600">
        {createdAtText}
      </Typography>
    </div>
  );
};

export default ChallengeCertifyItem;
