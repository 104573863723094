import { withAsyncBoundary } from '@community-group/components';
import { MouseEventHandler, useMemo } from 'react';

import { useGetGroupPostEmotionList } from '@/api/hooks/useGetGroupPostEmotionList';
import EmotionCountMessage from '@/components/common/EmotionCountMessage';
import { useReadGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';

import * as s from './PostDetailEmotionCountMessage.css';

type Props = {
  groupId: string;
  postId: string;
  postEmotionCount: number;
  hasCurrentUserEmotion: boolean;
  onClick: MouseEventHandler;
};

const PostDetailEmotionCountMessage = ({ groupId, postId, postEmotionCount, ...props }: Props) => {
  const { data: currentUser } = useReadGroupMe({ groupId });
  const { data } = useGetGroupPostEmotionList({
    id: Number(groupId),
    postId: Number(postId),
    limit: 2,
  });

  const emotionUsers = useMemo(
    () => data?.pages.flatMap((page) => page?.data.users) ?? [],
    [data?.pages]
  );
  const emotionCount =
    data?.pages.reduce((count, page) => count + page?.data.emotionCount, 0) ?? postEmotionCount;

  if (!currentUser || !emotionCount) return null;

  return (
    <div className={s.Wrapper}>
      <EmotionCountMessage
        currentUser={currentUser}
        emotionCount={emotionCount}
        emotionUsers={emotionUsers}
        {...props}
      />
    </div>
  );
};

export default withAsyncBoundary(PostDetailEmotionCountMessage, {});
