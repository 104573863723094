import { ActivityComponentType } from '@stackflow/react';

import { AppScreen } from '@/stackflow/components/AppScreen';

import PayRejectAccessoryBar from '../components/PayRejectAccessoryBar';
import PayRejectInfoSection from '../components/PayRejectInfoSection';

/**
 * 2025.01 챌린지 프로모션 이벤트입니다.
 *  이후 관련 내용을 삭제해주세요.
 */

const ChallengePayRejectPage: ActivityComponentType = () => {
  return (
    <AppScreen accessoryBar={<PayRejectAccessoryBar />}>
      <PayRejectInfoSection />
    </AppScreen>
  );
};

export default ChallengePayRejectPage;
