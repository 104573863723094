import { groupClient } from '@community-group/api';
import { useInfiniteQuery, useSuspenseInfiniteQuery } from '@tanstack/react-query';

import { GROUP_URL } from '../../base/group';
import { useFetchInstance } from '../instance/useFetchInstance';

export const useGetRoomList = (id: number, initialCursor?: string | undefined) => {
  const fetchInstance = useFetchInstance();
  const getRoomList = groupClient.api.TalkRoomApi.apiV1GroupsIdRoomsGet({ axios: fetchInstance });

  const { fetchNextPage, isFetchingNextPage, data, refetch } = useSuspenseInfiniteQuery({
    queryKey: [`${GROUP_URL}/${id}/rooms`],
    queryFn: ({ pageParam = initialCursor }) =>
      getRoomList({
        id,
        cursor: pageParam,
        limit: 15,
      }),
    initialPageParam: initialCursor,
    getNextPageParam: ({ data }) => (data.hasNext ? data?.endCursor ?? undefined : undefined),
    refetchInterval: 10000,
  });

  return {
    fetchNextPage,
    hasNextPage: data?.pages[data.pages.length - 1].data.hasNext,
    isFetchingNextPage,
    data,
    refetch,
  };
};
