import { groupClient } from '@community-group/api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { GROUP_PROFILE_QUERY_KEY } from '@/domain/GroupProfile/queries';
import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

type Props = {
  groupId: string;
  userId: string;
};
export const useQueryGroupProfileUserInfo = ({ groupId, userId }: Props) => {
  const read = useAsyncRead(groupClient.api.GroupMemberApi.apiV1GroupsIdMembersUserIdProfileGet);
  return {
    queryKey: GROUP_PROFILE_QUERY_KEY.base(groupId, userId),
    queryFn: () => read({ id: Number(groupId), userId: Number(userId) }).then((res) => res.profile),
  };
};

export const useReadGroupProfileUserInfo = ({ groupId, userId }: Props) => {
  const query = useQueryGroupProfileUserInfo({ groupId, userId });
  return useSuspenseQuery(query);
};
