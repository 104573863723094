import { GroupFeedType } from '@community-group/api/lib/group/models';
import { Spinner } from '@community-group/components';
import { $Values } from 'utility-types';

import { ORDER_HOME } from '@/api/base/group';
import { AllTypeMeetupProps, useGetAllMeetups } from '@/api/hooks/allTypeMeetup/useGetAllMeetups';
import { useGetMyRoomList } from '@/api/hooks/apartment/useGetMyRoomList';
import { useGetRoomList } from '@/api/hooks/apartment/useGetRoomList';
import { useGetApplications } from '@/api/hooks/useGetApplications';
import { useGetGroupFeedList } from '@/api/hooks/useGetGroupFeedList';
import { useGetHomeList } from '@/api/hooks/useGetHomeList';
import {
  MEETUP_PROGRESS_STATUS_FILTER,
  MEETUP_PROGRESS_STATUS_FILTER_KEYS,
  useGetMeetupList,
} from '@/api/hooks/useGetMeetupList';
import { useGetMemberList } from '@/api/hooks/useGetMemberList';
import { useGetMyGroupPostList } from '@/api/hooks/useGetMyGroupPostList';
import useInfinityScroll from '@/hooks/useInfinityScroll';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { trackEvent } from '@/utils/analytics';

import { Container } from '../Container';
import * as s from './loader.css';

type Props = {
  filter: {
    order?: $Values<typeof ORDER_HOME>;
    category?: number;
  };
};

/**
 * @deprecated
 * 페이지네이션을 원할 경우 `@community-group/components/shared`의 `PaginationList` 또는 `PaginationGrid`를 이용해주세요.
 */
export const LoadMoreHomeContainer = ({ filter }: Props) => {
  const { fetchNextPage, hasNextPage, isFetchingNextPage } = useGetHomeList({
    ...filter,
  });

  const { ref } = useInfinityScroll({
    callback: () => hasNextPage && !isFetchingNextPage && fetchNextPage(),
    intersectionOptions: {
      threshold: 0.3,
    },
  });

  return (
    <Container paddingX={0} style={{ position: 'relative' }}>
      <div className={s.trigger} ref={ref} />
      <div className={s.loading}>
        <Spinner variant="primary" size="small" />
      </div>
    </Container>
  );
};

type LoadMoreFeedContainerProps = {
  selectedCategoryKey: number;
  feedType?: GroupFeedType;
};

/**
 * @deprecated
 * 페이지네이션을 원할 경우 `@community-group/components/shared`의 `PaginationList` 또는 `PaginationGrid`를 이용해주세요.
 */
export const LoadMoreFeedContainer = ({
  selectedCategoryKey,
  feedType,
}: LoadMoreFeedContainerProps) => {
  const { groupId } = usePathParams();
  const { fetchNextPage, hasNextPage, isFetchingNextPage } = useGetGroupFeedList(
    groupId,
    feedType,
    [selectedCategoryKey]
  );

  const { ref } = useInfinityScroll({
    callback: () => hasNextPage && !isFetchingNextPage && fetchNextPage(),
    intersectionOptions: {
      threshold: 0.3,
    },
  });

  return (
    <Container paddingX={0} style={{ position: 'relative' }}>
      <div className={s.trigger} ref={ref} />
      <div className={s.loading}>
        <Spinner variant="gray" />
      </div>
    </Container>
  );
};

/**
 * @deprecated
 * 페이지네이션을 원할 경우 `@community-group/components/shared`의 `PaginationList` 또는 `PaginationGrid`를 이용해주세요.
 */
export const LoadMoreMyGroupFeedContainer = ({ lastItemIdx }: { lastItemIdx?: number }) => {
  const { fetchNextPage, hasNextPage, isFetchingNextPage } = useGetMyGroupPostList({});

  const { ref } = useInfinityScroll({
    callback: () => {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
        trackEvent({
          event: 'impression_my_group_feed_load_more_container',
          params: {
            lastItemIdx: lastItemIdx ?? undefined,
          },
        });
      }
    },
    intersectionOptions: {
      threshold: 0.3,
    },
  });

  return (
    <Container paddingX={0} style={{ position: 'relative' }}>
      <div className={s.trigger} ref={ref} />
      <div className={s.loading}>
        <Spinner variant="gray" />
      </div>
    </Container>
  );
};

type LoadMoreGroupFeedContainerProps = {
  selectedMeetupProgressStatusKey: MEETUP_PROGRESS_STATUS_FILTER_KEYS;
  order: 'meet_at_asc' | 'meet_at_desc';
};

/**
 * @deprecated
 * 페이지네이션을 원할 경우 `@community-group/components/shared`의 `PaginationList` 또는 `PaginationGrid`를 이용해주세요.
 */
export const LoadMoreGroupMeetupContainer = ({
  selectedMeetupProgressStatusKey,
  order,
}: LoadMoreGroupFeedContainerProps) => {
  const { groupId } = usePathParams();
  const { fetchNextPage, hasNextPage, isFetchingNextPage } = useGetMeetupList(
    groupId,
    MEETUP_PROGRESS_STATUS_FILTER[selectedMeetupProgressStatusKey],
    order
  );

  const { ref } = useInfinityScroll({
    callback: () => hasNextPage && !isFetchingNextPage && fetchNextPage(),
    intersectionOptions: {
      threshold: 0.3,
    },
  });

  return (
    <Container paddingX={0} style={{ position: 'relative' }}>
      <div className={s.trigger} ref={ref} />
      <div className={s.loading}>
        <Spinner variant="gray" />
      </div>
    </Container>
  );
};

/**
 * @deprecated
 * 페이지네이션을 원할 경우 `@community-group/components/shared`의 `PaginationList` 또는 `PaginationGrid`를 이용해주세요.
 */
export const LoadMoreMemberListContainer = ({ searchValue }: { searchValue?: string }) => {
  const { groupId } = usePathParams();
  const { fetchNextPage, hasNextPage, isFetchingNextPage } = useGetMemberList({
    groupId,
    keyword: searchValue,
  });

  const { ref } = useInfinityScroll({
    callback: () => hasNextPage && !isFetchingNextPage && fetchNextPage(),
    intersectionOptions: {
      threshold: 0,
    },
  });

  return (
    <Container paddingX={0} style={{ position: 'relative' }}>
      <div className={s.loading} ref={ref}>
        <Spinner variant="gray" />
      </div>
    </Container>
  );
};

/**
 * @deprecated
 * 페이지네이션을 원할 경우 `@community-group/components/shared`의 `PaginationList` 또는 `PaginationGrid`를 이용해주세요.
 */
export const LoadMoreApplications = () => {
  const { groupId } = usePathParams();
  const { fetchNextPage, hasNextPage, isFetchingNextPage } = useGetApplications(groupId);

  const { ref } = useInfinityScroll({
    callback: () => hasNextPage && !isFetchingNextPage && fetchNextPage(),
    intersectionOptions: {
      threshold: 0.3,
    },
  });

  return (
    <Container paddingX={0} style={{ position: 'relative' }}>
      <div className={s.trigger} ref={ref} />
      <div className={s.loading}>
        <Spinner variant="gray" />
      </div>
    </Container>
  );
};

/**
 * @deprecated
 * 페이지네이션을 원할 경우 `@community-group/components/shared`의 `PaginationList` 또는 `PaginationGrid`를 이용해주세요.
 */
export const LoadMoreRoomList = () => {
  const { groupId } = usePathParams();

  const { fetchNextPage, hasNextPage, isFetchingNextPage } = useGetRoomList(Number(groupId));

  const { ref } = useInfinityScroll({
    callback: () => hasNextPage && !isFetchingNextPage && fetchNextPage(),
    intersectionOptions: {
      threshold: 0.3,
    },
  });

  return (
    <Container paddingX={0} style={{ position: 'relative' }}>
      <div className={s.trigger} ref={ref} />
      <div className={s.loading}>
        <Spinner variant="gray" />
      </div>
    </Container>
  );
};

/**
 * @deprecated
 * 페이지네이션을 원할 경우 `@community-group/components/shared`의 `PaginationList` 또는 `PaginationGrid`를 이용해주세요.
 */
export const LoadMoreMyRoomList = () => {
  const { groupId } = usePathParams();

  const { fetchNextPage, hasNextPage, isFetchingNextPage } = useGetMyRoomList(Number(groupId));

  const { ref } = useInfinityScroll({
    callback: () => hasNextPage && !isFetchingNextPage && fetchNextPage(),
    intersectionOptions: {
      threshold: 0.3,
    },
  });

  return (
    <Container paddingX={0} style={{ position: 'relative' }}>
      <div className={s.trigger} ref={ref} />
      <div className={s.loading}>
        <Spinner variant="gray" />
      </div>
    </Container>
  );
};

type LoadAllTypeMeetupProps = {
  filter: AllTypeMeetupProps;
};

//홈에 있는 일정 탭 리스트 무한스크롤
/**
 * @deprecated
 * 페이지네이션을 원할 경우 `@community-group/components/shared`의 `PaginationList` 또는 `PaginationGrid`를 이용해주세요.
 */
export const LoadMoreAllTypeMeetupContainer = ({ filter }: LoadAllTypeMeetupProps) => {
  const { fetchNextPage, hasNextPage, isFetchingNextPage } = useGetAllMeetups({
    ...filter,
  });

  const { ref } = useInfinityScroll({
    callback: () => hasNextPage && !isFetchingNextPage && fetchNextPage(),
    intersectionOptions: {
      threshold: 0.3,
    },
  });

  return (
    <Container paddingX={0} style={{ position: 'relative' }}>
      <div className={s.trigger} ref={ref} />
      <div className={s.loading}>
        <Spinner variant="gray" />
      </div>
    </Container>
  );
};

type LoadMoreParams = {
  callback: () => void;
};

/**
 * @deprecated
 * 페이지네이션을 원할 경우 `@community-group/components/shared`의 `PaginationList` 또는 `PaginationGrid`를 이용해주세요.
 */
export const LoadMoreListContainer = ({ callback }: LoadMoreParams) => {
  const { ref } = useInfinityScroll({
    callback,
    intersectionOptions: {
      threshold: 0,
    },
  });

  return (
    <Container paddingX={0} style={{ position: 'relative' }}>
      <div className={s.loading} ref={ref}>
        <Spinner variant="gray" />
      </div>
    </Container>
  );
};
