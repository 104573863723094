import {
  getTemperature,
  HelpBubbleTrigger,
  HorizontalSpacing,
  ProgressBar,
  Typography,
  VerticalSpacing,
} from '@community-group/components';
import { IconILowercaseSerifCircleLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { useState } from 'react';

import * as s from './style.css';

type Props = {
  temperature: number;
};
const GroupProfileTemperature = ({ temperature }: Props) => {
  const [shownTooltip, setShownTooltip] = useState(false);
  return (
    <div className={s.Wrapper}>
      <div className={s.TemperatureWrapper}>
        <Typography typography="caption1Regular" color="gray700">
          매너온도
        </Typography>
        <VerticalSpacing size={2} />
        <HelpBubbleTrigger
          title={`매너온도는 당근 사용자로부터 받은 ${'\n'}칭찬, 후기, 비매너 평가, 운영자 제재${'\n'}등을 종합해서 만든 매너지표예요. ${'\n'}모임의 활동도 반영돼요.`}
          isOpen={shownTooltip}
          UNSAFE_style={{
            whiteSpace: 'pre-wrap',
          }}
          positioning={{
            placement: 'bottom-start',
          }}
          zIndex={3}
          marginLeft={-14}
          closeOnInteractOutside
          onOpenChange={() => setShownTooltip((p) => !p)}
        >
          <IconILowercaseSerifCircleLine
            data-tooltip-id="info-mode"
            size={14}
            color={vars.$scale.color.gray700}
          />
        </HelpBubbleTrigger>
      </div>
      <HorizontalSpacing size={4} />
      <div className={s.TemperatureBox}>
        <div className={s.TemperatureText}>
          <span style={{ color: getTemperature(temperature).color }}>
            {getTemperature(temperature).displayText}
          </span>
          <VerticalSpacing size={6} />
          <img
            src={getTemperature(temperature).emoji}
            alt="temperature"
            style={{ width: '1.25rem' }}
          />
        </div>
        <HorizontalSpacing size={4} />
        <ProgressBar
          value={temperature}
          height="0.25rem"
          fillColor={getTemperature(temperature).color}
          width="3.125rem"
        />
      </div>
    </div>
  );
};

export default GroupProfileTemperature;
