import { GroupMeetupMemberSummaryPresentation } from '@community-group/api/lib/group/models';
import {
  ActionList,
  ActionListItem,
  ActionSheet,
  useBottomSheet,
} from '@community-group/components';
import { IconDot3VerticalLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import clsx from 'clsx';
import { useCallback, useMemo } from 'react';

import ProfileListItem from '@/components/common/Profile/ListItem';
import { useReadGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';
import { openGroupProfileReport } from '@/utils/link';

import * as s from './GroupMeetupMemberItem.css';

type Props = {
  groupId: string;
  user: GroupMeetupMemberSummaryPresentation;
  shownMeBadge?: boolean;
} & React.HTMLAttributes<HTMLLIElement>;

export const GroupMeetupMemberItem = ({ groupId, user, shownMeBadge, ...props }: Props) => {
  const { push } = useFlow();
  const { open: openBottomSheet, close: closeBottomSheet } = useBottomSheet();
  const { data: myInfo } = useReadGroupMe({ groupId });

  const handleMoreButton = useCallback(() => {
    openBottomSheet({
      element: (
        <ActionSheet
          bottomButton={{
            label: '닫기',
          }}
        >
          <ActionList>
            <ActionListItem
              onClick={async () => {
                await closeBottomSheet();

                openGroupProfileReport({
                  groupId,
                  userId: user.userId.toString(),
                });
              }}
            >
              신고
            </ActionListItem>
          </ActionList>
        </ActionSheet>
      ),
    });
  }, [user]);

  const shownMoreButton = useMemo(() => {
    if (myInfo.role === 'none') return false;

    if (user.userId === myInfo.id) return false;

    return true;
  }, [user]);

  const MoreButton = useCallback(() => {
    return (
      <IconDot3VerticalLine
        size={20}
        fill={vars.$scale.color.gray900}
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();

          handleMoreButton();
        }}
      />
    );
  }, [user]);

  return (
    <li
      {...props}
      className={clsx([props.className, s.listItem])}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();

        push('GroupUserProfileDetailPage', {
          groupId,
          userId: user.userId.toString(),
          from: 'meetup_member',
        });

        trackEvent({
          event: 'click_profile',
          params: {
            clickedUserId: user.userId,
            clickedUserRole: user.meetupRole,
            name: 'groupMeetupMemberItem',
          },
        });
      }}
    >
      <div className={s.leftSection}>
        <ProfileListItem
          user={{
            id: user.userId,
            nickname: user.nickname,
            subNickname: user.subNickname,
            description: user.description,
            profileImage: user.profileImage,
            userRegion: user.region,
            role: user.groupRole,
            isAccountDeleted: false,
          }}
          config={{
            shownMeBadge,
            handledBadgeClick: false,
          }}
        />
      </div>
      {shownMoreButton && (
        <div className={s.rightSection}>
          <MoreButton />
        </div>
      )}
    </li>
  );
};
