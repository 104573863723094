import {
  ChallengeInfoPostDetail,
  ChallengeStatusEnum,
  GroupMemberRoleEnum,
  PostDetail,
  PostDetailMeetupInfoPresentation,
} from '@community-group/api/lib/group/models';
import { ContainerWithGap, isNotMember, Typography } from '@community-group/components';
import { VerticalSpacing } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { MouseEvent, useMemo } from 'react';

import { FeedContentsChallengeBanner } from '@/components/common/Banner/FeedContentsChallengeBanner';
import { MeetupBanner } from '@/components/common/Banner/MeetupBanner';
import { PoiBanner } from '@/components/common/Banner/PoiBanner';
import { PollBanner } from '@/components/common/Banner/PollBanner';
import Warning from '@/components/common/Icons/warning';
import LinkifyContainer from '@/components/common/Linkify';
import { isMember } from '@/utils/role';

import { getMeetupStatusTitleColor } from '../../../utils/getMeetupStatusTitleColor';
import * as s from './FeedItemContents.css';
import FeedItemContentText from './FeedItemContentText';
import FeedItemMediaSlider from './FeedItemMediaSlider';

interface Props {
  post: PostDetail;
  currentUserRole: GroupMemberRoleEnum;
  meetup?: PostDetailMeetupInfoPresentation;
  challenge?: ChallengeInfoPostDetail;
  onMeetupBannerClick?: (e: MouseEvent) => void;
  onPollBannerClick?: (e: MouseEvent) => void;
}

const getChallengeDescription = (status: ChallengeStatusEnum | undefined): string => {
  switch (status) {
    case 'closed':
      return '종료';
    case 'prepared':
      return '진행 예정';
    default:
      return '진행중';
  }
};

const getChallengeStatusStyle = (status: ChallengeStatusEnum | undefined): { color: string } => {
  switch (status) {
    case 'prepared':
      return { color: vars.$scale.color.yellow400 };
    case 'closed':
      return { color: vars.$scale.color.gray600 };
    default:
      return { color: vars.$semantic.color.primary };
  }
};

const FeedItemPublicContents = ({
  post,
  currentUserRole,
  meetup,
  challenge,
  onMeetupBannerClick,
  onPollBannerClick,
}: Props) => {
  const hasMedias = useMemo(() => post?.medias?.length > 0, [post?.medias?.length]);

  const isReview = post.postType?.type === 'meetupReview';

  const canReadMeetup = useMemo(() => {
    const isGroupMember = isMember(currentUserRole);

    return meetup?.exposureRange === 'ALL' ? true : isGroupMember;
  }, [currentUserRole, meetup]);

  const renderContentsPrefix = useMemo(() => {
    const isChallenge = post.postType?.type === 'challenge';
    if (!meetup && !isChallenge) return null;
    if (isReview) return null;

    const challengeStatus = {
      status:
        challenge?.status === 'closed'
          ? ('closed' as ChallengeStatusEnum)
          : ('opened' as ChallengeStatusEnum),
      description: getChallengeDescription(challenge?.status),
      title: challenge?.name,
      style: getChallengeStatusStyle(challenge?.status),
    };

    const meetupStatus = {
      status: meetup?.progressStatus?.status,
      description: meetup?.progressStatus?.description,
      title: meetup?.title,
      style: {
        color: meetup?.progressStatus?.status
          ? getMeetupStatusTitleColor(meetup.progressStatus.status)
          : undefined,
      },
    };

    const progressStatus = challenge ? challengeStatus : meetupStatus;

    return (
      <>
        <FeedItemContentText>
          <span
            style={{
              ...progressStatus.style,
              marginRight: 4,
            }}
          >
            {progressStatus.description}
          </span>
          {progressStatus.title}
        </FeedItemContentText>
        <br />
      </>
    );
  }, [challenge, isReview, meetup, post.postType?.type]);

  // 본문 존재 여부에 따라 제목의 폰트가 다름
  // 관련 이슈 : https://daangn.slack.com/archives/C0336FFHA8L/p1710816075026709
  const renderPostTitleAndContent = useMemo(() => {
    return (
      <>
        {renderContentsPrefix}
        {post?.content && (
          <FeedItemContentText>
            <LinkifyContainer text={post.content} />
          </FeedItemContentText>
        )}
      </>
    );
  }, [post.content, renderContentsPrefix]);

  return (
    <ContainerWithGap name="FeedItemContents" gap={12}>
      {renderPostTitleAndContent}
      {hasMedias && <FeedItemMediaSlider medias={post.medias} />}
      {canReadMeetup && meetup && (
        <MeetupBanner meetup={meetup} isReview={isReview} onClick={onMeetupBannerClick} />
      )}
      {post?.challengeInfo && (
        <FeedContentsChallengeBanner
          postType={post.postType?.type ?? 'post'}
          challengeInfo={post.challengeInfo}
        />
      )}
      {post.pois?.[0] && <PoiBanner poi={post.pois[0]} onClick={() => {}} />}
      {post.poll && <PollBanner poll={post.poll} onClick={onPollBannerClick} />}
    </ContainerWithGap>
  );
};

const FeedItemPrivateContents = () => {
  return (
    <div className={s.PrivateContentsWrapper}>
      <Warning />
      <VerticalSpacing size={4} />
      <Typography typography="bodyM1Regular" color="gray600">
        모임에만 공개된 게시글이에요.
      </Typography>
    </div>
  );
};

/**
 * @description post: PostDetail이 아닌 GroupFeedSummary로 통일이 되면 domain/GroupFeed/components/FeedItemContentsV2를 사용해주세요
 */
const FeedItemContents = (props: Props) => {
  const isPrivateContents = props.post.publishType === 'groupOnly';

  if (isPrivateContents && isNotMember(props.currentUserRole)) {
    return <FeedItemPrivateContents />;
  }

  return <FeedItemPublicContents {...props} />;
};

export default FeedItemContents;
