import { Spacing, Typography } from '@community-group/components';

import MyChallengeCalendar from './MyChallengeCalendar';
import MyChallengeStatus from './MyChallengeStatus';
import * as s from './style.css';

const MyChallengeInfoSection = () => {
  return (
    <div className={s.Wrapper}>
      <Typography typography="title3Bold">내 인증 기록</Typography>
      <Spacing size={20} />
      <MyChallengeStatus />
      <Spacing size={26} />
      <MyChallengeCalendar />
    </div>
  );
};

export default MyChallengeInfoSection;
