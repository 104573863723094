import {
  GroupCurrentUser,
  GroupMemberProfileResponseProfile,
  UserProfileSnsTagPresentation,
} from '@community-group/api/lib/group/models';

import SnsTagMySection from '@/features/GroupProfile/SnsTagSection/SnsTagMySection';
import SnsTagOtherMemberSection from '@/features/GroupProfile/SnsTagSection/SnsTagOtherMemberSection';

type Props = {
  snsTags: UserProfileSnsTagPresentation[];
  isMyProfile?: boolean;
  profile: GroupMemberProfileResponseProfile;
  currentUser: GroupCurrentUser;
};

const SnsTagSection = ({ snsTags, isMyProfile, profile, currentUser }: Props) => {
  if (isMyProfile) {
    return <SnsTagMySection snsTags={snsTags} profile={profile} />;
  }

  return <SnsTagOtherMemberSection snsTags={snsTags} currentUser={currentUser} />;
};

export default SnsTagSection;
