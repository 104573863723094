import { groupClient } from '@community-group/api';
import { useQuery } from '@tanstack/react-query';

import { getGroupPath } from '../base/group';
import { useFetchInstance } from './instance/useFetchInstance';

export const getApplicationPath = (groupId: number) => `${getGroupPath(groupId)}/applications`;

export const useGetApplicationAcceptance = (id: string, userId?: number) => {
  const groupId = Number(id);
  const fetchInstance = useFetchInstance();
  const getApplicationAcceptance =
    groupClient.api.GroupApplicationApi.apiV1GroupsIdApplicationsUserIdAcceptanceGet({
      axios: fetchInstance,
    });

  const { data, refetch } = useQuery({
    queryKey: [getApplicationPath(groupId), userId],
    queryFn: () => {
      if (!userId) return;
      return getApplicationAcceptance({ id: groupId, userId });
    },
    enabled: false,
  });

  return { data: data?.data, refetch };
};
