import { getChallengeBannerPath } from '@/api/hooks/useGetChallengeBanner';
import { getChallengeDetailPath } from '@/api/hooks/useGetChallengeDetail';
import { getChallengeMyCertifyActivityPath } from '@/api/hooks/useGetChallengeMyCertifyActivity';
import { getChallengeNotificationSettingPath } from '@/api/hooks/useGetChallengeNotificationSetting';
import { getChallengeParticipantsRankPath } from '@/api/hooks/useGetChallengeParticipantsRank';
import { getGroupChallengeListPath } from '@/api/hooks/useGetGroupChallengeList';
import { getMyChallengeCalendarPath } from '@/api/hooks/useGetMyChallengeCalendar';
import { GROUP_CHALLENGE_QUERY_KEY } from '@/domain/GroupChallenge/queries';
import { queryClient } from '@/shared/api/instance';

type Params = {
  groupId?: string;
  challengeId?: string;
};

/*
 *  모임 상세 페이지에서 사용하는 쿼리를 한번에 리페치하는 함수
 */
export const refetchGroupChallenge = ({ groupId, challengeId }: Params) => {
  if (!groupId) return;

  const numberGroupId = Number(groupId);

  // 모임 상세 - 챌린지 상태 배너
  queryClient.refetchQueries({
    queryKey: [getChallengeBannerPath(numberGroupId)],
  });

  // 챌린지 목록 - 각 상태 별 데이터
  queryClient.refetchQueries({
    queryKey: [getGroupChallengeListPath(numberGroupId, 'prepared'), 'prepared'],
  });
  queryClient.refetchQueries({
    queryKey: [getGroupChallengeListPath(numberGroupId, 'opened'), 'opened'],
  });
  queryClient.refetchQueries({
    queryKey: [getGroupChallengeListPath(numberGroupId, 'closed'), 'closed'],
  });

  if (challengeId) {
    const numberChallengeId = Number(challengeId);

    // 챌린지 상세 - 알림 설정 여부
    queryClient.refetchQueries({
      queryKey: [getChallengeNotificationSettingPath(numberGroupId, numberChallengeId)],
    });

    // 챌린지 상세 - 참여자 랭킹
    queryClient.refetchQueries({
      queryKey: [
        getChallengeParticipantsRankPath({
          groupId: numberGroupId,
          challengeId: numberChallengeId,
        }),
      ],
    });

    // 챌린지 상세 - 나의 현황 섹션
    queryClient.refetchQueries({
      queryKey: [
        getChallengeMyCertifyActivityPath({
          groupId: numberGroupId,
          challengeId: numberChallengeId,
        }),
      ],
    });

    // 챌린지 상세
    queryClient.refetchQueries({
      queryKey: [getChallengeDetailPath(groupId, challengeId)],
    });

    // 챌린지 상세 - 나의 챌린지 캘린더
    queryClient.refetchQueries({
      queryKey: [getMyChallengeCalendarPath(numberGroupId, numberChallengeId)],
    });

    // 챌린지 상세 - 인증 모아보기 피드
    queryClient.refetchQueries({
      queryKey: GROUP_CHALLENGE_QUERY_KEY.feeds(groupId, challengeId),
    });
  }
};
