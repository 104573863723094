import { groupClient } from '@community-group/api';
import { PaginationQueryParams } from '@community-group/components/shared';
import { useInfiniteQuery, useSuspenseInfiniteQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

type RelatedContentType = 'post' | 'meetup';
type Props = {
  groupId: number;
  relatedId: number;
  commentId: number;
  relatedContentType: RelatedContentType;
  limit?: number;
  joinedUser?: boolean;
} & PaginationQueryParams;

export const getCommentEmotionsPath = (
  groupId: number,
  postId: number,
  commentId: number,
  relatedContentType: RelatedContentType
) => {
  const isPostComment = relatedContentType === 'post';
  const method = isPostComment
    ? groupClient.api.CommentApi.getapiV1GroupsIdPostsPostIdCommentsCommentIdEmotionsGetPath
    : groupClient.api.CommentApi.getapiV1GroupsIdMeetupsMeetupIdCommentsCommentIdEmotionsGetPath;

  return method(groupId, postId, commentId);
};

export const useGetCommentEmotions = ({
  groupId,
  relatedId,
  commentId,
  relatedContentType,
  limit = 30,
  joinedUser,
  initialCursor = undefined,
}: Props) => {
  const isPostComment = relatedContentType === 'post';
  const apiMethod = isPostComment
    ? groupClient.api.CommentApi.apiV1GroupsIdPostsPostIdCommentsCommentIdEmotionsGet
    : groupClient.api.CommentApi.apiV1GroupsIdMeetupsMeetupIdCommentsCommentIdEmotionsGet;

  const fetchInstance = useFetchInstance();
  const getCommentEmotions = apiMethod({
    axios: fetchInstance,
  });

  const path = getCommentEmotionsPath(groupId, relatedId, commentId, relatedContentType);
  const { fetchNextPage, hasNextPage, isFetchingNextPage, data, refetch } =
    useSuspenseInfiniteQuery({
      queryKey: [path, joinedUser],
      queryFn: ({ pageParam = undefined }) => {
        const cursor = pageParam === null ? undefined : pageParam;
        return getCommentEmotions({
          id: Number(groupId),
          postId: Number(relatedId),
          meetupId: Number(relatedId),
          commentId: Number(commentId),
          cursor,
          limit,
          joinedUser,
        });
      },
      initialPageParam: initialCursor,
      getNextPageParam: ({ data }) => (data.hasNext ? data?.endCursor ?? undefined : undefined),
    });

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
  };
};
