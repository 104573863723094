import React, { forwardRef } from 'react';

import * as s from './ImpressionTriggeredComponents.css';

const ImpressionTriggeredComponents = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ ...rest }, ref) => {
  return <div {...rest} className={s.Container} ref={ref} />;
});

export default ImpressionTriggeredComponents;
