import { groupClient } from '@community-group/api';
import { GroupMeetupDetailResponse } from '@community-group/api/lib/group/models';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useFetchInstance } from '@/api/hooks/instance/useFetchInstance';
import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';
import { queryClient } from '@/shared/api/instance';

import { getGroupMeetupDetailPath } from './useGetGroupMeetupDetail';

type Props = UseMutationOptions<AxiosResponse, Error, Request>;

type Request = {
  groupId: string;
  meetupId: string;
};

export const useDeleteMeetupEmotion = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const deleteEmotion = groupClient.api.MeetupApi.apiV1GroupsIdMeetupsMeetupIdEmotionsDelete({
    axios: fetchInstance,
  });

  const mutate = async ({ groupId, meetupId }: Request) => {
    const numberGroupId = Number(groupId);
    const numberMeetupId = Number(meetupId);

    const res = await deleteEmotion({ id: numberGroupId, meetupId: numberMeetupId });
    deleteMeetupDetailEmotion(groupId, meetupId);
    return res;
  };

  return useThrottleMutation(mutate, {
    onError,
    onSuccess,
  });
};

const deleteMeetupDetailEmotion = (groupId: string, meetupId: string) => {
  const meetupDetailPath = getGroupMeetupDetailPath(groupId, meetupId);

  // prveData 체크하지 않고 바로 setQueryData를 하면 해당 query를 직접적으로 사용하는 페이지에서 에러가 발생해요.
  const prevData = queryClient.getQueryData<AxiosResponse<GroupMeetupDetailResponse> | undefined>([
    meetupDetailPath,
  ]);
  if (!prevData) return;

  queryClient.setQueryData<AxiosResponse<GroupMeetupDetailResponse> | undefined>(
    [meetupDetailPath],
    (prevData) => {
      if (!prevData || !prevData.data.groupMeetup) {
        return;
      }

      const prevEmotionCount = prevData.data.groupMeetup?.emotion.count ?? 1;

      return {
        ...prevData,
        data: {
          ...prevData.data,
          groupMeetup: {
            ...prevData.data.groupMeetup,
            emotion: {
              count: prevEmotionCount - 1,
              myEmotion: undefined,
            },
          },
        },
      };
    }
  );
};
