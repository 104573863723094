import { useEffectOnce } from '@community-group/components';
import * as Sentry from '@sentry/react';

import { initVConsole } from '@/_app/utils/vConsole';
import { useFetchInstance } from '@/api/hooks/instance/useFetchInstance';
import { useBridge } from '@/contexts/Bridge';
import {
  initAmplitude,
  setAdditionalUserDataToAmplitude,
  setAdditionalUserExperimentalGroupToAmplitude,
  setAdditionalUserSegmentsToAmplitude,
  setInitialUserDataToAmplitude,
} from '@/utils/analytics/amplitude';
import { initClarity } from '@/utils/analytics/clarity';
import { initAppLink } from '@/utils/appLinkStore';
import { initAppInfo } from '@/utils/bridge/appInfo';
import sentry from '@/utils/sentry';

const useInitAppInfo = () => {
  const { bridge } = useBridge();
  const getUserInfo = async () => {
    try {
      await initAppInfo(bridge);
    } catch (error) {
      if (globalThis.appConfig.isAppEnvProduction) {
        Sentry.captureException(error);
      }

      bridge.openToast({
        toast: {
          body: '모임을 불러오는데 실패했어요. 앱을 다시 시작해주세요.',
        },
      });
    }
  };

  const fetchInstance = useFetchInstance();

  useEffectOnce(() => {
    if (typeof window === 'undefined') return;

    const fetchUserInfo = async () => {
      await getUserInfo();

      if (window.location) {
        initVConsole();
        initAppLink();
        initClarity();
      }

      if (!window?.location?.href?.includes('token-setting')) {
        initAmplitude(() => {
          setInitialUserDataToAmplitude();
          setAdditionalUserDataToAmplitude({ fetchInstance });
          setAdditionalUserSegmentsToAmplitude();
          setAdditionalUserExperimentalGroupToAmplitude();
        });
      }

      if (globalThis.appConfig.isAppEnvProduction || globalThis.appConfig.appEnv === 'alpha') {
        sentry.setUserInfo();
      }
    };

    fetchUserInfo();
  });
};

export default useInitAppInfo;
