import { useExperimentVariable } from '@/api/hooks/useExperimentVariable';

const useHomeRegionRangeExpandExperiment = () => {
  const experimentKey = 'kr_communitygroup_home_region_expand_server_v1';
  const experimentVariable = useExperimentVariable(experimentKey, {
    skipTrigger: true,
  });

  const value = experimentVariable?.value;
  return { data: experimentVariable, regionExpandExperimentValue: value };
};

export default useHomeRegionRangeExpandExperiment;
