import { groupClient } from '@community-group/api';
import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

export const useGetRestrictedMembers = (groupId?: string, initialCursor?: string | undefined) => {
  const fetchInstance = useFetchInstance();
  const getApplications =
    groupClient.api.GroupRestrictedMemberApi.apiV1GroupsIdRestrictedMembersGet({
      axios: fetchInstance,
    });

  const { fetchNextPage, hasNextPage, isFetchingNextPage, data, refetch } =
    useSuspenseInfiniteQuery({
      queryKey: [
        groupClient.api.GroupRestrictedMemberApi.getapiV1GroupsIdRestrictedMembersGetPath(
          Number(groupId)
        ),
      ],
      queryFn: ({ pageParam = initialCursor }) =>
        getApplications({ id: Number(groupId), cursor: pageParam, limit: 30 }),
      initialPageParam: initialCursor,
      getNextPageParam: ({ data }) => (data.hasNext ? data?.endCursor ?? undefined : undefined),
      refetchInterval: 10000,
    });

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
  };
};
