import { GroupMemberGradeApplicationPresentation } from '@community-group/api/lib/group/models';
import {
  BoxButton,
  Checkbox,
  Dialog,
  formatDate,
  Spacing,
  useDialog,
  useSnackbarAdapter,
  VerticalSpacing,
  withAsyncBoundary,
} from '@community-group/components';
import { PaginationEmpty, PaginationList } from '@community-group/components/shared';
import { useMemo, useRef, useState } from 'react';

import ProfileListItem from '@/components/common/Profile/ListItem';
import { usePatchGroupMemberGradeApplication } from '@/domain/GroupMember/hooks/usePatchGroupMemberGradeApplication';
import { useReadGroupMemberApplicationCount } from '@/domain/GroupMember/hooks/useReadGroupMemberApplicationCount';
import { useReadGroupMemberGradeApplications } from '@/domain/GroupMember/hooks/useReadGroupMemberGradeApplications';
import ActivityBox from '@/features/GroupProfile/UserActivities/ActivityBox';
import { useFlow } from '@/stackflow';

import * as s from './MemberProfileDetailGradeApplicationList.css';
import MemberProfileDetailGradeApplicationListItemMoreButton from './MemberProfileDetailGradeApplicationListItemMoreButton';

type Props = {
  groupId: string;
};

const getAppendDescription = (joinedAt: string) => {
  return `${formatDate(new Date(Date.parse(joinedAt)), 'yy.MM.dd 가입')}`;
};

const MemberProfileDetailGradeApplicationList = ({ groupId }: Props) => {
  const { push } = useFlow();
  const { open: openDialog, close: closeDialog } = useDialog();
  const { create: openSnackbar, dismiss: closeSnackbar } = useSnackbarAdapter();

  const accessoryBarRef = useRef<HTMLDivElement>(null);

  const handleMemberListItemClick = (memberId: number) => () => {
    push('GroupUserProfileDetailPage', {
      groupId,
      userId: memberId.toString(),
    });
  };

  const {
    data: items,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetch: refetchMemberGradeApplications,
  } = useReadGroupMemberGradeApplications({ groupId });
  const { data: memberGradeApplicationCount, refetch: refetchMemberGradeApplicationCount } =
    useReadGroupMemberApplicationCount(groupId);
  const members = useMemo(() => items.flatMap((item) => item.member), [items]);

  const [allItemsSelected, setAllItemsSelected] = useState(false);
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);
  const accessoryBarCheckboxChecked = useMemo(() => {
    return allItemsSelected || selectedItemIds.length === items.length;
  }, [allItemsSelected, items.length, selectedItemIds.length]);
  const accessoryBarButtonDisabled = useMemo(() => {
    return !(allItemsSelected || selectedItemIds.length > 0);
  }, [allItemsSelected, selectedItemIds]);

  const { mutateAsync: patchGroupMemberGradeApplication } = usePatchGroupMemberGradeApplication({
    onSuccess: () => {
      refetchMemberGradeApplications();
      refetchMemberGradeApplicationCount();
    },
  });
  const handleAccept = (item: GroupMemberGradeApplicationPresentation) => async () => {
    await patchGroupMemberGradeApplication({
      groupId,
      status: 'accept',
      gradeApplicationIds: [item.gradeApplicationId],
    });

    openSnackbar({
      message: `${item.member.nickname}님을 정회원으로 설정했어요.`,
      type: 'default',
      timeout: 3000,
      onClick: closeSnackbar,
    });
  };
  const handleReject = (item: GroupMemberGradeApplicationPresentation) => async () => {
    await patchGroupMemberGradeApplication({
      groupId,
      status: 'reject',
      gradeApplicationIds: [item.gradeApplicationId],
    });

    openSnackbar({
      message: `${item.member.nickname}님의 등업 요청을 거절했어요.`,
      type: 'default',
      timeout: 3000,
      onClick: closeSnackbar,
    });
  };

  const handleMultipleAccept = () => {
    const applicationCount = allItemsSelected
      ? memberGradeApplicationCount
      : selectedItemIds.length;

    openDialog({
      element: (
        <Dialog
          title="요청을 승인할까요?"
          description={`멤버 ${applicationCount}명의 등업 요청이 승인돼요.`}
          primaryActionLabel="확인"
          secondaryActionLabel="취소"
          onPrimaryAction={async () => {
            // TODO: 전체 선택인 경우 별도의 API 호출
            await patchGroupMemberGradeApplication({
              groupId,
              status: 'accept',
              gradeApplicationIds: selectedItemIds,
            });

            openSnackbar({
              message: `${selectedItemIds.length}명의 멤버를 정회원으로 설정했어요.`,
              type: 'default',
              timeout: 3000,
              onClick: closeSnackbar,
            });
          }}
          onSecondaryAction={async () => {
            await closeDialog();
          }}
        />
      ),
    });
  };
  const handleMultipleReject = () => {
    const applicationCount = allItemsSelected
      ? memberGradeApplicationCount
      : selectedItemIds.length;

    openDialog({
      element: (
        <Dialog
          title="요청을 거절할까요?"
          description={`멤버 ${applicationCount}명의 등업 요청이 거절돼요.`}
          primaryActionLabel="확인"
          secondaryActionLabel="취소"
          onPrimaryAction={async () => {
            // TODO: 전체 선택인 경우 별도의 API 호출
            await patchGroupMemberGradeApplication({
              groupId,
              status: 'reject',
              gradeApplicationIds: selectedItemIds,
            });

            openSnackbar({
              message: `${selectedItemIds.length}명의 등업 요청을 거절했어요.`,
              type: 'default',
              timeout: 3000,
              onClick: closeSnackbar,
            });
          }}
          onSecondaryAction={async () => {
            await closeDialog();
          }}
        />
      ),
    });
  };

  if (items.length <= 0) {
    return <PaginationEmpty>등업 요청이 없어요.</PaginationEmpty>;
  }

  return (
    <div className={s.Container}>
      <div className={s.ListWrapper}>
        <PaginationList
          items={items}
          render={(item) => {
            const isSelectedItem = selectedItemIds.includes(item.gradeApplicationId);

            return (
              <li key={item.gradeApplicationId} className={s.ListItem}>
                <Checkbox
                  size="large"
                  isSelected={allItemsSelected || isSelectedItem}
                  onChange={() => {
                    if (allItemsSelected) {
                      setAllItemsSelected(false);
                      setSelectedItemIds((prev) =>
                        prev.filter((id) => id !== item.gradeApplicationId)
                      );
                      return;
                    }

                    if (isSelectedItem) {
                      setSelectedItemIds((prev) =>
                        prev.filter((id) => id !== item.gradeApplicationId)
                      );
                    } else {
                      setSelectedItemIds((prev) => [...prev, item.gradeApplicationId]);
                    }
                  }}
                />
                <VerticalSpacing size={12} />
                <div className={s.ListItemContent}>
                  <div className={s.ProfileListItemContainer}>
                    <ProfileListItem
                      user={item.member}
                      appendLabel={item.member.userRegion.name}
                      appendDescription={getAppendDescription(item.member.joinedAt)}
                      onClick={handleMemberListItemClick(item.member.id)}
                    />
                    <MemberProfileDetailGradeApplicationListItemMoreButton
                      groupId={groupId}
                      profile={item.member}
                      hostMemberList={members}
                    />
                  </div>
                  <Spacing size={8} />
                  <div className={s.ActivityContainer}>
                    <ActivityBox title="모임 방문" value={String(item.activity.groupVisitCount)} />
                    <div className={s.ActivityDivider} />
                    <ActivityBox title="댓글" value={String(item.activity.entireCommentCount)} />
                    <div className={s.ActivityDivider} />
                    <ActivityBox
                      title="게시글"
                      value={String(item.activity?.createdPostAndMeetupCount ?? 0)} // OAS 업데이트 하면서 임시대응 - 조은 한번 봐주세요
                    />
                    <div className={s.ActivityDivider} />
                    <ActivityBox
                      title="일정 참여"
                      value={String(item.activity.entireMeetupCount)}
                    />
                  </div>
                  <Spacing size={8} />
                  <div className={s.ButtonContainer}>
                    <BoxButton
                      variant="secondary"
                      size="medium"
                      width="100%"
                      onClick={handleReject(item)}
                    >
                      거절
                    </BoxButton>
                    <BoxButton
                      variant="primaryLow"
                      size="medium"
                      width="100%"
                      onClick={handleAccept(item)}
                    >
                      승인
                    </BoxButton>
                  </div>
                </div>
              </li>
            );
          }}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
        />
        <Spacing size={accessoryBarRef.current?.clientHeight ?? 0} />
      </div>
      <div ref={accessoryBarRef} className={s.AccessoryBarContainer}>
        <Checkbox
          size="large"
          isSelected={accessoryBarCheckboxChecked}
          bold={accessoryBarCheckboxChecked}
          onChange={() => {
            if (allItemsSelected) {
              setAllItemsSelected(false);
              setSelectedItemIds([]);
            } else {
              setAllItemsSelected(true);
              setSelectedItemIds(items.map((item) => item.gradeApplicationId));
            }
          }}
        >
          전체 선택
        </Checkbox>
        <div className={s.AccessoryBarButtonContainer}>
          <BoxButton
            variant="secondary"
            size="medium"
            isDisabled={accessoryBarButtonDisabled}
            onClick={handleMultipleReject}
          >
            거절
          </BoxButton>
          <BoxButton
            variant="primaryLow"
            size="medium"
            isDisabled={accessoryBarButtonDisabled}
            onClick={handleMultipleAccept}
          >
            승인
          </BoxButton>
        </div>
      </div>
    </div>
  );
};

export default withAsyncBoundary(MemberProfileDetailGradeApplicationList, {});
