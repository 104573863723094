import { groupClient } from '@community-group/api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { GROUP_DETAIL_QUERY_KEY } from '@/domain/GroupDetail/queries';
import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

type Props = {
  groupId: string;
  refetchOnWindowFocus?: boolean;
};
export const useQueryGroupMe = ({ groupId, refetchOnWindowFocus }: Props) => {
  const read = useAsyncRead(groupClient.api.GroupApi.apiV1GroupsIdMeGet);
  return {
    queryKey: GROUP_DETAIL_QUERY_KEY.me(groupId),
    queryFn: () => read({ id: Number(groupId) }).then((res) => res.currentUser),
    refetchOnWindowFocus,
  };
};

export const useReadGroupMe = ({ groupId, refetchOnWindowFocus = true }: Props) => {
  const query = useQueryGroupMe({ groupId, refetchOnWindowFocus });
  return useSuspenseQuery({ ...query });
};
