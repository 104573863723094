import clsx from 'clsx';
import { motion } from 'framer-motion';
import React, { useMemo } from 'react';

import { useStickInputStore } from '..';
import { useStickyInputFormContext } from '../provider/StickyInputFormProvider';
import { StickyInputEditButton } from './EditButton';
import * as s from './FooterRightButtons.css';
import { StickyInputSubmitButton } from './SubmitButton';

type Props = {
  type: 'new' | 'edit';
  handleEmptyContentOpenToast: () => void;
};

function FooterRightButtons({ type, handleEmptyContentOpenToast }: Props) {
  const { focused } = useStickInputStore();
  const { watch } = useStickyInputFormContext();

  const hideRightButton = useMemo(() => {
    const hasEmptyContent = watch('content').length === 0 && watch('images').length === 0;
    return !focused && type === 'new' && hasEmptyContent;
  }, [focused, type, watch]);

  if (hideRightButton) return null;

  return (
    <motion.div
      className={clsx(s.SubmitButtonWrapper, 'right-buttons')}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, display: 'none' }}
    >
      {type === 'new' ? (
        <StickyInputSubmitButton handleEmptyContentOpenToast={handleEmptyContentOpenToast} />
      ) : (
        <StickyInputEditButton />
      )}
    </motion.div>
  );
}

export default FooterRightButtons;
