import { groupClient } from '@community-group/api';
import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import { GROUP_URL } from '../base/feed';
import { useFetchInstance } from './instance/useFetchInstance';

export const getFeedListQueryKey = (id: string | undefined) => {
  return `${GROUP_URL}/${id}/posts`;
};

export const useGetFeedList = (id?: string, boardCategories?: number[], initialCursor?: string) => {
  const fetchInstance = useFetchInstance();
  const getGroupFeedList = groupClient.api.PostApi.apiV1GroupsIdPostsGet({
    axios: fetchInstance,
  });

  const {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
    isLoading: isInitialLoading,
  } = useSuspenseInfiniteQuery({
    queryKey: [getFeedListQueryKey(id), boardCategories],
    queryFn: ({ pageParam = initialCursor }) =>
      getGroupFeedList({
        id: Number(id),
        cursor: pageParam,
        limit: 20,
        boardCategoryIds:
          !boardCategories || boardCategories[0] === 0 ? undefined : boardCategories,
      }),
    initialPageParam: initialCursor,
    getNextPageParam: ({ data }) => (data.hasNext ? data?.endCursor ?? undefined : undefined),
    refetchInterval: 10000,
  });

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
    isLoading: isInitialLoading,
  };
};
