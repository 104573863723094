import { Spinner } from '@daangn/sprout-components-spinner';
import React from 'react';

import * as s from './ActionSheetLoader.css';

export const ActionSheetLoader = () => {
  return (
    <div className={s.Container}>
      <Spinner size="small" />
    </div>
  );
};
