import { groupClient } from '@community-group/api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

type Props = {
  groupId: number;
  challengeId: number;
};

export const getChallengeMyCertifyActivityPath = ({ groupId, challengeId }: Props) =>
  groupClient.api.GroupChallengeMemberApi.getapiV1GroupsGroupIdChallengesChallengeIdCertifiedActivitiesMeGetPath(
    groupId,
    challengeId
  );

export const useGetChallengeMyCertifyActivity = ({ groupId, challengeId }: Props) => {
  const fetchInstance = useFetchInstance();

  const getChallengeMyCertifyActivity =
    groupClient.api.GroupChallengeMemberApi.apiV1GroupsGroupIdChallengesChallengeIdCertifiedActivitiesMeGet(
      {
        axios: fetchInstance,
      }
    );

  const { data, refetch } = useSuspenseQuery({
    queryKey: [getChallengeMyCertifyActivityPath({ groupId, challengeId })],
    queryFn: () => {
      return getChallengeMyCertifyActivity({
        groupId,
        challengeId,
      });
    },
  });

  return { data: data?.data, refetch };
};
