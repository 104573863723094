import { groupClient } from '@community-group/api';
import { UseMutationOptions } from '@tanstack/react-query';

import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';
import { deleteLikePhotoContestEntries } from './useGetPhotoContestEntries';

type Props = UseMutationOptions<void, Error, string>;

export const useDeleteLikePhotoContestEntry = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const deleteLike =
    groupClient.api.GroupPhotoContestApi.apiV1GroupsPhotoContestsEntriesEntryIdVoteDelete({
      axios: fetchInstance,
    });

  const mutate = async (entryId: string) => {
    const { data } = await deleteLike({ entryId });
    deleteLikePhotoContestEntries(entryId);
    return data;
  };

  return useThrottleMutation(mutate, {
    onError,
    onSuccess,
  });
};
