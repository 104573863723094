import { Region } from '@community-group/api/lib/group/models';
import { getDateDiffFromNow, resizeImage, Typography } from '@community-group/components';
import { VerticalSpacing } from '@community-group/components';
import { useMemo } from 'react';

import GroupLevel2DBadge from '@/components/common/GroupLevel/GroupLevel2DBadge';
import { useFlow } from '@/stackflow';

import * as s from './GroupTitle.css';

type Props = {
  region?: Region;
  name?: string;
  memberCount?: number;
  lastActivatedAt?: string;
  profileImage?: string;
  isEventGroup?: boolean;
  groupLevel?: number;
};

function GroupTitle({
  name,
  memberCount,
  region,
  lastActivatedAt,
  profileImage,
  isEventGroup = false,
  groupLevel,
}: Props) {
  const { push } = useFlow();
  const existGroupLevel = groupLevel && groupLevel !== 0;

  const activityLog = useMemo(() => {
    if (!lastActivatedAt) return null;
    const activityDate = new Date(Date.parse(lastActivatedAt));
    const dateDiff = getDateDiffFromNow(activityDate);

    // 10일
    const DAY10 = 1000 * 60 * 60 * 24 * 10;
    if (dateDiff.diff / DAY10 > 1) return null;

    const renderText = `${dateDiff.text} 전 활동`;
    return (
      <>
        <VerticalSpacing size={4} />
        <Typography typography="caption1Regular" color="gray600">
          ∙
        </Typography>
        <VerticalSpacing size={4} />
        <Typography typography="caption1Regular" color="primary">
          {renderText}
        </Typography>
      </>
    );
  }, [lastActivatedAt]);

  const handleGroupThumbnailClick = () => {
    if (!profileImage) return;
    const resizedImageUrl = resizeImage(profileImage, {
      quality: 'high',
      size: '1200x1200',
      type: 'outside',
    });

    push('ImageViewerPage', {
      content: 'images',
      imageUrls: JSON.stringify([resizedImageUrl]),
      initialIndex: '0',
    });
  };

  return (
    <div className={s.Layout}>
      <div className={s.Thumbnail} onClick={handleGroupThumbnailClick}>
        <div className={s.BorderWrapper} />
        <img src={profileImage} alt="group-thumbnail" className={s.ProfileImage} />
        {existGroupLevel ? (
          <GroupLevel2DBadge
            level={groupLevel}
            type="small"
            size={28}
            className={s.GroupLevelBadge}
          />
        ) : null}
      </div>
      <div className={s.InformationSection}>
        <div className={s.TitleContainer}>
          <Typography typography="title3Bold" color="gray900" ellipsisAfterLines={1}>
            {name}
          </Typography>
        </div>
        <div className={s.DescriptionContainer}>
          {region?.town && (
            <Typography typography="caption1Regular" color="gray600">
              {region?.town} ∙
            </Typography>
          )}
          <Typography typography="caption1Regular" color="gray600">
            {isEventGroup ? `${memberCount}명 참여` : `멤버 ${memberCount}`}
          </Typography>
          {activityLog}
        </div>
      </div>
    </div>
  );
}

export default GroupTitle;
