import {
  GroupFeedSummaryType,
  PostTypePresentationType,
} from '@community-group/api/lib/group/models';
import { match } from 'ts-pattern';

export const overrideFeedTypeToPostType = (
  feed: GroupFeedSummaryType
): PostTypePresentationType => {
  return match(feed)
    .with('group_meetup_review', () => 'meetupReview' as const)
    .with('challenge', () => 'challenge' as const)
    .otherwise(() => 'post' as const);
};
