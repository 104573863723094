import { groupClient } from '@community-group/api';
import { GroupMeetupDetailPresentation } from '@community-group/api/lib/group/models';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import { useRead } from '@/shared/api/hooks/useRead';

import { GROUP_URL } from '../base/group';

export const getGroupMeetupDetailPath = (groupId: string, meetupId: string) =>
  `${GROUP_URL}/${groupId}/meetups/${meetupId}`;

type Params = {
  groupId: string;
  meetupId: string;
};

export const useReadGroupMeetupDetail = ({ groupId, meetupId }: Params) => {
  const read = useRead(groupClient.api.MeetupApi.apiV1GroupsGroupIdMeetupsMeetupIdGet);

  const { data, isLoading, isError, error, refetch } = useSuspenseQuery({
    queryKey: [getGroupMeetupDetailPath(groupId, meetupId)],
    queryFn: () => {
      if (!meetupId) return null;

      return read({
        groupId: Number(groupId),
        meetupId: Number(meetupId),
      });
    },
  });

  const response = data?.data.groupMeetup as GroupMeetupDetailPresentation;

  return { data: response, isLoading, isError, error, refetch };
};

export const useReadGroupMeetupDetailNoSuspense = ({ groupId, meetupId }: Params) => {
  const read = useRead(groupClient.api.MeetupApi.apiV1GroupsGroupIdMeetupsMeetupIdGet);

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: [getGroupMeetupDetailPath(groupId, meetupId)],
    queryFn: () => read({ groupId: Number(groupId), meetupId: Number(meetupId) }),
    enabled: !!meetupId,
    throwOnError: true,
  });

  const response = data?.data.groupMeetup as GroupMeetupDetailPresentation;

  return { data: response, isLoading, isError, refetch };
};
