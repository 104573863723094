import { GroupMemberRoleEnum } from '@community-group/api/lib/group/models';
import { useCallback } from 'react';
import { match, P } from 'ts-pattern';

import { useReadGroupDirectChatState } from '@/domain/GroupDirectChat/hooks/useReadGroupDirectChatState';

import { GroupDirectChatStatusOnAwaitingApprovalButton } from './DirectChatStatusButton/GroupDirectChatStatusOnAwaitingApprovalButton';
import { GroupDirectChatStatusOnEnabledButton } from './DirectChatStatusButton/GroupDirectChatStatusOnEnabledButton';
import { GroupDirectChatStatusOnRequiredApprovalButton } from './DirectChatStatusButton/GroupDirectChatStatusOnRequiredApprovalButton';
import * as s from './style.css';

type Props = {
  groupId: string;
  targetUserId: string;
  nickname: string;
  requestUserRole: GroupMemberRoleEnum;
};

const GroupDirectChatButtonV2 = ({ groupId, targetUserId, nickname, requestUserRole }: Props) => {
  const { data: directChatState } = useReadGroupDirectChatState({
    groupId,
    userId: targetUserId,
  });

  const { directChatEnabledStatus, requestGroupDirectChatId } = directChatState;

  const ChatButtons = useCallback(() => {
    return match({
      status: directChatEnabledStatus,
      requestId: requestGroupDirectChatId,
    })
      .with({ status: 'required_approval' }, () => (
        <div className={s.Wrapper}>
          <GroupDirectChatStatusOnRequiredApprovalButton
            groupId={groupId}
            targetUserId={targetUserId}
            targetUserName={nickname}
            requestUserRole={requestUserRole}
          />
        </div>
      ))
      .with({ status: 'awaiting_approval', requestId: P.not(P.nullish) }, ({ requestId }) => (
        <div className={s.Wrapper}>
          <GroupDirectChatStatusOnAwaitingApprovalButton
            groupId={groupId}
            targetUserId={targetUserId}
            requestUserRole={requestUserRole}
            requestGroupDirectChatId={requestId}
          />
        </div>
      ))
      .with({ status: 'enabled' }, () => (
        <div className={s.Wrapper}>
          <GroupDirectChatStatusOnEnabledButton
            groupId={groupId}
            targetUserId={targetUserId}
            requestUserRole={requestUserRole}
            requestUserDirectChatEnabledStatus={directChatEnabledStatus}
          />
        </div>
      ))
      .otherwise(() => <></>);
  }, [
    directChatEnabledStatus,
    groupId,
    nickname,
    requestGroupDirectChatId,
    requestUserRole,
    targetUserId,
  ]);

  return <ChatButtons />;
};

export default GroupDirectChatButtonV2;
