import { BoxButton } from '@community-group/components';

const GroupDirectChatFallbackButton = () => {
  return (
    <BoxButton
      size="medium"
      variant="secondary"
      UNSAFE_style={{
        width: '100%',
      }}
    >
      채팅하기
    </BoxButton>
  );
};

export default GroupDirectChatFallbackButton;
