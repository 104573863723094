import { groupClient } from '@community-group/api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

import { GROUP_MEMBER_QUERY_KEY } from '../queries';

export const useQueryGroupMemberApplicationCount = (groupId: string) => {
  const read = useAsyncRead(
    groupClient.api.GroupMemberApi.apiV1GroupsIdMembersGradeApplicationCountGet
  );
  return {
    queryKey: GROUP_MEMBER_QUERY_KEY.memberGradeApplicationCount(groupId),
    queryFn: () => read({ id: Number(groupId) }).then((res) => res.totalGradeApplicationCount),
  };
};

export const useReadGroupMemberApplicationCount = (groupId: string) => {
  const query = useQueryGroupMemberApplicationCount(groupId);
  return useSuspenseQuery({ ...query });
};
