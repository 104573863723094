import {
  BoxButton,
  Render,
  Spacing,
  Typography,
  useSnackbarAdapter,
  VerticalSpacing,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import {
  IconCalendarFill,
  IconCheckmarkLine,
  IconExclamationmarkCircleLine,
  IconEyeFill,
  IconHorizline2VerticalChatbubbleRectangularRightFill,
  IconPenHorizlineFill,
} from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { useSuspenseQueries } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useMemo } from 'react';

import { useQueryGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { usePostGroupMemberGradeApplication } from '@/domain/GroupMember/hooks/usePostGroupMemberGradeApplication';
import { useQueryGroupMemberGradeApplicationStatus } from '@/domain/GroupMember/hooks/useReadGroupMemberGradeApplicationStatus';
import { useQueryGroupMemberGradeRequirements } from '@/domain/GroupMember/hooks/useReadGroupMemberGradeRequirements';
import { useQueryGroupProfileUserActivities } from '@/domain/GroupProfile/hooks/useReadGroupProfileUserActivities';

import MemberGradeIcon from './MemberGradeIcon';
import RequirementProgress from './RequirementProgress';
import * as s from './style.css';

type Props = {
  groupId: string;
  userId: string;
};

const MyMemberGradeSection = ({ groupId, userId }: Props) => {
  const { create: openSnackbar, dismiss: closeSnackbar } = useSnackbarAdapter();

  const [
    { data: me },
    {
      data: { count, activities },
    },
    { data: requirements },
    { data: isApplicationPending, refetch: refetchGroupMemberGradeApplicationStatus },
  ] = useSuspenseQueries({
    queries: [
      useQueryGroupMe({ groupId }),
      useQueryGroupProfileUserActivities({ groupId, userId }),
      useQueryGroupMemberGradeRequirements(groupId),
      useQueryGroupMemberGradeApplicationStatus({ groupId, userId }),
    ],
  });

  const { mutateAsync: postGroupMemberGradeApplication } = usePostGroupMemberGradeApplication({
    onSuccess: () => {
      refetchGroupMemberGradeApplicationStatus();
    },
  });
  const handleApplicationButtonClick = async () => {
    await postGroupMemberGradeApplication({ groupId, userId });
    openSnackbar({
      message: '등업을 신청했어요. 승인되면 다시 알려드릴게요.',
      type: 'default',
      timeout: 3000,
      onClick: closeSnackbar,
    });
  };

  const activityStatuses = useMemo(() => {
    const activityMap = {
      visit_group: {
        key: 'groupVisitCount',
        label: '모임 방문',
        icon: <IconEyeFill size={24} color={vars.$scale.color.gray700} />,
        order: 1,
      },
      create_post: {
        key: 'createdPostAndMeetupCount',
        label: '게시글',
        icon: <IconPenHorizlineFill size={24} color={vars.$scale.color.gray700} />,
        order: 2,
      },
      create_comment: {
        key: 'createdCommentCount',
        label: '댓글',
        icon: (
          <IconHorizline2VerticalChatbubbleRectangularRightFill
            size={24}
            color={vars.$scale.color.gray700}
          />
        ),
        order: 3,
      },
      join_meetup: {
        key: 'joinedMeetupCount',
        label: '일정 참여',
        icon: <IconCalendarFill size={24} color={vars.$scale.color.gray700} />,
        order: 4,
      },
    };

    return requirements
      .map((requirement) => {
        const activity = activityMap[requirement.activity];
        if (activity.key === 'groupVisitCount') {
          return {
            ...requirement,
            ...activity,
            count: activities.groupVisitCount,
          };
        }

        return {
          ...requirement,
          ...activity,
          count: count[activity.key],
          order: activity.order,
        };
      })
      .sort((a, b) => a.order - b.order)
      .filter((activity) => activity.goalCount > 0);
  }, [requirements, count, activities.groupVisitCount]);
  const isMaxRequirements = activityStatuses.length === requirements.length;
  const isStandardMember = me.grade.name === '준회원';
  const isAllRequirementMet = useMemo(() => {
    return activityStatuses.every((activity) => activity.count >= activity.goalCount);
  }, [activityStatuses]);

  return (
    <div className={s.Container}>
      <div className={s.MyMemberGradeContainer}>
        <Typography typography="subtitle2Regular" color="gray900">
          내 멤버 등급
        </Typography>
        <Spacing size={8} />
        <div className={s.MemberGradeContainer}>
          <MemberGradeIcon grade={me.grade} />
          <VerticalSpacing size={6} />
          <Typography typography="title1Bold" color="gray900">
            {me.grade.name}
          </Typography>
        </div>
        <Spacing size={16} />
        <Typography typography="subtitle2Regular" color="gray600">
          {dayjs(me.joinedAt).format('YYYY년 M월 DD일')} 가입
        </Typography>
      </div>
      <Render condition={isStandardMember}>
        <Spacing size={24} />
        <ul className={s.RequirementList({ isMaxRequirements })}>
          {activityStatuses.map((activity) => (
            <li key={activity.key}>
              <RequirementProgress
                label={activity.label}
                representativeIcon={activity.icon}
                currentCount={activity.count}
                goalCount={activity.goalCount}
              />
            </li>
          ))}
        </ul>
      </Render>
      <Render condition={isStandardMember}>
        <Spacing size={24} />
        <Render condition={!isApplicationPending}>
          <BoxButton
            variant="primaryLow"
            size="medium"
            width="100%"
            isDisabled={!isAllRequirementMet}
            onClick={handleApplicationButtonClick}
          >
            등업 신청
          </BoxButton>
          <Render condition={!isAllRequirementMet}>
            <Spacing size={8} />
            <div className={s.RequirementGuideMessage}>
              <IconExclamationmarkCircleLine size={16} color={vars.$scale.color.gray600} />
              <VerticalSpacing size={4} />
              <Typography typography="label3Regular" color="gray600">
                아직 등업 조건이 충족되지 않았어요.
              </Typography>
            </div>
          </Render>
        </Render>
        <Render condition={isApplicationPending}>
          <BoxButton
            variant="primaryLow"
            size="medium"
            width="100%"
            prefix={<IconCheckmarkLine size={16} fill={vars.$scale.color.gray300} />}
            isDisabled
          >
            등업 신청 완료
          </BoxButton>
        </Render>
      </Render>
    </div>
  );
};

export default withAsyncBoundary(MyMemberGradeSection, {
  pendingFallback: <ViewLoader />,
  rejectedFallback: <></>,
});
