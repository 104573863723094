import { groupClient } from '@community-group/api';
import { useInfiniteQuery, useSuspenseInfiniteQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

export type SEARCH_MEETUP_ORDER_TYPE = 'created_at_desc' | 'meet_at_asc';

type Params = {
  searchQuery?: string;
  referrer?: string;
  limit?: number;
  order?: SEARCH_MEETUP_ORDER_TYPE;
  initialCursor?: string | undefined;
};

export const useGetMeetupSearchList = ({ initialCursor, ...params }: Params) => {
  const fetchInstance = useFetchInstance();
  const getMeetupSearchList = groupClient.api.GroupMeetupSearchApi.apiV1GroupMeetupSearchGet({
    axios: fetchInstance,
  });

  const { fetchNextPage, hasNextPage, isFetchingNextPage, data, refetch } =
    useSuspenseInfiniteQuery({
      queryKey: [
        groupClient.api.GroupMeetupSearchApi.getapiV1GroupMeetupSearchGetPath(
          params.searchQuery ?? '',
          20,
          params.referrer ?? '',
          params.order ?? 'meet_at_asc'
        ),
        params,
      ],
      queryFn: ({ pageParam = initialCursor }) =>
        getMeetupSearchList({
          query: params.searchQuery ?? '',
          limit: 20,
          referrer: params.referrer ?? '',
          order: params.order ?? 'meet_at_asc',
          cursor: pageParam,
        }),
      initialPageParam: initialCursor,
      getNextPageParam: ({ data }) => (data.nextCursor ? data?.nextCursor : undefined),
      refetchInterval: 10000,
    });

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
  };
};
