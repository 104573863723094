import { AnimatePresence } from 'framer-motion';
import React, {
  createContext,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { AsyncBoundary } from '../../components';
import { BottomSheet } from '../../components/BottomSheet';
import { Overlay } from '../../components/Overlay';
import { useModalState } from '../../hooks/useModalState';

export interface BottomSheetOptions {
  element: ReactNode;
  config?: {
    dimAnimationDuration?: number;
    bottomSheetAnimationDuration?: number;
    isDimAnimation?: boolean;
    dimOpacity?: number;
  };
  onOpened?: () => void;
  onClosed?: () => void;
  onDimClose?: MouseEventHandler<HTMLDivElement>;
}

export interface BottomSheetContextProps {
  open: (options: BottomSheetOptions) => void;
  close: () => void;
  closeAsync: () => Promise<void>;
  setBottomSheetOptions: (options: BottomSheetOptions) => void;
  isOpen: boolean;
}

const BottomSheetContext = createContext<BottomSheetContextProps | undefined>(undefined);

export const BottomSheetProvider = ({ children }: { children: ReactNode }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [bottomSheetOptions, setBottomSheetOptions] = useState<BottomSheetOptions>({
    element: <></>,
    config: {
      dimAnimationDuration: DIM_DURATION,
      bottomSheetAnimationDuration: BOTTOM_SHEET_DURATION,
      isDimAnimation: true,
      dimOpacity: 1,
    },
  });

  const { config } = bottomSheetOptions;

  const { isModalOpen, setModalClose } = useModalState({
    isModalOpen: isOpen,
    setModalClose: () => setIsOpen(false),
  });

  const isOpenRef = useRef(isOpen);

  const open = useCallback((options: BottomSheetOptions) => {
    if (isOpenRef.current) {
      setModalClose();
    }
    setBottomSheetOptions({ ...options });
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setModalClose();
  }, []);

  const closeAsync = useCallback(
    () =>
      new Promise<void>((resolve) => {
        setModalClose();

        setTimeout(() => resolve(undefined), CLOSE_DURATION);
      }),
    []
  );

  const controls = useMemo(
    () => ({ open, close, closeAsync, setBottomSheetOptions, isOpen }),
    [open, close, closeAsync, setBottomSheetOptions, isOpen]
  );

  const handleDimClose = (e: React.MouseEvent<HTMLDivElement>) => {
    if (bottomSheetOptions && bottomSheetOptions.onDimClose) {
      return bottomSheetOptions.onDimClose(e);
    }

    close();
  };

  useEffect(() => {
    isOpenRef.current = isModalOpen;
  }, [isModalOpen, isOpen]);

  return (
    <BottomSheetContext.Provider value={controls}>
      {children}
      <AnimatePresence>
        {isOpen && (
          <BottomSheet
            isOpen={Boolean(isOpen)}
            dimDuration={config?.bottomSheetAnimationDuration ?? BOTTOM_SHEET_DURATION}
          >
            <AsyncBoundary pendingFallback={<></>} rejectedFallback={<></>}>
              {bottomSheetOptions.element}
            </AsyncBoundary>
          </BottomSheet>
        )}
      </AnimatePresence>
      {config?.isDimAnimation ??
        (true && (
          <Overlay
            dimOpacity={config?.dimOpacity}
            isOpen={Boolean(isOpen)}
            onDimClose={handleDimClose}
            dimDuration={config?.dimAnimationDuration ?? DIM_DURATION}
          />
        ))}
    </BottomSheetContext.Provider>
  );
};

export const useBottomSheet = () => {
  const controls = useContext(BottomSheetContext);

  if (controls === undefined) {
    throw new Error('BottomSheetContext 안에서 사용해주세요.');
  }

  return controls;
};

const CLOSE_DURATION = 200;
const DIM_DURATION = 0.15;
const BOTTOM_SHEET_DURATION = 0.2;
