import {
  Callout,
  CalloutDescription,
  CalloutTitle,
  Spacing,
  Switch,
  Typography,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { IconChevronRightLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { ActivityComponentType } from '@stackflow/react';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback } from 'react';
import { Controller, ControllerRenderProps, FieldValues, useForm } from 'react-hook-form';

import { useReadGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { usePutGroup } from '@/api/hooks/usePutGroup';
import SubNicknamePreview from '@/components/common/GroupMemberSubNicknamePreview/SubNicknamePreview';
import { useBridge } from '@/contexts/Bridge';
import { useConnectedFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';
import { refetchCurrentUser } from '@/utils/refetch/currentUser';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';
import { refetchGroupProfile } from '@/utils/refetch/groupGroupProfile';
import { refetchGroupSetting } from '@/utils/refetch/groupSetting';

import * as s from './groupSettingEditSubNickname.css';

export type GroupSettingPageParams = Pick<PageParams, 'groupId'>;

const GroupSettingEditSubNicknamePage: ActivityComponentType<GroupSettingPageParams> = () => {
  const { groupId } = usePathParams();
  const { from } = useQueryParams();

  const { push } = useConnectedFlow();

  const { group } = useReadGroupDetail(groupId);

  const { watch, control } = useForm<{
    hasSubNickname: boolean;
  }>({
    defaultValues: {
      hasSubNickname: group?.subNicknameSetting?.isSettingOn ?? false,
    },
  });

  const subNicknameSetting = group?.subNicknameSetting;
  const hasSubNicknameFieldValue = watch('hasSubNickname');

  const { bridge } = useBridge();
  const { mutate } = usePutGroup({
    onSuccess: () => {
      bridge.openToast({
        toast: {
          body: '별명이 잠시 후 반영돼요.',
        },
      });
      trackEvent({
        event: 'click_setting_on_subnickname',
        params: {
          groupId: groupId,
          groupName: group?.name,
          groupCategory: group?.category.name,
          isSettingOnSubnickname: false,
          subnicknameRequestText: '',
          from,
        },
      });
      refetchGroupDetail({ groupId });
      refetchCurrentUser({ groupId });
      refetchGroupProfile();
      refetchGroupSetting({ groupId });
    },
  });

  const handleSubNicknameSetting = useCallback(async () => {
    const responseData = (await push('BottomSheet/GroupSettingEditSubNicknameQuestionBottomSheet', {
      groupId,
    })) as { isSettingOn: boolean };
    return responseData.isSettingOn;
  }, [groupId, push]);

  const renderRightSide = () => {
    if (subNicknameSetting?.isSettingOn) {
      return <IconChevronRightLine size={20} />;
    }
    return (
      <Typography typography="label2Regular" color="carrot500">
        입력하기
      </Typography>
    );
  };

  const handleSwitchOn = useCallback(
    async (onChange: ControllerRenderProps['onChange']) => {
      const isSettingOn = await handleSubNicknameSetting();
      onChange(isSettingOn);
    },
    [handleSubNicknameSetting]
  );
  const handleSwitchOff = useCallback(
    (onChange: ControllerRenderProps['onChange'], isSelected: boolean) => {
      mutate(
        {
          id: groupId,
          groupModifyForm: {
            subNicknameSetting: {
              isSettingOn: false,
              requestText: undefined,
            },
          },
        },
        { onError: () => onChange(!isSelected) }
      );
    },
    [groupId, mutate]
  );

  return (
    <AppScreen
      appBar={{
        title: '별명',
        border: true,
        borderColor: vars.$semantic.color.divider3,
        borderSize: '0.5px',
      }}
    >
      <div style={{ padding: '1rem' }}>
        <Callout>
          <CalloutTitle>안내</CalloutTitle>
          <CalloutDescription>별명은 이 모임에서만 닉네임 옆에 표시돼요.</CalloutDescription>
        </Callout>
        <Spacing size={16} />
        <SubNicknamePreview subNicknameText="별명" />
        <div className={s.groupSettingEditSubNicknameSwitchBox}>
          <Typography typography="bodyL1Regular" color="gray900">
            별명 사용
          </Typography>
          <Controller
            control={control}
            name="hasSubNickname"
            render={({ field: { onChange, name, value } }) => (
              <Switch
                onChange={async (isSelected) => {
                  onChange(isSelected);
                  if (isSelected) return handleSwitchOn(onChange);
                  handleSwitchOff(onChange, isSelected);
                }}
                name={name}
                isSelected={value}
              />
            )}
          />
        </div>
        <Spacing size={8} />
        <AnimatePresence>
          {hasSubNicknameFieldValue && (
            <motion.div
              className={s.groupSettingEditSubNicknameWrapper}
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
              }}
              transition={{
                duration: 0.1,
              }}
              exit={{ opacity: 0 }}
              onClick={handleSubNicknameSetting}
            >
              <div className={s.groupSettingEditSubNicknameRequestMessageBox}>
                <Typography typography="bodyM1Regular" color="gray900">
                  별명 입력 요청 문구
                </Typography>
                {subNicknameSetting?.requestText && (
                  <Typography typography="caption1Regular" color="gray600">
                    {subNicknameSetting?.requestText}
                  </Typography>
                )}
              </div>
              {renderRightSide()}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupSettingEditSubNicknamePage, {
  pendingFallback: (
    <>
      <AppScreen>
        <ViewLoader />
      </AppScreen>
    </>
  ),
  rejectedFallback: (
    <>
      <AppScreen>
        <ViewError />
      </AppScreen>
    </>
  ),
});
