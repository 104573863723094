import { Typography } from '@community-group/components';
import React from 'react';

import * as s from './GuideCallout.css';

const BonusCallout = () => {
  return (
    <div className={s.OuterContainer}>
      <div className={s.Container}>
        <Typography typography="bodyL2Bold" color="gray900">
          🔥 예상 지급액보다 더 많은 당근머니를 받았어요!
        </Typography>
        <Typography typography="bodyL2Regular" color="gray900">
          월, 목요일마다 2~30배 많은 당근머니를 드려요.
        </Typography>
      </div>
    </div>
  );
};

export default BonusCallout;
