import { Typography } from '@community-group/components';
import { useMemo } from 'react';
import { Controller } from 'react-hook-form';

import useShowPhotoContestInOngoingExerciseGroup from '@/components/group/PhotoContest/hooks/useShowPhotoContestInOngoingExerciseGroup';
import { useReadGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useMembersForMentions } from '@/hooks/useMembersForMentions';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

import { ReviewPostFields } from '../common/ReviewPostFields';
import { ReviewRatingFields } from '../common/ReviewRatingFields';
import * as s from './style.css';
import { MeetupReviewFormHandler } from './type';

type Props = {
  formHandler: MeetupReviewFormHandler;
};

export const MeetupReviewForm = ({ formHandler }: Props) => {
  const { groupId } = usePathParams();
  const { data: currentUserData } = useReadGroupMe({ groupId });
  const mentionMemberList = useMembersForMentions(groupId);

  const userName = useMemo(() => {
    if (!currentUserData.nickname) return '';
    if (!currentUserData.subNickname) return currentUserData.nickname;

    return `${currentUserData.nickname}(${currentUserData.subNickname})`;
  }, [currentUserData.nickname, currentUserData.subNickname]);

  // TODO: 모임사진전
  const shownPhotoContest = useShowPhotoContestInOngoingExerciseGroup({ groupId });

  const { control, watch, resetField } = formHandler;

  const selectedReviewRating = useMemo(() => watch('reviewRating'), [watch('reviewRating')]);

  return (
    <section className={s.Wrapper}>
      <Typography typography="title2Bold" wordBreak="break-word">
        {userName}님,
        <br />
        모임 활동은 어떠셨나요?
      </Typography>
      <Controller
        control={control}
        name="reviewRating"
        render={({ field: { onChange, value } }) => {
          return (
            <ReviewRatingFields
              value={value}
              onChange={(value) => {
                onChange(value);
                resetField('reviewPost');
              }}
            />
          );
        }}
      />
      {selectedReviewRating && (
        <ReviewPostFields
          shownPhotoContest={shownPhotoContest}
          mentionMemberList={mentionMemberList}
          selectedReviewRating={selectedReviewRating}
          formHandler={formHandler}
        />
      )}
    </section>
  );
};
