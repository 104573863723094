import { useKeyboardSize } from '@community-group/components';
import { useCallback, useEffect, useRef } from 'react';

import { useBridge } from '@/contexts/Bridge';
import { useStore } from '@/store';

/**
 * keyboard height 변동에 따른 화면 전체 사이즈를 조정하는 hook
 * 이 훅 제거시 input의 위치에 따라 화면이 밀려 올라감
 * 이슈: karrot-plugin의 AppScreen 사용시에 scroll to top 로직과 충돌이 일어나 화면이 상단 고정 되는 이슈가 있음
 * 해당 이슈를 해결하기 위해 @stackflow/plugin-basic-ui의 plugin을 사용하고 @stackflow/plugin-basic-ui의 AppScreen 컴포넌트를 사용.
 *
 * @returns {*} fixedLayout 가변 제어 함수
 */

function useFixedLayoutSize() {
  const { bridge } = useBridge();
  const { keyboardHeight, visualViewportOffsetTop, isKeyboardOn } = useKeyboardSize(bridge);
  const defaultAppScreenStyle = useRef<any>(null);
  // 페이지 별로 fixedLayout을 사용할지 여부를 store에서 가져옴
  // useSetFixedLayoutSize 훅을 사용해 페이지 별로, 혹은 로직에 따라 fixedLayout을 사용할지 여부를 변경할 수 있음
  const { fixedLayout } = useStore();

  const handleFixedTopAndBottom = useCallback(
    (AppScreenEl: HTMLElement, { topOnly }: { topOnly?: boolean }) => {
      if (!AppScreenEl.style) return;

      AppScreenEl.style.top = `${visualViewportOffsetTop}px`;
      if (topOnly) return;
      AppScreenEl.style.bottom = isKeyboardOn
        ? `${keyboardHeight - visualViewportOffsetTop}px`
        : '0px';
    },
    [isKeyboardOn, keyboardHeight, visualViewportOffsetTop]
  );

  const initAppScreenStyle = useCallback(() => {
    // Chrome에서 outerHeight가 브라우저 전체 height로 계산하는 방식으로 변경됨. 이로 인해 local에서 의도대로 동작하지 않는 이슈가 있어서 dev일 때에는 동작하지 않도록 변경
    if (globalThis.appConfig.appEnv === 'local') return;

    const root = document.getElementById('root');
    const child = root?.children[0] ?? [];
    const AppScreen = child as HTMLElement;

    if (!AppScreen || !AppScreen.style) return;

    AppScreen.style.top = `0`;
    AppScreen.style.bottom = `0`;
    Object.keys(defaultAppScreenStyle.current).forEach((prop) => {
      AppScreen.style.setProperty(prop, defaultAppScreenStyle.current[prop]);
    });
  }, []);

  useEffect(() => {
    if (fixedLayout === false) {
      // fixedLayout이 false인 경우에는 기존의 AppScreen 스타일을 초기화 함
      initAppScreenStyle();
      return () => {
        initAppScreenStyle();
      };
    }

    // Chrome에서 outerHeight가 브라우저 전체 height로 계산하는 방식으로 변경됨. 이로 인해 local에서 의도대로 동작하지 않는 이슈가 있어서 dev일 때에는 동작하지 않도록 변경
    if (globalThis.appConfig.appEnv === 'local') return;

    const root = document.getElementById('root');
    if (root) {
      const child = root.children[0] ?? [];
      const AppScreen = child as HTMLElement;
      defaultAppScreenStyle.current = AppScreen.style;

      if (AppScreen && AppScreen.style) {
        AppScreen.style.height = 'auto';
        AppScreen.style.position = 'fixed';

        AppScreen.style.top = `0`;
        AppScreen.style.bottom = `0`;
        AppScreen.style.left = `0`;
        AppScreen.style.right = `0`;

        AppScreen.style.willChange = 'top, bottom, height';

        handleFixedTopAndBottom(AppScreen as HTMLElement, { topOnly: true });
        // visualViewport.offsetTop이 늦게 반영되어 0이 되는 경우가 있어 재보정하는 타이머를 추가함.
        const Timer = setTimeout(() => {
          handleFixedTopAndBottom(AppScreen as HTMLElement, {});
        }, 200);
        return () => {
          clearTimeout(Timer);
          initAppScreenStyle();
        };
      }
    }
  }, [
    fixedLayout,
    handleFixedTopAndBottom,
    initAppScreenStyle,
    keyboardHeight,
    visualViewportOffsetTop,
  ]);

  return undefined;
}

export default useFixedLayoutSize;
