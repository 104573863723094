import { Spacing, Typography } from '@community-group/components';
import { IconChevronRightSmallFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';

import { openLink } from '@/utils/link';

import * as s from './ChallengeListPromotionBanner.css';

// TODO: 챌린지 프로모션 랜드킷 수정 필요
export const CHALLENGE_PROMOTION_LANDKIT_LINK =
  globalThis.appConfig.appEnv === 'production'
    ? 'https://landkit.krrt.io/pages/j52vcn'
    : 'https://landkit-alpha.krrt.io/pages/u3hegd';

const ChallengeListPromotionBanner = ({ style }: { style?: React.CSSProperties }) => {
  const handleOnClickMoreButton = () => {
    openLink(CHALLENGE_PROMOTION_LANDKIT_LINK, true);
  };

  return (
    <div className={s.Container} style={style}>
      <div className={s.InfoContainer}>
        <Typography typography="bodyM2Regular" color="blue800">
          새해 목표 챌린지에 인증글 남기고, 매일 보상금 받아가세요!💰
        </Typography>
        <Spacing size={10} />
        <div className={s.MoreButton} onClick={handleOnClickMoreButton}>
          <Typography typography="label4Bold" color="blue800">
            이벤트 자세히 보기
          </Typography>
          <IconChevronRightSmallFill size={14} color={vars.$scale.color.blue800} />
        </div>
      </div>
      <img
        src="https://assetstorage.krrt.io/1025317940251365954/d761928e-8da8-46d2-94b8-80fe01ce8d0e/width=240,height=240.webp"
        className={s.ImageContainer}
      />
    </div>
  );
};

export default ChallengeListPromotionBanner;
