import { groupClient } from '@community-group/api';
import { useInfiniteQuery, useSuspenseInfiniteQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

export type SEARCH_MEETUP_ORDER_TYPE = 'created_at_desc' | 'meet_at_asc';

type Params = {
  groupId: number;
  userId: number;
  initialCursor?: string | undefined;
};

export const getMemberFeedsSummaryQueryKey = (groupId: number, userId: number) =>
  groupClient.api.GroupMemberApi.getapiV1GroupsIdMembersUserIdFeedsGetPath(groupId, userId);

export const useGetMemberFeeds = ({ groupId, userId, initialCursor }: Params) => {
  const fetchInstance = useFetchInstance();
  const getMemberFeeds = groupClient.api.GroupMemberApi.apiV1GroupsIdMembersUserIdFeedsGet({
    axios: fetchInstance,
  });

  const { fetchNextPage, hasNextPage, isFetchingNextPage, data, refetch } =
    useSuspenseInfiniteQuery({
      queryKey: [getMemberFeedsSummaryQueryKey(groupId, userId)],
      queryFn: ({ pageParam = initialCursor }) =>
        getMemberFeeds({
          id: Number(groupId),
          userId: Number(userId),
          cursor: pageParam,
          limit: 20,
        }),
      initialPageParam: initialCursor,
      getNextPageParam: ({ data }) => (data.endCursor ? data?.endCursor : undefined),
      refetchInterval: 10000,
    });

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
  };
};
