import {
  GroupMemberSummaryPresentation,
  GroupProfile,
  MainProfile,
} from '@community-group/api/lib/group/models';
import { useMemo } from 'react';

import { useGetMainProfile } from '@/api/hooks/useGetMainProfile';

import { useGetGroupProfile } from './useGetGroupProfile';

type Props = {
  userId: number;
  groupId: number;
  queryOptions?: {
    enabled?: boolean;
    suspense?: boolean;
    hasOnError?: boolean;
    onError?: (error: unknown) => void;
  };
  queryKeys?: (string | number)[];
};

export type GroupMainProfile = MainProfile & GroupProfile;

export const getGroupMainProfileQueryKey = (userId: number, groupId: number) => [
  'GroupMainProfile',
  userId,
  groupId,
];

// 통합 모임 프로필 조회 API
// 참고 URL: https://daangn.slack.com/archives/C0336FFHA8L/p1714380677716699
export const useGetGroupMainProfile = ({
  userId,
  groupId = 0,
  queryOptions,
  queryKeys = [],
}: Props) => {
  const currentQueryKeys = [...queryKeys, ...getGroupMainProfileQueryKey(userId, groupId)];

  const {
    data: mainData,
    isLoading: isMainLoading,
    refetch: refetchMain,
  } = useGetMainProfile({
    userId,
    queryOptions,
    queryKeys: currentQueryKeys,
  });

  const {
    data: groupData,
    isLoading: isGroupLoading,
    refetch: refetchGroup,
  } = useGetGroupProfile({
    groupId,
    userId,
    queryOptions,
    queryKeys: currentQueryKeys,
  });

  const data = useMemo(() => {
    if (!mainData || !groupData) {
      return undefined;
    }

    return {
      ...mainData,
      ...groupData,
    };
  }, [mainData, groupData]) as GroupMainProfile;

  const isLoading = isMainLoading || isGroupLoading;
  const refetch = () => {
    refetchMain();
    refetchGroup();
  };

  return { data, isLoading, refetch };
};

export const convertGroupMainProfileToGroupMemberSummary = (
  profile: GroupMainProfile
): GroupMemberSummaryPresentation => ({
  id: profile.id,
  role: profile.role,
  state: profile.state,
  temperature: profile.temperature,
  userRegion: profile.userRegion,
  nickname: profile.nickname,
  subNickname: profile?.subNickname,
  profileImage: profile.profileImage ?? '',
  description: profile?.description,
  isAccountDeleted: profile?.isAccountDeleted,
  createdAt: profile.createdAt,
  updatedAt: profile.updatedAt,
  deletedAt: profile.deletedAt,
  joinedAt: profile.joinedAt,
  verifiedUserInfoText: profile?.verifiedUserInfoText,
  verified: profile.verified,
  memberKey: profile.memberKey,
  userRegionCheckInCount: profile.userRegionCheckInCount,
});
