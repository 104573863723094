import {
  GroupCurrentUser,
  GroupMemberProfileResponseProfile,
  GroupMemberSummaryPresentation,
} from '@community-group/api/lib/group/models';

import IconWrapper from '@/components/common/Icons';
import { useHandleProfileMoreButtonV2 } from '@/domain/GroupProfile/utils/useHandleProfileMoreButtonV2';

type Props = {
  groupId: string;
  profile: GroupMemberProfileResponseProfile;
  currentUser: GroupCurrentUser;
  hostMemberList: GroupMemberSummaryPresentation[];
};
const GroupMemberProfileAppBarRightButton = ({
  groupId,
  profile,
  currentUser,
  hostMemberList,
}: Props) => {
  const { renderProfileMoreButton } = useHandleProfileMoreButtonV2({
    groupId,
    profileUser: profile,
    currentUser,
    hostMemberList,
  });
  const component = renderProfileMoreButton();
  if (!component) return <></>;
  return <IconWrapper>{component}</IconWrapper>;
};

export default GroupMemberProfileAppBarRightButton;
