import { GroupCurrentUser, GroupDetailPresentation } from '@community-group/api/lib/group/models';
import { Tab, TabList, TabPanel, TabPanelGroup, Tabs } from '@community-group/components';
import { PaginationLoader } from '@community-group/components/shared';
import { Suspense } from 'react';

import GroupProfileContentsTabComments from '@/features/GroupProfile/ContentsTab/Comments';
import GroupProfileContentsTabFeeds from '@/features/GroupProfile/ContentsTab/Feeds';
import GroupProfileContentsTabJoinedMeetups from '@/features/GroupProfile/ContentsTab/JoinedMeetups';

import * as s from './style.css';

type Props = {
  groupId: string;
  userId: string;
  groupDetail: GroupDetailPresentation;
  currentUser: GroupCurrentUser;
  totalContentsCount: number;
  commentCount: number;
  meetupCount: number;
};

const MemberContents = ({
  groupId,
  userId,
  groupDetail,
  currentUser,
  totalContentsCount,
  commentCount,
  meetupCount,
}: Props) => {
  const contents = [
    {
      value: 'feeds',
      label: `게시글 ${totalContentsCount}`,
      content: (
        <Suspense fallback={<PaginationLoader />}>
          <GroupProfileContentsTabFeeds
            groupId={groupId}
            userId={userId}
            groupDetail={groupDetail}
            currentUser={currentUser}
          />
        </Suspense>
      ),
    },
    {
      value: 'comments',
      label: `댓글 ${commentCount}`,
      content: (
        <Suspense fallback={<PaginationLoader />}>
          <GroupProfileContentsTabComments
            groupId={groupId}
            userId={userId}
            groupDetail={groupDetail}
          />
        </Suspense>
      ),
    },
    {
      value: 'meetups',
      label: `일정 참여 ${meetupCount}`,
      content: (
        <Suspense fallback={<PaginationLoader />}>
          <GroupProfileContentsTabJoinedMeetups
            groupId={groupId}
            userId={userId}
            shouldSetSubNickname={groupDetail.subNicknameSetting.isSettingOn}
          />
        </Suspense>
      ),
    },
  ];

  return (
    <div className={s.Wrapper}>
      <Tabs
        layout="fill"
        isSwipeable={false}
        defaultValue={contents[0].value}
        isLazy={true}
        lazyMode="unmount"
      >
        <TabList>
          {contents.map((content) => (
            <Tab key={content.value} value={content.value}>
              {content.label}
            </Tab>
          ))}
        </TabList>
        <TabPanelGroup>
          {contents.map((content) => (
            <TabPanel key={content.value} value={content.value}>
              {content.content}
            </TabPanel>
          ))}
        </TabPanelGroup>
      </Tabs>
    </div>
  );
};

export default MemberContents;
