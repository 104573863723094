export const exerciseTemplates = [
  {
    name: '매일 운동 인증해요! 🏋️',
    defaultData: { name: '매일 운동 인증해요! 🏋️' },
  },
  {
    name: '하루 10분 스트레칭 습관 만들어요! 🧘',
    defaultData: { name: '하루 10분 스트레칭 습관 만들어요! 🧘' },
  },
  {
    name: '매일 만 보 걷기 도전 🚶‍♂️',
    defaultData: { name: '매일 만 보 걷기 도전 🚶‍♂️' },
  },
  {
    name: '건강한 식단 인증해요! 🥗',
    defaultData: { name: '건강한 식단 인증해요! 🥗' },
  },
  {
    name: '나만의 홈트 루틴을 공유해요! 🏠',
    defaultData: { name: '나만의 홈트 루틴을 공유해요! 🏠' },
  },
];

export const selfDevelopmentTemplates = [
  {
    name: '미라클 모닝 도전해요! ⏰',
    defaultData: { name: '미라클 모닝 도전해요! ⏰' },
  },
  {
    name: '하루 한 단어 영어 공부를 인증해요! 📚',
    defaultData: { name: '하루 한 단어 영어 공부를 인증해요! 📚' },
  },
  {
    name: '오늘의 공부 시간을 기록해요! ✍️',
    defaultData: { name: '오늘의 공부 시간을 기록해요! ✍️' },
  },
  {
    name: '일주일에 한 권 책 읽기 도전해요! 📖',
    defaultData: { name: '일주일에 한 권 책 읽기 도전해요! 📖' },
  },
  {
    name: '오늘의 목표 3가지 공유하기 🎯',
    defaultData: { name: '오늘의 목표 3가지 공유하기 🎯' },
  },
];

export const neighborFriendTemplates = [
  {
    name: '하루 한 번 생존 신고해요! 🙋‍♀️',
    defaultData: { name: '하루 한 번 생존 신고해요! 🙋‍♀️' },
  },
  {
    name: '동네 카페 탐방하고 인증해요! ☕',
    defaultData: { name: '동네 카페 탐방하고 인증해요! ☕' },
  },
  {
    name: '동네 산책하고 풍경 사진 남겨요! 🌳',
    defaultData: { name: '동네 산책하고 풍경 사진 남겨요! 🌳' },
  },
  {
    name: '같이 가고 싶은 맛집 하나씩 공유해요! 🍴',
    defaultData: { name: '같이 가고 싶은 맛집 하나씩 공유해요! 🍴' },
  },
  {
    name: '주말에 가볼만한 곳 추천하기 📍',
    defaultData: { name: '주말에 가볼만한 곳 추천하기 📍' },
  },
];

export const outdoorTravelTemplates = [
  {
    name: '등산 다녀오고 인증해요! 🥾',
    defaultData: { name: '등산 다녀오고 인증해요! 🥾' },
  },
  {
    name: '캠핑 요리 인증샷 남겨요! 🍲',
    defaultData: { name: '캠핑 요리 인증샷 남겨요! 🍲' },
  },
  {
    name: '주말에 갔던 근교 여행지 추천하기 🏕',
    defaultData: { name: '주말에 갔던 근교 여행지 추천하기 🏕' },
  },
  {
    name: '여행 버킷리스트 공유하기 🗺️',
    defaultData: { name: '여행 버킷리스트 공유하기 🗺️' },
  },
  {
    name: '여행 중 찍은 최고의 풍경 사진 공유하기 🌅',
    defaultData: { name: '여행 중 찍은 최고의 풍경 사진 공유하기 🌅' },
  },
];

export const familyParentingTemplates = [
  {
    name: '아이와 함께 독서해요! 📚',
    defaultData: { name: '아이와 함께 독서해요! 📚' },
  },
  {
    name: '하루 한 번 육아 일기를 작성해요! 🍼',
    defaultData: { name: '하루 한 번 육아 일기를 작성해요! 🍼' },
  },
  {
    name: '육아 중 얻은 행복한 순간 공유해요! 😊',
    defaultData: { name: '육아 중 얻은 행복한 순간 공유해요! 😊' },
  },
  {
    name: '우리집 육아 노하우 자랑해요! 🍼',
    defaultData: { name: '우리집 육아 노하우 자랑해요! 🍼' },
  },
  {
    name: '오늘 아이가 한 귀여운 말 공유해요! 🗣️💕',
    defaultData: { name: '오늘 아이가 한 귀여운 말 공유해요! 🗣️💕' },
  },
];

export const petTemplates = [
  {
    name: '1일 1산책 인증해요! 🐾',
    defaultData: { name: '1일 1산책 인증해요! 🐾' },
  },
  {
    name: '오늘의 귀여운 순간 포착하기 📸',
    defaultData: { name: '오늘의 귀여운 순간 포착하기 📸' },
  },
  {
    name: '오늘의 반려동물 기분을 표현해요! 😺🐶',
    defaultData: { name: '오늘의 반려동물 기분을 표현해요! 😺🐶' },
  },
  {
    name: '펫푸드 레시피 공유해요! 🍖',
    defaultData: { name: '펫푸드 레시피 공유해요! 🍖' },
  },
  {
    name: '아기 시절 사진 소환 🕰️',
    defaultData: { name: '아기 시절 사진 소환 🕰️' },
  },
];

export const foodBeverageTemplates = [
  {
    name: '나만의 집밥 레시피 공유해요! 🍳',
    defaultData: { name: '나만의 집밥 레시피 공유해요! 🍳' },
  },
  {
    name: '건강한 아침 식단 인증해요! 🥗',
    defaultData: { name: '건강한 아침 식단 인증해요! 🥗' },
  },
  {
    name: '오늘의 맛있는 한 끼 인증! 🍔',
    defaultData: { name: '오늘의 맛있는 한 끼 인증! 🍔' },
  },
  {
    name: '나만의 맛집 리스트를 작성해요! 🗒️',
    defaultData: { name: '나만의 맛집 리스트를 작성해요! 🗒️' },
  },
  {
    name: '주말에 만든 디저트 인증해요 🍰',
    defaultData: { name: '주말에 만든 디저트 인증해요 🍰' },
  },
];

export const hobbyEntertainmentTemplates = [
  {
    name: '하루 30분 취미 시간 기록해요! 🎨',
    defaultData: { name: '하루 30분 취미 시간 기록해요! 🎨' },
  },
  {
    name: '오늘의 영화/드라마 감상평을 남겨요! 🎬',
    defaultData: { name: '오늘의 영화/드라마 감상평을 남겨요! 🎬' },
  },
  {
    name: '오늘 한 손뜨개 작품 자랑하기 🧶',
    defaultData: { name: '오늘 한 손뜨개 작품 자랑하기 🧶' },
  },
  {
    name: '오늘의 레벨업 자랑하기! 🕹️',
    defaultData: { name: '오늘의 레벨업 자랑하기! 🕹️' },
  },
  {
    name: '스트레스 날리는 힐링 게임 추천🪄',
    defaultData: { name: '스트레스 날리는 힐링 게임 추천🪄' },
  },
];

export const readingHumanitiesTemplates = [
  {
    name: '하루 10분 독서 습관을 만들어요! 📖',
    defaultData: { name: '하루 10분 독서 습관을 만들어요! 📖' },
  },
  {
    name: '기분 전환하기 좋은 책 추천하기! 🌞',
    defaultData: { name: '기분 전환하기 좋은 책 추천하기! 🌞' },
  },
  {
    name: '인상 깊었던 문장을 공유해요! ✍️',
    defaultData: { name: '인상 깊었던 문장을 공유해요! ✍️' },
  },
  {
    name: '주제를 정해 에세이 한 편을 작성해요! 📝',
    defaultData: { name: '주제를 정해 에세이 한 편을 작성해요! 📝' },
  },
  {
    name: '올해 읽고 싶은 책 리스트 공유하기 📚',
    defaultData: { name: '올해 읽고 싶은 책 리스트 공유하기 📚' },
  },
];

export const cultureArtTemplates = [
  {
    name: '내가 만든 작품 자랑하고 감상평 나눠요! 🎨',
    defaultData: { name: '내가 만든 작품 자랑하고 감상평 나눠요! 🎨' },
  },
  {
    name: '오늘의 영감 공유하기! 💡',
    defaultData: { name: '오늘의 영감 공유하기! 💡' },
  },
  {
    name: '같이 가고 싶은 전시회를 공유해요! 🏛️',
    defaultData: { name: '같이 가고 싶은 전시회를 공유해요! 🏛️' },
  },
  {
    name: '매일 작업 과정 공유해요! 🖌️',
    defaultData: { name: '매일 작업 과정 공유해요! 🖌️' },
  },
  {
    name: '인생 영화, 인생 작품 추천해요! 🎬',
    defaultData: { name: '인생 영화, 인생 작품 추천해요! 🎬' },
  },
];

export const musicInstrumentTemplates = [
  {
    name: '매일 30분 악기 연습에 도전해요! 🎻',
    defaultData: { name: '매일 30분 악기 연습에 도전해요! 🎻' },
  },
  {
    name: '하루 한 곡 노래방 연습 인증 🎤',
    defaultData: { name: '하루 한 곡 노래방 연습 인증 🎤' },
  },
  {
    name: '주간 플레이리스트 공유하기 🎶',
    defaultData: { name: '주간 플레이리스트 공유하기 🎶' },
  },
  {
    name: '악기 연주 영상 올리고 피드백 받기 🎹',
    defaultData: { name: '악기 연주 영상 올리고 피드백 받기 🎹' },
  },
  {
    name: '매일 아침 나만의 음악으로 하루 시작하기 🌅',
    defaultData: { name: '매일 아침 나만의 음악으로 하루 시작하기 🌅' },
  },
];

export const defaultTemplates = [
  {
    name: '매일 운동 인증해요! 🏋️',
    defaultData: { name: '매일 운동 인증해요! 🏋️' },
  },
  {
    name: '하루 10분 독서 습관을 만들어요! 📖',
    defaultData: { name: '하루 10분 독서 습관을 만들어요! 📖' },
  },
  {
    name: '하루 한 번 생존 신고해요! 🙋‍♀️',
    defaultData: { name: '하루 한 번 생존 신고해요! 🙋‍♀️' },
  },
  {
    name: '동네 산책하고 풍경 사진 남겨요! 🌳',
    defaultData: { name: '동네 산책하고 풍경 사진 남겨요! 🌳' },
  },
  {
    name: '미라클 모닝 도전해요! ⏰',
    defaultData: { name: '미라클 모닝 도전해요! ⏰' },
  },
];

export const getTemplatesByCategory = (category: string) => {
  switch (category) {
    case '운동':
      return exerciseTemplates;
    case '자기계발':
      return selfDevelopmentTemplates;
    case '동네친구':
      return neighborFriendTemplates;
    case '아웃도어/여행':
      return outdoorTravelTemplates;
    case '가족/육아':
      return familyParentingTemplates;
    case '반려동물':
      return petTemplates;
    case '음식/음료':
      return foodBeverageTemplates;
    case '취미/오락':
      return hobbyEntertainmentTemplates;
    case '독서/인문학':
      return readingHumanitiesTemplates;
    case '문화/예술':
      return cultureArtTemplates;
    case '음악/악기':
      return musicInstrumentTemplates;
    default:
      return defaultTemplates;
  }
};
