import { groupClient } from '@community-group/api';
import { MeetupProgressStatus } from '@community-group/components';
import { useInfiniteQuery, useSuspenseInfiniteQuery } from '@tanstack/react-query';

import { GROUP_URL } from '../base/group';
import { useFetchInstance } from './instance/useFetchInstance';

export const getMeetupListPath = (groupId?: string) => `${GROUP_URL}/${groupId}/meetups`;

export const useGetMeetupList = (
  groupId?: string,
  progressStatuses?: MeetupProgressStatus['status'][],
  order: 'meet_at_asc' | 'meet_at_desc' = 'meet_at_asc',
  initialCursor?: string | undefined
) => {
  const fetchInstance = useFetchInstance();
  const getMeetupList = groupClient.api.MeetupApi.apiV1GroupsIdMeetupsGet({ axios: fetchInstance });

  const { fetchNextPage, isFetchingNextPage, data, refetch } = useSuspenseInfiniteQuery({
    queryKey: [getMeetupListPath(groupId), progressStatuses],
    queryFn: ({ pageParam = initialCursor }) =>
      getMeetupList({
        id: Number(groupId),
        cursor: pageParam ? pageParam : undefined,
        limit: 20,
        progressStatuses,
        order,
      }),
    initialPageParam: initialCursor,
    refetchInterval: 1000000,
    getNextPageParam: ({ data }) => (data.hasNext ? data?.endCursor ?? undefined : undefined),
  });

  return {
    fetchNextPage,
    hasNextPage: data?.pages[data?.pages.length - 1]?.data?.hasNext,
    isFetchingNextPage,
    data,
    refetch,
  };
};

export const MEETUP_PROGRESS_STATUS_FILTER: Record<string, MeetupProgressStatus['status'][]> = {
  OPENED: ['opened', 'fully_recruited'],
  CLOSED: ['closed'],
};

export type MEETUP_PROGRESS_STATUS_FILTER_KEYS = keyof typeof MEETUP_PROGRESS_STATUS_FILTER;
