import {
  GroupDetailPresentation,
  GroupMemberRoleEnum,
} from '@community-group/api/lib/group/models';
import {
  AsyncBoundary,
  HelpBubbleAnchor,
  isHigherManager,
  Typography,
} from '@community-group/components';
import {
  IconCalendarLine,
  IconDot3HorizontalChatbubbleLeftLine,
  IconFlameLine,
  IconPerson2Line,
} from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { useMemo, useState } from 'react';

import { useReadGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { useReadGroupUnreadChatCount } from '@/domain/GroupDetailShortcutMenu/hooks/useReadGroupUnreadChatCount';
import { useStorage } from '@/hooks/useStorage';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import * as s from './GroupDetailShortcutMenu.css';

type Props = { group: GroupDetailPresentation; currentUserRole: GroupMemberRoleEnum };
export type ShortcutMenuProps = 'notice' | 'meetup' | 'chat' | 'members' | 'challenge';

const GroupDetailShortcutMenu = ({ group, currentUserRole }: Props) => {
  const activity = useFlow();
  const groupId = group.id.toString();

  const eventParams = {
    event: 'click_group_detail_shortcut_menu',
    params: {
      groupId,
      groupName: group.name,
      groupCategoryName: group.category.name,
      isGroupCategoryOn: group.isBoardManaged,
      role: currentUserRole,
      version: '2',
    },
  } as const;

  const MenuCondition = {
    notice: {
      onClick: () => {
        trackEvent({
          event: eventParams.event,
          params: {
            ...eventParams.params,
            groupDetailShortcutMenu: 'notice',
          },
          sample: true,
        });
        activity.push('GroupDetailNoticeMorePage', {
          groupId,
        });
      },
    },
    meetup: {
      onClick: () => {
        trackEvent({
          event: eventParams.event,
          params: {
            ...eventParams.params,
            groupDetailShortcutMenu: 'meetup',
          },
          sample: true,
        });
        activity.push('GroupDetailMeetupPage', {
          groupId,
        });
      },
    },
    chat: {
      onClick: () => {
        trackEvent({
          event: eventParams.event,
          params: {
            ...eventParams.params,
            groupDetailShortcutMenu: 'chat',
          },
          sample: true,
        });
        activity.push('GroupDetailChatRoomPage', {
          groupId,
        });
      },
    },
    members: {
      onClick: () => {
        trackEvent({
          event: eventParams.event,
          params: {
            ...eventParams.params,
            groupDetailShortcutMenu: 'members',
          },
          sample: true,
        });
        if (isHigherManager(currentUserRole)) {
          activity.push('GroupMemberListForHostPage', {
            groupId,
          });
          return;
        }
        activity.push('GroupMemberListPage', {
          groupId,
        });
      },
    },
  };

  const handleClick = (menu: ShortcutMenuProps) => {
    MenuCondition[menu].onClick();
  };

  return (
    <ul className={s.Wrapper}>
      <li
        className={s.Menu}
        onClick={() => {
          handleClick('meetup');
        }}
      >
        <IconCalendarLine size={20} color={vars.$scale.color.gray800} />
        <Typography typography="caption1Regular" color="gray800">
          일정
        </Typography>
      </li>
      <ChallengeButton groupId={groupId} />
      <AsyncBoundary>
        <li
          className={s.Menu}
          onClick={() => {
            handleClick('chat');
          }}
        >
          <ChattingButton groupId={groupId} />
          <Typography typography="caption1Regular" color="gray800">
            채팅
          </Typography>
        </li>
      </AsyncBoundary>
      <li
        className={s.Menu}
        onClick={() => {
          handleClick('members');
        }}
      >
        <IconPerson2Line size={20} color={vars.$scale.color.gray800} />
        <Typography typography="caption1Regular" color="gray800">
          멤버
        </Typography>
      </li>
    </ul>
  );
};

export default GroupDetailShortcutMenu;

type ButtonProps = {
  groupId: string;
};

const ChattingButton = ({ groupId }: ButtonProps) => {
  const { data: unreadChatCount } = useReadGroupUnreadChatCount({
    groupId,
    refetchInterval: 1000 * 60,
  });
  const hasNewTalk = useMemo(() => unreadChatCount > 0, [unreadChatCount]);
  return (
    <div className={s.RelativeButtonWrapper}>
      <div className={hasNewTalk ? s.RedDot : undefined}>
        <IconDot3HorizontalChatbubbleLeftLine size={20} color={vars.$scale.color.gray800} />
      </div>
    </div>
  );
};

const ChallengeButton = ({ groupId }: ButtonProps) => {
  const activity = useFlow();

  const [closeChallengeHelpBubble, setCloseChallengeHelpBubble] = useStorage(
    'closeGroupChallengeHelpBubble',
    false
  );

  const defaultValue = !!closeChallengeHelpBubble;
  const [isClosed, setIsClosed] = useState(defaultValue);

  const handleClose = () => {
    setCloseChallengeHelpBubble(true);
    setIsClosed(true);
  };
  const { group } = useReadGroupDetail(groupId);

  return (
    <HelpBubbleAnchor
      title={`챌린지가 새로 생겼어요!`}
      isOpen={!isClosed}
      onFocusOutside={handleClose}
      onPointerDownOutside={handleClose}
      portalled={false}
      positioning={{
        placement: 'bottom',
      }}
      UNSAFE_style={{
        pointerEvents: 'none',
        whiteSpace: 'pre-wrap',
      }}
    >
      <li
        className={s.Menu}
        onClick={() => {
          trackEvent({
            event: 'click_group_detail_shortcut_menu',
            params: {
              groupId,
              groupName: group?.name,
              categoryId: group?.category.id,
              groupDetailShortcutMenu: 'challenge',
            },
          });
          activity.push('GroupChallengeListPage', {
            groupId,
          });
        }}
      >
        <IconFlameLine size={20} color={vars.$scale.color.gray800} />
        <Typography typography="caption1Regular" color="gray800">
          챌린지
        </Typography>
      </li>
    </HelpBubbleAnchor>
  );
};
