import { SetStateAction, useMemo, useState } from 'react';
import { $Values } from 'utility-types';

import { useBridge } from '@/contexts/Bridge';
import { HOME_ACTIVE_TAB, useQueryParams } from '@/stackflow/hooks/useQueryParams';

const useActiveHomeTabActiveKey = () => {
  const { activeHomeTab } = useQueryParams();

  const initialActiveTabKey = useMemo(() => {
    // HOME_ACTIVE_TAB에 존재하는 Key인지 확인
    if (activeHomeTab && Object.values(HOME_ACTIVE_TAB).includes(activeHomeTab)) {
      return activeHomeTab;
    }
    return HOME_ACTIVE_TAB.EXPLORER_GROUP_TAB;
  }, [activeHomeTab]);

  const [activeTabKey, setActiveTabKey] =
    useState<$Values<typeof HOME_ACTIVE_TAB>>(initialActiveTabKey);

  const { bridge } = useBridge();

  const handleActiveTabKey = (key: SetStateAction<$Values<typeof HOME_ACTIVE_TAB>>) => {
    setActiveTabKey(key);

    bridge.setHapticSelect({});
  };

  return { activeTabKey, setActiveTabKey: handleActiveTabKey };
};

export default useActiveHomeTabActiveKey;

//replaceState
