import {
  AsyncBoundary,
  Callout,
  CalloutDescription,
  CalloutTitle,
  Dialog,
  Spacing,
  Switch,
  Typography,
  useDialog,
  useSnackbarAdapter,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { IconChevronLeftLine } from '@daangn/react-monochrome-icon';
import { useConnectedActions } from '@daangn/stackflow-connection-actions';
import { ActivityComponentType } from '@stackflow/react';
import { useState } from 'react';

import { useReadGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { useGetMe } from '@/api/hooks/useGetMe';
import { usePutGroup } from '@/api/hooks/usePutGroup';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useUpdateStyleCurrentRouter } from '@/hooks/useUpdateStyleCurrentRouter';
import { useSubscribeAndroidBackButtonPress } from '@/shared/hooks/useSusbscribeAndroidBackButtonPress';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { LayoutWrapper } from '@/stackflow/components/Layout';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import useSetFixedLayoutSize from '@/stackflow/hooks/useSetFixedLayoutSize';
import { trackEvent } from '@/utils/analytics';
import { getDateDiffFromFuture } from '@/utils/date';
import { refetchGroupSetting } from '@/utils/refetch/groupSetting';

import VerificationPreview from '../../new/Verification/components/VerificationPreview';
import { useHandleVerificationBottomSheet } from '../../new/Verification/hooks/useHandleVerificationBottomSheet';
import * as s from './GroupSettingEditVerification.css';

const GroupSettingEditVerificationPage: ActivityComponentType = () => {
  const snackbarAdapter = useSnackbarAdapter();

  const { push: asyncPush } = useConnectedActions();
  const { data: userMe } = useGetMe();

  const { groupId } = usePathParams();
  const { from } = useQueryParams();
  const { group } = useReadGroupDetail(groupId);

  const [needVerification, setNeedVerification] = useState(group?.needVerification ?? false);
  const [canViewVerifiedUserInfo, setCanViewVerifiedUserInfo] = useState(
    group?.canViewVerifiedUserInfo ?? false
  );
  const [isDirty, setIsDirty] = useState(false);

  const { open: openDialog, close: closeDialog } = useDialog();

  const { pop, push } = useFlow();

  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate } = usePutGroup({
    onError: handleErrorWithToast,
    onSuccess: () => {
      snackbarAdapter.create({
        message: '본인인증 설정을 변경했어요.',
        type: 'default',
        timeout: 3000,
        onClick: () => {
          snackbarAdapter.dismiss();
        },
      });

      refetchGroupSetting({ groupId });
      trackEvent({
        event: 'click_verification_from_superhost_update',
        params: {
          groupId,
          needVerification,
          canViewVerifiedUserInfo,
          from,
        },
      });

      handleCloseEditVerificationBottomSheet();
    },
  });

  const { handleVerificationBottomSheet } = useHandleVerificationBottomSheet();

  useSetFixedLayoutSize(false);
  useUpdateStyleCurrentRouter({ IOS_ONLY_hideKeyboardAccessoryView: false });

  const handleSubmit = async (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();
    if (!isDirty || !group?.canUpdateVerification) return pop();
    openDialog({
      element: (
        <Dialog
          title="본인인증 설정을 변경할까요?"
          description="7일동안 최대 2회까지만 바꿀 수 있어요."
          primaryActionLabel="변경"
          secondaryActionLabel="나가기"
          onSecondaryAction={async () => {
            await closeDialog();
            pop();
          }}
          onPrimaryAction={async () => {
            await closeDialog();
            mutate({
              id: groupId,
              groupModifyForm: {
                needVerification,
                canViewVerifiedUserInfo,
              },
            });
          }}
        />
      ),
    });
  };

  const handleOpenChangeNotAvailableDialog = () => {
    const modifiableDate = new Date(group?.verificationModifiableDate ?? '');
    const diffModifiableDate = getDateDiffFromFuture(modifiableDate);
    const verificationModifiableDateText = diffModifiableDate.text;

    openDialog({
      element: (
        <Dialog
          title="본인인증 설정을 바꿀 수 없어요"
          description={`7일동안 최대 2회까지만 변경할 수 있어요. ${verificationModifiableDateText} 후에 다시 시도해주세요.`}
          primaryActionLabel="확인"
          onPrimaryAction={() => {
            closeDialog();
          }}
        />
      ),
    });
  };

  const handleCloseEditVerificationBottomSheet = () => {
    return pop();
  };

  const handleNeedVerificationChange = async (value: boolean) => {
    if (!group?.canUpdateVerification) return handleOpenChangeNotAvailableDialog();
    setIsDirty(
      value !== group?.needVerification ||
        canViewVerifiedUserInfo !== group?.canViewVerifiedUserInfo
    );
    if (!value) {
      setCanViewVerifiedUserInfo(false);
      setNeedVerification(false);
      return;
    }

    const verified = await handleVerificationBottomSheet({
      certificationCandidateType: userMe.verified ? 'pass_verification' : 'create_group',
    });

    setNeedVerification(verified ?? false);
    setIsDirty(
      verified !== group?.needVerification ||
        canViewVerifiedUserInfo !== group?.canViewVerifiedUserInfo
    );
  };

  const handleCanViewVerifiedUserInfoChange = async (value: boolean) => {
    if (!group?.canUpdateVerification) return handleOpenChangeNotAvailableDialog();
    setIsDirty(value !== group?.canViewVerifiedUserInfo);
    if (!value) {
      setCanViewVerifiedUserInfo(false);
      return;
    }

    // 본인인증이 완료되어있지 않은 경우
    if (!userMe.verified) {
      const verified = await handleVerificationBottomSheet({
        certificationCandidateType: userMe.verified
          ? 'pass_verification'
          : 'create_group_with_user_info',
      });

      setCanViewVerifiedUserInfo(verified ?? false);
      setNeedVerification(verified ?? false);
      setIsDirty(verified !== group?.canViewVerifiedUserInfo);
      return;
    }

    const checked = (await asyncPush(
      'BottomSheet/CheckCanViewVerifiedUserInfoBottomSheet',
      {}
    )) as {
      canViewUser: boolean;
    };

    setCanViewVerifiedUserInfo(checked.canViewUser as boolean);
    setIsDirty(checked.canViewUser !== group?.canViewVerifiedUserInfo);
    if (checked.canViewUser) {
      setNeedVerification(checked.canViewUser as boolean);
    }
  };

  const openInfoFAQVerification = () => {
    push('GroupSettingFAQVerificationPage', {
      groupIdForChangeVerification: groupId,
    });
  };

  useSubscribeAndroidBackButtonPress({ pressHandler: handleSubmit, enabled: false });
  return (
    <AppScreen
      appBar={{
        title: '본인인증',
        closeButton: {
          renderIcon: () => <IconChevronLeftLine />,
          onClick: handleSubmit,
        },
        backButton: {
          renderIcon: () => <IconChevronLeftLine />,
          onClick: handleSubmit,
        },
      }}
      preventSwipeBack
    >
      <AsyncBoundary pendingFallback={<></>} rejectedFallback={<></>}>
        <LayoutWrapper padding="0 1rem">
          <Spacing size={16} />
          <Callout>
            <CalloutTitle>안내</CalloutTitle>
            <CalloutDescription>
              설정을 켜면 본인인증을 완료한 이웃만 모임에 가입할 수 있어요.
              <span className={s.CalloutTextButton} onClick={openInfoFAQVerification}>
                자세히 알아보기
              </span>
            </CalloutDescription>
          </Callout>
          <Spacing size={16} />
          <div className={s.guideTextBox}>
            <label className={s.guideLabelText}>예시</label>
            <Typography typography="bodyL2Regular" color="blue900">
              인증을 완료하면 프로필에 표시돼요.
            </Typography>
          </div>
          <Spacing size={12} />
          <VerificationPreview verified={userMe.verified} />
          <Spacing size={16} />
          <div className={s.switchBox}>
            <Typography typography="bodyL1Regular" color="gray900">
              본인인증 사용
            </Typography>
            <Switch
              onChange={(isSelected) => {
                handleNeedVerificationChange(isSelected);
              }}
              isSelected={needVerification}
            />
          </div>
          <div className={s.switchBox}>
            <div className={s.switchBoxTextBox}>
              <Typography typography="bodyL1Regular" color="gray900">
                나이대와 성별 정보 받기
              </Typography>
              <Typography typography="caption1Regular" color="gray600">
                가입하는 이웃이 모임장님의 정보도 볼 수 있어요.
              </Typography>
            </div>
            <Switch
              onChange={(isSelected) => {
                handleCanViewVerifiedUserInfoChange(isSelected);
              }}
              isSelected={canViewVerifiedUserInfo}
            />
          </div>
          <Spacing size={32} />
        </LayoutWrapper>
      </AsyncBoundary>
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupSettingEditVerificationPage, {
  pendingFallback: (
    <AppScreen fallbackLoading={<ViewLoader />}>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
