import { AsyncBoundary, Divider, ViewLoader, withAsyncBoundary } from '@community-group/components';
import { IconDot3VerticalLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { ActivityComponentType } from '@stackflow/react';
import { Suspense } from 'react';

import { GroupProfileAppScreenRenderRight } from '@/features/GroupProfile/AppScreen/RenderRight';
import GroupProfileContentsTab from '@/features/GroupProfile/ContentsTab';
import GroupProfileDeletedAccount from '@/features/GroupProfile/DeletedAccount';
import { useRenderGroupProfileCreatePage } from '@/features/GroupProfile/hooks/useRenderGroupProfileCreatePage';
import GroupProfileUserActivities from '@/features/GroupProfile/UserActivities';
import GroupProfileUserInfo from '@/features/GroupProfile/UserInfo';
import HideScrollBar from '@/shared/components/AppScreen/components/HideScrollBar';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';

export type GroupProfileDetailV2PageParams = Pick<PageParams, 'groupId' | 'userId' | 'from'>;

// 모임원 프로필 상세 페이지 V2 (모임 상세 > 멤버 리스트 > 프로필)
const GroupProfileDetailV2Page: ActivityComponentType<GroupProfileDetailV2PageParams> = () => {
  const { groupId, userId } = usePathParams();
  const { from = '' } = useQueryParams();

  useRenderGroupProfileCreatePage({ groupId, userId });

  return (
    <AppScreen
      appBar={{
        title: '모임 프로필',
        renderRight: () => (
          <Suspense fallback={<IconDot3VerticalLine />}>
            <GroupProfileAppScreenRenderRight groupId={groupId} userId={userId} />
          </Suspense>
        ),
        borderSize: '0.5px',
        borderColor: vars.$semantic.color.divider3,
      }}
    >
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
        <HideScrollBar>
          <GroupProfileUserInfo groupId={groupId} userId={userId} from={from} />
          <Divider size={8} />
          <GroupProfileUserActivities userId={userId} groupId={groupId} />
          <GroupProfileContentsTab groupId={groupId} userId={userId} />
        </HideScrollBar>
      </AsyncBoundary>
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupProfileDetailV2Page, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <GroupProfileDeletedAccount />
    </AppScreen>
  ),
});
