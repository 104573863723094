import humps from 'humps';

import { bridge } from '@/contexts/Bridge';

import { UNSAFE_appInfo } from '../bridge/appInfo';
import { VITE_APP_DEBUG_EVENT } from './../../constants/common';
// Todo: 기존에 'analytics.ts'에 있는 trackEvent를 마이그레이션 해와야 함.
import { trackAmplitudeEvent } from './amplitude';
import { GroupEventParams, ReferParams, SpecificEventType, UTMParams } from './type';

export type EventLoggerType = 'KARROT' | 'FIREBASE' | 'AMPLITUDE' | 'SEARCH_KARROT' | 'APPSFLYER';

const defaultLoggerType: EventLoggerType[] = ['AMPLITUDE', 'FIREBASE', 'KARROT'];
export const extendAppsflyerLoggerType: EventLoggerType[] = [...defaultLoggerType, 'APPSFLYER'];

type Params = {
  event: SpecificEventType;
  params?: GroupEventParams;
  loggerType?: EventLoggerType[];
  sample?: boolean;
};

// 앰플리튜드 외에 브릿지 로깅 등을 함께 하기 위한 코드
export const trackEvent = ({
  event,
  params,
  loggerType = defaultLoggerType,
  sample = false,
}: Params) => {
  const extendedDefaultParams = {
    enabledSSR: true,
    locationOriginPathname: window?.location?.pathname,
    locationMaskedPathname: (window?.location?.pathname ?? '').replace(/\/\d{1,9}/g, '/{id}'),
    locationPrevPageName: window.previousHistoryPageName,
    locationCurrentPageName: window.currentPageName,
    ...(window?.utm as UTMParams),
    ...(window?.referrer as ReferParams),
    ...params,
  };

  if (globalThis.appConfig.appEnv === 'local' && VITE_APP_DEBUG_EVENT) {
    console.table({
      event,
      ...extendedDefaultParams,
      loggerType: loggerType.join(', '),
    });
  }

  loggerType?.forEach((logger) => {
    // Amplitude
    if (logger === 'AMPLITUDE') {
      if (sample) {
        // TODO: optional 풀면 에러남 확인필요
        const sampledUser = UNSAFE_appInfo.getData()?.user?.sample;

        if (!sampledUser) {
          return;
        }
      }
      trackAmplitudeEvent(event, {
        ...extendedDefaultParams,
        sampled: sample,
      });
    }

    // 검색팀 당근 SDK 이벤트 로깅을 위한 logger
    if (logger === 'SEARCH_KARROT') {
      bridge.logEvent({
        analytics: {
          target: 'KARROT',
          name: `client_${event}`,
          params: params ? JSON.stringify(params) : '',
        },
      });
    }

    // KarrotAnalyticsV1 로깅
    if (logger === 'KARROT') {
      const splitEvent = event.split('_');
      const eventAction = splitEvent[0] ?? '';

      switch (eventAction) {
        case 'enter': {
          splitEvent[0] = 'shown_group';
          break;
        }
        case 'click': {
          splitEvent[0] = 'clicked_group';
          break;
        }
        case 'impression': {
          splitEvent[0] = 'impressed_group';
          break;
        }
        default: {
          splitEvent[0] = `${splitEvent[0]}_group`;
        }
      }

      splitEvent.push('v1');
      const eventName = splitEvent.join('_');

      bridge.logEvent({
        analytics: {
          target: logger,
          name: eventName,
          params: JSON.stringify(humps.decamelizeKeys(extendedDefaultParams)),
        },
      });
    }

    // 당근 FIREBASE 이벤트 로깅을 위한 logger
    // event name 수 제한이 있어 group_click, group_enter, group_impression 으로 변환해서 로깅
    if (logger === 'FIREBASE') {
      const splitEvent = event.split('_');
      const eventName = splitEvent[0] ?? '';

      const extendedFirebaseParams = {
        ...extendedDefaultParams,
        event_detail_name: event,
      };

      bridge.logEvent({
        analytics: {
          target: logger,
          name: `group_${eventName}`,
          params: JSON.stringify(extendedFirebaseParams),
        },
      });
    }

    // APPSFLYER는 주로 마케팅 팀에서 사용하고 있어요.
    // 참고 1: https://daangn.slack.com/archives/C0324MBCA9Z/p1701856624112759?thread_ts=1700024905.747939&cid=C0324MBCA9Z
    // 참고 2: https://www.notion.so/daangn/4291bea646a94769ab7a884513cdf419?pvs=4
    //
    if (logger === 'APPSFLYER') {
      const splitEvent = event.split('_');
      const eventName = splitEvent[0] ?? '';

      if (eventName === 'click') {
        splitEvent[0] = 'click_group';
      } else if (eventName === 'enter') {
        splitEvent[0] = 'show_group_enter';
      }

      const currentEventName = splitEvent.join('_');

      bridge.logEvent({
        analytics: {
          target: logger,
          name: currentEventName,
          params: JSON.stringify(extendedDefaultParams),
        },
      });
    }
  });
};
