import { GroupMemberGrade } from '@community-group/api/lib/group/models';
import {
  IconCheckmarkFlowerFill,
  IconCrownFill,
  IconLeafFill,
} from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';

type Props = {
  grade: GroupMemberGrade;
};

const MemberGradeIcon = ({ grade }: Props) => {
  switch (grade.role) {
    case 'superHost':
      return <IconCrownFill size={22} color="#f7be68" />;
    case 'manager':
      return <IconCrownFill size={22} color="#30c795" />;
    case 'member': {
      // TODO: 커스텀 등급(name을 모임별로 다르게 가능)이 적용되면 수정 필요
      if (grade.name === '정회원') {
        return <IconCheckmarkFlowerFill size={22} color={vars.$scale.color.gray600} />;
      }
      return <IconLeafFill size={22} color={vars.$scale.color.gray600} />;
    }
    default:
      return null;
  }
};

export default MemberGradeIcon;
