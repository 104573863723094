import { groupClient } from '@community-group/api';

import { useRead } from '@/shared/api/hooks/useRead';
import {
  useThrottleMutation,
  UseThrottleMutationOption,
} from '@/shared/api/hooks/useThrottleMutation';

type Props = UseThrottleMutationOption;

export const usePostGrowthColdStartSendPrize = (props: Props) => {
  const mutate = useRead(
    groupClient.api.ChallengePromotionApi.apiV1GroupChallengeCertifyPrizeSendPost
  );

  return useThrottleMutation(mutate, props);
};
