import {
  Spacing,
  Typography,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { IconXmarkLine } from '@daangn/react-monochrome-icon';
import { ActivityComponentType } from '@stackflow/react';
import { useEffect, useState } from 'react';

import LOADING_CHECK_GIF from '@/assets/images/loading_check.gif';
import { LottieURL } from '@/components/common/Lottie/LottieURL';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';

import FAQCallout from '../components/FAQCallout';
import * as s from './ChallengeCertifyLLMPage.css';

type Params = Pick<PageParams, 'groupId' | 'postId' | 'challengeCertifyPromotionResult' | 'point'>;

const DISPLAY_TEXT = {
  READ_POST: {
    title: (
      <>
        방금 쓴 챌린지 인증글을
        <br />
        읽고 있어요
      </>
    ),
    description: (
      <>
        참여한 챌린지와 연관성이 있는 사진인지
        <br />
        확인하고 있어요
      </>
    ),
  },
  CHECK_MONEY: {
    title: (
      <>
        당근머니 지급 금액을
        <br />
        정하고 있어요
      </>
    ),
    description: (
      <>
        참여한 챌린지와 연관성이 있는 글인지
        <br />
        확인하고 있어요
      </>
    ),
  },
};

const ChallengeCertifyLLMPage: ActivityComponentType<Params> = () => {
  const { groupId, postId } = usePathParams();

  const { challengeCertifyPromotionResult, point } = useQueryParams();

  const { replace } = useFlow();

  const [displayText, setDisplayText] = useState<typeof DISPLAY_TEXT.READ_POST>(
    DISPLAY_TEXT.READ_POST
  );

  const parsedChallengeCertifyPromotionResult = JSON.parse(challengeCertifyPromotionResult ?? '{}');

  useEnterTrackEvent({
    event: 'enter_challenge_certify_llm_page',
    params: { groupId, postId, ...parsedChallengeCertifyPromotionResult, point },
  });

  useEffect(() => {
    const changeTextTimer = setTimeout(() => {
      setDisplayText(DISPLAY_TEXT.CHECK_MONEY);
    }, 1500);

    const changePageTimer = setTimeout(() => {
      replace('ChallengeCertifyPointResultPage', {
        groupId,
        postId,
        challengeCertifyPromotionResult,
        point,
      });
    }, 3000);

    return () => {
      clearTimeout(changeTextTimer);
      clearTimeout(changePageTimer);
    };
  }, []);

  return (
    <AppScreen
      appBar={{
        backButton: {
          renderIcon: () => <IconXmarkLine size={24} />,
        },
        closeButton: {
          renderIcon: () => <IconXmarkLine size={24} />,
        },
      }}
    >
      <div className={s.Container}>
        <div className={s.MainContents}>
          <div className={s.Badge}>
            <Typography typography="label4Regular" color="gray700">
              2025 새해목표 달성 챌린지
            </Typography>
          </div>
          <Spacing size={16} />
          <Typography typography="title1Bold" color="gray900" textAlign="center">
            {displayText.title}
          </Typography>
          <LottieURL
            src={
              'https://asset-town.krrt.io/production/motion/e70396f7-3bb6-44cf-89cc-6154b83c0fdd/9a242539a2d5c5dd7272900ab86a1acb09fd3d24.json '
            }
            className={s.HeroImage}
          />
          <Spacing size={16} />
          <Typography typography="subtitle2Regular" color="gray700" textAlign="center">
            {displayText.description}
          </Typography>
        </div>
        <FAQCallout itemIndex={Math.floor(Math.random() * 3)} />
      </div>
    </AppScreen>
  );
};

export default withAsyncBoundary(ChallengeCertifyLLMPage, {
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
});
