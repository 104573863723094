import { CommentDetail } from '@community-group/api/lib/group/models';
import { getDateDiffFromNow, GroupAvatar, Spacing, Typography } from '@community-group/components';
import { VerticalSpacing } from '@community-group/components';
import { MouseEventHandler } from 'react';

import { replaceMentionDisplayText } from '@/utils/mention';

import * as s from './GroupMeetupDetailCommentListForNotMember.css';

type Props = {
  comment: CommentDetail;
  onClick: MouseEventHandler;
};

const GroupMeetupDetailCommentListForNotMember = ({ comment, onClick }: Props) => {
  const { author, content, deletedAt } = comment;

  if (deletedAt) return <AbnormalComment comment={comment} onClick={onClick} />;

  return (
    <div className={s.Container} onClick={onClick}>
      <div className={s.Body}>
        <GroupAvatar src={author.profileImage} role={author.role} size="small" />
        <VerticalSpacing size={12} />
        <div>
          <div className={s.CommentAuthor}>
            <Typography typography="caption1Bold" color="gray900" ellipsisAfterLines={1}>
              {author.nickname}
              {author.subNickname && ` (${author.subNickname})`}
            </Typography>
            <VerticalSpacing size={4} />
            <Typography typography="caption2Regular" color="gray600">
              {getDateDiffFromNow(new Date(Date.parse(comment.createdAt))).text} 전
            </Typography>
          </div>
          <Spacing size={4} />
          <Typography typography="bodyM1Regular" color="gray900" ellipsisAfterLines={1}>
            {replaceMentionDisplayText(content)}
          </Typography>
        </div>
      </div>
    </div>
  );
};

const AbnormalComment = ({ comment, onClick }: Props) => {
  const { author, content } = comment;

  return (
    <div className={s.Container} onClick={onClick}>
      <div className={s.Body}>
        <GroupAvatar size="small" />
        <VerticalSpacing size={12} />
        <div>
          <div className={s.CommentAuthor}>
            <Typography typography="caption1Bold" color="gray500" ellipsisAfterLines={1}>
              {author.nickname}
            </Typography>
            <VerticalSpacing size={4} />
            <Typography typography="caption2Regular" color="gray600">
              {getDateDiffFromNow(new Date(Date.parse(comment.createdAt))).text} 전
            </Typography>
          </div>
          <Spacing size={4} />
          <Typography typography="bodyM1Regular" color="gray600" ellipsisAfterLines={1}>
            {content}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default GroupMeetupDetailCommentListForNotMember;
