import { useSuspenseQueries } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';

import { useBridge } from '@/contexts/Bridge';
import { useQueryGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useQueryCheckHasGroupProfile } from '@/domain/GroupProfile/hooks/useReadCheckHasGroupProfile';
import { useQueryGroupProfileUserInfo } from '@/domain/GroupProfile/hooks/useReadGroupProfileUserInfo';
import { useFlow } from '@/stackflow';

type Props = {
  groupId: string;
  userId: string;
};

export const useRenderGroupProfileCreatePage = ({ groupId, userId }: Props) => {
  const { replace } = useFlow();
  const { bridge } = useBridge();

  const [{ data: profile }, { data: me }, { data: isMainProfileSet }] = useSuspenseQueries({
    queries: [
      useQueryGroupProfileUserInfo({ groupId, userId }),
      useQueryGroupMe({ groupId }),
      useQueryCheckHasGroupProfile(),
    ],
  });

  const isMyProfile = useMemo(() => me.id === profile.id, [me, profile]);

  useEffect(() => {
    if (!isMyProfile) return;

    // 통합프로필이 없으면 본인 모임프로필을 조회할 수 없게 변경
    if (!isMainProfileSet) {
      bridge.openToast({
        toast: { body: '프로필이 없어요. 프로필을 생성해주세요.' },
      });
      replace('GroupProfileCreatePage', {}, { animate: false });
    }
  }, [bridge, isMyProfile, isMainProfileSet, replace]);
};
