import { groupClient } from '@community-group/api';
import { GroupMeetupCreateForm, GroupMemberRoleEnum } from '@community-group/api/lib/group/models';
import {
  ActionList,
  ActionListItem,
  ActionSheet,
  AsyncBoundary,
  ClientOnly,
  Dialog,
  isHigherManager,
  useBottomSheet,
  useDialog,
} from '@community-group/components';
import { IconDot3VerticalLine } from '@daangn/react-monochrome-icon';
import { HelpBubbleAnchor } from '@daangn/sprout-components-help-bubble';
import { vars } from '@seed-design/design-token';

import { useDeleteGroupMeetupDetail } from '@/api/hooks/useDeleteGroupMeetupDetail';
import { useReadGroupMeetupDetail } from '@/api/hooks/useGetGroupMeetupDetail';
import { usePutGroupMeetupClose } from '@/api/hooks/usePutGroupMeetupClose';
import { IconShareRegularExperiment } from '@/components/common/Icons/shareRegular';
import { useHandleIsNotViewGroup } from '@/hooks/NotViewGroup/useHandleIsNotViewGroup';
import useCurrentGroupMe from '@/hooks/useCurrentGroupMe';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useShareMeetupContents } from '@/hooks/useShareMeetupContents';
import { useStorage } from '@/hooks/useStorage';
import { useFlow } from '@/stackflow';
import { useBack } from '@/stackflow/hooks/useBack';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { openGroupMeetupReport } from '@/utils/link';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';
import { refetchGroupMeetupDetail } from '@/utils/refetch/groupMeetupDetail';
import { refetchHome } from '@/utils/refetch/home';
import { isMember } from '@/utils/role';

import { isCanceledGroupMeetup, isHostDeletedGroupMeetup } from '../../utils/progressStatus';
import { GroupHigherManagerMoreMenuAppBarBottomSheet } from './GroupHigherManagerMoreMenuAppBarBottomSheet';
import { GroupMemberMoreMenuAppBarBottomSheet } from './GroupMemberMoreMenuAppBarBottomSheet';
import * as s from './index.css';
import { MeetupHostMoreMenuAppBarBottomSheet } from './MeetupHostMoreMenuAppBarBottomSheet';
import { RecurringGroupMeetupDeleteBottomSheet } from './RecurringGroupMeetupDeleteBottomSheet';

export const GroupMeetupRightAppBar = () => {
  return (
    <AsyncBoundary>
      <ClientOnly render={() => <MoreButton />} />
    </AsyncBoundary>
  );
};

const MoreButton = () => {
  const { groupId, meetupId } = usePathParams();
  const { open: openBottomSheet, closeAsync: closeBottomSheet } = useBottomSheet();
  const { open: openDialog, close: closeDialog } = useDialog();

  const pop = useBack();
  const { push } = useFlow();

  const { data } = useReadGroupMeetupDetail({ groupId, meetupId });
  const currentUser = useCurrentGroupMe();

  const handleErrorWithToast = useHandleErrorWithToast();

  const { shownHandleIsNotViewGroupButton, isNotViewGroup, toggleIsNotViewGroup } =
    useHandleIsNotViewGroup({
      groupId,
      groupName: data.groupInfo.name ?? '',
    });

  const handleGroupFeedback = async () => {
    await toggleIsNotViewGroup();
  };

  const { mutate: mutateCloseGroupMeetup } = usePutGroupMeetupClose({
    onSettled: () => {
      refetchGroupDetail({ groupId });
      refetchGroupMeetupDetail({
        groupId,
        meetupId,
      });
    },
    onError: handleErrorWithToast,
  });

  const { mutate: mutateDeleteGroupMeetupDetail } = useDeleteGroupMeetupDetail({
    onSettled: () => {
      refetchHome();
      refetchGroupDetail({ groupId });
      setTimeout(() => {
        refetchGroupMeetupDetail({
          groupId,
          meetupId,
        });
      }, 500);
    },
    onError: handleErrorWithToast,
  });

  const deleteGroupMeetupDetail = (option?: groupClient.model.GroupMeetupDeleteOrModifyEnum) => {
    mutateDeleteGroupMeetupDetail(
      {
        groupId,
        meetupId,
        option,
      },
      {
        onSuccess: () => {
          pop();
        },
      }
    );
  };

  if (isCanceledGroupMeetup(data?.progressStatus?.status)) return <></>;
  if (isHostDeletedGroupMeetup(data?.progressStatus?.status)) return <></>;

  const handleClick = () => {
    const handleClone = () => {
      push('GroupMeetupNewPage', {
        groupId: groupId.toString(),
        cloneGroupMeetupFormData: JSON.stringify({
          title: data.title,
          description: data.description,
          maximumParticipantsNumber: data.maximumParticipantsNumber,
          poiItems: data?.poi ? [data.poi] : undefined,
          exposureRange: data.exposureRange,
          images: data.images.map(({ id }) => id),
        } as GroupMeetupCreateForm),
        poiName: data.poi?.name,
      });
    };

    const handleDelete = () => {
      if (data.meetupType === 'recurring') {
        return openBottomSheet({
          element: (
            <RecurringGroupMeetupDeleteBottomSheet
              onDefaultDelete={async () => {
                await closeBottomSheet();

                deleteGroupMeetupDetail('this');
              }}
              onRecurringDelete={async () => {
                await closeBottomSheet();

                deleteGroupMeetupDetail('afterAll');
              }}
            />
          ),
        });
      }

      return openDialog({
        element: (
          <Dialog
            title="일정을 삭제할까요?"
            description="모든 데이터가 삭제되고 다시 볼 수 없어요."
            primaryActionLabel="삭제"
            secondaryActionLabel="취소"
            onSecondaryAction={closeDialog}
            onPrimaryAction={async () => {
              await closeDialog();
              deleteGroupMeetupDetail();
            }}
          />
        ),
      });
    };

    const hostId = data?.host?.userId ?? 0;
    const isCurrentUserHost = hostId === data.currentUserInfo.userId;
    const handleReport = isCurrentUserHost
      ? undefined
      : () => openGroupMeetupReport({ meetupId, hostId: hostId.toString() });

    if (data?.currentUserInfo.role === 'HOST') {
      return openBottomSheet({
        element: (
          <MeetupHostMoreMenuAppBarBottomSheet
            progressStatus={data.progressStatus.status}
            onClone={handleClone}
            onModifyClick={() => {
              push('GroupMeetupEditPage', {
                meetupId: meetupId.toString(),
                groupId: groupId.toString(),
              });
            }}
            onClose={() => {
              mutateCloseGroupMeetup({
                groupId,
                meetupId,
              });
            }}
            onDelete={handleDelete}
            onReport={handleReport}
          />
        ),
      });
    }

    if (
      isHigherManager(data?.currentUserInfo.groupRole) &&
      currentUser.permissions.deletePostAndComment
    ) {
      return openBottomSheet({
        element: (
          <GroupHigherManagerMoreMenuAppBarBottomSheet
            onClone={handleClone}
            onDelete={handleDelete}
            onReport={handleReport}
          />
        ),
      });
    }

    if (isMember(data?.currentUserInfo.groupRole)) {
      return openBottomSheet({
        element: (
          <GroupMemberMoreMenuAppBarBottomSheet onClone={handleClone} onReport={handleReport} />
        ),
      });
    }

    return openBottomSheet({
      element: (
        <ActionSheet bottomButton={{ label: '닫기' }}>
          <ActionList>
            {shownHandleIsNotViewGroupButton && (
              <ActionListItem
                onClick={async () => {
                  await closeBottomSheet();
                  await handleGroupFeedback();
                }}
              >
                {isNotViewGroup ? '이 모임의 글 다시 보기' : '이 모임의 글 보지 않기'}
              </ActionListItem>
            )}
            {handleReport && (
              <ActionListItem
                onClick={async () => {
                  await closeBottomSheet();

                  handleReport();
                }}
              >
                신고
              </ActionListItem>
            )}
          </ActionList>
        </ActionSheet>
      ),
    });
  };

  return (
    <>
      <div className={s.iconWrapper}>
        <ShareButton
          groupId={groupId}
          meetupId={meetupId}
          permalinkId={data?.permalink.id ?? ''}
          currentUserRole={data.currentUserInfo?.groupRole ?? 'none'}
        />
      </div>
      <div className={s.iconWrapper}>
        <IconDot3VerticalLine
          data-tooltip-id="notice-clone-mode"
          size={24}
          color={vars.$scale.color.gray900}
          onClick={handleClick}
        />
      </div>
    </>
  );
};

const ShareButton = ({
  meetupId,
  permalinkId,
  currentUserRole,
  groupId,
}: {
  groupId: string;
  meetupId: string;
  permalinkId: string;
  currentUserRole: GroupMemberRoleEnum;
}) => {
  const [showedShareContentsTooltip, setShowedShareContentsTooltip] = useStorage(
    'showedShareContentsTooltip',
    false
  );
  const handleShareMeetupContents = useShareMeetupContents();

  return (
    <HelpBubbleAnchor
      title={`참여중인 채팅방에\n게시글을 공유해보세요!`}
      onInteractOutside={() => {
        setShowedShareContentsTooltip(true);
      }}
      isOpen={!showedShareContentsTooltip}
      UNSAFE_style={{
        whiteSpace: 'pre-wrap',
      }}
      positioning={{
        placement: 'bottom-start',
      }}
      marginLeft={-14}
      onOpenChange={() => {
        setShowedShareContentsTooltip(true);
      }}
    >
      <IconShareRegularExperiment
        currentUserRole={currentUserRole}
        width={24}
        height={24}
        fill={vars.$scale.color.gray900}
        onClick={() => {
          setShowedShareContentsTooltip(true);
          handleShareMeetupContents({
            groupId,
            meetupId,
            permalinkId,
            currentUserRole,
            from: 'meetupDetailAppBar',
          });
        }}
      />
    </HelpBubbleAnchor>
  );
};
