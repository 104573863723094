import { isSuperHost } from '@community-group/components';
import { useActivity } from '@stackflow/react';
import { useCallback, useEffect } from 'react';

import { useGetRunningGrowthMission } from '@/api/hooks/useGetRunningGrowthMission';
import { useReadGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import useActiveActivities from '@/hooks/useActiveActivities';
import { useStorage } from '@/hooks/useStorage';
import { useFlow } from '@/stackflow';

interface Props {
  groupId: string;
}

const useCheckRunningGrowthMissionCompleteBottomSheet = ({ groupId }: Props) => {
  const { data: me } = useReadGroupMe({ groupId });
  const { push } = useFlow();
  const { isTop } = useActivity();

  const { activeActivities } = useActiveActivities();

  const { runningGrowthData } = useGetRunningGrowthMission(groupId);

  const [runningGrowthStepCompletedList, setRunningGrowthStepCompletedList] = useStorage(
    'runningGrowthStepCompletedList',
    {}
  );

  // 현재 진행중인 step
  const checkStepSuccess = useCallback(() => {
    if (runningGrowthData && runningGrowthData?.length !== 0) {
      for (let i = 0; i < runningGrowthData.length; i++) {
        if (!runningGrowthData[i].isStepCompleted && runningGrowthData[i].isStepSuccess) {
          return runningGrowthData[i].step;
        }
      }
    }

    return undefined;
  }, [runningGrowthData]);

  // groupDetail 페이지 진입시 didMount 시점에 실행되어야 하는 로직
  const checkTurnOnCheckRunningGrowthMissionCompleteBottomSheet = useCallback(() => {
    const currentStep = checkStepSuccess();

    if (runningGrowthData?.length !== 0 && isSuperHost(me.role) && currentStep) {
      // 해당 step의 바텀시트가 오픈된적이 있었는지 여부
      const isOpenedStep = runningGrowthStepCompletedList?.[groupId];

      // 이미 해당 step 성공 bottomSheet가 열렸다면 false 리턴
      if (isOpenedStep && isOpenedStep === currentStep) return false;

      // 안 열렸었다면 바텀시트 open
      push('BottomSheet/RunningGrowthMissionCompleteBottomSheet', {
        groupId,
        step: currentStep,
      });

      setRunningGrowthStepCompletedList({
        ...runningGrowthStepCompletedList,
        ...{
          [groupId]: currentStep,
        },
      });

      return true;
    }
    return false;
  }, [
    checkStepSuccess,
    groupId,
    me.role,
    push,
    runningGrowthData,
    runningGrowthStepCompletedList,
    setRunningGrowthStepCompletedList,
  ]);

  useEffect(() => {
    const isLastActivityIsPostDetail =
      activeActivities.slice(-1)[0]?.name === 'GroupPostDetailPage';
    if (isTop || isLastActivityIsPostDetail) {
      checkTurnOnCheckRunningGrowthMissionCompleteBottomSheet();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTop, runningGrowthData, activeActivities]);

  return { checkTurnOnCheckRunningGrowthMissionCompleteBottomSheet };
};

export default useCheckRunningGrowthMissionCompleteBottomSheet;
