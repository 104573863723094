import { isMember, isSuperHost, Render, useDialog } from '@community-group/components';
import { SettingList } from '@community-group/components/features';
import { IconChevronRightLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { useSuspenseQueries } from '@tanstack/react-query';

import { DeleteMemberDialog } from '@/components/group/Setting/components/DeleteMemberDialog';
import { useQueryGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useQueryGroupHostMembers } from '@/domain/GroupMember/hooks/useReadGroupHostMembers';
import { useQueryGroupProfileUserInfo } from '@/domain/GroupProfile/hooks/useReadGroupProfileUserInfo';
import { useHandleManagerRoleProfileV2 } from '@/domain/GroupProfile/utils/useHandleManagerRoleProfileV2';
import { useHandleMemberGradeProfile } from '@/domain/GroupProfile/utils/useHandleMemberGradeProfile';
import { openGroupProfileReport } from '@/utils/link';

import * as s from './style.css';
import SuperHostDelegationSettingItem from './SuperHostDelegationSettingItem';
type Props = {
  groupId: string;
  userId: string;
};
const GroupProfileMemberManage = ({ groupId, userId }: Props) => {
  const [{ data: profile }, { data: hostMembersData }, { data: me }] = useSuspenseQueries({
    queries: [
      useQueryGroupProfileUserInfo({ groupId, userId }),
      useQueryGroupHostMembers({ groupId, shownOnlyHost: true, limit: 30 }),
      useQueryGroupMe({ groupId }),
    ],
  });

  const isMyProfile = String(me.id) === userId;
  const hostMembers = hostMembersData.members;
  const isAbleChangeRole = isSuperHost(me.role) && isMember(profile.role);
  const hasRestrictPermission = me.permissions.restrictMember;
  const isAbleBan = isMember(profile.role) && !isSuperHost(profile.role) && hasRestrictPermission;
  const { roleLabel, handleManagerRoleProfile } = useHandleManagerRoleProfileV2(
    groupId,
    profile,
    hostMembers,
    true,
    undefined
  );
  const { memberGradeEnabled, memberGradeEditSections, handleMemberGradeProfile } =
    useHandleMemberGradeProfile({ groupId, currentUser: me, profileUser: profile });

  const { open: openDialog, close: closeDialog } = useDialog();

  const handleBanProfile = () => {
    openDialog({
      element: (
        <DeleteMemberDialog
          groupId={groupId}
          userId={userId}
          nickname={profile.nickname}
          subNickname={profile.subNickname}
        />
      ),
      onOutsideClick: closeDialog,
    });
  };

  if (isMyProfile) return null;

  return (
    <section className={s.Wrapper}>
      <div className={s.Title}>멤버 관리</div>
      <SettingList.Wrapper
        paddingY={0}
        renderRight={<IconChevronRightLine size={16} color={vars.$scale.color.gray600} />}
      >
        <Render condition={isSuperHost(me.role)}>
          <SuperHostDelegationSettingItem groupId={groupId} targetUser={profile} me={me} />
        </Render>
        <Render condition={!memberGradeEnabled && isAbleChangeRole}>
          <SettingList.Item
            title={roleLabel}
            onClick={handleManagerRoleProfile}
            renderRight={<IconChevronRightLine size={16} color={vars.$scale.color.gray600} />}
          />
        </Render>
        <Render condition={!!memberGradeEnabled}>
          {memberGradeEditSections.map((section) => (
            <SettingList.Item
              key={section.displaySectionText}
              title={section.displaySectionText}
              onClick={() => handleMemberGradeProfile(section)}
              renderRight={<IconChevronRightLine size={16} color={vars.$scale.color.gray600} />}
            />
          ))}
        </Render>
        <SettingList.Item
          title="신고하기"
          onClick={() =>
            openGroupProfileReport({
              groupId,
              userId,
            })
          }
        />
        <Render condition={isAbleBan}>
          <SettingList.Item
            title="내보내기"
            warning
            onClick={handleBanProfile}
            renderRight={<IconChevronRightLine size={16} color={vars.$scale.color.gray600} />}
          />
        </Render>
      </SettingList.Wrapper>
    </section>
  );
};

export default GroupProfileMemberManage;
