import { BoxButton } from '@community-group/components';
import { Spacing } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useCallback } from 'react';

import { useReadGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useFlow } from '@/stackflow';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';
import { convertNicknameDisplay } from '@/utils/nickname';

import * as s from './CongratsManagerRoleBottomSheet.css';

export type CongratsManagerRoleBottomSheetParams = Pick<PageParams, 'groupId'>;

const CongratsManagerRoleBottomSheet: ActivityComponentType<
  CongratsManagerRoleBottomSheetParams
> = () => {
  const { pop } = useFlow();
  const { groupId } = usePathParams();
  const { data: currentUserData } = useReadGroupMe({ groupId });

  const nickname = currentUserData.nickname ?? '';
  const subNickname = currentUserData.subNickname ?? '';

  const handleCongratsManagerRoleBottomSheetClick = useCallback(() => {
    pop();
  }, [pop]);

  return (
    <BottomSheet style={{ padding: 0 }}>
      <div className={s.heroWrapper} />
      <div className={s.contentsWrapper}>
        <h1 className={s.title}>
          {convertNicknameDisplay({
            nickname: nickname,
            subNickname: subNickname,
          })}
          님!
          <br />
          운영진이 되신 것을 축하드려요
        </h1>
        <Spacing size={8} />
        <p className={s.description}>운영진은 아래와 같은 역할을 해요.</p>
        <ul className={s.contentsList}>
          <li className={s.contentsListItem}>
            <p className={s.contentsItemIndex}>1</p>
            <p className={s.contentsItemText}>공지사항을 등록할 수 있어요.</p>
          </li>
          <li className={s.contentsListItem}>
            <p className={s.contentsItemIndex}>2</p>
            <p className={s.contentsItemText}>가입 요청을 승인하거나 거절할 수 있어요.</p>
          </li>
          <li className={s.contentsListItem}>
            <p className={s.contentsItemIndex}>3</p>
            <p className={s.contentsItemText}>이웃을 강퇴할 수 있어요.</p>
          </li>
          <li className={s.contentsListItem}>
            <p className={s.contentsItemIndex}>4</p>
            <p className={s.contentsItemText}>글/댓글을 삭제할 수 있어요.</p>
          </li>
        </ul>
      </div>
      <div className={s.buttonWrapper}>
        <BoxButton width="100%" size="xlarge" onClick={handleCongratsManagerRoleBottomSheetClick}>
          알겠어요
        </BoxButton>
      </div>
    </BottomSheet>
  );
};

export default CongratsManagerRoleBottomSheet;
