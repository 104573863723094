import { groupClient } from '@community-group/api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { GROUP_MEMBER_QUERY_KEY } from '@/domain/GroupMember/queries';
import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

export type MemberListParams = {
  groupId: string;
  shownOnlyHost?: boolean;
  limit?: number;
};

export const useQueryGroupHostMembers = ({
  groupId,
  shownOnlyHost,
  limit = 30,
}: MemberListParams) => {
  const read = useAsyncRead(groupClient.api.GroupMemberApi.apiV1GroupsIdMembersGet);
  return {
    queryKey: GROUP_MEMBER_QUERY_KEY.hostMembers(groupId),
    queryFn: () =>
      read({
        id: Number(groupId),
        order: 'joinedAtAscWithRole',
        shownOnlyHost,
        gradeIds: undefined,
        roles: undefined,
        shownMemberActivities: false,
        shownMemberApplication: false,
        cursor: undefined,
        limit,
      }).then((res) => {
        return {
          memberCount: res.memberCount,
          members: res.members,
        };
      }),
  };
};
export const useReadGroupHostMembers = ({
  groupId,
  shownOnlyHost = true,
  limit = 30,
}: MemberListParams) => {
  const query = useQueryGroupHostMembers({ groupId, shownOnlyHost, limit });
  return useSuspenseQuery(query);
};
