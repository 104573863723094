import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { User, USER_ME_URL } from '@/api/base/user';

import { useFetchInstance } from './instance/useFetchInstance';

/** @deprecated  domain/Group/hooks/useReadMe를 사용해주세요 */
export const useGetMe = () => {
  const fetchInstance = useFetchInstance();

  const getUserMe = async () => {
    return await fetchInstance.get<undefined, AxiosResponse<User>>(USER_ME_URL);
  };
  const { data, refetch, isFetching } = useSuspenseQuery({
    queryKey: [USER_ME_URL],
    queryFn: () => getUserMe(),
  });

  return { data: data?.data as User, refetch, isFetching };
};
