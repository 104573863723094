import { groupClient } from '@community-group/api';
import { MeetupProgressStatus } from '@community-group/components';
import { useInfiniteQuery, useSuspenseInfiniteQuery } from '@tanstack/react-query';

import { GROUP_URL } from '../base/group';
import { useFetchInstance } from './instance/useFetchInstance';

export const getMyGroupsMeetupLikesPath = `${GROUP_URL}/my-groups/meetups/likes`;

type Props = {
  progressStatuses?: MeetupProgressStatus['status'][];
  initialCursor?: string | undefined;
};

export const useGetMyGroupsMeetupLikes = ({ progressStatuses, initialCursor }: Props) => {
  const fetchInstance = useFetchInstance();
  const getMyGroupsMeetupLikes = groupClient.api.MyGroupsApi.apiV1GroupsMyGroupsMeetupsLikesGet({
    axios: fetchInstance,
  });

  const { fetchNextPage, isFetchingNextPage, data, refetch, hasNextPage } =
    useSuspenseInfiniteQuery({
      queryKey: [getMyGroupsMeetupLikesPath, progressStatuses],
      queryFn: ({ pageParam = initialCursor }) =>
        getMyGroupsMeetupLikes({
          cursor: pageParam,
          limit: 20,
          progressStatuses,
        }),
      initialPageParam: initialCursor,
      getNextPageParam: ({ data }) => (data.hasNext ? data?.endCursor ?? undefined : undefined),
    });

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
  };
};
