import { Divider, ViewLoader } from '@community-group/components';
import { withAsyncBoundary } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';

import MemberGradeSpecSection from '@/features/GroupMember/MemberGradeSpec';
import MyMemberGradeSection from '@/features/GroupMember/MyMemberGrade';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';

export type GroupMemberGradeDetailPageParams = Pick<PageParams, 'groupId' | 'userId'>;

const GroupMemberGradeDetailPage: ActivityComponentType<GroupMemberGradeDetailPageParams> = () => {
  const { groupId, userId } = usePathParams();

  return (
    <AppScreen>
      <MyMemberGradeSection groupId={groupId} userId={userId} />
      <Divider size={8} />
      <MemberGradeSpecSection groupId={groupId} />
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupMemberGradeDetailPage, {
  pendingFallback: <ViewLoader />,
  rejectedFallback: <></>,
});
