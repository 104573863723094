import {
  GroupMemberProfileResponseProfile,
  GroupMemberSummaryPresentation,
} from '@community-group/api/lib/group/models';
import { Dialog, isHigherManager, useDialog } from '@community-group/components';
import { useCallback, useMemo } from 'react';

import { getGroupPath } from '@/api/base/group';
import { getGroupMainProfileQueryKey } from '@/api/hooks/useGetGroupMainProfile';
import { MemberListOrderType } from '@/api/hooks/useGetMemberList';
import { useBridge } from '@/contexts/Bridge';
import { usePatchGroupMemberRole } from '@/domain/GroupProfile/hooks/usePatchGroupMemberRole';
import { queryClient } from '@/shared/api/instance';

export const useHandleManagerRoleProfileV2 = (
  groupId: string,
  user: GroupMemberProfileResponseProfile,
  hostMemberList: GroupMemberSummaryPresentation[],
  shownOnlyHost?: boolean,
  selectedMemberOrderType?: MemberListOrderType
) => {
  const { open: openDialog, close: closeDialog } = useDialog();

  const { mutate: patchGroupMembersRole } = usePatchGroupMemberRole({});

  const isHigherManagerRole = useMemo(() => isHigherManager(user.role), [user.role]);
  const roleLabel = useMemo(
    () => (isHigherManagerRole ? '운영진에서 제외' : '운영진으로 설정'),
    [isHigherManagerRole]
  );

  const hostMemberCount = useMemo(() => hostMemberList.length, [hostMemberList]);

  const refetchMemberList = useCallback(() => {
    setTimeout(() => {
      queryClient.refetchQueries({
        queryKey: [`${getGroupPath(groupId)}/members`, selectedMemberOrderType, shownOnlyHost],
      });

      queryClient.refetchQueries({
        queryKey: [`${getGroupPath(groupId)}/members`, undefined, true],
      });
      queryClient.refetchQueries({
        queryKey: getGroupMainProfileQueryKey(user.id, parseInt(groupId)),
      });
    }, 30);
  }, [groupId, selectedMemberOrderType, shownOnlyHost, user.id]);
  const { bridge } = useBridge();
  const handleSetHost = useCallback(async () => {
    if (hostMemberCount > 10) {
      return bridge.openToast({
        toast: {
          body: '운영진은 최대 10명까지 설정할 수 있어요.',
        },
      });
    }

    return openDialog({
      element: (
        <Dialog
          title="운영진 설정"
          description={`${user.nickname}님을 운영진으로 설정할까요?`}
          primaryActionLabel="설정"
          secondaryActionLabel="취소"
          onPrimaryAction={async () => {
            patchGroupMembersRole(
              {
                groupId,
                userId: String(user.id),
                profile: {
                  role: 'manager',
                },
              },
              {
                onSuccess: () => {
                  bridge.openToast({
                    toast: { body: `${user.nickname}님을 운영진으로 설정했어요.` },
                  });
                  refetchMemberList();
                },
              }
            );

            await closeDialog();
          }}
          onSecondaryAction={closeDialog}
        />
      ),
    });
  }, [
    closeDialog,
    hostMemberCount,
    openDialog,
    user.nickname,
    user.id,
    bridge,
    patchGroupMembersRole,
    groupId,
    refetchMemberList,
  ]);

  const handleUnsetHost = useCallback(async () => {
    return openDialog({
      element: (
        <Dialog
          title="운영진 제외"
          description={`${user.nickname}님을 운영진에서 제외할까요?`}
          primaryActionLabel="제외"
          secondaryActionLabel="취소"
          onPrimaryAction={async () => {
            patchGroupMembersRole(
              {
                groupId,
                userId: String(user.id),
                profile: {
                  role: 'member',
                },
              },
              {
                onSuccess: () => {
                  bridge.openToast({
                    toast: { body: `${user.nickname}님을 운영진에서 제외했어요.` },
                  });
                  refetchMemberList();
                },
              }
            );

            await closeDialog();
          }}
          onSecondaryAction={closeDialog}
        />
      ),
    });
  }, [
    closeDialog,
    openDialog,
    user.nickname,
    user.id,
    patchGroupMembersRole,
    groupId,
    bridge,
    refetchMemberList,
  ]);

  return {
    roleLabel: useMemo(() => roleLabel, [roleLabel]),
    handleManagerRoleProfile: useMemo(
      () => (isHigherManagerRole ? handleUnsetHost : handleSetHost),
      [isHigherManagerRole, handleSetHost, handleUnsetHost]
    ),
  };
};
