import { GroupFeedSummaryType, GroupMemberRoleEnum } from '@community-group/api/lib/group/models';

import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';
import { shareGroupPostPermalink } from '@/utils/link';
import { isMember } from '@/utils/role';

type Params = {
  groupId: string;
  postId: string;
  postType: GroupFeedSummaryType;
  permalinkId: string;
  currentUserRole: GroupMemberRoleEnum;
  from: 'postDetailAppBar' | 'postDetailFeedback' | 'feedItem';
};

export const useShareContents = () => {
  const { push } = useFlow();

  return ({ groupId, postId, postType, permalinkId, currentUserRole, from }: Params) => {
    const isJoinedUser = isMember(currentUserRole);

    trackEvent({
      event: 'click_share',
      params: {
        groupId,
        postId,
        type: postType,
        isJoined: isJoinedUser,
        role: currentUserRole,
        from,
      },
      sample: true,
    });

    if (isJoinedUser) {
      const contentType = postType === 'group_meetup_review' ? 'groupMeetupReview' : 'groupPost';

      push('BottomSheet/SharePostBottomSheet', {
        groupId: groupId,
        permalinkId: permalinkId,
        contentId: postId,
        contentType,
      });
      return;
    }

    shareGroupPostPermalink(permalinkId);
  };
};
