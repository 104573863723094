import { GroupFeedSummaryType } from '@community-group/api/lib/group/models';

import { getDateDiffFromNow } from '@/utils/date';

type InfoTextPostType = {
  createdAt: string;
  isPublished: boolean;
  isGroupOnly: boolean;
  isNoticed?: boolean;
  boardCategory?: string;
  postType?: GroupFeedSummaryType;
};

// 목록 페이지에서 사용하는 포스트 정보 텍스트
export const getFeedPostInfoTextV2 = (post: InfoTextPostType) => {
  const createdAtText = getDateDiffFromNow(new Date(Date.parse(post.createdAt))).text + ' 전';
  const isNoticedText = post.isNoticed ? ' ∙ 공지' : undefined;
  const boardName = post?.boardCategory ?? '';
  const slicedBoardName = boardName?.length > 10 ? `${boardName.slice(0, 10)}...` : boardName;

  const isChallenge = post.postType === 'challenge';
  const isMeetup = post.postType === 'group_meetup';

  const postTypeText = isChallenge ? ` ∙ 챌린지` : isMeetup ? ` ∙ 모임일정` : '';

  const renderBoardNameText = slicedBoardName ? ` ∙ ${slicedBoardName}` : '';

  if (!isNoticedText) {
    return `${createdAtText}${renderBoardNameText}${postTypeText}`;
  }

  return `${createdAtText}${isNoticedText}`;
};
