import { axiosClient } from '@community-group/api';
import Cookies from 'universal-cookie';

import { COOKIE } from '@/constants/keys';
import { useDeviceConfig } from '@/contexts/DeviceConfig';
import { useUserConfig } from '@/contexts/UserConfig';

export const useKarrotCustomHeader = (): axiosClient.KarrotHeader => {
  const { deviceConfig } = useDeviceConfig();
  const { userConfig } = useUserConfig();

  const karrotCustomHeader = {
    'X-Auth-Token': userConfig.userAuthToken ?? '',
    'X-User-Id': (userConfig.userId ?? '').toString(),
    'X-Region-Id': (userConfig.regionId ?? '').toString(),
    'X-User-Agent': deviceConfig.xUserAgent ?? '',
  };

  if (!import.meta.env.SSR && !userConfig.userAuthToken) {
    karrotCustomHeader['X-Auth-Token'] = new Cookies().get(COOKIE.AUTH_TOKEN);
  }

  return karrotCustomHeader;
};
