import { Typography, withAsyncBoundary } from '@community-group/components';

import { useGetChallengeDetail } from '@/api/hooks/useGetChallengeDetail';

type Props = {
  groupId: string;
  challengeId: string;
};

const ChallengeCertifyFeedsTitle = ({ groupId, challengeId }: Props) => {
  const { data: challengeDetail } = useGetChallengeDetail({ groupId, challengeId });

  return (
    <Typography typography="title3Bold" ellipsisAfterLines={1}>
      {challengeDetail?.name}
    </Typography>
  );
};

export default withAsyncBoundary(ChallengeCertifyFeedsTitle, {
  rejectedFallback: <></>,
  pendingFallback: <></>,
});
