import { Typography } from '@community-group/components';
import React from 'react';

import * as s from './GuideCallout.css';
type Props = {
  guide: string;
};

const GuideCallout = ({ guide }: Props) => {
  return (
    <div className={s.OuterContainer}>
      <div className={s.Container}>
        <Typography typography="bodyL2Bold" color="gray900">
          ✍️ 다음에는 이렇게 작성해보세요
        </Typography>
        <Typography typography="bodyL2Regular" color="gray900">
          {guide}
        </Typography>
      </div>
    </div>
  );
};

export default GuideCallout;
