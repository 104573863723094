import { Spacing, Typography, ViewLoader, withAsyncBoundary } from '@community-group/components';
import { useMemo } from 'react';

import { usePutGroupMemberGradeRequirement } from '@/domain/GroupMember/hooks/usePutGroupMemberGradeRequirement';
import { useReadGroupMemberGradeRequirements } from '@/domain/GroupMember/hooks/useReadGroupMemberGradeRequirements';
import * as stackflow from '@/stackflow';

import * as s from './SettingMemberGradeRequirements.css';

type Props = {
  groupId: string;
};

const SettingMemberGradeRequirements = ({ groupId }: Props) => {
  const { data: requirements, refetch: refetchRequirements } =
    useReadGroupMemberGradeRequirements(groupId);
  const { mutate: putGroupMemberGradeRequirement } = usePutGroupMemberGradeRequirement({
    onSuccess: () => refetchRequirements(),
  });

  const requirementsWithLabel = useMemo(() => {
    const labelMap = {
      visit_group: '모임 방문',
      create_post: '게시글 작성',
      create_comment: '댓글 작성',
      join_meetup: '일정 참여',
    };

    return requirements.map((requirement) => {
      const label = labelMap[requirement.activity];

      return {
        ...requirement,
        label,
      };
    });
  }, [requirements]);

  const connectedFlow = stackflow?.useConnectedFlow();
  const handleClickRequirement = (requirement) => async () => {
    const request = await connectedFlow.push('BottomSheet/RequirementCountSelectorBottomSheet', {
      title: requirement.label,
      selectedCount: requirement.goalCount,
    });

    putGroupMemberGradeRequirement({
      groupId,
      activity: requirement.activity,
      goalCount: request.selectedCount,
    });
  };

  return (
    <div className={s.Container}>
      <div className={s.TitleContainer}>
        <Typography typography="subtitle1Bold" color="gray900">
          정회원 등업 조건
        </Typography>
        <Spacing size={2} />
        <Typography typography="caption1Regular" color="gray600">
          모든 조건을 만족하는 멤버는 등업을 신청할 수 있어요. 승인 이후 정회원이 돼요.
        </Typography>
      </div>
      <ul>
        {requirementsWithLabel.map((requirement) => {
          return (
            <li
              key={requirement.activity}
              className={s.ListItem}
              onClick={handleClickRequirement(requirement)}
            >
              <Typography typography="bodyM1Regular" color="gray900">
                {requirement.label}
              </Typography>
              <Typography typography="bodyM1Regular" color="primary">
                {requirement.goalCount}회
              </Typography>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default withAsyncBoundary(SettingMemberGradeRequirements, {
  pendingFallback: <ViewLoader />,
});
