import { GroupLevelPresentation } from '@community-group/api/lib/group/models';
import { AsyncBoundary, Spacing } from '@community-group/components';

import CurrentLevelBadge from './CurrentLevelBadge';
import GroupLevelLeaderBoardRank from './GroupLevelLeaderBoardRank';
import * as s from './index.css';
import LevelUpMissions from './LevelUpMissions';
import MaintainLevel from './MaintainLevel';
import MissionAchievePercentage from './MissionAchievePercentage';

type Props = {
  groupId: string;
  currentLevel: number;
  nextLevel: number;
  forLevelUp: GroupLevelPresentation['forLevelUp'];
  forMaintain?: GroupLevelPresentation['forMaintain'];
};

const CurrentLevelAndMission = ({
  groupId,
  currentLevel,
  nextLevel,
  forLevelUp,
  forMaintain,
}: Props) => {
  return (
    <div className={s.Container}>
      <CurrentLevelBadge currentLevel={currentLevel} />
      <Spacing size={16} />
      <MissionAchievePercentage
        currentLevel={currentLevel}
        nextLevel={nextLevel}
        currentAchievePercentage={forLevelUp.percentage}
      />
      <Spacing size={30} />
      <LevelUpMissions missions={forLevelUp.missions} groupId={groupId} />
      <MaintainLevel currentLevel={currentLevel} forMaintain={forMaintain} />

      <AsyncBoundary pendingFallback={<></>} rejectedFallback={<></>}>
        <GroupLevelLeaderBoardRank />
      </AsyncBoundary>
    </div>
  );
};

export default CurrentLevelAndMission;
