import { isNotMember } from '@community-group/components';

import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import InformationCore, { InformationCoreProps } from './InformationCore';

// 홈 > 우측 상단 프로필 페이지(메인 프로필) 노출 컴포넌트
const MainInformation = ({ profile, currentUser, ...rest }: InformationCoreProps) => {
  const { push } = useFlow();

  const handleImageClick = () => {
    const isBlock = currentUser && isNotMember(currentUser?.role);
    trackEvent({
      event: 'click_user_profile_image',
      params: { isJoined: !isBlock, userState: currentUser?.state },
      sample: true,
    });

    const profileImages = profile?.profileImage ? [profile.profileImage] : [];

    push('ImageViewerPage', {
      content: 'images',
      initialIndex: '0',
      imageUrls: JSON.stringify(profileImages),
    });
  };

  return (
    <InformationCore
      onClickProfileImage={handleImageClick}
      profile={profile}
      currentUser={currentUser}
      {...rest}
    />
  );
};

export default MainInformation;
