import { groupClient } from '@community-group/api';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Params = {
  groupId: string;
  meetupId: string;
  option?: groupClient.model.GroupMeetupDeleteOrModifyEnum;
};
type Props = UseMutationOptions<AxiosResponse, Error, Params>;

export const useDeleteGroupMeetupDetail = ({ onError, onSuccess, onMutate, onSettled }: Props) => {
  const fetchInstance = useFetchInstance();
  const deleteGroupMeetupDetail = groupClient.api.MeetupApi.apiV1GroupsGroupIdMeetupsMeetupIdDelete(
    { axios: fetchInstance }
  );

  const deleteGroupMeetupDetailWrapperFp = ({ groupId, meetupId, option }: Params) => {
    return deleteGroupMeetupDetail({
      groupId: Number(groupId),
      meetupId: Number(meetupId),
      option,
    });
  };

  return useThrottleMutation(deleteGroupMeetupDetailWrapperFp, {
    onMutate,
    onSettled,
    onError,
    onSuccess,
  });
};
