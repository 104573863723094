import { IconChevronLeftLine } from '@daangn/react-monochrome-icon';
import { useActivity } from '@stackflow/react';

import * as stackflow from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { useBack } from '@/stackflow/hooks/useBack';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

type Props = {
  children: React.ReactNode;
};

export const MemberListAppScreen = ({ children }: Props) => {
  const { groupId } = usePathParams();

  const stack = stackflow?.useFlow();
  const { isRoot } = useActivity();
  const pop = useBack();

  return (
    <AppScreen
      appBar={{
        closeButton: {
          renderIcon: () => <IconChevronLeftLine />,
          onClick: pop,
        },
        backButton: {
          renderIcon: () => <IconChevronLeftLine />,
          onClick: pop,
        },
        title: '모임 멤버',
        renderRight: () =>
          isRoot && (
            <span
              onClick={() => {
                stack?.push('GroupDetailPage', {
                  groupId,
                });
              }}
              style={{
                paddingRight: '0.5rem',
              }}
            >
              모임으로 가기
            </span>
          ),
      }}
    >
      {children}
    </AppScreen>
  );
};
