import { Typography } from '@community-group/components';
import { PaginationLoader } from '@community-group/components/shared';
import { Suspense } from 'react';

import GroupProfileContentsTabFeeds from '@/features/GroupProfile/ContentsTab/Feeds';

import * as s from './style.css';

type Props = Parameters<typeof GroupProfileContentsTabFeeds>[0];

const NotMemberContents = (props: Props) => {
  return (
    <Suspense fallback={<PaginationLoader />}>
      <div>
        <div className={s.NotMemberContentsWrapper}>
          <Typography typography="title3Bold">게시글</Typography>
        </div>
        <GroupProfileContentsTabFeeds {...props} />
      </div>
    </Suspense>
  );
};

export default NotMemberContents;
