import {
  ActionList,
  ActionListItem,
  ActionSheet,
  useBottomSheet,
} from '@community-group/components';
import { useMemo } from 'react';

import { getGroupFeedType } from '@/domain/GroupFeed/utils/getGroupFeedType';
import { trackEvent } from '@/utils/analytics';
import { openGroupChallengeReport, openGroupMeetupReport, openGroupPostReport } from '@/utils/link';

import { FeedModalProps } from './FeedModal';

const NormalUserRoleOptionsList = ({ groupId, post, onReportPost }: FeedModalProps) => {
  const { closeAsync: closeBottomSheet } = useBottomSheet();

  const feedType = useMemo(() => getGroupFeedType(post.postType), [post.postType]);
  return (
    <ActionSheet
      bottomButton={{
        label: '닫기',
      }}
    >
      <ActionList>
        <ActionListItem
          onClick={() => {
            if (onReportPost) return onReportPost();
            trackEvent({
              event: 'click_report',
              params: {
                type: 'post',
              },
            });
            closeBottomSheet();

            if (feedType === 'challenge' && post.challengeInfo?.id) {
              openGroupChallengeReport({
                challengeId: post.challengeInfo.id.toString(),
                authorId: post.author.id.toString(),
              });
              return;
            }

            if (feedType === 'meetup' && post.postType?.meetupId) {
              openGroupMeetupReport({
                meetupId: post.postType?.meetupId.toString(),
                hostId: post.author.id.toString(),
              });
              return;
            }

            openGroupPostReport({
              groupId: String(groupId),
              postId: String(post.id),
            });
          }}
        >
          신고
        </ActionListItem>
      </ActionList>
    </ActionSheet>
  );
};

export default NormalUserRoleOptionsList;
