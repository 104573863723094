export interface RegionInfo {
  id: number;
}

interface Region {
  id: number;
  name: string;
  fullName: string;
  province: string;
  city: string;
  town: string;
  location: string;
}
interface CheckInRegionInfo {
  certified: boolean;
  count: number;
  region: Region;
  regionId: number;
  regionName: string;
}
export interface UserInfo {
  id: number;
  authToken: string;
}

export interface AppInfo {
  userAgent: string;
  os: 'IOS' | 'ANDROID' | 'UNKNOWN';
  osVersion: string;
  version: string;
}

export interface UserMe {
  id: number;
  nickname: string;
  age: number;
  birthday: string;
  gender: string;
  profileImage: string;
  regionCheckIns: CheckInRegionInfo;
  status: string;
  temperature: number;
  verified: boolean;
}

export interface AppFoundation {
  app: AppInfo;
  user: UserInfo;
  region: RegionInfo;
  me?: UserMe;
}

export const APP_FOUNDATION_QUERY_KEY = ['appFoundation'];
