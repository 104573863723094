import { groupClient } from '@community-group/api';
import { GroupPermissionUpdateRequest } from '@community-group/api/lib/group/models';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useRead } from '@/shared/api/hooks/useRead';

type Params = {
  groupId: string;
  request: GroupPermissionUpdateRequest;
};

type Props = UseMutationOptions<AxiosResponse<void>, Error, Params>;

export const usePatchGroupPermissions = ({ onError, onSuccess, onMutate, onSettled }: Props) => {
  const put = useRead(groupClient.api.PermissionsApi.apiV1GroupsIdPermissionsPatch);

  return useMutation({
    mutationFn: ({ groupId, request }: Params) => {
      return put({
        id: Number(groupId),
        groupPermissionUpdateRequest: request,
      });
    },
    onError,
    onSuccess,
    onMutate,
    onSettled,
  });
};
