import {
  GroupCurrentUser,
  GroupMemberProfileResponseProfile,
  GroupMemberSummaryPresentation,
} from '@community-group/api/lib/group/models';
import { useBottomSheet } from '@community-group/components';
import { IconDot3VerticalLine } from '@daangn/react-monochrome-icon';
import { Suspense, useCallback } from 'react';

import { MemberListOrderType } from '@/api/hooks/useGetMemberList';
import MemberProfileActionBottomSheet, {
  MemberProfileActionBottomSheetFallback,
} from '@/components/group/memberList/components/bottomSheet/MemberProfileActionBottomSheet';
import useActiveActivities from '@/hooks/useActiveActivities';
import { useFlow } from '@/stackflow';

type Props = {
  groupId: string;
  profileUser: GroupMemberProfileResponseProfile;
  currentUser: GroupCurrentUser;
  shownOnlyHost?: boolean;
  selectedMemberOrderType?: MemberListOrderType;
  hostMemberList: GroupMemberSummaryPresentation[];
};

export const useHandleProfileMoreButtonV2 = ({
  groupId,
  profileUser,
  currentUser,
  shownOnlyHost,
  selectedMemberOrderType,
  hostMemberList,
}: Props) => {
  const { activeActivities } = useActiveActivities();
  const { push } = useFlow();

  const currentActivity = activeActivities[activeActivities.length - 1];
  const currentUserRole = currentUser.role ?? 'none';

  const { open: openBottomSheet } = useBottomSheet();

  const handleMoreButtonClick = useCallback(() => {
    const isGroupUserProfileDetailPage = currentActivity.name === 'GroupUserProfileDetailPage';
    const props = {
      groupId,
      currentUser,
      // FIXME: GroupMemberSummaryPresentation와의 호환성을 맞추기 위해 확장
      profileUser: {
        ...profileUser,
        isAccountDeleted: false, // 기존에는 탈퇴 여부인 isAccountDeleted를 체크했지만, 현재는 탈퇴 경우 404 내려줌.
        memberKey: '',
        createdAt: '',
        updatedAt: '',
        joinedAt: '',
      },
      hostMemberList,
      isGroupUserProfileDetailPage,
      shownOnlyHost,
      selectedMemberOrderType,
      push,
    };

    openBottomSheet({
      element: (
        <Suspense fallback={<MemberProfileActionBottomSheetFallback {...props} />}>
          <MemberProfileActionBottomSheet {...props} />
        </Suspense>
      ),
    });
  }, [
    currentActivity.name,
    currentUser,
    groupId,
    hostMemberList,
    openBottomSheet,
    profileUser,
    push,
    selectedMemberOrderType,
    shownOnlyHost,
  ]);

  const renderProfileMoreButton = useCallback(() => {
    if (currentUserRole === 'none') return null;

    if (currentUser.id === profileUser.id) return null;

    return (
      <button onClick={handleMoreButtonClick}>
        <IconDot3VerticalLine size={20} />
      </button>
    );
  }, [currentUserRole, currentUser.id, profileUser.id, handleMoreButtonClick]);

  return {
    renderProfileMoreButton,
  };
};
