import { ViewError, ViewLoader, withAsyncBoundary } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';

import useActiveActivities from '@/hooks/useActiveActivities';
import { useSubscribedStream } from '@/hooks/useSubscribedStream';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { PageParams } from '@/stackflow/types/params';

import RoomList from '../../Detail/components/RoomList';

type GroupDetailChatRoomPageParams = Pick<PageParams, 'groupId'>;

const GroupDetailChatRoomPage: ActivityComponentType<GroupDetailChatRoomPageParams> = () => {
  const { pop, replace } = useFlow();
  const { isExistSpecificActivities, diffActivitiesFromCurrent } = useActiveActivities();

  useSubscribedStream({
    EVENT_KEY: 'COMMUNITY_GROUP_LEAVE_CHAT',
    callback: (data) => {
      // groupDetailk
      if (isExistSpecificActivities('GroupDetailPage')) {
        const diff = diffActivitiesFromCurrent('GroupDetailPage');
        for (let i = 0; i < diff; i++) {
          pop();
        }
        return;
      }

      replace('HomePage', {});
    },
  });

  return (
    <AppScreen appBar={{ title: '채팅' }}>
      <RoomList />
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupDetailChatRoomPage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
