import { groupClient } from '@community-group/api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { GROUP_PROFILE_MEMBER_MANAGE_QUERY_KEY } from '@/domain/GroupProfileMemberManage/queries';
import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

type Props = {
  groupId: string;
  userId: string;
};

export const useQueryGroupMemberApplicationInfo = ({ groupId, userId }: Props) => {
  const read = useAsyncRead(
    groupClient.api.GroupMemberApi.apiV1GroupsIdMembersUserIdApplicationInfoGet
  );
  return {
    queryKey: GROUP_PROFILE_MEMBER_MANAGE_QUERY_KEY.applicationInfo(groupId, userId),
    queryFn: () => read({ id: Number(groupId), userId: Number(userId) }),
  };
};

export const useReadGroupMemberApplicationInfo = ({ groupId, userId }: Props) => {
  const query = useQueryGroupMemberApplicationInfo({ groupId, userId });
  return useSuspenseQuery({ ...query, select: (data) => data.application });
};
