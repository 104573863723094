import {
  GroupMeetupExposureRangeEnum,
  GroupMeetupProgressStatusPresentation,
  GroupMemberJoinedMeetupPresentation,
} from '@community-group/api/lib/group/models';
import { Typography } from '@community-group/components';
import { PaginationEmpty, PaginationList } from '@community-group/components/shared';
import { useCallback, useMemo } from 'react';

import GroupDetailFeedMeetupItem from '@/components/group/DetailV2/components/GroupDetailFeedMeetupItem';
import {
  BLOCK_GROUP_ONLY_TEXT,
  useHandleGroupOnly,
} from '@/components/group/JoinGroupState/hooks/useHandleGroupOnly';
import { getMeetupStatusColor } from '@/components/group/Meetup/Detail/utils/progressStatus';
import { useReadGroupMemberJoinedMeetups } from '@/domain/GroupProfile/hooks/useReadGroupMemberJoinedMeetups';
import { useFlow } from '@/stackflow';

import * as s from './style.css';

type Props = {
  groupId: string;
  userId: string;
  shouldSetSubNickname: boolean;
};

const GroupProfileContentsTabJoinedMeetups = ({ groupId, userId, shouldSetSubNickname }: Props) => {
  const {
    data: items,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useReadGroupMemberJoinedMeetups({ groupId, userId });
  const { push } = useFlow();
  const { handleGroupOnly, isGroupMember } = useHandleGroupOnly({ groupId });

  const getMeetupLabel = useCallback(
    (
      meetupState: GroupMeetupProgressStatusPresentation,
      exposureRange: GroupMeetupExposureRangeEnum,
      isDeleted: boolean
    ) => {
      const showGroupOnlyLabel = isGroupMember && exposureRange !== 'ALL';
      const exposureRangeLabel = showGroupOnlyLabel ? ' ∙ 모임에만 공개' : undefined;

      if (isDeleted) return <>{'종료'}</>;
      return (
        <>
          <Typography typography="caption1Regular" color={getMeetupStatusColor(meetupState.status)}>
            {meetupState.description}
          </Typography>
          {exposureRangeLabel}
        </>
      );
    },
    [isGroupMember]
  );

  const handleMeetupListItemClick = (meetup: GroupMemberJoinedMeetupPresentation) => () => {
    const blockedGroupOnly = !isGroupMember && meetup.exposureRange !== 'ALL';

    return handleGroupOnly({
      isBlock: blockedGroupOnly,
      blockGroupOnlyTitle: '모임 가입 안내',
      blockGroupOnlyText: BLOCK_GROUP_ONLY_TEXT.enterGroupMeetup,
      onSettled: () => {
        push('GroupMeetupDetailPage', {
          meetupId: meetup.id.toString(),
          groupId,
          from: 'groupDetailPage',
        });
      },
      onSuccess() {
        if (!shouldSetSubNickname) return;
        push('BottomSheet/GroupSetMemberProfileSubNicknameBottomSheet', {
          groupId,
        });
      },
    });
  };

  const firstItemsByYear = useMemo(() => {
    const yearMap = new Map<number, number>();
    items.forEach((item) => {
      const year = new Date(item.meetDate).getFullYear();
      if (!yearMap.has(year)) {
        yearMap.set(year, item.id);
      }
    });
    return yearMap;
  }, [items]);

  if (items.length <= 0) {
    return <PaginationEmpty>참여한 일정이 없어요.</PaginationEmpty>;
  }

  return (
    <div className={s.ListWrapper}>
      <div style={{ width: '100%' }}>
        <PaginationList
          items={items}
          render={(item) => {
            const meetDate = new Date(item.meetDate);
            const year = meetDate.getFullYear();
            const isFirstItemOfYear = firstItemsByYear.get(year) === item.id;
            console.log(!isGroupMember && item.exposureRange !== 'ALL');
            return (
              <div key={item.id}>
                {isFirstItemOfYear && (
                  <div className={s.YearHeader}>
                    <Typography typography="subtitle1Bold">{year}년</Typography>
                  </div>
                )}
                <GroupDetailFeedMeetupItem
                  key={item.id}
                  title={item.title}
                  label={getMeetupLabel(item.progressStatus!, item.exposureRange, item.isDeleted)}
                  meetDate={item.meetDate}
                  currentParticipantsNumber={item.currentParticipantsNumber}
                  maximumParticipantsNumber={item.maximumParticipantsNumber}
                  image={item.isDeleted ? undefined : item.image?.url}
                  meetTime={item?.meetTime}
                  showMonth
                  blockedGroupOnly={!isGroupMember && item.exposureRange !== 'ALL'}
                  onClick={handleMeetupListItemClick(item)}
                  isDeleted={item.isDeleted}
                />
              </div>
            );
          }}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          hasNextPage={hasNextPage}
        />
      </div>
    </div>
  );
};

export default GroupProfileContentsTabJoinedMeetups;
