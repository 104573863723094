import {
  UserMeetupMemberReview,
  UserMeetupMemberReviewMessage,
} from '@community-group/api/lib/group/models';
import { useMemo } from 'react';

import { MEETUP_FEEDBACK_TYPES } from '@/components/group/Meetup/Review/constant/reviewOptions';

export const useHandleMeetupReviews = (
  isMyProfile: boolean,
  meetupReviews: UserMeetupMemberReview[],
  meetupReviewMessages: UserMeetupMemberReviewMessage[]
) => {
  const filteredLikeReviews = useMemo(
    () =>
      meetupReviews.filter(({ feedbackTypeId }) => MEETUP_FEEDBACK_TYPES.LIKE.get(feedbackTypeId)),
    [meetupReviews]
  );

  const hasMeetupMemberReviews = useMemo(
    () => filteredLikeReviews.length > 0,
    [filteredLikeReviews]
  );

  const filteredLikeReviewMessages = useMemo(() => {
    if (filteredLikeReviews.length <= 0) return '';
    const mostReceivedReview = filteredLikeReviews.sort((a, b) => b.count - a.count)[0];
    const feedback = MEETUP_FEEDBACK_TYPES.LIKE.get(mostReceivedReview.feedbackTypeId);
    const message = `${mostReceivedReview.count}명의 멤버에게 ‘${feedback?.prefixIcon} ${feedback?.label}’ 평가를 받았어요.`;
    return message;
  }, [filteredLikeReviews]);

  const filteredMeetupMemberReviewMessages = useMemo(() => {
    if (isMyProfile) return meetupReviewMessages;

    return meetupReviewMessages.filter(({ reviewExposureRange }) => reviewExposureRange === 'ALL');
  }, [meetupReviewMessages, isMyProfile]);

  const reviewCount = filteredLikeReviews.reduce((acc, { count }) => acc + count, 0);

  //Info: 받은 감사 인사가 없는 경우에는 가장 많이 받은 후기 메시지를 표시
  const thumbnailReviewMessage =
    filteredMeetupMemberReviewMessages.find(
      ({ reviewExposureRange }) => reviewExposureRange === 'ALL'
    )?.reviewMessage ?? filteredLikeReviewMessages;

  return {
    hasMeetupMemberReviews,
    filteredMeetupMemberReviewMessages,
    reviewCount,
    thumbnailReviewMessage,
  };
};
