import { Typography } from '@community-group/components';
import { IconFlameFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { useMemo } from 'react';

import * as s from './PoiBanner.css';

type Props = {
  challengeId: number;
  name: string;
  totalDays: number;
  participantsCount: number;

  onClick?: () => void;
  renderRight?: () => React.ReactNode;
};

// Legacy 피드 게시글 내부 - 챌린지 배너
// TODO: feed api 내 challengeInfo에 추가 정보 내려오도록 서버 작업 이후 feature flag 적용 이후 이 컴포넌트 제거 필요
export const LegacyFeedContentsChallengeBanner = ({
  challengeId,
  name,
  totalDays,
  participantsCount,
  onClick: handleClick,
  renderRight,
}: Props) => {
  const subInfo = useMemo(() => {
    const totalWeeks = totalDays / 7;
    const attendedCount = participantsCount;

    return `${totalWeeks}주 챌린지 ⸱ ${attendedCount}명 참여`;
  }, [totalDays, participantsCount]);

  return (
    <div
      className={s.wrapper}
      onClick={() => {
        return handleClick?.();
      }}
    >
      <div className={s.ImageContainer}>
        <IconFlameFill size={24} color={vars.$semantic.color.primary} />
      </div>
      <div className={s.contentsWrapper}>
        <div className={s.contents}>
          <Typography typography="bodyM2Regular" ellipsisAfterLines={1}>
            {name}
          </Typography>
          <Typography typography="caption1Regular" color="gray600" ellipsisAfterLines={1}>
            {subInfo}
          </Typography>
        </div>
        {renderRight && <div className={s.right}>{renderRight()}</div>}
      </div>
    </div>
  );
};
