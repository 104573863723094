import { Spacing, Typography } from '@community-group/components';
import { motion } from 'framer-motion';
import { ReactNode } from 'react';

import * as s from './RequirementProgress.css';

type Props = {
  label: string;
  representativeIcon: ReactNode;
  currentCount: number;
  goalCount: number;
};

const RequirementProgress = ({ label, representativeIcon, currentCount, goalCount }: Props) => {
  const progress = Math.min((currentCount / goalCount) * 100, 100);
  const circleSize = 60;
  const strokeWidth = 2;

  const radius = (circleSize - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;

  return (
    <div className={s.Container}>
      <div className={s.ProgressCircleContainer}>
        <svg className={s.Svg} viewBox={`0 0 ${circleSize} ${circleSize}`}>
          <circle className={s.Circle} cx={circleSize / 2} cy={circleSize / 2} r={radius} />
          <motion.circle
            className={s.Progress}
            cx={circleSize / 2}
            cy={circleSize / 2}
            r={radius}
            initial={{ strokeDasharray: circumference, strokeDashoffset: circumference }}
            animate={{
              strokeDashoffset: circumference - (progress / 100) * circumference,
            }}
            transition={{ duration: 1, ease: 'easeInOut' }}
          />
        </svg>
        <div className={s.Content}>{representativeIcon}</div>
      </div>
      <Spacing size={12} />
      <Typography typography="label3Regular" color="gray900">
        {label}
      </Typography>
      <Spacing size={6} />
      <Typography typography="label3Regular" color="gray700">
        {currentCount}/{goalCount}
      </Typography>
    </div>
  );
};

export default RequirementProgress;
