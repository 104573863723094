import {
  AsyncBoundary,
  BottomBasicButton,
  MultilineTextField,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { Spacing } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { useGetEventLogMyInfo } from '@/api/hooks/useGetEventLogMyInfo';
import { useReadGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { useReadGroupMeetupDetail } from '@/api/hooks/useGetGroupMeetupDetail';
import { usePostApplication } from '@/api/hooks/usePostApplication';
import { usePutGroupMeetupJoin } from '@/api/hooks/usePutGroupMeetupJoin';
import { FormContainer, FormTitle } from '@/components/common/Form';
import { useBridge } from '@/contexts/Bridge';
import { useUserConfig } from '@/contexts/UserConfig';
import useCurrentGroupMe from '@/hooks/useCurrentGroupMe';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { LayoutWrapper } from '@/stackflow/components/Layout';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { useStore } from '@/store';
import { MODAL_KEY } from '@/store/modal/modalSliceStore';
import { extendAppsflyerLoggerType, trackEvent } from '@/utils/analytics';
import { GroupEventParams } from '@/utils/analytics/type';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';
import { refetchGroupMeetupDetail } from '@/utils/refetch/groupMeetupDetail';

import useHomeRegionRangeExpandExperiment from '../../Home/hooks/useHomeRegionRangeExpandExperiment';
import { useJoinGroupState } from '../../JoinGroupState/hooks/useJoinGroupState';
import useGroup10월운동MarketingPromotion from '../../MarketingPromotion/24.10/hooks/useGroup10월운동MarketingPromotion';
import useGroup11월독서MarketingPromotion from '../../MarketingPromotion/24.11/hooks/useGroup11월독서MarketingPromotion';
import useGroup12월덕질MarketingPromotion from '../../MarketingPromotion/24.12/hooks/useGroup12월덕질MarketingPromotion';
import * as s from './index.css';

export type GroupProfileApprovalPageParams = Pick<
  PageParams,
  'groupId' | 'instantJoinMeetupId' | 'joinType'
>;

const GroupProfileApprovalPage: ActivityComponentType<GroupProfileApprovalPageParams> = () => {
  const { watch, setValue } = useForm();
  const { pop } = useFlow();
  const answerField = watch('answer');

  const { groupId } = usePathParams();

  //TODO: 10월 운동 프로모션 가입 본 마케팅 프로모션 바텀시트, 종료 후 제거 가능
  const { handleOpen10월운동MarketingPromotionBottomSheet } = useGroup10월운동MarketingPromotion();
  //TODO: 11월 독서 프로모션 가입 본 마케팅 프로모션 바텀시트, 종료 후 제거 가능
  const { handleOpen11월독서MarketingPromotionBottomSheet } = useGroup11월독서MarketingPromotion();

  //TODO: 12월 덕질 프로모션 가입 본 마케팅 프로모션 바텀시트, 종료 후 제거 가능
  const { handleOpen12월덕질MarketingPromotionBottomSheet } = useGroup12월덕질MarketingPromotion();

  const { userConfig } = useUserConfig();

  const { instantJoinMeetupId, joinType } = useQueryParams();
  const { setOpenWebviewModal, setRecommendGroup } = useStore();

  const { group } = useReadGroupDetail(groupId);
  const { groupInfo, approvalConditions, userState } = useJoinGroupState({ groupId });

  const { data: groupMeetupData } = useReadGroupMeetupDetail({
    groupId,
    meetupId: instantJoinMeetupId?.toString() ?? '',
  });

  const currentUser = useCurrentGroupMe();

  // 홈피드 지역 노출 제한 제거 실험
  const { regionExpandExperimentValue } = useHomeRegionRangeExpandExperiment();

  const handleErrorWithToast = useHandleErrorWithToast();

  const hasQuestion =
    approvalConditions === 'optional_answer' || approvalConditions === 'require_answer';

  const { data: eventMyInfo } = useGetEventLogMyInfo();

  const trackJoinGroupEvent = () => {
    const isFirstJoinGroup = !eventMyInfo?.data.isJoinedGroup;

    const eventParams: GroupEventParams = {
      joinType: joinType,
      groupId: group?.id,
      groupName: group?.name,
      groupCategory: group?.category.name,
      checkinReigionId: userConfig.regionId ?? 0,
      checkinMostAccurate: userConfig.regionTownName ?? '',
      isInstantMeetupJoin: !!instantJoinMeetupId,
      needVerification: group?.needVerification,
      canViewVerifiedUserInfo: group?.canViewVerifiedUserInfo,
      watched: group?.watched,
      isFirstJoinGroup,
      isChatRequired: !groupInfo.isShowChatRoomSetting,
      isChatActivated: !groupInfo.isDeactivateChatRoom,
      regionExpandExperimentValue,
    };

    trackEvent({
      event: 'click_join_group',
      params: eventParams,
      loggerType: extendAppsflyerLoggerType,
    });

    if (isFirstJoinGroup) {
      trackEvent({
        event: 'click_first_join_group',
        params: eventParams,
        loggerType: ['APPSFLYER'],
      });
    }
  };

  const trackJoinGroupMeetupEvent = () => {
    trackEvent({
      event: 'click_join_group_meetup',
      params: {
        meetupId: instantJoinMeetupId ?? '',
        groupId: groupId.toString() ?? '',
        userRole: currentUser.role,
        currentMeetupJoinStatus: groupMeetupData.currentUserInfo.meetupJoinStatus,
        category: group?.category.name,
        currentUserState: userState,
        isInstantMeetupJoin: true,
        needVerification: group?.needVerification,
      },
      loggerType: extendAppsflyerLoggerType,
    });
  };
  const { bridge } = useBridge();

  const { mutate: mutateGroupMeetupJoin, isPending: isLoadingJoin } = usePutGroupMeetupJoin({
    onError: (error) => handleErrorWithToast(error),
    onSuccess: async () => {
      trackJoinGroupEvent();

      if (hasQuestion) {
        bridge.openToast({
          toast: {
            body: '참여를 신청했어요. 승인되면 다시 알려드릴게요.',
          },
        });
      }

      refetchGroupMeetupDetail({
        groupId,
        meetupId: instantJoinMeetupId?.toString() ?? '',
      });

      refetchGroupDetail({
        groupId,
      });
      trackJoinGroupMeetupEvent();

      pop();

      handleOpen10월운동MarketingPromotionBottomSheet({
        groupId,
        type: 'join',
      });
      handleOpen11월독서MarketingPromotionBottomSheet({
        groupId,
        type: 'join',
      });
      handleOpen12월덕질MarketingPromotionBottomSheet({
        groupId,
        type: 'join',
      });
    },
  });

  const { mutate: applicationMutate, isPending: isLoadingApplication } = usePostApplication({
    onError: (error) => handleErrorWithToast(error),
    onSuccess: async (data) => {
      trackJoinGroupEvent();

      // INFO: 서버 작업 필요해서 Known Issue로 추후에 수정할 예정
      if (data.data.application.recommendedGroups) {
        await setRecommendGroup(data.data.application.recommendedGroups);
        setOpenWebviewModal(MODAL_KEY.RECOMMEND_3_OTHER_GROUPS, true);
      }
      refetchGroupDetail({ groupId });

      pop();

      handleOpen10월운동MarketingPromotionBottomSheet({
        groupId,
        type: 'apply',
      });
      handleOpen11월독서MarketingPromotionBottomSheet({
        groupId,
        type: 'apply',
      });
      handleOpen12월덕질MarketingPromotionBottomSheet({
        groupId,
        type: 'apply',
      });
    },
  });

  const handleSubmit = () => {
    if (instantJoinMeetupId) {
      mutateGroupMeetupJoin({
        groupId: groupId,
        meetupId: instantJoinMeetupId,
        groupMeetupJoinForm: {
          profile: undefined,
          answer: answerField
            ? {
                questionId: group?.applicationConfig?.questions[0].id || 0,
                answer: String(answerField),
              }
            : undefined,
        },
      });
      return;
    }
    applicationMutate({
      id: Number(groupId),
      groupMemberApplicationForm: {
        answers: answerField
          ? [
              {
                questionId: group?.applicationConfig?.questions[0].id || 0,
                answer: String(answerField),
              },
            ]
          : [],
        profile: undefined,
      },
    });
  };

  const isDisabledButton = useMemo(() => {
    if (!group?.applicationConfig?.questions[0].required) return false;

    return !answerField;
  }, [answerField]);

  return (
    <AppScreen
      accessoryBar={
        <BottomBasicButton
          loading={isLoadingApplication || isLoadingJoin}
          disabled={isDisabledButton}
          onClick={handleSubmit}
        >
          신청
        </BottomBasicButton>
      }
    >
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
        <LayoutWrapper padding="16px">
          <p className={s.title}>
            모임장의 승인이 <br />
            필요한 모임이에요
          </p>
          <Spacing size={8} />
          <p className={s.subTitle}>모임장이 설정한 질문에 답변해주세요.</p>
          <Spacing size={24} />
          <FormContainer>
            <FormTitle>{group?.applicationConfig?.questions[0].question}</FormTitle>
            <div id="description-textarea">
              <MultilineTextField
                value={answerField ?? ''}
                onChange={(value) => {
                  setValue('answer', value);
                }}
                maxLength={200}
                placeholder="질문에 대한 답변을 적어주세요."
              />
            </div>
          </FormContainer>
        </LayoutWrapper>
      </AsyncBoundary>
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupProfileApprovalPage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
