import { BoxButton, useBottomSheet } from '@community-group/components';

import GroupEditMyProfileBottomSheet from '@/components/group/profile/MemberProfileDetail/pages/bottomSheet/GroupEditMyProfileBottomSheet';
import { useFlow } from '@/stackflow';

type Props = {
  groupId: string;
  userId: string;
  isSubNicknameSettingOn: boolean;
};

const GroupProfileEditButton = ({ groupId, userId, isSubNicknameSettingOn }: Props) => {
  const { push } = useFlow();
  const { open: openBottomSheet } = useBottomSheet();
  const handleEditProfilePage = () => push('GroupProfileEditPage', { groupId, userId });
  const handleEditSubNicknamePage = () =>
    push('GroupEditMemberSubNicknamePage', { groupId, userId });

  const handleClick = () => {
    if (!isSubNicknameSettingOn) return handleEditProfilePage();
    openBottomSheet({
      element: (
        <GroupEditMyProfileBottomSheet
          pushEditProfilePage={handleEditProfilePage}
          pushEditSubNicknamePage={isSubNicknameSettingOn ? handleEditSubNicknamePage : undefined}
        />
      ),
    });
  };
  return (
    <BoxButton
      size="medium"
      variant="secondary"
      UNSAFE_style={{ width: '100%' }}
      onClick={handleClick}
    >
      모임 프로필 수정
    </BoxButton>
  );
};

export default GroupProfileEditButton;
