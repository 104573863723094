import { withAsyncBoundary } from '@community-group/components';
import { MouseEventHandler, useMemo } from 'react';

import { useGetCommentEmotions } from '@/api/hooks/useGetCommentEmotions';
import EmotionCountMessage from '@/components/common/EmotionCountMessage';
import { useReadGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';

import * as s from './CommentEmotionCountMessage.css';

type Props = {
  groupId: string;
  relatedId: string;
  commentId: string;
  relatedContentType: 'post' | 'meetup';
  commentEmotionCount: number;
  hasCurrentUserEmotion: boolean;
  onClick: MouseEventHandler;
};

const CommentEmotionCountMessage = ({
  groupId,
  relatedId,
  commentId,
  relatedContentType,
  commentEmotionCount,
  ...props
}: Props) => {
  const { data: currentUser } = useReadGroupMe({ groupId });
  const { data } = useGetCommentEmotions({
    groupId: Number(groupId),
    relatedId: Number(relatedId),
    commentId: Number(commentId),
    relatedContentType,
    limit: 2,
  });

  const emotionUsers = useMemo(
    () => data?.pages.flatMap((page) => page?.data.users) ?? [],
    [data?.pages]
  );
  const emotionCount =
    data?.pages.reduce((count, page) => count + page?.data.emotionCount, 0) ?? commentEmotionCount;

  if (!currentUser || !emotionCount) return null;

  return (
    <div className={s.Wrapper}>
      <EmotionCountMessage
        currentUser={currentUser}
        emotionCount={emotionCount}
        emotionUsers={emotionUsers}
        {...props}
      />
    </div>
  );
};

export default withAsyncBoundary(CommentEmotionCountMessage, {});
