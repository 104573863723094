import { GroupMemberSummaryPresentation } from '@community-group/api/lib/group/models';
import { withAsyncBoundary } from '@community-group/components';

import IconWrapper from '@/components/common/Icons';

import { useHandleProfileMoreButton } from '../hooks/useHandleProfileMoreButton';

type Props = {
  groupId: string;
  profile: GroupMemberSummaryPresentation;
  hostMemberList: GroupMemberSummaryPresentation[];
};

const MemberProfileDetailGradeApplicationListItemMoreButton = ({
  groupId,
  profile,
  hostMemberList,
}: Props) => {
  const { renderProfileMoreButton } = useHandleProfileMoreButton({
    groupId,
    profileUser: profile,
    hostMemberList,
  });
  const component = renderProfileMoreButton();
  if (!component) return <></>;

  return <IconWrapper>{component}</IconWrapper>;
};

export default withAsyncBoundary(MemberProfileDetailGradeApplicationListItemMoreButton, {});
