import { Typography } from '@community-group/components';
import { PropsWithChildren } from 'react';

import * as s from './style.css';

type Props = PropsWithChildren;

export const PaginationEmpty = ({
  children = (
    <>
      아직 게시글이 없어요.
      <br />
      가장 먼저 게시글을 남겨보세요.
    </>
  ),
}: Props) => (
  <div className={s.FallbackWrapper}>
    <Typography typography="bodyM1Regular" color="gray600" textAlign="center">
      {children}
    </Typography>
  </div>
);
