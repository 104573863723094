import { groupClient } from '@community-group/api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useRead } from '@/shared/api/hooks/useRead';

export const getMyGroupListPath = () =>
  groupClient.api.MyGroupsApi.getapiV1GroupsSummaryMyGroupsGetPath();

export const useQueryMyGroupList = () => {
  const read = useRead(groupClient.api.MyGroupsApi.apiV1GroupsSummaryMyGroupsGet);
  return {
    queryKey: [getMyGroupListPath()],
    queryFn: () => read(),
  };
};

export const useGetMyGroupList = () => {
  const query = useQueryMyGroupList();
  const { data, refetch } = useSuspenseQuery({
    ...query,
  });

  return { data: data?.data, refetch };
};
