import { groupClient } from '@community-group/api';
import {
  ChallengeListPresentation,
  ChallengeStatusEnum,
  ChallengeSummaryPresentation,
} from '@community-group/api/lib/group/models';
import { InfiniteData, useSuspenseInfiniteQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

export const getGroupChallengeListPath = (
  id: number,
  status: ChallengeStatusEnum,
  cursor?: string,
  limit?: number
) =>
  groupClient.api.GroupChallengeApi.getapiV1GroupsGroupIdChallengesGetPath(
    id,
    status,
    cursor,
    limit
  );

// 모임 상세 페이지에서 사용하는 챌린지 목록 조회
// groupId 기반 해당 모임에 만들어진 챌린지 목록 조회
export const useGetGroupChallengeList = ({
  id,
  status,
  initialCursor = undefined,
  limit = 30,
}: {
  id: number;
  status: ChallengeStatusEnum;
  initialCursor?: string;
  limit?: number;
}) => {
  const fetchInstance = useFetchInstance();
  const getChallengeList = groupClient.api.GroupChallengeApi.apiV1GroupsGroupIdChallengesGet({
    axios: fetchInstance,
  });

  const { fetchNextPage, hasNextPage, isFetchingNextPage, data, refetch } =
    useSuspenseInfiniteQuery({
      queryKey: [getGroupChallengeListPath(id, status, undefined, limit), status],
      queryFn: ({ pageParam = initialCursor }) => {
        return getChallengeList({
          groupId: Number(id),
          status,
          cursor: pageParam,
          limit,
        });
      },
      initialPageParam: initialCursor,
      getNextPageParam: ({ data }) =>
        data.pagination?.hasNext ? data?.pagination.endCursor : undefined,
      refetchInterval: 10000,
      select: selectPaginationFlatten,
    });

  return {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  };
};

function selectPaginationFlatten(
  data: InfiniteData<{ data: ChallengeListPresentation }> | undefined
): ChallengeSummaryPresentation[] {
  if (!data) return [];
  return data.pages.flatMap((page) => page.data.contents ?? []);
}
