import { groupClient } from '@community-group/api';
import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import { useRead } from '@/shared/api/hooks/useRead';
import { selectPaginationFlatten } from '@/shared/utils/paginationQuery';

import { GROUP_MEMBER_QUERY_KEY } from '../queries';

type Props = {
  groupId: string;
  cursor?: string;
  limit?: number;
};

export const useReadGroupMemberGradeApplications = ({
  groupId,
  cursor = undefined,
  limit = 20,
}: Props) => {
  const read = useRead(groupClient.api.GroupMemberApi.apiV1GroupsIdMembersGradeApplicationsGet);
  return useSuspenseInfiniteQuery({
    queryKey: GROUP_MEMBER_QUERY_KEY.memberGradeApplications(groupId),
    queryFn: async ({ pageParam = cursor }) => {
      const response = await read({
        id: Number(groupId),
        cursor: pageParam,
        size: limit,
      });
      return {
        ...response,
        data: {
          items: response.data.gradeApplications,
          hasNext: response.data.hasNext,
          endCursor: response.data.endCursor ?? '',
        },
      };
    },
    initialPageParam: cursor,
    getNextPageParam: ({ data }) => (data.hasNext ? data?.endCursor ?? undefined : undefined),
    select: selectPaginationFlatten,
  });
};
