import {
  GroupCurrentUser,
  GroupMemberProfileResponseProfile,
} from '@community-group/api/lib/group/models';
import { Dialog, useDialog } from '@community-group/components';
import { SettingList } from '@community-group/components/features';
import { IconChevronRightLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';

import { useBridge } from '@/contexts/Bridge';
import { usePatchGroupMemberRole } from '@/domain/GroupProfile/hooks/usePatchGroupMemberRole';
import { convertNicknameDisplay } from '@/utils/nickname';

type Props = {
  groupId: string;
  targetUser: GroupMemberProfileResponseProfile;
  me: GroupCurrentUser;
};
const SuperHostDelegationSettingItem = ({ groupId, targetUser, me }: Props) => {
  const { open: openDialog, close: closeDialog } = useDialog();
  const { bridge } = useBridge();
  const { mutate: patchGroupMembersRole } = usePatchGroupMemberRole({});
  const handleProfileClick = () => {
    openDialog({
      element: (
        <Dialog
          title="모임장 위임"
          description={`${convertNicknameDisplay({
            nickname: targetUser.nickname,
            subNickname: targetUser.subNickname,
          })}님에게 모임장을 위임할까요? 위임하면 ${convertNicknameDisplay({
            nickname: me.nickname ?? '',
            subNickname: me.subNickname,
          })}님은 멤버로 변경돼요.`}
          primaryActionLabel="위임"
          secondaryActionLabel="취소"
          onSecondaryAction={closeDialog}
          onPrimaryAction={async () => {
            patchGroupMembersRole(
              {
                groupId,
                userId: String(targetUser.id),
                profile: {
                  role: 'superHost',
                },
              },
              {
                onSuccess: () => {
                  bridge.openToast({
                    toast: {
                      body: `${convertNicknameDisplay({
                        nickname: targetUser.nickname,
                        subNickname: targetUser.subNickname,
                      })}님에게 모임장을 위임했어요.`,
                    },
                  });
                },
              }
            );
            await closeDialog();
          }}
        />
      ),
    });
  };

  return (
    <SettingList.Item
      title="모임장 위임"
      onClick={handleProfileClick}
      renderRight={<IconChevronRightLine size={16} color={vars.$scale.color.gray600} />}
    />
  );
};

export default SuperHostDelegationSettingItem;
