import { groupClient } from '@community-group/api';
import { ApiV1GroupsIdApplicationsPostRequestParams } from '@community-group/api/lib/group/api/group-application-api';

import { useRead } from '@/shared/api/hooks/useRead';
import {
  useThrottleMutation,
  UseThrottleMutationOption,
} from '@/shared/api/hooks/useThrottleMutation';

type Props = UseThrottleMutationOption;
export const usePostApplication = (props: Props) => {
  const fetch = useRead(groupClient.api.GroupApplicationApi.apiV1GroupsIdApplicationsPost);

  const mutation = ({
    id,
    groupMemberApplicationForm,
  }: ApiV1GroupsIdApplicationsPostRequestParams) => fetch({ id, groupMemberApplicationForm });

  return useThrottleMutation(mutation, props);
};
