import { groupClient } from '@community-group/api';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Params = {
  groupId: string;
  challengeId: string;
};
type Props = UseMutationOptions<AxiosResponse<void>, Error, Params>;

export const useDeleteChallenge = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const deleteChallenge =
    groupClient.api.GroupChallengeApi.apiV1GroupsGroupIdChallengesChallengeIdDelete({
      axios: fetchInstance,
    });

  const fetchDeleteChallenge = ({ groupId, challengeId }: Params) => {
    return deleteChallenge({ groupId: Number(groupId), challengeId: Number(challengeId) });
  };

  return useThrottleMutation(fetchDeleteChallenge, {
    onError,
    onSuccess,
  });
};
