import { Typography } from '@community-group/components';
import { IconChevronRightLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';

import { useFlow } from '@/stackflow';

import * as s from './style.css';

type Props = {
  reviewCount: number;
  content: string;
  userId: string;
  groupId: string;
};

const ReviewActionCallout = ({ reviewCount, content, userId, groupId }: Props) => {
  const { push } = useFlow();

  return (
    <div
      className={s.ReviewActionCallout}
      onClick={() => push('ProfileMeetupMemberReviewMessagesPage', { userId, groupId })}
    >
      <div className={s.ReviewActionCalloutContent}>
        <Typography typography="bodyL2Regular" ellipsisAfterLines={2}>
          <span style={{ ...vars.$semantic.typography.bodyL2Bold }}>받은 후기 {reviewCount} </span>
          {` ${content.trim().replace(/\n/g, ' ')}`}
        </Typography>
      </div>
      <IconChevronRightLine size={16} />
    </div>
  );
};

export default ReviewActionCallout;
