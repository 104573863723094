import { groupClient } from '@community-group/api';
import { PaginationQueryParams } from '@community-group/components/shared';
import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import { GROUP_PROFILE_QUERY_KEY } from '@/domain/GroupProfile/queries';
import { useRead } from '@/shared/api/hooks/useRead';
import { selectPaginationFlatten } from '@/shared/utils/paginationQuery';

type Props = {
  groupId: string;
  userId: string;
} & PaginationQueryParams;

export const useReadGroupProfileComments = ({
  groupId,
  userId,
  initialCursor = undefined,
  limit = 20,
}: Props) => {
  const read = useRead(groupClient.api.GroupMemberApi.apiV1GroupsIdMembersUserIdCommentsGet);
  return useSuspenseInfiniteQuery({
    queryKey: GROUP_PROFILE_QUERY_KEY.comments(groupId, userId),
    queryFn: async ({ pageParam = initialCursor }) => {
      const response = await read({
        id: Number(groupId),
        userId: Number(userId),
        cursor: pageParam,
        limit,
      });
      return {
        ...response,
        data: {
          items: response.data.comments,
          hasNext: response.data.hasNext,
          endCursor: response.data.endCursor ?? '',
        },
      };
    },
    initialPageParam: initialCursor,
    getNextPageParam: ({ data }) => (data.hasNext ? data?.endCursor ?? undefined : undefined),
    select: selectPaginationFlatten,
  });
};
