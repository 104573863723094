import { Typography } from '@community-group/components';

import * as s from './style.css';

const EmptyChallengeList = ({ withSurvey = false }: { withSurvey?: boolean }) => {
  return (
    <div className={s.Container}>
      <div className={s.EmptyTextContainer({ withSurvey })}>
        <Typography typography="subtitle1Regular" textAlign="center" color="gray600">
          아직 챌린지가 없어요.
          <br />
          가장 먼저 챌린지를 만들어보세요.
        </Typography>
      </div>
    </div>
  );
};

export default EmptyChallengeList;
