import { BoxButton, BulletItem, Spacing, Typography } from '@community-group/components';

import { useFlow } from '@/stackflow';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { openLink } from '@/utils/link';

import { CHALLENGE_PROMOTION_LANDKIT_LINK } from '../components/PromotionBanner/ChallengeListPromotionBanner';
import * as s from './ChallengeCertifyPromotionGuideBottomSheet.css';

const ChallengeCertifyPromotionGuideBottomSheet = () => {
  const { pop } = useFlow();

  const handleClick = () => {
    openLink(CHALLENGE_PROMOTION_LANDKIT_LINK, true);
  };

  return (
    <BottomSheet
      style={{
        padding: 0,
      }}
    >
      <div className={s.Wrapper}>
        <div className={s.ListWrapper}>
          <Typography typography="title2Bold" className={s.HeaderWrapper}>
            이벤트 안내
          </Typography>
          <Spacing size={20} />
          <BulletItem bulletIndent="0" gap=".375rem">
            이벤트는 2025년 01월 13일 18:00부터 2월 3일 11:59까지 진행돼요.{' '}
            <span
              onClick={handleClick}
              style={{
                textDecoration: 'underline',
              }}
            >
              이벤트 자세히 보기
            </span>
          </BulletItem>
          <Spacing size={10} />
          <BulletItem bulletIndent="0" gap=".375rem">
            당근머니는 챌린지 인증글을 작성하면, 게시글 본문의 풍성함과 챌린지 연관성 등을
            종합적으로 판단 후 퀄리티에 따라 차등으로 랜덤 지급돼요.
          </BulletItem>
          <Spacing size={10} />
          <BulletItem bulletIndent="1rem">
            1인당 하루 최대 3회까지만 당근머니를 받을 수 있어요.
          </BulletItem>
          <Spacing size={10} />
          <BulletItem bulletIndent="0" gap=".375rem">
            당근머니는 당근페이를 통해서만 지급되니 인증글 작성 전에 꼭 당근페이에 가입해 주세요.
          </BulletItem>
          <Spacing size={10} />
          <BulletItem bulletIndent="0" gap=".375rem">
            매주 월요일, 목요일에는 인증글당 최소 2배 ~ 최대 30배 당근머니를 지급해요.
          </BulletItem>
          <Spacing size={10} />
          <BulletItem bulletIndent="0" gap=".375rem">
            아래의 경우 게시글 삭제·이용 제한 또는 지급된 당근머니 회수등의 제재 조치가 있을 수
            있어요.
          </BulletItem>
          <Spacing size={10} />
          <BulletItem bulletIndent="1rem">
            무의미하거나 반복적인 챌린지를 생성하거나 인증글을 작성한 경우
          </BulletItem>
          <Spacing size={10} />
          <BulletItem bulletIndent="1rem">
            운영 정책을 위반한 인증글, 챌린지를 작성한 모임인 경우
          </BulletItem>
          <Spacing size={10} />
          <BulletItem>
            후기 삭제 후 재작성하거나 비정상적인 경로, 방법을 통해 이벤트에 참여하면 당근머니가
            지급되지 않아요.
          </BulletItem>
          <Spacing size={10} />
          <BulletItem>예산 소진 등 내부 사정으로 이벤트가 조기 종료될 수 있어요.</BulletItem>
          <Spacing size={20} />
        </div>
        <div className={s.CloseButtonWrapper}>
          <BoxButton width="100%" variant="secondary" onClick={() => pop()} size="xlarge">
            <Typography typography="label1Bold" color="gray900">
              닫기
            </Typography>
          </BoxButton>
        </div>
      </div>
    </BottomSheet>
  );
};

export default ChallengeCertifyPromotionGuideBottomSheet;
