import { groupClient } from '@community-group/api';
import { useInfiniteQuery, useSuspenseInfiniteQuery } from '@tanstack/react-query';
import { $Values } from 'utility-types';

import { GROUP_URL, ORDER_MEETUP } from '../../base/group';
import { useFetchInstance } from '../instance/useFetchInstance';

export interface AllTypeMeetupProps {
  regionId: number;
  categoryId?: number;
  meetupStatus?: ('opened' | 'fully_recruited' | 'closed')[];
  order: $Values<typeof ORDER_MEETUP>;
  initialCursor?: string | undefined;
}

export const getAllMeetupsPath = `${GROUP_URL}/all-type-meetups`;

export const useGetAllMeetups = ({
  regionId,
  meetupStatus = ['opened'],
  categoryId,
  order,
  initialCursor,
}: AllTypeMeetupProps) => {
  const fetchInstance = useFetchInstance();
  const getAllMeetups = groupClient.api.AllMeetupsApi.apiV1AllTypeMeetupsGet({
    axios: fetchInstance,
  });

  const { fetchNextPage, hasNextPage, isFetchingNextPage, data, refetch } =
    useSuspenseInfiniteQuery({
      queryKey: [getAllMeetupsPath, regionId, meetupStatus, categoryId, order],
      queryFn: ({ pageParam = initialCursor }) =>
        getAllMeetups({
          cursor: pageParam ? pageParam : undefined,
          limit: 20,
          regionId,
          statuses: meetupStatus,
          categoryId,
          order,
        }),
      initialPageParam: initialCursor,
      getNextPageParam: ({ data }) => (data.hasNext ? data?.endCursor ?? undefined : undefined),
    });

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
  };
};
