import { groupClient } from '@community-group/api';
import { ContentType } from '@community-group/api/lib/group/models';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

export const useGetShareTargetList = ({
  contentType,
  contentId,
}: {
  contentType: ContentType;
  contentId: string;
}) => {
  const fetchInstance = useFetchInstance();
  const getShareTargetList = groupClient.api.ShareContentsApi.apiV1ShareTargetListGet({
    axios: fetchInstance,
  });

  const { data, refetch } = useSuspenseQuery({
    queryKey: ['ShareTargetList', contentType, contentId],
    queryFn: () => getShareTargetList({ contentType, contentId }),
  });

  const response = data?.data;

  return { data: response, refetch };
};
