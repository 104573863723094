import { ColorValue, Spacing, Typography, TypographyValue } from '@community-group/components';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';

import * as s from './FeedItemContents.css';

type Props = PropsWithChildren & {
  typography?: TypographyValue;
  moreButtonTypography?: TypographyValue;
  color?: ColorValue;
};

const MAX_LINE = 5;
const LINE_HEIGHT = 24;

/**
 * @description 아파트와 동일한 폴더구조를 가져가기 위해 domain/GroupFeed/components/FeedItemContentText를 사용해주세요
 */
const FeedItemContentText = ({
  children,
  typography = 'bodyL1Regular',
  color = 'gray900',
  moreButtonTypography = 'bodyL2Regular',
}: Props) => {
  const textRef = useRef<HTMLParagraphElement>(null);
  const [isOverflow, setIsOverflow] = useState(false);

  useEffect(() => {
    const current = textRef.current;
    if (!current) return;

    // 실제 텍스트 높이와 라인 하이트를 바탕으로 줄 수 계산
    const computedStyle = window.getComputedStyle(current);
    const actualLineHeight = parseInt(computedStyle.lineHeight, 10) || LINE_HEIGHT;
    const actualLines = Math.ceil(current.scrollHeight / actualLineHeight);
    setIsOverflow(actualLines > MAX_LINE);
  }, []);

  return (
    <>
      <Typography
        as="p"
        ref={textRef}
        className={s.Content}
        color={color}
        style={{
          maxHeight: `${LINE_HEIGHT * MAX_LINE}px`,
        }}
        typography={typography}
      >
        {children}
      </Typography>
      {isOverflow && (
        <>
          <Spacing size={4} />
          <Typography typography={moreButtonTypography} color="gray600">
            ...더보기
          </Typography>
        </>
      )}
    </>
  );
};

export default FeedItemContentText;
