import { groupClient } from '@community-group/api';
import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import { GROUP_URL } from '../../base/group';
import { useFetchInstance } from '../instance/useFetchInstance';

export const useGetMyRoomList = (
  id: number,
  limit?: number,
  initialCursor?: string | undefined
) => {
  const fetchInstance = useFetchInstance();
  const getRoomList = groupClient.api.TalkRoomApi.apiV1GroupsIdMyRoomsGet({ axios: fetchInstance });

  const { fetchNextPage, isFetchingNextPage, data, refetch } = useSuspenseInfiniteQuery({
    queryKey: [`${GROUP_URL}/${id}/my-rooms`],
    queryFn: ({ pageParam = initialCursor }) => {
      const currentPageParam = pageParam === null ? undefined : pageParam;

      return getRoomList({
        id,
        cursor: currentPageParam,
        limit: limit ?? 15,
      });
    },
    initialPageParam: initialCursor,
    getNextPageParam: ({ data }) => (data.hasNext ? data?.endCursor ?? undefined : undefined),
    refetchInterval: 10000,
  });

  return {
    fetchNextPage,
    hasNextPage: data?.pages[data.pages.length - 1].data.hasNext,
    isFetchingNextPage,
    data,
    refetch,
  };
};
