import { GroupChallengeCreateRequest } from '@community-group/api/lib/group/models';
import {
  AsyncBoundary,
  BottomBasicButton,
  MultilineTextField,
  useStickInputStore,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { Spacing } from '@community-group/components';
import { useEffect, useRef } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

import { usePostChallenge } from '@/api/hooks/usePostChallenge';
import { usePostGroupProfile } from '@/api/hooks/usePostGroupProfile';
import { FormInput } from '@/components/common/base/Input/Text';
import { FormContainer, FormTitle } from '@/components/common/Form';
import { useUserConfig } from '@/contexts/UserConfig';
import useBackToActivity from '@/hooks/useBackToActivity';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useHandleSetGroupProfile } from '@/hooks/useHandleSetGroupProfile';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import {
  LayoutRefProps,
  LayoutWrapper,
} from '@/stackflow/components/Layout/components/ContentsLayout';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { trackEvent } from '@/utils/analytics';
import { refetchGroupChallenge } from '@/utils/refetch/challenge';
import { refetchGroupProfile } from '@/utils/refetch/groupGroupProfile';

import { GroupCreateProfileHeader } from './GroupCreateProfileHeader';
import { GroupCreateProfileImageUploader } from './GroupProfileImageUploader';

const GroupCreateProfileAndInstantCreateChallenge = () => {
  const { createChallengeForm } = useQueryParams();

  const parsedCreateChallengeForm = JSON.parse(
    createChallengeForm ?? '{}'
  ) as GroupChallengeCreateRequest;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const { setFocused } = useStickInputStore();

  const { userConfig } = useUserConfig();

  const { register, watch, setValue } = useForm<FieldValues>({
    defaultValues: {
      name: userConfig.userNickname || '',
    },
  });

  const nameFieldValue = watch('name');
  const descriptionFieldValue = watch('description');

  const { replace } = useFlow();
  const handleBack = useBackToActivity();

  const {
    profileImage,
    handleSetGroupProfile,
    isLoading: isProfileImageLoading,
  } = useHandleSetGroupProfile({});

  const handleErrorWithToast = useHandleErrorWithToast();
  // TODO: 신규 API, 서버 배포 후 동작시켜야함
  const { mutate: mutateCreateChallenge, isPending: isCreateChallengeLoading } = usePostChallenge({
    onSuccess: ({ data }) => {
      if (!data.challengeId) return;
      const formData = parsedCreateChallengeForm.challenge;
      trackEvent({
        event: 'click_finish_create_challenge',
        params: {
          challengeId: data.challengeId.toString(),
          challengeName: formData.name,
          challengeDuration: formData.certifyIntervalDays,
          challengeFrequency: formData.certifyIntervalTargetCount,
          challengeDescription: formData.description.slice(0, 50),
          hasExampleImage: (formData.images ?? []).length > 0,
          groupId: data.groupId.toString(),
        },
      });

      refetchGroupChallenge({
        groupId: data.groupId.toString(),
        challengeId: data.challengeId.toString(),
      });

      handleBack('GroupChallengeNewPage');
      replace('GroupChallengeDetailPage', {
        groupId: data.groupId.toString(),
        challengeId: data.challengeId.toString(),
        from: 'instantCreateChallenge',
      });
    },
    onError: handleErrorWithToast,
  });

  const { mutate: mutateCreateGroupProfile } = usePostGroupProfile({
    onError: (error) => {
      handleErrorWithToast(error);
    },
    onSuccess: () => {
      refetchGroupProfile();

      mutateCreateChallenge({
        groupChallengeCreateRequest: parsedCreateChallengeForm,
      });
    },
  });

  const handleSubmit = () => {
    mutateCreateGroupProfile({
      groupMainProfileCreateForm: {
        nickname: nameFieldValue,
        description: descriptionFieldValue,
        profileImageId: profileImage?.id,
      },
    });
  };

  const ref = useRef<LayoutRefProps>(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.setViweportInput({
        wrapperRef,
        callbackHandler: [
          {
            elementId: 'description-textarea',
            callback: (e) => {
              const el = e.target as HTMLTextAreaElement;
              el?.parentElement?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              });
            },
          },
        ],
      });
    }
  }, []);

  const isSubmitLoading = isCreateChallengeLoading || isProfileImageLoading;

  const renderAccessoryBarButton = () => {
    return (
      <BottomBasicButton
        disabled={!nameFieldValue || isSubmitLoading}
        loading={isSubmitLoading}
        onClick={handleSubmit}
      >
        완료
      </BottomBasicButton>
    );
  };

  return (
    <AppScreen layoutRef={ref} accessoryBar={renderAccessoryBarButton()}>
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
        <LayoutWrapper ref={wrapperRef} padding="0 1rem 1rem 1rem">
          <GroupCreateProfileHeader />
          <Spacing size={24} />
          <GroupCreateProfileImageUploader
            profileImage={profileImage}
            handleSetGroupProfile={handleSetGroupProfile}
            isLoading={isProfileImageLoading}
          />
          <Spacing size={24} />

          <FormContainer>
            <FormTitle>닉네임</FormTitle>
            <FormInput
              register={register}
              name="name"
              placeholder="닉네임을 입력해주세요."
              maxLength={12}
              value={nameFieldValue}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
            />
            <Spacing size={24} />
            <FormTitle>자기소개(선택)</FormTitle>

            <div id="description-textarea">
              <MultilineTextField
                value={descriptionFieldValue ?? ''}
                onChange={(value) => {
                  setValue('description', value);
                }}
                placeholder="자기소개를 입력해주세요."
                maxLength={200}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
              />
            </div>
          </FormContainer>
        </LayoutWrapper>
      </AsyncBoundary>
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupCreateProfileAndInstantCreateChallenge, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
