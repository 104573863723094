import { groupClient } from '@community-group/api';
import { useQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

export const getExploreOtherGroupsPath = ({
  categoryId,
  regionId,
  limit,
}: {
  categoryId: number;
  regionId: number;
  limit?: number;
}) => groupClient.api.GroupApi.getapiV1GroupsExploreOtherGroupsGetPath(categoryId, regionId, limit);

export const useGetExploreOtherGroups = ({
  categoryId,
  regionId,
  limit,
}: {
  categoryId: number;
  regionId: number;
  limit?: number;
}) => {
  const fetchInstance = useFetchInstance();
  const getExploreOtherGroups = groupClient.api.GroupApi.apiV1GroupsExploreOtherGroupsGet({
    axios: fetchInstance,
  });

  return useQuery({
    queryKey: [getExploreOtherGroupsPath({ categoryId, regionId, limit })],
    queryFn: () => getExploreOtherGroups({ categoryId, regionId, limit }),
  });
};
