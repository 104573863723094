import { GroupMemberGrade } from '@community-group/api/lib/group/models';
import { IconCheckmarkFlowerFill, IconCrownFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { MouseEventHandler } from 'react';

import * as s from './ListItemGradeBadge.css';

type Props = {
  grade: GroupMemberGrade;
  onClick: MouseEventHandler;
  memberGradeEnabled: boolean;
};

export const ListItemGradeBadge = ({ grade, onClick, memberGradeEnabled }: Props) => {
  if (!memberGradeEnabled && grade.name === '준회원') {
    return null;
  }

  return (
    <div className={s.Badge} onClick={onClick}>
      <div className={s.Icon}>
        <GradeIcon grade={grade} memberGradeEnabled={memberGradeEnabled} />
      </div>
    </div>
  );
};

type IconProps = {
  grade: GroupMemberGrade;
  memberGradeEnabled: boolean;
};

const GradeIcon = ({ grade, memberGradeEnabled }: IconProps) => {
  switch (grade.role) {
    case 'superHost':
      return <IconCrownFill size={14} color="#f7be68" />;
    case 'manager':
      return <IconCrownFill size={14} color="#30c795" />;
    case 'member': {
      // TODO: 커스텀 등급(name을 모임별로 다르게 가능)이 적용되면 수정 필요
      if (memberGradeEnabled && grade.name === '정회원') {
        return <IconCheckmarkFlowerFill size={14} color={vars.$scale.color.gray600} />;
      }
      return null;
    }
    default:
      return null;
  }
};
