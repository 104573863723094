import { PaginationLoader } from '@community-group/components/shared';
import { Suspense } from 'react';

import { useReadGroupProfileUserActivities } from '@/domain/GroupProfile/hooks/useReadGroupProfileUserActivities';
import JoinedMeetupList from '@/features/GroupProfileMemberJoinedMeetups/JoinedMeetupList';
import JoinedMeetupDashBoard from '@/features/GroupProfileMemberManage/JoinedMeetup/JoinedMeetupDashBoard';

import * as s from './style.css';

type Props = {
  groupId: string;
  userId: string;
};

const GroupProfileMemberJoinedMeetups = ({ groupId, userId }: Props) => {
  const { data: userActivityData } = useReadGroupProfileUserActivities({ groupId, userId });
  const userActivityCount = userActivityData.count;

  return (
    <section className={s.HideScrollBar}>
      <JoinedMeetupDashBoard userActivityCount={userActivityCount} />
      <Suspense fallback={<PaginationLoader />}>
        <JoinedMeetupList groupId={groupId} userId={userId} />
      </Suspense>
    </section>
  );
};

export default GroupProfileMemberJoinedMeetups;
