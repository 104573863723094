import { datadogRum as datadog } from '@datadog/browser-rum';

function createDatadogRum() {
  function init() {
    const { appEnv, datadogRum } = globalThis.appConfig;

    if (!datadogRum) return;

    const sessionSampleRate = appEnv === 'production' ? 1 : 100; // 1%의 세션만 기록, 개발 환경에서는 모든 세션 기록

    datadog.init({
      env: appEnv,
      applicationId: datadogRum.applicationId,
      clientToken: datadogRum.clientToken,
      version: import.meta.env.VITE_APP_SENTRY_RELEASE,
      site: 'datadoghq.com',
      service: 'community-web-group-webview',
      sessionSampleRate,
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask', // sessionReplay를 안쓰고 있지만, 보수적으로 모든 항목에 대해 마스킹으로 기본 설정
    });

    console.log('[Datadog] startSessionReplayRecording');
    datadog.startSessionReplayRecording();
  }

  return {
    init,
  };
}

const datadogRum = createDatadogRum();

export default datadogRum;
