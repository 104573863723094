import { GroupPhotoPresentation } from '@community-group/api/lib/group/models';
import { Typography, videoDurationText, ViewLoader } from '@community-group/components';
import { AsyncBoundary } from '@community-group/components';
import { PaginationGrid } from '@community-group/components/shared';
import { IconPushpinFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import React, { useCallback, useMemo } from 'react';

import { useGetGroupPhotoList } from '@/api/hooks/useGetGroupPhotoList';
import { isVideo } from '@/components/common/base/slider/ImageSlider/utils/medias';
import EmptySection from '@/components/common/Empty';
import { getInfinitiveImagesFlatList } from '@/components/group/ImageViewerPage/utils/imageUtil';
import { useReadGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { trackEvent } from '@/utils/analytics';
import { isHigherManager } from '@/utils/role';

import { AlbumPhotoItem } from './PhotoItem';
import * as s from './PhotoList.css';

const PhotoListSection = () => (
  <AsyncBoundary pendingFallback={<></>} rejectedFallback={<ViewLoader />}>
    <PhotoList />
  </AsyncBoundary>
);

export default React.memo(PhotoListSection);

const PhotoList = () => {
  const { groupId = '' } = usePathParams();
  const { data, hasNextPage, isFetchingNextPage, fetchNextPage } = useGetGroupPhotoList({
    groupId,
  });
  const { data: me } = useReadGroupMe({ groupId });
  const role = me.role ?? 'none';

  const photoList = useMemo(() => getInfinitiveImagesFlatList(data), [data]);
  const hasPhotoList = photoList.length > 0;

  const { push } = useFlow();
  const handlePhotoClick = useCallback(
    async (postId: number, index: number) => {
      trackEvent({
        event: 'click_album_item',
        params: {
          location: 'group_album',
          groupId,
          postId,
          mediaType: photoList?.[index]?.type,
        },
        sample: true,
      });

      push('ImageViewerPage', {
        content: 'album',
        groupId,
        initialIndex: index.toString(),
      });
    },
    [groupId, photoList, push]
  );

  const renderHeroImageSelectorBox = useMemo(() => {
    if (!isHigherManager(role)) return null;

    return (
      <li
        className={s.photoItem}
        key="superhost-hero-image-selector"
        onClick={() => {
          trackEvent({
            event: 'click_pin_the_image_album_page',
            params: {
              groupId: groupId,
            },
          });
          push('GroupSettingHeroImageSelectorPage', {
            groupId,
          });
        }}
      >
        <div className={s.HeroImageSelectBox}>
          <IconPushpinFill size={24} color={vars.$static.color.staticWhite} />
          <Typography typography="label4Regular" color="staticWhite">
            배경사진 설정
          </Typography>
        </div>
      </li>
    );
  }, [groupId, push, role]);

  const renderDecoratePhoto = useCallback((data: GroupPhotoPresentation) => {
    const { isPinned, media } = data;

    const isVideoMedia = isVideo(media);

    if (isVideoMedia) {
      const durationText = videoDurationText(media.duration);
      return (
        <div className={s.VideoDurationWrapper}>
          <Typography typography="label6Regular" color="staticWhite">
            {durationText}
          </Typography>
        </div>
      );
    }

    if (isPinned) {
      return (
        <>
          <div className={s.PinnedGradation} />
          <div className={s.PinnedIcon}>
            <IconPushpinFill size={24} color={vars.$static.color.staticWhite} />
          </div>
        </>
      );
    }

    return null;
  }, []);

  if (!hasPhotoList)
    return (
      <EmptySection>
        사진이 없어요.
        <br />
        글을 작성하고 모임 사진을 올려보세요.
      </EmptySection>
    );

  return (
    <PaginationGrid
      items={photoList}
      render={(data, index) => {
        const { post, image, id } = data;
        const key = id.split('#')?.[1] ?? id;

        return (
          <>
            {index === 0 && renderHeroImageSelectorBox}
            <li
              className={s.photoItem}
              key={`img-${key}-${index}`}
              onClick={() => {
                handlePhotoClick(post.id, index);
              }}
            >
              {renderDecoratePhoto(data)}
              <div className={s.photo}>
                <AlbumPhotoItem image={image} />
              </div>
            </li>
          </>
        );
      }}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      fetchNextPage={fetchNextPage}
    />
  );
};
