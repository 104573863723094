import { groupClient } from '@community-group/api';
import { CommentResponse } from '@community-group/api/lib/group/models';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { queryClient } from '@/shared/api/instance';

import { useFetchInstance } from './instance/useFetchInstance';

export const getPostCommentPath = (groupId: string, postId: string, commentId: string) =>
  groupClient.api.CommentApi.getapiV1GroupsIdPostsPostIdCommentsCommentIdGetPath(
    Number(groupId),
    Number(postId),
    Number(commentId)
  );

export const getMeetupCommentPath = (groupId: string, meetupId: string, commentId: string) =>
  groupClient.api.CommentApi.getapiV1GroupsIdMeetupsMeetupIdCommentsCommentIdGetPath(
    Number(groupId),
    Number(meetupId),
    Number(commentId)
  );

type RelatedContentType = 'post' | 'meetup';

type Params = {
  groupId: string;
  relatedId: string;
  commentId: string;
  relatedContentType: RelatedContentType;
  order?: 'created_at_desc' | 'created_at_asc';
  subCommentLimit?: number;
  subCommentOrder?: 'created_at_desc' | 'created_at_asc';
};

export const useGetComment = ({
  groupId,
  relatedId,
  commentId,
  relatedContentType,
  order = 'created_at_desc',
  subCommentLimit = 6,
  subCommentOrder = 'created_at_asc',
}: Params) => {
  const isPostComment = relatedContentType === 'post';
  const apiMethod = isPostComment
    ? groupClient.api.CommentApi.apiV1GroupsIdPostsPostIdCommentsCommentIdGet
    : groupClient.api.CommentApi.apiV1GroupsIdMeetupsMeetupIdCommentsCommentIdGet;

  const fetchInstance = useFetchInstance();
  const getComment = apiMethod({
    axios: fetchInstance,
  });

  const path = isPostComment
    ? getPostCommentPath(groupId, relatedId, commentId)
    : getMeetupCommentPath(groupId, relatedId, commentId);
  const { data, refetch } = useSuspenseQuery({
    queryKey: [path],
    queryFn: () =>
      getComment({
        id: Number(groupId),
        postId: Number(relatedId),
        meetupId: Number(relatedId),
        commentId: Number(commentId),
        order,
        subCommentLimit,
        subCommentOrder,
      }),
  });

  return {
    data,
    refetch,
  };
};

const updateCommentEmotion =
  (addMyEmotion: boolean, relatedContentType: RelatedContentType) =>
  (groupId?: string, relatedContentId?: string, commentId?: string) => {
    if (!groupId || !relatedContentId || !commentId) return;

    const queryKey =
      relatedContentType === 'post'
        ? [getPostCommentPath(groupId, relatedContentId, commentId)]
        : [getMeetupCommentPath(groupId, relatedContentId, commentId)];
    const cache = queryClient.getQueriesData({ queryKey });

    cache.forEach(([queryKey]) => {
      queryClient.setQueryData(queryKey, (prev?: AxiosResponse<CommentResponse>) => {
        if (!prev) return;

        const targetCommentId = parseInt(commentId);
        if (prev.data.comment.id !== targetCommentId) return prev;

        return {
          ...prev,
          data: {
            comment: {
              ...prev.data.comment,
              emotion: {
                ...prev.data.comment.emotion,
                count: addMyEmotion
                  ? prev.data.comment.emotion.count + 1
                  : prev.data.comment.emotion.count - 1,
                myEmotion: addMyEmotion ? 'like' : '',
              },
            },
          },
        };
      });
    });
  };

export const deleteLikePostComment = updateCommentEmotion(false, 'post');
export const patchLikePostComment = updateCommentEmotion(true, 'post');

export const deleteLikeMeetupComment = updateCommentEmotion(false, 'meetup');
export const patchLikeMeetupComment = updateCommentEmotion(true, 'meetup');
