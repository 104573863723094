import { groupClient } from '@community-group/api';

export const isSuperHost = (role: groupClient.model.GroupMemberRoleEnum = 'none') => {
  return role === groupClient.model.GroupMemberRoleEnum.superHost;
};

export const isNotSuperHost = (role: groupClient.model.GroupMemberRoleEnum = 'none') => {
  return !isSuperHost(role);
};

export const isHigherManager = (role: groupClient.model.GroupMemberRoleEnum = 'none') => {
  return (
    role === groupClient.model.GroupMemberRoleEnum.superHost ||
    role === groupClient.model.GroupMemberRoleEnum.manager
  );
};

export const isMember = (role: groupClient.model.GroupMemberRoleEnum = 'none') => {
  return role !== groupClient.model.GroupMemberRoleEnum.none;
};

export const isNotMember = (role: groupClient.model.GroupMemberRoleEnum = 'none') => {
  return !isMember(role);
};

export const renderTranslateToKoreanRole = (
  role: Array<groupClient.model.GroupPermissionRoleEnum>
) => {
  if (role.includes(groupClient.model.GroupPermissionRoleEnum.member)) {
    return '모든 멤버';
  }
  if (role.includes(groupClient.model.GroupPermissionRoleEnum.manager)) {
    return '모임장, 운영진';
  }
  return '모임장';
};
