import { groupClient } from '@community-group/api';
import { model } from '@community-group/api/lib/group';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Props = UseMutationOptions<
  AxiosResponse<model.NoticePostResponse>,
  Error,
  { id: number; postId: number }
>;

export const usePatchNotice = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const patchNotice = groupClient.api.NoticeApi.apiV1GroupsIdPostsPostIdNoticesPatch({
    axios: fetchInstance,
  });

  const patchNoticeWrapperFp = ({ id, postId }: { id: number; postId: number }) => {
    return patchNotice({
      id,
      postId,
    });
  };

  return useThrottleMutation(patchNoticeWrapperFp, {
    onError,
    onSuccess,
  });
};
