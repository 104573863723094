import { AsyncBoundary, Thumbnail } from '@community-group/components';
import { Spacing } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { motion } from 'framer-motion';
import { useMemo } from 'react';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useGetMyGroupList } from '@/api/hooks/useGetMyGroupList';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import * as s from './index.css';
import MyGroupSkeleton from './Skeleton';

const MyGroup = () => {
  return (
    <div>
      <AsyncBoundary pendingFallback={<MyGroupSkeleton />} rejectedFallback={<></>}>
        <MyGroupWrapper />
      </AsyncBoundary>
    </div>
  );
};

const toMatrix = (arr: any[], width: number) =>
  arr.reduce(
    (rows, key, index) =>
      (index % width == 0 ? rows.push([key]) : rows[rows.length - 1].push(key)) && rows,
    []
  );

const MyGroupWrapper = () => {
  const { push } = useFlow();
  const { data } = useGetMyGroupList();

  const matrixGroups = useMemo(() => {
    return toMatrix(data?.groups ?? [], 4);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.groups, data?.groups.length]);

  if (!data?.groups) {
    return <></>;
  }

  return (
    <>
      {data?.groups.length > 0 && (
        <>
          <div className={s.listItemWrapper}>
            <Spacing size={24} />
            <h3 className={s.title}>내 모임</h3>
            <Spacing size={8} />
            <motion.ul
              className={s.list}
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
              }}
              exit={{ opacity: 0 }}
            >
              <div className={s.swiperWrapper}>
                <Swiper
                  pagination={{
                    dynamicBullets: false,
                  }}
                  modules={[Pagination]}
                  className="mySwiper"
                >
                  {matrixGroups.map((matrix: any, matrixGroupIndex: number) => {
                    return (
                      <SwiperSlide key={matrixGroupIndex}>
                        <div className={s.slideWrapper}>
                          {matrix.map((group: any, matrixIndex: number) => (
                            <div
                              className={s.item}
                              key={group?.id}
                              onClick={() => {
                                trackEvent({
                                  event: 'click_my_group',
                                  params: {
                                    groupId: group?.id,
                                    groupCategoryName: group?.category?.name,
                                    listItemIndex: matrixGroupIndex + matrixIndex,
                                  },
                                  sample: true,
                                });

                                push('GroupDetailPage', {
                                  groupId: group?.id,
                                });
                              }}
                            >
                              <Thumbnail src={group?.profileImage.small} size={56} />
                              <Spacing size={8} />
                              {group?.isHost ? (
                                <>
                                  <p className={s.itemNameWithHostText}>{group?.name}</p>
                                  <p className={s.hostBadge}>운영</p>
                                </>
                              ) : (
                                <p className={s.itemNameText}>{group?.name}</p>
                              )}
                            </div>
                          ))}
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </motion.ul>
          </div>
          <Spacing size={8} fill={vars.$semantic.color.paperBackground} />
        </>
      )}
    </>
  );
};

export default MyGroup;
