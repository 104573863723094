import { Spacing, Typography } from '@community-group/components';

import * as s from './PayRejectInfoSection.css';

const WARNING_ICON_URL =
  'https://assetstorage.krrt.io/1025317940251365954/83544524-2194-4f4b-b47d-249bcd999a7a/width=234,height=234.webp';

const PayRejectInfoSection = () => {
  return (
    <div className={s.Container}>
      <Spacing size={24} />
      <img src={WARNING_ICON_URL} alt="warning" width={78} height={78} />
      <Spacing size={17} />
      <Typography typography="title1Bold" textAlign="center">
        당근머니 지급에 실패했어요
      </Typography>
      <Spacing size={12} />
      <Typography typography="label3Regular" textAlign="center">
        알 수 없는 에러가 발생했어요.
        <br />
        당첨된 당근머니 지급을 위해 고객센터에 문의해 주세요.
        <br />
        <br />
        (당근페이 고객센터: 1877-9735)
      </Typography>
    </div>
  );
};

export default PayRejectInfoSection;
