import { PostDetail } from '@community-group/api/lib/group/models';
import { Typography, useSnackbarAdapter } from '@community-group/components';
import { VerticalSpacing } from '@community-group/components';
import {
  IconHorizline2VerticalChatbubbleRectangularRightLine,
  IconThumbUpFill,
  IconThumbUpLine,
} from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';

import { useDeleteLikePost } from '@/api/hooks/useDeleteLikePost';
import { getGroupPostEmotionListPath } from '@/api/hooks/useGetGroupPostEmotionList';
import {
  deleteLikePhotoContestEntries,
  patchLikePhotoContestEntries,
} from '@/api/hooks/useGetPhotoContestEntries';
import { usePatchLikePost } from '@/api/hooks/usePatchLikePost';
import { IconShareRegularExperiment } from '@/components/common/Icons/shareRegular';
import {
  BLOCK_GROUP_ONLY_TEXT,
  useHandleGroupOnly,
} from '@/components/group/JoinGroupState/hooks/useHandleGroupOnly';
import { useBridge } from '@/contexts/Bridge';
import { useReadGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useSharePostContents } from '@/hooks/useSharePostContents';
import { queryClient } from '@/shared/api/instance';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { trackEvent } from '@/utils/analytics';

import PostDetailEmotionCountMessage from '../EmotionCountMessage/PostDetailEmotionCountMessage';
import * as s from './Reaction.css';

interface Props {
  post: PostDetail;
  onClickCommentIcon?: () => void;
}

const Reaction = ({ post, onClickCommentIcon }: Props) => {
  const { photoContestEntryId = '' } = useQueryParams();
  const { groupId, postId } = usePathParams();
  const { handleGroupOnly, groupInfo } = useHandleGroupOnly({ groupId });
  const { data: me } = useReadGroupMe({ groupId });

  const { push } = useFlow();
  const { bridge } = useBridge();
  const snackbar = useSnackbarAdapter();

  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate: mutateDislike } = useDeleteLikePost({
    onError: (error) => handleErrorWithToast(error),
    onSuccess: () => {
      const queryKey = getGroupPostEmotionListPath(Number(groupId), Number(postId));
      queryClient.refetchQueries({
        queryKey: [queryKey],
      });
    },
  });

  const { mutate: mutateLike } = usePatchLikePost({
    onError: (error) => handleErrorWithToast(error),
    onSuccess: async () => {
      const queryKey = getGroupPostEmotionListPath(Number(groupId), Number(postId));
      queryClient.refetchQueries({
        queryKey: [queryKey],
      });
      trackEvent({
        event: 'click_like',
        params: {
          contentType: 'post',
        },
        sample: true,
      });
    },
  });

  const handleLikeMessageClick = () => {
    push('PostDetailLikeListPage', {
      groupId,
      postId: post.id.toString(),
    });

    trackEvent({
      event: 'click_like_list',
      params: {
        type: 'post',
        contentType: 'post',
      },
      sample: true,
    });
  };

  const handleLikeIconClick = (event) => {
    event.stopPropagation();

    if (post.author.isAccountDeleted) {
      snackbar.create({
        message: '탈퇴 사용자의 게시글에는 공감, 댓글을 남길 수 없어요.',
        type: 'default',
        timeout: 3000,
        onClick: () => {
          snackbar.dismiss();
        },
      });
      return;
    }

    bridge.setHapticLightFeedback({});
    if (post.emotion.myEmotion) {
      mutateDislike(
        {
          groupId,
          authorId: post.author.id.toString(),
          postId,
          boardCategories: post?.boardCategories?.map((categories) => categories.id),
        },
        {
          onSuccess: () => {
            if (photoContestEntryId) {
              deleteLikePhotoContestEntries(photoContestEntryId);
            }
          },
        }
      );

      return;
    }
    mutateLike(
      {
        groupId,
        authorId: post.author.id.toString(),
        postId,
        boardCategories: post?.boardCategories?.map((categories) => categories.id),
      },
      {
        onSuccess: () => {
          if (photoContestEntryId) {
            patchLikePhotoContestEntries(photoContestEntryId);
          }
        },
      }
    );
  };

  const handleCommentIconClick = (event) => {
    event.stopPropagation();

    if (post.author.isAccountDeleted) {
      snackbar.create({
        message: '탈퇴 사용자의 게시글에는 공감, 댓글을 남길 수 없어요.',
        type: 'default',
        timeout: 3000,
        onClick: () => {
          snackbar.dismiss();
        },
      });
      return;
    }

    handleGroupOnly({
      blockGroupOnlyTitle: '모임 가입 안내',
      blockGroupOnlyText: BLOCK_GROUP_ONLY_TEXT.getComment,
      onSettled: () => {
        onClickCommentIcon?.();
      },
      onSuccess() {
        if (groupInfo.isSettingSubNickname) {
          push('BottomSheet/GroupSetMemberProfileSubNicknameBottomSheet', {
            groupId,
          });
        }
      },
    });
  };

  const handleSharePostContents = useSharePostContents();
  const handleShareIconClick = () => {
    if (!post.postType) return;
    const currentUserRole = post.currentUserInfo?.role ?? 'none';

    handleSharePostContents({
      groupId,
      postId,
      postType: post.postType.type,
      permalinkId: post.permalinkId,
      currentUserRole: currentUserRole,
      from: 'postDetailFeedback',
    });
  };

  return (
    <div className={s.ReactionWrapper}>
      <PostDetailEmotionCountMessage
        groupId={groupId}
        postId={postId}
        postEmotionCount={post.emotion.count}
        hasCurrentUserEmotion={Boolean(post.emotion.myEmotion)}
        onClick={handleLikeMessageClick}
      />
      <div className={s.FeedbackWrapper}>
        <div className={s.FeedbackLeftBox}>
          <div className={s.IconWrapper} onClick={handleLikeIconClick}>
            {post.emotion.myEmotion ? (
              <IconThumbUpFill width={22} height={22} color={vars.$semantic.color.primary} />
            ) : (
              <IconThumbUpLine width={22} height={22} color={vars.$scale.color.gray800} />
            )}
            {post.emotion.count > 0 && (
              <>
                <VerticalSpacing size={4} />
                <Typography
                  as="p"
                  typography="caption1Regular"
                  color={post.emotion.myEmotion ? 'primary' : 'gray900'}
                >
                  {post.emotion.count}
                </Typography>
              </>
            )}
          </div>
          <VerticalSpacing size={20} />
          <div className={s.IconWrapper} onClick={handleCommentIconClick}>
            <IconHorizline2VerticalChatbubbleRectangularRightLine
              width={22}
              height={22}
              color={vars.$scale.color.gray800}
            />
            {post.commentCount > 0 && (
              <>
                <VerticalSpacing size={4} />
                <Typography as="p" typography="caption1Regular" color="gray900">
                  {post.commentCount}
                </Typography>
              </>
            )}
          </div>
          <VerticalSpacing size={20} />
          <div className={s.IconWrapper} onClick={handleShareIconClick}>
            <IconShareRegularExperiment
              currentUserRole={me.role}
              width={22}
              height={22}
              color={vars.$scale.color.gray800}
            />
          </div>
        </div>
        {post.readCount > 0 && (
          <Typography as="p" typography="caption1Regular" color="gray600">
            조회 {post.readCount}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default Reaction;
