import React, { useEffect, useRef } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import { OriginImage } from '../../HookForm/hooks/useHookFormImages';
import { UploadImageType } from '../../HookForm/utils/image';
import { useStickInputStore } from '../store';

export interface StickyInputFormProviderProps {
  onFocused: () => void;
  onSubmit: (
    formData: StickyInputFormData,
    callbackHandler: {
      onSuccess: () => void;
      onError?: () => void;
    }
  ) => void;
  initialValues?: StickyInputFormData;
  children: React.ReactNode;
}

const StickyInputFormProvider = ({
  onFocused,
  onSubmit,
  initialValues,
  children,
}: StickyInputFormProviderProps) => {
  const contentRef = useRef<HTMLFormElement | undefined>(null);
  const methods = useForm({
    defaultValues: initialValues ?? initialStickyInputFormData,
  });

  useEffect(() => {
    if (initialValues) {
      methods.reset(initialValues);

      setTimeout(() => {
        methods.setValue('content', methods.watch('content') + ' ');
        methods.setValue('content', methods.watch('content').slice(0, -1));
      }, 0);
    }
  }, [initialValues]);

  // EDIT에서는 작성된 글 초기화 시키기 위해 사용
  const { focused, stickyInputProps, setFocused } = useStickInputStore();
  const prevType = useRef<'new' | 'edit' | undefined>();
  useEffect(() => {
    if (focused) {
      prevType.current = stickyInputProps?.type;
      onFocused();
    } else {
      if (prevType.current === 'edit') {
        methods.reset(initialStickyInputFormData);
      }
    }
  }, [focused]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const handleSubmit = async (formData) => {
    await new Promise((resolve) => {
      onSubmit(formData, {
        onSuccess: () => {
          resolve({});

          setFocused(false);
          (document.activeElement as HTMLTextAreaElement)?.blur();
          methods.reset(initialStickyInputFormData);
        },
        onError: () => {
          resolve({});
          setFocused(false);
          (document.activeElement as HTMLTextAreaElement)?.blur();
          // TODO: 이후에 서버 ERROR을 hook-form에서 쓰고 싶다면 error를 여기서 전달 받아서 setError에 넣어줄 것
        },
      });
    });
  };

  return (
    <FormProvider {...methods}>
      <form
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ref={contentRef}
        style={{ width: '100%' }}
        onSubmit={methods.handleSubmit(handleSubmit)}
      >
        {children}
      </form>
    </FormProvider>
  );
};

export default StickyInputFormProvider;

export type StickyInputFormData = {
  id?: string;
  content: string;
  mentionedUserIds?: (number | string)[];
  images: UploadImageType[];
  originImages: OriginImage[]; // 내부에서 이미지 컴포넌트 처리 시 사용
  showFoldButton?: boolean;
  allowMultiline?: boolean;
};

export const initialStickyInputFormData = {
  content: '',
  images: [],
  originImages: [],
  allowMultiline: true,
};

export const useStickyInputFormContext = () => {
  return useFormContext<StickyInputFormData>();
};
