import { groupClient } from '@community-group/api';
import { CommentModifyForm } from '@community-group/api/lib/group/models';
import { UseMutationOptions } from '@tanstack/react-query';

import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Request = {
  groupId: string;
  relatedId: string;
  commentId: string;
  commentModifyForm: CommentModifyForm;
};

type Props = {
  relatedContentType: 'post' | 'meetup';
} & UseMutationOptions<string | undefined, Error, Request>;

export const usePutComment = ({ relatedContentType, onError, onSuccess }: Props) => {
  const isPostComment = relatedContentType === 'post';
  const apiMethod = isPostComment
    ? groupClient.api.CommentApi.apiV1GroupsIdPostsPostIdCommentsCommentIdPut
    : groupClient.api.CommentApi.apiV1GroupsIdMeetupsMeetupIdCommentsCommentIdPut;

  const fetchInstance = useFetchInstance();
  const putComment = apiMethod({
    axios: fetchInstance,
  });

  return useThrottleMutation(
    async ({ groupId, relatedId, commentId, commentModifyForm }: Request) => {
      await putComment({
        id: Number(groupId),
        postId: Number(relatedId),
        meetupId: Number(relatedId),
        commentId: Number(commentId),
        commentModifyForm,
      });
      return commentId;
    },
    {
      onError,
      onSuccess,
    }
  );
};
