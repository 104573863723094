import { groupClient } from '@community-group/api';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Params = {
  groupId: number;
  imageIds: string[];
};
type Props = UseMutationOptions<AxiosResponse<void>, Error, Params>;

export const usePostSetHeroImage = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const postHeroImage = groupClient.api.ImageApi.apiV1GroupsGroupIdPhotosPinPost({
    axios: fetchInstance,
  });

  const fetchPutHeroImage = ({ groupId, imageIds }: Params) => {
    return postHeroImage({
      groupId,
      pinPhotoListRequest: { ids: imageIds },
    });
  };

  return useThrottleMutation(fetchPutHeroImage, {
    onError,
    onSuccess,
  });
};
