import { useXproperty } from './useXproperty';

// !프로덕션에 정의했는지 확인하기
// 프로덕션 : https://xproperty.kr.wekarrot.net/admin/edit?pkey=community_group_web_feature_flags&country=KR
// 알파 : https://xproperty.alpha.kr.wekarrot.net/admin/edit?pkey=community_group_web_feature_flags&country=KR

// 사용중인 피쳐플래그 타입
export type FeatureFlagKeysType = {
  shownPhotoContest?: boolean;
  useContestResult?: boolean; // 사진전 종료 결과 섹션 사용 여부
  photoContestVersion?: number;
  // 10월 운동 마케팅 프로모션
  shown10월운동MarketingPromotion?: boolean;

  // 12월 덕질 마케팅 프로모션
  shown12FandomMarketingPromotion?: boolean;

  huggiesGroupJoinMarketingPromotion?: boolean; // 허기스 그룹 가입 마케팅 프로모션
  blockRunningGrowthMission5?: boolean; // [알파] 러닝그로스 5단계 미션 날짜 차단 여부

  shown11월독서MarketingPromotion?: boolean; // 11월 독서 마케팅 프로모션

  parentingGroupNotMemberBannerEnabled?: boolean; // 육아 모임 미가입자 배너 노출 여부
  memberGradeEnabled?: boolean; // 멤버 등급 사용 여부
  newFeedChallengeBanner?: boolean; // 피드의 챌린지 게시글 내부 새로운 챌린지 배너 사용 여부

  enableChallengeReport?: boolean; // 챌린지 신고 기능 사용 여부

  enableChallengePromotion?: boolean; // 25년1월 챌린지 프로모션 시작 여부 - 이벤트 분기용
  enableNewChallengePostApi?: boolean; // 새로운 챌린지 생성 API 사용 여부
  enableHomeChallengePromotionTab?: boolean; // 홈 탭 > 챌린지 프로모션 이벤트 탭 노출 여부
};

type FeatureFlagType = keyof FeatureFlagKeysType;

export const useFeatureFlag = <T extends FeatureFlagType>(
  featureFlagName: T
): FeatureFlagKeysType[T] | false => {
  const xProperty = useXproperty();

  return xProperty?.communityGroupWebFeatureFlags?.[featureFlagName] ?? false;
};
