import { Video } from '@community-group/api/lib/group/models';
import { videoDurationText, VideoPlayInfoButton } from '@community-group/components';
import { useMemo } from 'react';

import * as s from './item.css';

type Props = {
  video: Video;
  aspectRatio: string;
  alt?: string;
  isMultipleItem?: boolean;
};
/**
 * @description 아파트와 동일한 폴더구조를 가져가기 위해 domain/GroupFeed/components/FeedMediaItem/VideoItem을 사용해주세요
 */
function VideoItem({ video, alt = '게시글 첨부 영상', isMultipleItem }: Props) {
  const durationText = useMemo(() => {
    return videoDurationText(video.duration);
  }, [video.duration]);

  const videoAspectRatio = useMemo(() => {
    //세로형이면 정방형, 가로형이면 16:9
    const isPortrait = video.width <= video.height;
    return isPortrait ? '1/1' : '16/9';
  }, [video.width, video.height]);

  return (
    <div className={s.ImageContainer}>
      <div className={s.ImageBorder} />
      <img
        src={video.thumbnailUrl}
        className={s.Image}
        style={{ aspectRatio: isMultipleItem ? '1/1' : videoAspectRatio }}
        alt={alt}
      />
      <div className={s.VideoInfoWrapper}>
        <VideoPlayInfoButton durationText={durationText} />
      </div>
    </div>
  );
}

export default VideoItem;
