import { isMember } from '@community-group/components';
import { useEffect } from 'react';

import { useReadGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';

import { useStorage } from '../useStorage';

export interface PostNotificationProps {
  groupId: string;
  groupName?: string;
  from?: 'GroupDetail' | 'PostDetail' | 'PostList';
}

type CountStorageKey =
  | 'groupDetailVisitCounts'
  | 'postDetailVisitCounts'
  | 'postDetailNotificationVisitCounts';

const useCountStorage = (
  key: CountStorageKey,
  targetId: string
): [number, (value: number) => void] => {
  const [storage, setStorage] = useStorage(key, {
    [targetId]: 0,
  });

  const count = storage?.[targetId];
  const setCount = (value: number) => {
    setStorage({
      ...storage,
      [targetId]: value,
    });
  };

  return [count ?? 0, setCount];
};

interface TriggerProps {
  key: CountStorageKey;
  targetId: string;
  triggerCount: number;
  trigger: () => void;
  enableCount?: boolean;
}

export const useVisitCountsTrigger = ({
  key,
  targetId,
  triggerCount,
  trigger,
  enableCount,
}: TriggerProps) => {
  const [count, setCount] = useCountStorage(key, targetId);

  useEffect(() => {
    if (enableCount && count < triggerCount) {
      setCount(count + 1);
    }
  }, [enableCount]);

  useEffect(() => {
    if (triggerCount === count) {
      trigger();
    }
  }, [triggerCount, count, trigger]);
};

export const useIsGroupMember = (groupId: string): boolean => {
  const { data: myInfo } = useReadGroupMe({ groupId });
  return isMember(myInfo.role);
};

type ShownStorageProps = {
  targetId: string;
  storageKey: 'shownPostNotificationTurnOnSnackbar' | 'shownPostNotificationTurnOffSnackbar';
};

export const useShownStorage = ({
  targetId,
  storageKey,
}: ShownStorageProps): [boolean, (value: boolean) => void] => {
  const [storage, setStorage] = useStorage(storageKey, {
    [targetId]: false,
  });

  const shown = storage?.[targetId];
  const setShown = (value: boolean) => {
    setStorage({
      ...storage,
      [targetId]: value,
    });
  };

  useEffect(() => {
    if (shown !== undefined) return;

    setStorage({
      ...storage,
      [targetId]: false,
    });
  }, [shown, storage, setStorage, targetId]);

  return [shown ?? false, setShown];
};
