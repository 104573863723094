import { groupClient } from '@community-group/api';
import { GroupMemberRoleEnum } from '@community-group/api/lib/group/models';
import { useSuspenseQuery } from '@tanstack/react-query';

import { GROUP_MEMBER_QUERY_KEY } from '@/domain/GroupMember/queries';
import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

type Props = {
  groupId: string;
  roles?: GroupMemberRoleEnum[];
  limit?: number;
};

export const useQueryGroupMembers = ({ groupId, roles, limit = 30 }: Props) => {
  const read = useAsyncRead(groupClient.api.GroupMemberApi.apiV1GroupsIdMembersGet);
  return {
    queryKey: GROUP_MEMBER_QUERY_KEY.members(groupId),
    queryFn: () =>
      read({
        id: Number(groupId),
        order: 'joinedAtAscWithRole',
        shownOnlyHost: undefined,
        gradeIds: undefined,
        roles: roles,
        shownMemberActivities: false,
        shownMemberApplication: false,
        cursor: undefined,
        limit,
      }).then((res) => {
        return {
          memberCount: res.memberCount,
          members: res.members,
        };
      }),
  };
};

/** @note useGetMemberList의 기능을 옮겨와야 해요 */
export const useReadGroupMembers = (props: Props) => {
  const query = useQueryGroupMembers(props);
  return useSuspenseQuery(query);
};
