import {
  AsyncBoundary,
  BoxButton,
  ChipToggleButton,
  Spacing,
  Typography,
  useBottomSheet,
  useKeyboardSize,
  ViewLoader,
} from '@community-group/components';
import { IconChevronDownLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { useSuspenseQueries } from '@tanstack/react-query';
import { useMemo, useState } from 'react';

import { useReadGroupDetail } from '@/api/hooks/useGetGroupDetail';
import {
  MEMBER_LIST_ORDER_TYPE,
  MemberListOrderType,
  useGetMemberList,
} from '@/api/hooks/useGetMemberList';
import { Container } from '@/components/common/Container';
import { LoadMoreListContainer } from '@/components/common/LoadMoreContainer';
import MemberSearchBar from '@/components/common/MemberSearchBar';
import { useQueryGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useQueryGroupMemberGrades } from '@/domain/GroupMember/hooks/useReadGroupMemberGrades';
import { useQueryGroupMemberGradeStatus } from '@/domain/GroupMember/hooks/useReadGroupMemberGradeStatus';
import useCurrentGroupMe from '@/hooks/useCurrentGroupMe';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { trackEvent } from '@/utils/analytics';

import { MemberListFilter } from '../pages/MemberListForHost';
import { MemberGradeBottomSheet } from './bottomSheet/MemberGradeBottomSheet';
import { MemberOrderTypeBottomSheet } from './MemberOrderTypeBottomSheet';
import * as s from './MemberProfileDetailList.css';
import { MemberProfileDetailSection } from './MemberProfileDetailSection';

type Props = {
  memberListFilter: MemberListFilter;
  setMemberListFiler: React.Dispatch<React.SetStateAction<MemberListFilter>>;
  selectedMemberOrderType: MemberListOrderType;
  setSelectedMemberOrderType: (selectedMemberOrderType: MemberListOrderType) => void;
};

export const MemberProfileDetailList = ({
  memberListFilter,
  setMemberListFiler,
  selectedMemberOrderType,
  setSelectedMemberOrderType,
}: Props) => {
  const { groupId } = usePathParams();

  const { open: openBottomSheet } = useBottomSheet();
  const [isFocusedSearchBar, setIsFocusedSearchBar] = useState(false);
  const { keyboardHeight } = useKeyboardSize();

  const [searchValue, setSearchValue] = useState('');

  const memberGradeEnabledFeatureFlag = useFeatureFlag('memberGradeEnabled');
  const [{ data: me }, { data: memberGradeStatus }, { data: memberGrades }] = useSuspenseQueries({
    queries: [
      useQueryGroupMe({ groupId }),
      useQueryGroupMemberGradeStatus(groupId),
      useQueryGroupMemberGrades(groupId),
    ],
  });

  const memberGradeEnabled = useMemo(() => {
    return Boolean(memberGradeEnabledFeatureFlag) && memberGradeStatus === 'system';
  }, [memberGradeEnabledFeatureFlag, memberGradeStatus]);

  const filteredMemberGrades = useMemo(() => {
    let memberGradeList = memberGrades.filter((grade) => grade.role !== 'superHost');

    if (!memberGradeEnabled) {
      // 멤버 등급제가 활성화된 경우
      // 준회원 외의 멤버 등급을 제거하고 준회원을 '멤버'로 보여주어야 함
      const manager = memberGradeList.filter((grade) => grade.role === 'manager');
      const members = memberGradeList.filter((grade) => grade.role === 'member');

      // 멤버 중 priority의 숫자가 높은 것이 준회원
      const defaultGradeMember = members.sort((a, b) => b.priority - a.priority)[0];
      memberGradeList = [...manager, { ...defaultGradeMember, name: '멤버' }];
    }

    return memberGradeList;
  }, [memberGrades, memberGradeEnabled]);
  const selectedMemberGradeName = filteredMemberGrades.find(
    (grade) => grade.id === memberListFilter.gradeIds?.[0]
  )?.name;

  const handleFilterTrackEvent = ({
    orderType,
    prevOrderType,
    memberListFilter,
  }: {
    orderType: MemberListOrderType;
    prevOrderType: MemberListOrderType;
    memberListFilter: MemberListFilter;
  }) => {
    trackEvent({
      event: 'click_member_list_order_type',
      params: {
        orderType,
        prevOrderType,
        role: me.role,
        shownOnlyHost: memberListFilter.shownOnlyHost,
      },
      sample: true,
    });
  };

  const handleSelectedMemberOrderType = (orderType: MemberListOrderType) => {
    handleFilterTrackEvent({
      orderType,
      prevOrderType: selectedMemberOrderType,
      memberListFilter,
    });

    setSelectedMemberOrderType(orderType);
  };
  const handleOrderTypeChipClick = () => {
    openBottomSheet({
      element: (
        <MemberOrderTypeBottomSheet
          selectedOrderType={selectedMemberOrderType}
          setSelectedOrderType={handleSelectedMemberOrderType}
        />
      ),
    });
  };
  const handleMemberGradeFilterChipClick = () => {
    openBottomSheet({
      element: (
        <MemberGradeBottomSheet
          memberGrades={filteredMemberGrades}
          selectedMemberGradeId={memberListFilter.gradeIds?.[0]}
          setSelectedMemberGradeId={(gradeId) => {
            setMemberListFiler({
              ...memberListFilter,
              gradeIds: gradeId ? [gradeId] : undefined,
            });
          }}
        />
      ),
    });
  };

  return (
    <div
      className={s.Wrapper}
      style={{
        paddingBottom: isFocusedSearchBar
          ? `calc(env(safe-area-inset-bottom) + ${keyboardHeight}px )`
          : 'env(safe-area-inset-bottom)',
      }}
    >
      <div className={s.SearchWrapper}>
        <MemberSearchBar
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          setIsFocusedSearchBar={setIsFocusedSearchBar}
          placeholder="닉네임을 검색해보세요"
        />
      </div>
      <div className={s.FilterWrapper}>
        <div className={s.FilterItem}>
          <ChipToggleButton onClick={handleOrderTypeChipClick}>
            <div className={s.ChipWrapper}>
              {MEMBER_LIST_ORDER_TYPE[selectedMemberOrderType as MemberListOrderType]}{' '}
              <IconChevronDownLine size={14} color={vars.$scale.color.gray900} />
            </div>
          </ChipToggleButton>
        </div>
        <div className={s.FilterItem}>
          <ChipToggleButton
            isSelected={Boolean(selectedMemberGradeName)}
            onClick={handleMemberGradeFilterChipClick}
          >
            <div className={s.ChipWrapper}>
              {selectedMemberGradeName ?? '멤버 등급'}{' '}
              <IconChevronDownLine
                size={14}
                color={vars.$scale.color[selectedMemberGradeName ? 'gray00' : 'gray900']}
              />
            </div>
          </ChipToggleButton>
        </div>
        <div className={s.FilterItem}>
          <ChipToggleButton
            isSelected={memberListFilter.shownMemberActivities}
            onClick={() => {
              handleFilterTrackEvent({
                orderType: selectedMemberOrderType,
                prevOrderType: selectedMemberOrderType,
                memberListFilter,
              });

              setMemberListFiler({
                ...memberListFilter,
                shownMemberActivities: !memberListFilter.shownMemberActivities,
              });
            }}
          >
            활동 요약
          </ChipToggleButton>
        </div>
        <div className={s.FilterItem}>
          <ChipToggleButton
            isSelected={memberListFilter.shownMemberApplication}
            onClick={() => {
              handleFilterTrackEvent({
                orderType: selectedMemberOrderType,
                prevOrderType: selectedMemberOrderType,
                memberListFilter,
              });

              setMemberListFiler({
                ...memberListFilter,
                shownMemberApplication: !memberListFilter.shownMemberApplication,
              });
            }}
          >
            가입 질문
          </ChipToggleButton>
        </div>
      </div>
      <AsyncBoundary pendingFallback={<ViewLoader height={500} />} rejectedFallback={<></>}>
        <MemberList
          groupId={groupId}
          selectedMemberOrderType={selectedMemberOrderType}
          memberListFilter={memberListFilter}
          setMemberListFiler={setMemberListFiler}
          searchValue={searchValue}
          memberGradeEnabled={memberGradeEnabled}
        />
      </AsyncBoundary>
    </div>
  );
};

type MemberListProps = {
  groupId: string;
  selectedMemberOrderType: MemberListOrderType;
  memberListFilter: MemberListFilter;
  setMemberListFiler: React.Dispatch<React.SetStateAction<MemberListFilter>>;
  searchValue: string;
  memberGradeEnabled: boolean;
};

const MemberList = ({
  groupId,
  selectedMemberOrderType,
  memberListFilter,
  setMemberListFiler,
  searchValue,
  memberGradeEnabled,
}: MemberListProps) => {
  const { data, hasNextPage, fetchNextPage, isFetchingNextPage } = useGetMemberList({
    groupId,
    order: selectedMemberOrderType,
    keyword: searchValue,
    ...memberListFilter,
  });

  const currentUser = useCurrentGroupMe();
  const { group } = useReadGroupDetail(groupId);

  const memberList = data?.pages.map(({ data }) => data.members);
  const flattenMemberList = memberList?.flat(1) ?? [];
  const filteredMemberList = flattenMemberList.filter(({ nickname }) => {
    if (!searchValue) return true;

    return nickname.toLowerCase().includes(searchValue.toLowerCase());
  });

  const handleLoadMoreImpression = () => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };

  const handleResetFilter = () => {
    setMemberListFiler({
      gradeIds: undefined,
      shownMemberActivities: false,
      shownMemberApplication: false,
      shownOnlyHost: false,
    });
  };

  if (filteredMemberList.length === 0) {
    return (
      <Container paddingX={54} paddingY={70} className={s.ListContainer}>
        <Typography typography="subtitle1Regular" color="gray900">
          설정한 필터에 맞는 멤버가 없어요.
        </Typography>
        <Spacing size={4} />
        <Typography typography="subtitle2Regular" color="gray600">
          필터를 조정하거나 초기화 해보세요.
        </Typography>
        <Spacing size={38} />
        <BoxButton variant="secondary" size="medium" onClick={handleResetFilter}>
          필터 초기화
        </BoxButton>
      </Container>
    );
  }

  return (
    <Container paddingX={0} paddingY={0}>
      <ul className={s.List}>
        {filteredMemberList.map((member) => (
          <MemberProfileDetailSection
            key={member.id}
            user={member}
            currentUserRole={currentUser.role}
            memberListFilter={memberListFilter}
            selectedMemberOrderType={selectedMemberOrderType}
            canViewVerifiedUserInfo={group?.canViewVerifiedUserInfo ?? false}
            needVerification={group?.needVerification ?? false}
            memberGradeEnabled={memberGradeEnabled}
          />
        ))}
      </ul>
      {hasNextPage && <LoadMoreListContainer callback={handleLoadMoreImpression} />}
    </Container>
  );
};
