import { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  condition: boolean;
  fallback?: React.ReactNode;
}>;

export const Render = ({ children, condition, fallback }: Props) => {
  if (!condition) return <>{fallback ?? null}</>;

  return <>{children}</>;
};
