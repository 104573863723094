import { groupClient } from '@community-group/api';
import { BoxButton, Typography } from '@community-group/components';
import { useState } from 'react';

import { useFetchInstance } from '@/api/hooks/instance/useFetchInstance';
import { useDeleteGroup } from '@/api/hooks/useDeleteGroup';
import { useDeleteMember } from '@/api/hooks/useDeleteMember';
import { useGetMe } from '@/api/hooks/useGetMe';
import { getMyGroupListPath, useGetMyGroupList } from '@/api/hooks/useGetMyGroupList';
import { useBridge } from '@/contexts/Bridge';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { queryClient } from '@/shared/api/instance';

import * as s from './QuitGroup.css';

const DefaultValue = {
  quitGroups: [] as number[],
};

const QuitHostGroup = () => {
  const { bridge } = useBridge();

  const fetchInstance = useFetchInstance();
  const [open, setOpen] = useState(false);
  const [formValues, setFormValues] = useState(DefaultValue);

  const { data: myGroupData } = useGetMyGroupList();

  const myJoinedGroupData = myGroupData?.groups.filter((el) => el.isHost) ?? [];

  const handleChange = (groupId: number, check: boolean) => {
    if (check) {
      setFormValues((prevValues) => ({
        ...prevValues,
        ['quitGroups']: [...prevValues.quitGroups, groupId],
      }));
      return;
    }
    setFormValues((prevValues) => ({
      ...prevValues,
      ['quitGroups']: prevValues.quitGroups.filter((id) => id !== groupId),
    }));
  };

  const handleErrorWithToast = useHandleErrorWithToast();

  const { mutate: deleteGroupMutate } = useDeleteGroup({
    onError: (error) => handleErrorWithToast(error),
    onSuccess: () => {
      bridge.openToast({
        toast: {
          body: '모임을 삭제했습니다.',
        },
      });
    },
  });

  const { mutate: mutateDeleteMember } = useDeleteMember({
    onSuccess: () => {},
    onError: handleErrorWithToast,
  });

  const { data: me } = useGetMe();

  const onSubmit = async () => {
    for (const groupId of formValues.quitGroups) {
      // 1. 해당 그룹의 멤버 목록을 가져옵니다
      const get = groupClient.api.GroupMemberApi.apiV1GroupsIdMembersGet({
        axios: fetchInstance,
      });
      const { data: memberList } = await get({ id: groupId, limit: 1000 });

      // 2. 호스트를 제외한 모든 멤버를 필터링합니다
      const filteredMembers = memberList?.members?.filter((member) => member.id !== me.id) ?? [];

      // 3. 각 멤버를 강퇴처리 합니다
      for (const member of filteredMembers) {
        await mutateDeleteMember({
          groupId: groupId.toString(),
          userId: member.id.toString(),
          memberState: 'removed',
        });
        await delay(500);
      }

      // 4. 모든 멤버를 내보낸 후 그룹을 삭제합니다
      await deleteGroupMutate(groupId.toString());
      await delay(1000);
      queryClient.invalidateQueries({ queryKey: [getMyGroupListPath()] });
    }

    setFormValues({
      ['quitGroups']: [],
    });
  };

  const handleSelectAll = () => {
    const isSelectAll = myJoinedGroupData.length === formValues.quitGroups.length;
    const allGroups = myJoinedGroupData.map((group) => group.id) ?? [];
    setFormValues((prevValues) => ({
      ...prevValues,
      ['quitGroups']: isSelectAll ? [] : allGroups,
    }));
  };

  return (
    <div>
      <div
        className={s.TitleWrapper}
        onClick={() => {
          setOpen((prev) => !prev);
          queryClient.invalidateQueries({ queryKey: [getMyGroupListPath()] });
        }}
      >
        <Typography typography="subtitle1Bold">운영중인 모임 삭제하기</Typography>
        <Typography typography="subtitle2Regular" color="gray700">
          {open ? '▲' : '▼'}
        </Typography>
      </div>
      {open && (
        <form className={s.FormWrapper}>
          <div className={s.GroupListWrapper}>
            {myJoinedGroupData.map((group) => {
              return (
                <div key={group.id} className={s.ItemWrapper}>
                  <Typography typography="subtitle2Bold" color="gray900">
                    {group.name}
                  </Typography>
                  <input
                    className="checkbox"
                    type="checkbox"
                    checked={formValues.quitGroups.includes(group.id)}
                    onChange={(e) => {
                      handleChange(group.id, e.target.checked);
                    }}
                  />
                </div>
              );
            })}
          </div>
          <Typography typography="caption1Regular" color="gray600">
            선택 리스트 : {formValues.quitGroups.join(', ')}
          </Typography>
          <div className={s.RowButtonGroup}>
            <BoxButton size="small" onClick={handleSelectAll} variant="secondary">
              전체 선택
            </BoxButton>
            <BoxButton size="small" onClick={onSubmit}>
              삭제하기
            </BoxButton>
          </div>
        </form>
      )}
    </div>
  );
};

export default QuitHostGroup;

// 딜레이 함수: milliseconds만큼 기다린 후 Promise를 해결합니다.
function delay(milliseconds: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}
