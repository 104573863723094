import { groupClient } from '@community-group/api';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

export const getGroupFeedSummaryBulletin = (groupId: number) =>
  groupClient.api.GroupFeedApi.getapiV1GroupsIdFeedSummaryBulletinGetPath(groupId);

export const useGetGroupFeedSummaryBulletin = (groupId: number) => {
  const fetchInstance = useFetchInstance();
  const get = groupClient.api.GroupFeedApi.apiV1GroupsIdFeedSummaryBulletinGet({
    axios: fetchInstance,
  });

  const { data, refetch } = useSuspenseQuery({
    queryKey: [getGroupFeedSummaryBulletin(groupId)],
    queryFn: () => get({ id: groupId }),
  });
  const response = data?.data;

  return { data: response, refetch };
};
