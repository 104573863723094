import { useUserConfig } from '@/contexts/UserConfig';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useStorage } from '@/hooks/useStorage';
import { useFlow } from '@/stackflow';
import { useStore } from '@/store';
import { isDevelopmentAppStage, isExposeInternalUser } from '@/utils/check';

import { isDuringRealJoin12월덕질GroupMarketingPromotion } from '../utils';

function useGroup12월덕질MarketingPromotion() {
  const [marketingPromotionBottomSheet] = useStorage('promotion_group_2412_fandom_create', false);
  const [marketingPromotionBottomSheetJoin] = useStorage('promotion_group_2412_fandom_join', false);
  const { push } = useFlow();
  const { userConfig } = useUserConfig();

  const { is12월덕질MarketingJoinable, is12월덕질MarketingCreate } = useStore();

  const isOngoing12월덕질GroupJoinMarketingPromotion = useFeatureFlag(
    'shown12FandomMarketingPromotion'
  );

  const validIsOnGoing12월덕질GroupCreateMarketingPromotion = () => {
    if (isDevelopmentAppStage()) return true;

    if (!isOngoing12월덕질GroupJoinMarketingPromotion) return false;

    if (marketingPromotionBottomSheet) return false;

    if (!isDuringRealJoin12월덕질GroupMarketingPromotion()) return false;

    return true;
  };

  const validIsOnGoing12월덕질GroupJoinMarketingPromotion = () => {
    if (isDevelopmentAppStage()) return true;
    if (isExposeInternalUser(userConfig?.userId?.toString() ?? '', false)) return true;

    if (!isOngoing12월덕질GroupJoinMarketingPromotion) return false;

    if (marketingPromotionBottomSheetJoin) return false;

    if (!isDuringRealJoin12월덕질GroupMarketingPromotion()) return false;

    return true;
  };

  const handleOpen12월덕질MarketingPromotionBottomSheet = ({
    groupId,
    type,
  }: {
    groupId: string;
    type: 'create' | 'join' | 'apply';
  }) => {
    if ((type === 'join' || type === 'apply') && is12월덕질MarketingJoinable) {
      const isValid = validIsOnGoing12월덕질GroupJoinMarketingPromotion();
      if (!isValid) return false;

      setTimeout(() => {
        push('BottomSheet/DoneJoinGroup12월덕질MarketingPromotionBottomSheet', {
          groupId,
          type,
        });
      }, 500);
      return true;
    }

    if (type === 'create') {
      const isValid = validIsOnGoing12월덕질GroupCreateMarketingPromotion();
      if (!isValid) return false;

      setTimeout(() => {
        push('BottomSheet/DoneCreateGroup12월덕질MarketingPromotionBottomSheet', {
          groupId,
        });
      }, 500);
      return true;
    }
    return false;
  };

  return {
    handleOpen12월덕질MarketingPromotionBottomSheet,
    validIsOnGoing12월덕질GroupCreateMarketingPromotion,
  };
}

export default useGroup12월덕질MarketingPromotion;
