import { GroupCreateForm } from '@community-group/api/lib/group/models';
import {
  BoxButton,
  Checkbox,
  SelectBoxRadio,
  SelectBoxRadioGroup,
  Spacing,
  Typography,
  useSnackbarAdapter,
  VerticalSpacing,
} from '@community-group/components';
import { SeedTextFieldProps, TextField } from '@daangn/sprout-components-text-field';
import { useState } from 'react';

import { usePostNewGroup } from '@/api/hooks/usePostNewGroup';
import { FormContainer, FormItem } from '@/components/common/Form';
import { useBridge } from '@/contexts/Bridge';
import { useUserConfig } from '@/contexts/UserConfig';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';

import * as s from './CreateNewGroup.css';

const CREATE_NEW_GROUP_MOCK: GroupCreateForm = {
  name: 'TEST_모임',
  description: '모임을 빠르게 생성해보자~~',
  categoryId: 1,
  regionId: 366,
  joinType: 'free',
  publishType: 'public',
  needVerification: false,
  canViewVerifiedUserInfo: false,
};

const CreateNewGroup = () => {
  const { bridge } = useBridge();
  const { userConfig } = useUserConfig();
  const { push } = useFlow();
  const snackbarAdapter = useSnackbarAdapter();
  const [open, setOpen] = useState(false);
  const [formValues, setFormValues] = useState<GroupCreateForm>({
    ...CREATE_NEW_GROUP_MOCK,
    name: userConfig.userNickname + '_모임_',
  });

  const handleChange = (field: keyof GroupCreateForm, value: any) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate: mutateCreateNewGroup } = usePostNewGroup({
    onError: handleErrorWithToast,
    onSuccess: (data) => {
      snackbarAdapter.create({
        message: data.data.group.name + ' 모임을 생성했어요.',
        type: 'default',
        timeout: 2000,
        onClick: () => {
          snackbarAdapter.dismiss();
        },
        actionLabel: '이동하기',
        onAction: () => {
          push('GroupDetailPage', { groupId: data.data.group.id.toString() });
        },
      });
    },
  });

  const submitCreateNewGroup = () => {
    mutateCreateNewGroup({
      ...formValues,
      name: formValues.name + '_' + Math.floor(Math.random() * 100),
    });
  };

  return (
    <div>
      <div className={s.TitleWrapper} onClick={() => setOpen((prev) => !prev)}>
        <Typography typography="subtitle1Bold">신규 모임 생성하기</Typography>
        <Typography typography="subtitle2Regular" color="gray700">
          {open ? '▲' : '▼'}
        </Typography>
      </div>

      {open && (
        <FormContainer>
          <FormItem className={s.CreateFormWrapper}>
            <TextField
              variant="outlined"
              label="이름 + DateTime"
              value={formValues.name}
              onChange={(value) => handleChange('name', value)}
            />
          </FormItem>

          <FormItem className={s.CreateFormWrapper}>
            <TextField
              variant="outlined"
              label="소개"
              value={formValues.description}
              onChange={(value) => handleChange('description', value)}
            />
          </FormItem>

          <FormItem className={s.CreateFormWrapper}>
            <TextField
              variant="outlined"
              type="number"
              label="카테고리ID"
              value={formValues.categoryId?.toString()}
              onChange={(value) => handleChange('categoryId', Number(value))}
            />
          </FormItem>

          <FormItem className={s.CreateFormWrapper}>
            <TextField
              variant="outlined"
              type="number"
              label="지역ID"
              value={formValues.regionId?.toString()}
              onChange={(value) => handleChange('regionId', Number(value))}
            />
          </FormItem>

          <FormItem className={s.CreateFormWrapper}>
            <Typography typography="label3Bold" color="gray900">
              Join Type
            </Typography>
            <VerticalSpacing size={12} />
            <select
              value={formValues.joinType}
              onChange={(e) => handleChange('joinType', e.target.value)}
            >
              <option value="free">Free</option>
              <option value="approval">Approval</option>
            </select>
          </FormItem>

          <FormItem className={s.CreateFormWrapper}>
            <Typography typography="label3Bold" color="gray900">
              Publish Type
            </Typography>
            <VerticalSpacing size={12} />
            <select
              value={formValues.publishType}
              onChange={(e) => handleChange('publishType', e.target.value)}
            >
              <option value="public">Public</option>
              <option value="private">Private</option>
              <option value="internal">Internal</option>
              <option value="temporary">Temporary</option>
            </select>
          </FormItem>

          <FormItem className={s.CreateFormWrapper}>
            <Typography typography="label3Bold" color="gray900">
              본인인증 여부
            </Typography>
            <VerticalSpacing size={12} />
            <Checkbox
              isSelected={formValues.needVerification}
              onChange={(value) => handleChange('needVerification', value)}
            />
          </FormItem>

          <FormItem className={s.CreateFormWrapper}>
            <Typography typography="label3Bold" color="gray900">
              본인인증 정보 조회 가능 여부
            </Typography>
            <VerticalSpacing size={12} />
            <Checkbox
              isSelected={formValues.canViewVerifiedUserInfo}
              onChange={(value) => handleChange('canViewVerifiedUserInfo', value)}
            />
          </FormItem>

          <FormItem className={s.CreateFormWrapper}>
            <BoxButton size="medium" width="100%" onClick={submitCreateNewGroup}>
              생성하기
            </BoxButton>
          </FormItem>
        </FormContainer>
      )}
    </div>
  );
};

export default CreateNewGroup;
