import { Typography } from '@community-group/components';

import * as s from './style.css';

type Props = {
  title: string;
  value: string;
};
const ActivityBox = ({ title, value }: Props) => {
  return (
    <div className={s.ActivityBox}>
      <Typography typography="caption1Regular" color="gray700">
        {title}
      </Typography>
      <Typography typography="subtitle2Bold" color="gray900">
        {value}
      </Typography>
    </div>
  );
};

export default ActivityBox;
