import {
  GroupMemberSummaryPresentation,
  GroupMemberSummaryWithActivityPresentation,
} from '@community-group/api/lib/group/models';
import { GroupAvatar } from '@community-group/components';
import clsx from 'clsx';
import React from 'react';

import { useUserConfig } from '@/contexts/UserConfig';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { getUserProfileUrlInduceExperiment } from '@/utils/analytics/experiment/experiments/userProfileInduceExperiment';

import * as s from './ListItem.css';
import { ListItemGradeBadge } from './ListItemGradeBadge';

export type ProfileListItemUser = Pick<
  GroupMemberSummaryPresentation,
  'id' | 'nickname' | 'isAccountDeleted' | 'userRegion' | 'profileImage' | 'role' | 'subNickname'
> &
  Partial<
    Omit<
      GroupMemberSummaryPresentation,
      | 'id'
      | 'nickname'
      | 'isAccountDeleted'
      | 'userRegion'
      | 'profileImage'
      | 'role'
      | 'subNickname'
    >
  > &
  Partial<Pick<GroupMemberSummaryWithActivityPresentation, 'grade'>>;

export interface ProfileListItemProps {
  appendLabel?: string;
  appendDescription?: string;
  user: ProfileListItemUser;
  config?: {
    shownMeBadge?: boolean;
    handledBadgeClick?: boolean;
    memberGradeEnabled?: boolean;
  };
}

interface Props extends React.InputHTMLAttributes<HTMLDivElement>, ProfileListItemProps {}

const ProfileListItem = ({
  appendLabel,
  appendDescription,
  user: {
    nickname,
    subNickname,
    isAccountDeleted,
    description,
    userRegion,
    profileImage = '',
    userRegionCheckInCount,
    grade,
  },
  config: { shownMeBadge = false, handledBadgeClick = true, memberGradeEnabled = false } = {},
  ...props
}: Props) => {
  const { userConfig } = useUserConfig();
  const { groupId } = usePathParams();

  const regionLabel = () => {
    if (appendLabel) {
      return appendLabel;
    }
    if (userRegionCheckInCount) {
      return `${userRegion?.name} 인증 ${userRegionCheckInCount}회`;
    }
    return userRegion?.name;
  };

  return (
    <div {...props} className={clsx([props.className, s.wrapper])}>
      {/* TODO: createdDate 3월 11일 프로필 실험을 위한 임시 코드 */}
      <GroupAvatar
        src={getUserProfileUrlInduceExperiment({
          groupId: parseInt(groupId),
          userId: userConfig.userId ?? 0,
          defaultUrl: profileImage,
        })}
        size="small"
      />
      <div className={s.contentsWrapper}>
        <div className={s.titleWrapper}>
          <div className={s.NameWrapper}>
            <h6 className={s.title({ isAccountDeleted })}>
              {shownMeBadge && <div className={s.meBadge}>나</div>}
              {nickname}
            </h6>
            {grade && (
              <ListItemGradeBadge
                grade={grade}
                onClick={() => {
                  if (!handledBadgeClick) return;
                }}
                memberGradeEnabled={memberGradeEnabled}
              />
            )}
            {subNickname && <h6 className={s.title({ isAccountDeleted })}>{`(${subNickname})`}</h6>}
          </div>
          <div className={s.label}>{regionLabel()}</div>
        </div>
        {(description || appendDescription) && (
          <p className={s.description}>{appendDescription ?? description}</p>
        )}
      </div>
    </div>
  );
};

export default ProfileListItem;
