import { GroupFeedListResponse } from '@community-group/api/lib/group/models';
import { InfiniteData } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { GROUP_FEED_QUERY_KEY } from '@/domain/GroupFeed/queries';
import { queryClient } from '@/shared/api/instance';

type Props = {
  type: 'like' | 'dislike';
  groupId?: string;
  userId?: string;
  targetId?: string;
};
export const updateGroupMemberFeeds = async ({ type, groupId, userId, targetId }: Props) => {
  if (!groupId || !userId || !targetId) return;
  const queryCache = queryClient.getQueriesData({
    queryKey: GROUP_FEED_QUERY_KEY.base(groupId, userId),
  });

  queryCache.forEach(([queryKey]) =>
    queryClient.setQueryData<InfiniteData<AxiosResponse<GroupFeedListResponse>>>(
      queryKey,
      (prev?: InfiniteData<AxiosResponse<GroupFeedListResponse>>) => {
        if (!prev || prev.pages.length === 0) return prev;
        const targetItemId = parseInt(targetId);
        const updatedPages = prev.pages.map((page) => {
          const updatedItems = page.data.items.map((item) => {
            if (item.id !== targetItemId) return item;
            return {
              ...item,
              emotion: {
                count: type === 'like' ? item.emotion.count + 1 : item.emotion.count - 1,
                myEmotion: type === 'like' ? 'like' : undefined,
              },
            };
          });
          return {
            ...page,
            data: {
              ...page.data,
              items: updatedItems,
            },
          };
        });
        return {
          ...prev,
          pages: updatedPages,
        };
      }
    )
  );
};
