import { GroupMemberSummaryPresentation } from '@community-group/api/lib/group/models';
import { useBottomSheet } from '@community-group/components';
import { IconDot3VerticalLine } from '@daangn/react-monochrome-icon';
import { Suspense, useCallback } from 'react';

import { MemberListOrderType } from '@/api/hooks/useGetMemberList';
import { useReadGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import useActiveActivities from '@/hooks/useActiveActivities';
import { useFlow } from '@/stackflow';

import MemberProfileActionBottomSheet, {
  MemberProfileActionBottomSheetFallback,
} from '../components/bottomSheet/MemberProfileActionBottomSheet';
type Props = {
  groupId: string;
  profileUser: GroupMemberSummaryPresentation;
  shownOnlyHost?: boolean;
  selectedMemberOrderType?: MemberListOrderType;
  hostMemberList: GroupMemberSummaryPresentation[];
};

export const useHandleProfileMoreButton = ({
  groupId,
  profileUser,
  shownOnlyHost,
  selectedMemberOrderType,
  hostMemberList,
}: Props) => {
  const { open: openBottomSheet } = useBottomSheet();
  const { activeActivities } = useActiveActivities();
  const currentActivity = activeActivities[activeActivities.length - 1];
  const { push } = useFlow();

  const { data: me } = useReadGroupMe({ groupId });

  const renderProfileMoreButton = useCallback(() => {
    if (me.role === 'none') return null;
    if (me.id === profileUser.id) return null;

    const handleMoreButtonClick = () => {
      const isGroupUserProfileDetailPage = currentActivity.name === 'GroupUserProfileDetailPage';
      const props = {
        groupId,
        currentUser: me,
        profileUser,
        hostMemberList,
        isGroupUserProfileDetailPage,
        shownOnlyHost,
        selectedMemberOrderType,
        push,
      };

      openBottomSheet({
        element: (
          <Suspense fallback={<MemberProfileActionBottomSheetFallback {...props} />}>
            <MemberProfileActionBottomSheet {...props} />
          </Suspense>
        ),
      });
    };

    return (
      <button onClick={handleMoreButtonClick}>
        <IconDot3VerticalLine size={20} />
      </button>
    );
  }, [
    me,
    push,
    profileUser,
    openBottomSheet,
    groupId,
    hostMemberList,
    currentActivity.name,
    shownOnlyHost,
    selectedMemberOrderType,
  ]);

  return {
    renderProfileMoreButton,
  };
};
