import { groupClient } from '@community-group/api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { GROUP_DETAIL_QUERY_KEY } from '@/domain/GroupDetail/queries';
import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

export const useQueryGroupDetail = (groupId: string) => {
  const read = useAsyncRead(groupClient.api.GroupApi.apiV1GroupsIdGet);
  return {
    queryKey: GROUP_DETAIL_QUERY_KEY.base(groupId),
    queryFn: () => read({ id: Number(groupId) }).then((res) => res.group),
  };
};

export const useReadGroupDetail = (groupId: string) => {
  const query = useQueryGroupDetail(groupId);
  return useSuspenseQuery({ ...query });
};
