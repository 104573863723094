import { Typography } from '@community-group/components';

import { useReadGroupMemberApplicationInfo } from '@/domain/GroupProfileMemberManage/hooks/useReadGroupMemberApplicationInfo';

import * as s from './style.css';
type Props = {
  groupId: string;
  userId: string;
};
const GroupProfileMemberManageApplicationInfo = ({ groupId, userId }: Props) => {
  const { data: applicationInfo } = useReadGroupMemberApplicationInfo({ groupId, userId });
  if (!applicationInfo) return <></>;
  return (
    <section>
      <div className={s.Title}>가입 질문 답변</div>
      <div className={s.Box}>
        <Typography typography="subtitle1Regular" color="gray600">
          Q. {applicationInfo.question}
        </Typography>
        <Typography typography="bodyL1Regular" color="gray900">
          {applicationInfo.answer}
        </Typography>
      </div>
    </section>
  );
};

export default GroupProfileMemberManageApplicationInfo;
