import { useEffect } from 'react';

import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { useStore } from '@/store';

// 유저가 사전신청 마케팅 페이지에서 넘어왔는지 확인해서 저장하는 훅
const useCheckUserFrom12월덕질MarketingPromotion = () => {
  const { from = '' } = useQueryParams();

  // 해당 진입 세션에서만 사전신청 가능해야해서 store에 저장
  const { set12월덕질MarketingJoinable, set12월덕질MarketingCreate } = useStore();

  const isCheck12월덕질 = from === 'create_12_fandom_marketing';

  useEffect(() => {
    if (from === 'join_12_fandom_marketing') {
      set12월덕질MarketingJoinable(true);
    }

    if (from === 'create_12_fandom_marketing') {
      set12월덕질MarketingCreate(true);
    }
  }, [from, set12월덕질MarketingCreate, set12월덕질MarketingJoinable]);

  return {
    isCheck12월덕질,
  };
};

export default useCheckUserFrom12월덕질MarketingPromotion;
