import { ChallengeCertifyPointsResponse } from '@community-group/api/lib/group/models';
import {
  Dialog,
  useDialog,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { IconXmarkLine } from '@daangn/react-monochrome-icon';
import { ActivityComponentType } from '@stackflow/react';
import { Suspense } from 'react';

import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { useBack } from '@/stackflow/hooks/useBack';
import { ActivityQueryParams, useQueryParams } from '@/stackflow/hooks/useQueryParams';

import GrowthColdStartJoinKarrotPayments from '../../components';
import JoinKarrotPaymentsAccessoryBar from '../../components/AccessoryBar';

/**
 * 2025.01 챌린지 프로모션 그로스 이벤트입니다.
 *  이후 관련 내용을 삭제해주세요.
 */

type Params = Pick<
  ActivityQueryParams,
  'challengeCertifyPromotionResult' | 'prizeEntryId' | 'point'
>;

const GrowthColdStartKarrotPaymentsJoinPage: ActivityComponentType<Params> = () => {
  const { prizeEntryId = '', challengeCertifyPromotionResult = '{}', point } = useQueryParams();
  const back = useBack();

  const { open: openDialog, close: closeDialog } = useDialog();

  const parsedChallengeCertifyPromotionResult = JSON.parse(
    challengeCertifyPromotionResult ?? '{}'
  ) as ChallengeCertifyPointsResponse;

  useEnterTrackEvent({
    event: 'enter_challenge_certify_not_member_pay_1',
    params: {
      ...parsedChallengeCertifyPromotionResult,
      point,
      prizeEntryId,
    },
  });

  const handleCloseButtonClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    openDialog({
      element: (
        <Dialog
          title="정말 나갈까요?"
          description="이대로 나가면 당근머니를 받을 수 없을수도 있어요."
          primaryActionLabel="나가기"
          secondaryActionLabel="취소"
          onSecondaryAction={closeDialog}
          onPrimaryAction={async () => {
            await closeDialog();
            back();
          }}
        />
      ),
    });
  };

  return (
    <AppScreen
      preventSwipeBack
      appBar={{
        closeButton: {
          renderIcon: () => <IconXmarkLine size={24} />,
          onClick: handleCloseButtonClick,
        },
        backButton: {
          renderIcon: () => <IconXmarkLine size={24} />,
          onClick: handleCloseButtonClick,
        },
      }}
      accessoryBar={
        <JoinKarrotPaymentsAccessoryBar
          prizeEntryId={prizeEntryId}
          point={point}
          challengeCertifyPromotionResult={challengeCertifyPromotionResult}
        />
      }
    >
      <Suspense fallback={<ViewLoader />}>
        <GrowthColdStartJoinKarrotPayments />
      </Suspense>
    </AppScreen>
  );
};

export default withAsyncBoundary(GrowthColdStartKarrotPaymentsJoinPage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
