import { useSuspenseQuery } from '@tanstack/react-query';
import { AxiosInstance, AxiosPromise } from 'axios';

import { User, USER_ME_URL } from '@/api/base/user';
import { GROUP_QUERY_KEY } from '@/domain/Group/queries';
import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

export const apiV1UsersMeGet = ({ axios }: { axios?: AxiosInstance }) => {
  if (!axios) throw new Error('axios is required');
  return (): AxiosPromise<User> => {
    return axios.get(USER_ME_URL);
  };
};

export const useQueryMe = () => {
  const read = useAsyncRead(apiV1UsersMeGet);
  return {
    queryKey: GROUP_QUERY_KEY.userMe(),
    queryFn: () => read(),
  };
};

export const useReadMe = () => {
  const query = useQueryMe();
  return useSuspenseQuery({ ...query });
};
