import { groupClient } from '@community-group/api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { GROUP_DETAIL_SHORTCUT_MENU_QUERY_KEY } from '@/domain/GroupDetailShortcutMenu/queries';
import { useRead } from '@/shared/api/hooks/useRead';

type Props = {
  groupId: string;
  refetchInterval?: number;
};
export const useQueryGroupUnreadChatCount = ({ groupId }: Props) => {
  const read = useRead(groupClient.api.MultiChatApi.apiV1GroupsGroupIdUnreadChatCountGet);
  return {
    queryKey: GROUP_DETAIL_SHORTCUT_MENU_QUERY_KEY.unreadChatCount(groupId),
    queryFn: () => read({ groupId: Number(groupId) }),
  };
};

export const useReadGroupUnreadChatCount = ({ groupId, refetchInterval }: Props) => {
  const query = useQueryGroupUnreadChatCount({ groupId });
  return useSuspenseQuery({
    ...query,
    select: (data) => data.data.unreadChatCount,
    refetchInterval: refetchInterval ?? 1000 * 60,
  });
};
