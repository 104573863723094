import { groupClient } from '@community-group/api';
import { useInfiniteQuery, useSuspenseInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useFetchInstance } from './instance/useFetchInstance';

export const getGroupMeetupMembersPath = (groupId: string, meetupId: string) =>
  `/api/v1/groups/${groupId}/meetups/${meetupId}/members`;

export type GetGroupMeetupMembersProps = {
  groupId: string;
  meetupId: string;
  meetupJoinStatus: groupClient.model.GroupMeetupJoinStatusEnum;
  limit?: number;
  initialCursor?: string | undefined;
};

export const useGetGroupMeetupMembers = ({
  groupId,
  meetupId,
  meetupJoinStatus,
  limit = 30,
  initialCursor = undefined,
}: GetGroupMeetupMembersProps) => {
  const fetchInstance = useFetchInstance();
  const getGroupMeetupMembers =
    groupClient.api.MeetupApi.apiV1GroupsGroupIdMeetupsMeetupIdMembersGet({ axios: fetchInstance });

  const fetchQueryKey = useMemo(
    () => [getGroupMeetupMembersPath(groupId, meetupId), meetupJoinStatus, limit],
    [groupId, meetupId, meetupJoinStatus, limit]
  );

  const { fetchNextPage, hasNextPage, isFetchingNextPage, data, refetch, isLoading, isFetching } =
    useSuspenseInfiniteQuery({
      queryKey: fetchQueryKey,
      queryFn: ({ pageParam = initialCursor }) =>
        getGroupMeetupMembers({
          groupId: Number(groupId),
          meetupId: Number(meetupId),
          meetupJoinStatus,
          cursor: pageParam === null ? undefined : pageParam,
          limit,
        }),
      initialPageParam: initialCursor,
      getNextPageParam: ({ data }) => (data.hasNext ? data?.endCursor ?? undefined : undefined),
    });

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
    isLoading,
    isFetching,
  };
};
