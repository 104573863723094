import { groupClient } from '@community-group/api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

import { GROUP_MEMBER_QUERY_KEY } from '../queries';

export const useQueryGroupMemberGradeStatus = (groupId: string) => {
  const read = useAsyncRead(groupClient.api.GroupMemberApi.apiV1GroupsIdMembersGradesStatusGet);
  return {
    queryKey: GROUP_MEMBER_QUERY_KEY.memberGradeStatus(groupId),
    queryFn: () => read({ id: Number(groupId) }).then((res) => res.status),
  };
};

export const useReadGroupMemberGradeStatus = (groupId: string) => {
  const query = useQueryGroupMemberGradeStatus(groupId);
  return useSuspenseQuery({ ...query });
};
