import React from 'react';

import * as s from './item.css';

type OptimizeImage = {
  id: string;
  src: string;
  width: number;
  height: number;
};

type Props = {
  image: OptimizeImage;
  aspectRatio: string;
  alt?: string;
};
/**
 * @description 아파트와 동일한 폴더구조를 가져가기 위해 domain/GroupFeed/components/FeedMediaItem/ImageItem를 사용해주세요
 */
function ImageItem({ image, aspectRatio, alt = '게시글 첨부 이미지' }: Props) {
  return (
    <div className={s.ImageContainer}>
      <div className={s.ImageBorder} />
      <img src={image.src} className={s.Image} style={{ aspectRatio }} alt={alt} />
    </div>
  );
}

export default ImageItem;
