import { groupClient } from '@community-group/api';
import { UseMutationOptions } from '@tanstack/react-query';

import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Request = {
  groupId: string;
  relatedId: string;
  commentId: string;
};

type Props = {
  relatedContentType: 'post' | 'meetup';
} & UseMutationOptions<string | undefined, Error, Request>;

export const useDeleteComment = ({ relatedContentType, onError, onSuccess }: Props) => {
  const isPostComment = relatedContentType === 'post';
  const fetchInstance = useFetchInstance();

  const deletePostComment =
    groupClient.api.CommentApi.apiV1GroupsIdPostsPostIdCommentsCommentIdDelete({
      axios: fetchInstance,
    });

  const deleteMeetupComment =
    groupClient.api.CommentApi.apiV1GroupsIdMeetupsMeetupIdCommentsCommentIdDelete({
      axios: fetchInstance,
    });

  return useThrottleMutation(
    async ({ groupId, relatedId, commentId }: Request) => {
      if (isPostComment) {
        await deletePostComment({
          id: Number(groupId),
          postId: Number(relatedId),
          commentId: Number(commentId),
        });
      } else {
        await deleteMeetupComment({
          id: Number(groupId),
          meetupId: Number(relatedId),
          commentId: Number(commentId),
        });
      }
      return commentId;
    },
    {
      onError,
      onSuccess,
    }
  );
};
