import { groupClient } from '@community-group/api';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

export const getGroupBotDetailPath = (groupId: string) => {
  const id = Number(groupId);
  return groupClient.api.GroupBotApi.getapiV1GroupsIdGroupBotDetailGetPath(id);
};

export const useGetGroupBotDetail = (groupId: string) => {
  const fetchInstance = useFetchInstance();
  const getGroupBot = groupClient.api.GroupBotApi.apiV1GroupsIdGroupBotDetailGet({
    axios: fetchInstance,
  });

  const { data, refetch } = useSuspenseQuery({
    queryKey: [getGroupBotDetailPath(groupId)],
    queryFn: () => {
      const id = Number(groupId);
      return getGroupBot({ id });
    },
  });

  return { data, refetch };
};
