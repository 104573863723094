import { groupClient } from '@community-group/api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useRead } from '@/shared/api/hooks/useRead';

export const getRestrictedMemberCountPath = (groupId?: string) =>
  groupClient.api.GroupRestrictedMemberApi.getapiV1GroupsIdRestrictedMembersTotalCountGetPath(
    Number(groupId)
  );

export const useGetRestrictedMemberCount = (groupId?: string) => {
  const read = useRead(
    groupClient.api.GroupRestrictedMemberApi.apiV1GroupsIdRestrictedMembersTotalCountGet
  );

  const path = getRestrictedMemberCountPath(groupId);
  const { data, refetch } = useSuspenseQuery({
    queryKey: [path],
    queryFn: () => read({ id: Number(groupId) }),
  });

  return {
    data: data.data,
    refetch,
  };
};
