import { groupClient } from '@community-group/api';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Props = UseMutationOptions<AxiosResponse<void>, Error, number>;

export const useDeleteApplication = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const deleteApplication = groupClient.api.GroupApplicationApi.apiV1GroupsIdApplicationsDelete({
    axios: fetchInstance,
  });

  const deleteApplicationWrapperFp = (id: number) => {
    return deleteApplication({ id });
  };

  return useThrottleMutation(deleteApplicationWrapperFp, {
    onError,
    onSuccess,
  });
};
