import { Divider, Spacing, Typography } from '@community-group/components';
import { vars } from '@seed-design/design-token';

import { useGetChallengeMyCertifyActivity } from '@/api/hooks/useGetChallengeMyCertifyActivity';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

import * as s from './style.css';

const MyChallengeStatus = () => {
  const { groupId, challengeId } = usePathParams();
  const { data } = useGetChallengeMyCertifyActivity({
    groupId: Number(groupId),
    challengeId: Number(challengeId),
  });

  return (
    <div className={s.StatusWrapper}>
      <div className={s.StatusItemWrapper}>
        <Typography typography="caption2Regular" color="gray600">
          순위
        </Typography>
        <Spacing size={4} />
        <Typography typography="subtitle1Bold" color="primary">
          {data?.currentRank}위
        </Typography>
      </div>
      <Divider
        size={1}
        direction="vertical"
        background={vars.$semantic.color.divider2}
        padding={0}
      />
      <div className={s.StatusItemWrapper}>
        <Typography typography="caption2Regular" color="gray600">
          이번 주 인증
        </Typography>
        <Spacing size={4} />
        <Typography typography="subtitle1Bold" color="gray700">
          {data?.intervalCertifiedProgress?.current}/{data?.intervalCertifiedProgress?.target}
        </Typography>
      </div>
      <Divider
        size={1}
        direction="vertical"
        background={vars.$semantic.color.divider2}
        padding={0}
      />
      <div className={s.StatusItemWrapper}>
        <Typography typography="caption2Regular" color="gray600">
          전체 인증
        </Typography>
        <Spacing size={4} />
        <Typography typography="subtitle1Bold" color="gray700">
          {data?.totalCertifiedProgress?.current}/{data?.totalCertifiedProgress?.target}
        </Typography>
      </div>
    </div>
  );
};

export default MyChallengeStatus;
