import clsx from 'clsx';
import React from 'react';

import * as s from './style.css';

type Props = {
  title?: string | React.ReactNode;
  children: React.ReactNode;
  paddingX?: number;
  paddingY?: number;
  className?: string;
  renderRight?: React.ReactNode;
};

const Wrapper = ({
  title,
  children,
  paddingX = 0,
  paddingY = 8,
  className,
  renderRight,
}: Props) => (
  <section
    className={clsx([s.Wrapper, className])}
    style={{
      padding: `${paddingY}px ${paddingX}px`,
    }}
  >
    {title && <h4 className={s.Title}>{title}</h4>}
    <ul>
      {React.Children.map(children, (child) => {
        if (React.isValidElement<{ renderRight?: React.ReactNode }>(child)) {
          return React.cloneElement(child, {
            renderRight,
            ...child.props,
          });
        }
        return child;
      })}
    </ul>
  </section>
);

export default Wrapper;
