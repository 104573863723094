import { groupClient } from '@community-group/api';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

const getMeetupReviewsForNotMemberPath = (id: number) =>
  groupClient.api.GroupFeedApi.getapiV1GroupsIdFeedMeetupReviewsForNotMemberGetPath(id);

export const useGetMeetupReviewsForNotMember = (groupId?: string) => {
  const fetchInstance = useFetchInstance();
  const getMeetupReviewsForNotMember =
    groupClient.api.GroupFeedApi.apiV1GroupsIdFeedMeetupReviewsForNotMemberGet({
      axios: fetchInstance,
    });

  const { data, refetch, isPending } = useSuspenseQuery({
    queryKey: [getMeetupReviewsForNotMemberPath(Number(groupId))],
    queryFn: () => getMeetupReviewsForNotMember({ id: Number(groupId) }),
  });

  const response = data?.data;

  return { data: response, refetch, isLoading: isPending };
};
