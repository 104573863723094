import { withAsyncBoundary } from '@community-group/components';
import { IconEnvelopeLine } from '@daangn/react-monochrome-icon';
import clsx from 'clsx';

import { useGetMainProfile } from '@/api/hooks/useGetMainProfile';
import IconWrapper from '@/components/common/Icons';
import { useUserConfig } from '@/contexts/UserConfig';
import { useReadGroupUserDirectChatRequests } from '@/domain/GroupDirectChat/hooks/useReadGroupUserDirectChatRequests';
import { useFlow } from '@/stackflow';

import * as s from './style.css';

const MyRequestDirectChatPageAppBarRightButton = () => {
  const { userConfig } = useUserConfig();
  const { data } = useGetMainProfile({ userId: userConfig.userId ?? 0 });

  if (!data?.isMyProfile) {
    return <></>;
  }

  return <Icon />;
};

export default withAsyncBoundary(MyRequestDirectChatPageAppBarRightButton, {
  pendingFallback: <></>,
  rejectedFallback: <></>,
});

const Icon = () => {
  const { data: requestGroupDirectChats } = useReadGroupUserDirectChatRequests();
  const hasRequestGroupDirectChats = (requestGroupDirectChats ?? []).length > 0;

  const { push } = useFlow();
  const handleClickRequestDirectChat = () => {
    push('MainProfileMyInfoRequestDirectChatsPage', {});
  };

  return (
    <IconWrapper>
      <div className={s.IconWrapper}>
        <div
          onClick={handleClickRequestDirectChat}
          className={clsx(hasRequestGroupDirectChats && [s.RedDot])}
        >
          <IconEnvelopeLine size={24} />
        </div>
      </div>
    </IconWrapper>
  );
};
