import { ActionListItem, useBottomSheet } from '@community-group/components';
import { UseActionsOutputType } from '@stackflow/react';

import { TypeActivities } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

type Props = {
  groupId: number;
  targetUserId: number;
  targetUserName: string;
  actionFlowPush: UseActionsOutputType<TypeActivities>['push'];
};

export const GroupDirectPayChatStatusOnRequiredApprovalActionListItem = ({
  groupId,
  targetUserId,
  targetUserName,
  actionFlowPush,
}: Props) => {
  const { closeAsync } = useBottomSheet();

  const handleRequestPayChat = async () => {
    trackEvent({
      event: 'click_pay_transfer_direct_chat_button',
      params: {
        groupId,
        type: 'profile_detail',
        directChatEnabledStatus: 'required_approval',
      },
    });

    await closeAsync();

    actionFlowPush('GroupProfileRequestGroupDirectChatNewPage', {
      groupId: groupId.toString(),
      userId: targetUserId.toString(),
      userName: targetUserName,
      directChatType: 'profile_pay_chat',
    });
  };

  return <ActionListItem onClick={handleRequestPayChat}>송금하기</ActionListItem>;
};
