import { useSuspenseQuery } from '@tanstack/react-query';
import type { AxiosInstance } from 'axios';
import humps from 'humps';

import { NEW_USER_AGENT_REGEX } from '@/constants/regex';
import { useDeviceConfig } from '@/contexts/DeviceConfig';
import { useUserConfig } from '@/contexts/UserConfig';
import { parseUserAgent } from '@/utils/parseUserAgent';

import { useFetchInstance } from './instance/useFetchInstance';

const postVariablesTriggerPath = '/api/v1/variables/trigger';
const postVariablesExperimentKeysPath = '/api/v1/variables/experiment-keys';

type RequestParams = {
  experimentKeys: string[];
  subjectType:
    | 'SUBJECT_TYPE_USER'
    | 'SUBJECT_TYPE_DEVICE'
    | 'SUBJECT_TYPE_SESSION'
    | 'SUBJECT_TYPE_UNSPECIFIED';
  subjectId: string;
  subjectMetadata: {
    platform: 'PLATFORM_IOS' | 'PLATFORM_ANDROID';
    appVersion: string;
    osVersion: string;
    appBuildType: 'debug' | 'release';
    countryCode: 'COUNTRY_CODE_KR';
  };
};

export type ExperimentVariablesData = {
  key: string;
  value: string;
};

type Options = {
  skipTrigger?: boolean;
};

export const getExperimentVariablesQueryKey = (experimentKeys: string[], skipTrigger?: boolean) => {
  const path = skipTrigger ? postVariablesExperimentKeysPath : postVariablesTriggerPath;
  return [path, ...experimentKeys];
};

export const getExperimentVariables =
  (
    instance: AxiosInstance,
    experimentKeys: string[],
    userAgent: string,
    userId: number | null,
    skipTrigger?: boolean
  ) =>
  async () => {
    const legacyAppInfo = parseUserAgent(userAgent);
    const brandNewAppInfo = parseUserAgent(userAgent, NEW_USER_AGENT_REGEX);

    const appInfo = brandNewAppInfo ?? legacyAppInfo;

    if (!userId || !appInfo) return;

    instance.defaults.baseURL = globalThis.appConfig.apiEndPoints.experiment;
    const params: RequestParams = {
      experimentKeys,
      subjectType: 'SUBJECT_TYPE_USER',
      subjectId: userId.toString(),
      subjectMetadata: {
        platform: appInfo.osType === 'iOS' ? 'PLATFORM_IOS' : 'PLATFORM_ANDROID',
        osVersion: appInfo.osVersion,
        appVersion: appInfo.appVersion,
        appBuildType: 'release',
        countryCode: 'COUNTRY_CODE_KR',
      },
    };

    const path = skipTrigger ? postVariablesExperimentKeysPath : postVariablesTriggerPath;

    const response = await instance.post<{
      variables: ExperimentVariablesData[];
    }>(path, humps.decamelizeKeys(params));

    return response?.data?.variables;
  };

const useExperimentVariables = (experimentKeys: string[], options?: Options) => {
  const fetchInstance = useFetchInstance();
  const { userConfig } = useUserConfig();
  const { deviceConfig } = useDeviceConfig();

  const queryKey = getExperimentVariablesQueryKey(experimentKeys, options?.skipTrigger);
  const get = getExperimentVariables(
    fetchInstance,
    experimentKeys,
    deviceConfig.userAgent,
    userConfig.userId,
    options?.skipTrigger
  );

  return useSuspenseQuery({
    queryKey,
    queryFn: get,
  });
};

export const useExperimentVariable = (experimentKey: string, options?: Options) => {
  const variables = useExperimentVariables([experimentKey], options);
  return variables?.data?.[0];
};
