import { groupClient } from '@community-group/api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { GROUP_PROFILE_QUERY_KEY } from '@/domain/GroupProfile/queries';
import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

type Props = {
  groupId: string;
  userId: string;
};
export const useQueryGroupProfileUserActivities = ({ groupId, userId }: Props) => {
  const read = useAsyncRead(groupClient.api.GroupMemberApi.apiV1GroupsIdMembersUserIdActivitiesGet);
  return {
    queryKey: GROUP_PROFILE_QUERY_KEY.activities(groupId, userId),
    queryFn: () =>
      read({
        id: Number(groupId),
        userId: Number(userId),
      }),
  };
};

export const useReadGroupProfileUserActivities = ({ groupId, userId }: Props) => {
  const query = useQueryGroupProfileUserActivities({ groupId, userId });
  return useSuspenseQuery(query);
};
