import { groupClient } from '@community-group/api';
import { ApiV1GroupsIdJoinPostRequestParams } from '@community-group/api/lib/group/api/group-api';

import { useRead } from '@/shared/api/hooks/useRead';
import { UseThrottleMutationOption } from '@/shared/api/hooks/useThrottleMutation';
import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';

type Props = UseThrottleMutationOption;

export const usePostJoinGroup = (props: Props) => {
  const fetch = useRead(groupClient.api.GroupApi.apiV1GroupsIdJoinPost);

  const mutation = ({ id, groupJoinForm }: ApiV1GroupsIdJoinPostRequestParams) =>
    fetch({ id, groupJoinForm });

  return useThrottleMutation(mutation, props);
};
