import { groupClient } from '@community-group/api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

import { GROUP_MEMBER_QUERY_KEY } from '../queries';

type Params = {
  groupId: string;
  userId: number;
};

// 변경 가능한 등급 목록을 조회해요
export const useQueryGroupMemberGradeEditSections = ({ groupId, userId }: Params) => {
  const read = useAsyncRead(
    groupClient.api.GroupMemberApi.apiV1GroupsIdMembersUserIdGradesEditSectionGet
  );
  return {
    queryKey: GROUP_MEMBER_QUERY_KEY.memberGradeEditSections(groupId, userId),
    queryFn: () =>
      read({ id: Number(groupId), userId: Number(userId) }).then((res) => res.sections),
  };
};

export const useReadGroupMemberGradeEditSections = ({ groupId, userId }: Params) => {
  const query = useQueryGroupMemberGradeEditSections({ groupId, userId });
  return useSuspenseQuery({ ...query });
};
