import { UserProfileSnsTagPresentationSnsType } from '@community-group/api/lib/group/models';
import { ActionList, ActionListItem, ActionSheet } from '@community-group/components';
import { vars } from '@seed-design/design-token';

import { openSnsLink } from '@/components/group/profile/GroupProfile/Detail/utils/openSnsLink';

type Props = {
  snsId: string;
  snsType: UserProfileSnsTagPresentationSnsType;
  onClickEditSnsTag: () => Promise<void>;
  onClickDeleteSnsTag: () => Promise<void>;
};
const SnsActionSheet = ({ snsId, snsType, onClickEditSnsTag, onClickDeleteSnsTag }: Props) => {
  return (
    <ActionSheet
      bottomButton={{
        label: '닫기',
      }}
    >
      <ActionList>
        <ActionListItem
          onClick={() =>
            openSnsLink({
              snsId,
              snsType,
            })
          }
        >
          링크로 이동
        </ActionListItem>
        <ActionListItem onClick={onClickEditSnsTag}>수정</ActionListItem>
        <ActionListItem color={vars.$semantic.color.danger} onClick={onClickDeleteSnsTag}>
          삭제
        </ActionListItem>
      </ActionList>
    </ActionSheet>
  );
};

export default SnsActionSheet;
