import { groupClient } from '@community-group/api';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

export const getMemberMissionListPath = (groupId: number) =>
  groupClient.api.GroupHomeApi.getapiV2GroupsIdMembersOnboardingGetPath(groupId);

export const useGetMemberMissionList = (groupId: string) => {
  const fetchInstance = useFetchInstance();
  const getMemberMission = groupClient.api.GroupHomeApi.apiV2GroupsIdMembersOnboardingGet({
    axios: fetchInstance,
  });

  const numberGroupId = Number(groupId);
  const path = getMemberMissionListPath(numberGroupId);
  const { data, refetch, isInitialLoading } = useSuspenseQuery({
    queryKey: [path],
    queryFn: () => getMemberMission({ id: numberGroupId }),
  });

  return {
    data,
    refetch,
    isLoading: isInitialLoading,
  };
};
