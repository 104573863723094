import { groupClient } from '@community-group/api';
import {
  JoinableRegionRangeEnum,
  RegionRangesResponse,
} from '@community-group/api/lib/group/models';
import { useQuery } from '@tanstack/react-query';

import { UNSAFE_appInfo } from '@/utils/bridge/appInfo';

import { useFetchInstance } from './instance/useFetchInstance';

export const useGetGroupRegionRanges = (regionId: number) => {
  const fetchInstance = useFetchInstance();
  const getGroupRegionRanges = groupClient.api.GroupRegionApi.apiV1GroupsRegionsRegionIdRangesGet({
    axios: fetchInstance,
  });

  const path = groupClient.api.GroupRegionApi.getapiV1GroupsRegionsRegionIdRangesGetPath(
    Number(regionId)
  );

  const { data } = useQuery({
    queryKey: [path],
    queryFn: () => getGroupRegionRanges({ regionId }),
  });

  return { data: data?.data };
};

export const REGION_RANGE_NAME: Record<JoinableRegionRangeEnum, string> = {
  adjacent: '가까운 동네',
  my: '가까운 동네',
  range2: '조금 먼 동네',
  range3: '먼 동네',
  unlimited: '지역 제한 없음',
};

export const joinableRegionRangeEnumList = Object.values(
  groupClient.model.JoinableRegionRangeEnum
) as groupClient.model.JoinableRegionRangeEnum[];

export const initialGroupRegionRanges = () =>
  ({
    regionInfo: {
      id: UNSAFE_appInfo?.getData().region.id,
      name: UNSAFE_appInfo?.getData().region.name,
      centerCoordinates: {
        ...UNSAFE_appInfo?.getData().region.centerCoordinates,
      },
    },
    joinableRegionRanges: [],
  } as RegionRangesResponse);
