import { Divider, ViewError, ViewLoader, withAsyncBoundary } from '@community-group/components';
import { PaginationList } from '@community-group/components/shared';
import { vars } from '@seed-design/design-token';
import { ActivityComponentType } from '@stackflow/react';
import React, { useMemo } from 'react';
import { useInView } from 'react-intersection-observer';

import { useGetChallengeList } from '@/api/hooks/useGetChallengeList';
import { useGetMe } from '@/api/hooks/useGetMe';
import ImpressionTriggeredComponents from '@/components/common/Impression/ImpressionTriggeredComponents';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { PageParams } from '@/stackflow/types/params';

import EmptyChallengeList from '../../GroupChallengeList/components/EmptyChallengeList';
import ChallengeListItemWithGroupInfo from '../components/ChallengeListItemWithGroupInfo';
import FloatingCreateChallengeButton from '../components/FloatingCreateChallengeButton';
import * as s from './ChallengeListPage.css';

type Params = PageParams;

// 내 근처 챌린지 목록을 보여주는 페이지
const ChallengeListPage: ActivityComponentType<Params> = () => {
  const { refetch } = useGetChallengeList({});
  const { data: userInfo } = useGetMe();

  const { ref, inView } = useInView({
    root: null,
    threshold: 1,
    triggerOnce: false,
  });

  useEnterTrackEvent({
    event: 'enter_all_challenge_list_page',
    params: {},
  });

  return (
    <AppScreen
      appBar={{
        title: `${userInfo.regionCheckIns.regionName} 챌린지`,
        border: !inView,
        borderColor: vars.$semantic.color.divider1,
        borderSize: '1px',
      }}
      onPull={refetch}
    >
      <div className={s.LayoutContainer}>
        <ImpressionTriggeredComponents ref={ref} />
        <ChallengeListCore />
        <FloatingCreateChallengeButton />
      </div>
    </AppScreen>
  );
};

const ChallengeListCore = () => {
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useGetChallengeList({});

  const hasChallengeData = useMemo(() => data?.length > 0, [data]);

  if (!hasChallengeData) {
    return <EmptyChallengeList />;
  }

  return (
    <div className={s.Container}>
      <PaginationList
        items={data ?? []}
        render={(challenge, index) =>
          challenge &&
          challenge.group && (
            <React.Fragment key={'ChallengeListItemWithGroupInfo_' + challenge.id}>
              <ChallengeListItemWithGroupInfo
                groupId={challenge.group.id}
                groupName={challenge.group.name}
                groupProfileImageUrl={challenge.group.profileImage.medium}
                groupRegionName={challenge.group.region.name}
                challenge={challenge}
              />
              {index !== data.length - 1 && <Divider />}
            </React.Fragment>
          )
        }
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
      />
    </div>
  );
};

export default withAsyncBoundary(ChallengeListPage, {
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
});
