import { AxiosInstance, AxiosResponse } from 'axios';

import { useFetchInstance } from './useFetchInstance';

type ReadClient<T, P extends unknown[]> = ({
  axios,
}: {
  axios?: AxiosInstance;
}) => (...params: P) => Promise<AxiosResponse<T>>;

export const useAsyncRead = <T, P extends unknown[]>(readClient: ReadClient<T, P>) => {
  const axios = useFetchInstance({});

  return async (...params: P) => {
    const response = await readClient({ axios })(...params);
    return response.data;
  };
};
