import { PaginationList } from '@community-group/components/shared';
import React from 'react';

import GroupMemberJoinedMeetupItem from '@/domain/GroupMember/components/GroupMemberJoinedMeetupItem';
import { useReadGroupMemberJoinedMeetups } from '@/domain/GroupProfile/hooks/useReadGroupMemberJoinedMeetups';

type Props = {
  groupId: string;
  userId: string;
};

const JoinedMeetupList = ({ groupId, userId }: Props) => {
  const {
    data: items,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useReadGroupMemberJoinedMeetups({ groupId, userId });
  return (
    <PaginationList
      items={items}
      render={(item) => <GroupMemberJoinedMeetupItem {...item} />}
      fetchNextPage={fetchNextPage}
      isFetchingNextPage={isFetchingNextPage}
      hasNextPage={hasNextPage}
    />
  );
};

export default JoinedMeetupList;
