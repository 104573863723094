import { groupClient } from '@community-group/api';
import { GroupAnotherMemberModifyForm } from '@community-group/api/lib/group/models';

import { useRead } from '@/shared/api/hooks/useRead';
import {
  useThrottleMutation,
  UseThrottleMutationOption,
} from '@/shared/api/hooks/useThrottleMutation';

type Params = {
  groupId: string;
  userId: string;
} & GroupAnotherMemberModifyForm;

type Props = UseThrottleMutationOption;

export const usePatchGroupMemberRole = (props: Props) => {
  const fetch = useRead(groupClient.api.GroupMemberApi.apiV1GroupsIdMembersUserIdPatch);
  const mutation = ({ groupId, userId, ...form }: Params) =>
    fetch({
      id: Number(groupId),
      userId: Number(userId),
      groupAnotherMemberModifyForm: form,
    });

  return useThrottleMutation(mutation, props);
};
