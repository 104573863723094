import {
  BoxButton,
  MultilineTextField,
  Spacing,
  Typography,
  useSnackbarAdapter,
} from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useMemo, useState } from 'react';

import { getGroupMainProfileQueryKey } from '@/api/hooks/useGetGroupMainProfile';
import { usePostUserChatRequestGroupPayDirectChats } from '@/api/hooks/usePostUserChatRequestGroupPayDirectChats';
import { GROUP_CHAT_REQUEST_QUERY_KEY } from '@/domain/GroupDirectChat/queries';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { queryClient } from '@/shared/api/instance';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';
import { openLink } from '@/utils/link';

import * as s from './new.css';

type GroupProfileRequestGroupDirectChatNewPageParams = Pick<
  PageParams,
  'groupId' | 'userId' | 'userName' | 'directChatType'
>;

const GroupProfileRequestGroupDirectChatNewPage: ActivityComponentType<
  GroupProfileRequestGroupDirectChatNewPageParams
> = () => {
  const { groupId = '0', userId = '0' } = usePathParams();
  const { userName = '', directChatType = 'profile_direct_chat' } = useQueryParams();

  const { pop } = useFlow();
  const snackbarAdapter = useSnackbarAdapter();

  const contents = useMemo(() => {
    const displayName = userName ? `${userName}님` : '상대방';

    if (directChatType === 'profile_pay_chat')
      return {
        title: (
          <>
            송금하려면 먼저
            <br />
            채팅을 신청해주세요
          </>
        ),
        description: (
          <>
            송금은 채팅방에서 할 수 있어요. 개별 이웃 간 채팅은 상대방의 수락이 필요하니 먼저 채팅을
            신청해주세요.
          </>
        ),
      };

    return {
      title: (
        <>
          메시지를 작성하고
          <br />
          채팅을 신청해보세요
        </>
      ),
      description: <>{displayName}이 수락해야 채팅을 시작할 수 있어요.</>,
    };
  }, [userName, directChatType]);

  const [message, setMessage] = useState('');
  const [focused, setFocused] = useState(false);

  const handleFieldChange = (value: string) => {
    if (value.length > 100) return;
    setMessage(value);
  };

  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate: mutateRequestDirectChat, isPending } = usePostUserChatRequestGroupPayDirectChats({
    onError: handleErrorWithToast,
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: getGroupMainProfileQueryKey(Number(userId), Number(groupId)),
      });
      queryClient.invalidateQueries({
        queryKey: GROUP_CHAT_REQUEST_QUERY_KEY.directChatState(groupId, userId),
      });

      trackEvent({
        event: 'click_submit_request_1on1_chat_button',
        params: {
          groupId,
          message,
          domain: 'group',
        },
        sample: true,
      });
    },
  });

  const handleSubmit = () => {
    mutateRequestDirectChat(
      {
        groupId: Number(groupId),
        targetUserId: Number(userId),
        message,
      },
      {
        onSuccess: () => {
          pop();
          snackbarAdapter.create({
            message: '채팅을 신청했어요.',
            type: 'default',
            timeout: 3000,
            onClick: () => {
              snackbarAdapter.dismiss();
            },
          });
        },
      }
    );
  };

  const handleCommunityGuidelineClick = () => {
    openLink('https://www.daangn.com/wv/faqs/601');
  };

  return (
    <AppScreen
      accessoryBar={
        <>
          {!focused && (
            <div className={s.AccessoryBarTopWrapper}>
              <Typography typography="caption1Regular" color="gray600" textAlign="center">
                채팅을 주고받을 때는{' '}
                <span
                  onClick={handleCommunityGuidelineClick}
                  style={{ textDecorationLine: 'underline' }}
                >
                  커뮤니티 가이드라인
                </span>
                을 준수하고
                <br />
                서로 존중해주세요.
              </Typography>
            </div>
          )}
          <div
            className={s.AccessoryBarWrapper}
            style={{
              padding: focused
                ? '0'
                : '0.75rem 1rem calc(0.5rem + env(safe-area-inset-bottom)) 1rem',
            }}
          >
            <BoxButton
              width="100%"
              size="xlarge"
              variant="primary"
              isDisabled={(message ?? '').length <= 0}
              isLoading={isPending}
              UNSAFE_style={focused ? { borderRadius: 0 } : undefined}
              onClick={handleSubmit}
            >
              신청하기
            </BoxButton>
          </div>
        </>
      }
    >
      <section className={s.Wrapper}>
        <Typography typography="title1Bold">{contents.title}</Typography>
        <Spacing size={8} />
        <Typography typography="subtitle1Regular">{contents.description}</Typography>
        <Spacing size={24} />
        <MultilineTextField
          placeholder="채팅 요청 메시지를 작성해주세요."
          onChange={handleFieldChange}
          value={message}
          onFocus={() => setFocused(true)}
          onBlur={() => {
            setTimeout(() => setFocused(false), 100); // 100ms 후에 focused 상태를 false로 설정
          }}
        />
      </section>
    </AppScreen>
  );
};

export default GroupProfileRequestGroupDirectChatNewPage;
