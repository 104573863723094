import { GroupPermissionsResponsePermissions } from '@community-group/api/lib/group/models';
import { Divider, ViewError, ViewLoader, withAsyncBoundary } from '@community-group/components';
import { SettingList } from '@community-group/components/features';
import { ActivityComponentType } from '@stackflow/react';
import { useSuspenseQueries } from '@tanstack/react-query';
import { useCallback } from 'react';
import { $Keys } from 'utility-types';

import { useQueryGroupMemberGrades } from '@/domain/GroupMember/hooks/useReadGroupMemberGrades';
import { useQueryGroupMemberGradeStatus } from '@/domain/GroupMember/hooks/useReadGroupMemberGradeStatus';
import { useQueryGroupPermissions } from '@/domain/GroupPermission/hooks/useReadGroupPermissions';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';
import { parsePermissionDescription } from '@/utils/permission';

export type GroupSettingPageParams = Pick<PageParams, 'groupId'>;

const GroupSettingPermissionPage: ActivityComponentType<GroupSettingPageParams> = () => {
  const { groupId } = usePathParams();
  const memberGradeEnabled = useFeatureFlag('memberGradeEnabled');

  const { push } = useFlow();

  const [{ data: memberGrades }, { data: permissions }, { data: memberGradeStatus }] =
    useSuspenseQueries({
      queries: [
        useQueryGroupMemberGrades(groupId),
        useQueryGroupPermissions(groupId),
        useQueryGroupMemberGradeStatus(groupId),
      ],
    });

  const handleOpenBottomSheet = useCallback(
    (permission: $Keys<GroupPermissionsResponsePermissions>) => {
      push('BottomSheet/GroupSettingEditPermissionBottomSheet', {
        groupId,
        targetPermission: permission,
      });
    },
    [groupId, push]
  );

  return (
    <AppScreen
      appBar={{
        title: '멤버 권한 관리',
      }}
    >
      <SettingList.Wrapper title="게시판">
        <SettingList.Item
          title="공지사항 등록"
          description={parsePermissionDescription(
            memberGrades,
            permissions.addNoticePost.currentPriority
          )}
          onClick={() => handleOpenBottomSheet('addNoticePost')}
        />
        <SettingList.Item
          title="다른 멤버의 게시글, 댓글 삭제"
          description={parsePermissionDescription(
            memberGrades,
            permissions.deletePostAndComment.currentPriority
          )}
          onClick={() => handleOpenBottomSheet('deletePostAndComment')}
        />
        <SettingList.Item
          title="게시글 공개 범위 수정"
          description={parsePermissionDescription(
            memberGrades,
            permissions.modifyPostGroupOnly.currentPriority
          )}
          onClick={() => handleOpenBottomSheet('modifyPostGroupOnly')}
        />
      </SettingList.Wrapper>
      <Divider />
      <SettingList.Wrapper title="모임 활동">
        <SettingList.Item
          title="1:1 채팅"
          description={parsePermissionDescription(
            memberGrades,
            permissions.openDirectChat.currentPriority
          )}
          onClick={() => handleOpenBottomSheet('openDirectChat')}
        />
        {memberGradeEnabled && (
          <SettingList.Item
            title="일정 만들기"
            description={parsePermissionDescription(
              memberGrades,
              permissions.createGroupMeetup.currentPriority
            )}
            onClick={() => handleOpenBottomSheet('createGroupMeetup')}
          />
        )}
      </SettingList.Wrapper>
      <Divider />
      <SettingList.Wrapper title="멤버 관리">
        <SettingList.Item
          title="멤버 가입 승인"
          description={parsePermissionDescription(
            memberGrades,
            permissions.applyApplicationMember.currentPriority
          )}
          onClick={() => handleOpenBottomSheet('applyApplicationMember')}
        />
        <SettingList.Item
          title="멤버 내보내기 및 차단"
          description={parsePermissionDescription(
            memberGrades,
            permissions.restrictMember.currentPriority
          )}
          onClick={() => handleOpenBottomSheet('restrictMember')}
        />
        {memberGradeEnabled && memberGradeStatus === 'system' && (
          <SettingList.Item
            title="멤버 등업"
            description={parsePermissionDescription(
              memberGrades,
              permissions.modifyGroupMemberGrade.currentPriority
            )}
            onClick={() => handleOpenBottomSheet('modifyGroupMemberGrade')}
          />
        )}
      </SettingList.Wrapper>
      <Divider />
      <SettingList.Wrapper title="기타">
        <SettingList.Item
          title="모임장봇 설정"
          description={parsePermissionDescription(
            memberGrades,
            permissions.modifyGroupBotSetting.currentPriority
          )}
          onClick={() => handleOpenBottomSheet('modifyGroupBotSetting')}
        />
      </SettingList.Wrapper>
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupSettingPermissionPage, {
  pendingFallback: (
    <>
      <AppScreen>
        <ViewLoader />
      </AppScreen>
    </>
  ),
  rejectedFallback: (
    <>
      <AppScreen>
        <ViewError />
      </AppScreen>
    </>
  ),
});
