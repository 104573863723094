import React from 'react';

import { useGetGroupMe } from '@/api/hooks/useGetGroupMe';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useStorage } from '@/hooks/useStorage';
import { useFlow } from '@/stackflow';
import { useStore } from '@/store';
import { isNotMember } from '@/utils/role';

// 2025.01 챌린지 인증 이벤트 진행중 안내 바텀시트 오픈 여부 훅
const useCheckOpenChallengeCertifyPromotionBottomSheet = (groupId?: string) => {
  const { push } = useFlow();

  const { data: groupMe } = useGetGroupMe(groupId);
  const {
    isChallengeCertifyPromotionBottomSheetOpened: isChallengeCertifyPromotionBottomSheetOpenedStore,
    setIsChallengeCertifyPromotionBottomSheetOpened:
      setIsChallengeCertifyPromotionBottomSheetOpenedStore,
  } = useStore();

  const enableChallengePromotion = useFeatureFlag('enableChallengePromotion');

  const [
    isChallengeCertifyPromotionBottomSheetOpened,
    setIsChallengeCertifyPromotionBottomSheetOpened,
  ] = useStorage('isChallengeCertifyPromotionBottomSheetOpened', false);

  const checkOpenChallengeCertifyPromotionBottomSheet = React.useCallback(() => {
    if (!enableChallengePromotion) return false;

    if (groupId && isNotMember(groupMe?.currentUser.role)) return false;

    if (
      !isChallengeCertifyPromotionBottomSheetOpened &&
      !isChallengeCertifyPromotionBottomSheetOpenedStore
    ) {
      push('BottomSheet/ChallengePromotionBottomSheet', {});
      setIsChallengeCertifyPromotionBottomSheetOpened(true);
      setIsChallengeCertifyPromotionBottomSheetOpenedStore(true);
      return true;
    }

    return false;
  }, [
    enableChallengePromotion,
    groupId,
    groupMe?.currentUser.role,
    isChallengeCertifyPromotionBottomSheetOpened,
    isChallengeCertifyPromotionBottomSheetOpenedStore,
    push,
    setIsChallengeCertifyPromotionBottomSheetOpened,
    setIsChallengeCertifyPromotionBottomSheetOpenedStore,
  ]);

  return checkOpenChallengeCertifyPromotionBottomSheet;
};

export default useCheckOpenChallengeCertifyPromotionBottomSheet;
