import { groupClient } from '@community-group/api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { GROUP_PROFILE_QUERY_KEY } from '@/domain/GroupProfile/queries';
import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

export const useQueryCheckHasGroupProfile = () => {
  const read = useAsyncRead(groupClient.api.GroupProfileApi.apiV1GroupsProfileCheckGet);
  return {
    queryKey: GROUP_PROFILE_QUERY_KEY.checkHasGroupProfile(),
    queryFn: () => read().then((res) => res.isMainProfileSet),
  };
};

export const useReadCheckHasGroupProfile = () => {
  const query = useQueryCheckHasGroupProfile();
  return useSuspenseQuery(query);
};
