import { isMember, Spacing, Typography } from '@community-group/components';
import { PaginationList } from '@community-group/components/shared';
import { IconChevronRightFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { useSuspenseQueries } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useGetChallengeParticipantsRank } from '@/api/hooks/useGetChallengeParticipantsRank';
import { useUserConfig } from '@/contexts/UserConfig';
import { useReadGroupChallengeCertifyFeeds } from '@/domain/GroupChallenge/hooks/useReadGroupChallengeCertifyFeeds';
import { useQueryGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

import ChallengeCertifyList from './ChallengeCertifyList';
import ParticipantsRankItem from './ParticipantsRankItem';
import * as s from './style.css';

const ParticipantsStatusSection = () => {
  const { groupId, challengeId } = usePathParams();
  const { userConfig } = useUserConfig();
  const { fetchNextPage, hasNextPage, isFetchingNextPage, data } = useGetChallengeParticipantsRank({
    groupId: Number(groupId),
    challengeId: Number(challengeId),
  });

  const [{ data: groupMe }] = useSuspenseQueries({
    queries: [useQueryGroupMe({ groupId })],
  });

  const { data: challengeCertifyFeeds } = useReadGroupChallengeCertifyFeeds({
    groupId: Number(groupId),
    challengeId: Number(challengeId),
  });

  const { push } = useFlow();

  const handleMoveToCertifyFeeds = useCallback(() => {
    push('ChallengeCertifyFeedsPage', {
      groupId: groupId.toString(),
      challengeId: challengeId.toString(),
    });
  }, [challengeId, groupId, push]);

  // 이미지 있는 아이템만 노출 + 멤버 또는 공개 인증만 노출
  const filteredCertifyFeeds = challengeCertifyFeeds
    .filter((el) => el.medias.length !== 0 && el.medias.some((media) => media.type === 'image'))
    .filter((el) => isMember(groupMe.role) || el.publishType === 'public');

  const hasChallengeCertifyFeeds = filteredCertifyFeeds.length !== 0;

  return (
    <div className={s.Container}>
      <div className={s.TitleWrapper}>
        <Typography typography="title3Bold">전체 인증 현황</Typography>
        {hasChallengeCertifyFeeds && (
          <div className={s.MoreButtonWrapper} onClick={handleMoveToCertifyFeeds}>
            <Typography typography="label3Regular" color="gray900">
              더보기
            </Typography>
            <IconChevronRightFill size={14} color={vars.$scale.color.gray900} />
          </div>
        )}
      </div>
      {hasChallengeCertifyFeeds ? (
        <>
          <Spacing size={20} />
          <ChallengeCertifyList
            challengeCertifyFeeds={filteredCertifyFeeds}
            groupId={Number(groupId)}
            challengeId={Number(challengeId)}
          />
          <Spacing size={16} />
        </>
      ) : (
        <Spacing size={8} />
      )}

      <PaginationList
        items={data?.pages ?? []}
        render={(item) =>
          item && (
            <ParticipantsRankItem
              key={item.id}
              groupId={groupId}
              isMe={item.id === userConfig.userId}
              {...item}
            />
          )
        }
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
      />
    </div>
  );
};

export default ParticipantsStatusSection;
