import { GroupMemberSummaryPresentation } from '@community-group/api/lib/group/models';
import { Dialog, isHigherManager, useDialog } from '@community-group/components';
import { useCallback, useMemo } from 'react';

import { getGroupPath } from '@/api/base/group';
import { getGroupMainProfileQueryKey } from '@/api/hooks/useGetGroupMainProfile';
import { MemberListOrderType } from '@/api/hooks/useGetMemberList';
import { usePatchGroupMembersRole } from '@/api/hooks/usePatchGroupMembersRole';
import { useBridge } from '@/contexts/Bridge';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { queryClient } from '@/shared/api/instance';

export const useHandleManagerRoleProfile = (
  groupId: string,
  user: GroupMemberSummaryPresentation,
  hostMemberList: GroupMemberSummaryPresentation[],
  shownOnlyHost?: boolean,
  selectedMemberOrderType?: MemberListOrderType
) => {
  const { open: openDialog, close: closeDialog } = useDialog();

  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate: patchGroupMembersRole } = usePatchGroupMembersRole({
    onError: handleErrorWithToast,
  });

  const isHigherManagerRole = useMemo(
    () => isHigherManager(user.role),
    [groupId, user?.role, shownOnlyHost, selectedMemberOrderType]
  );
  const roleLabel = useMemo(
    () => (isHigherManagerRole ? '운영진에서 제외' : '운영진으로 설정'),
    [isHigherManagerRole]
  );

  const hostMemberCount = useMemo(() => hostMemberList.length, [hostMemberList]);

  const refetchMemberList = useCallback(() => {
    setTimeout(() => {
      queryClient.refetchQueries({
        queryKey: [`${getGroupPath(groupId)}/members`, selectedMemberOrderType, shownOnlyHost],
      });

      queryClient.refetchQueries({
        queryKey: [`${getGroupPath(groupId)}/members`, undefined, true],
      });
      queryClient.refetchQueries({
        queryKey: getGroupMainProfileQueryKey(user.id, parseInt(groupId)),
      });
    }, 30);
  }, [groupId, selectedMemberOrderType, shownOnlyHost, user?.id]);
  const { bridge } = useBridge();
  const handleSetHost = useCallback(async () => {
    await closeDialog();

    // TODO: hostMemberCount가 실시간으로 반영 안되는 버그가 있어요. 대응이 필요해요.

    // hostMemberCount 는 (모임장 + 운영진) 의 합계로, 모임장은 운영진 수에 포함되지 않아요.
    if (hostMemberCount > 10) {
      return bridge.openToast({
        toast: {
          body: '운영진은 최대 10명까지 설정할 수 있어요.',
        },
      });
    }

    return openDialog({
      element: (
        <Dialog
          title="운영진 설정"
          description={`${user.nickname}님을 운영진으로 설정할까요?`}
          primaryActionLabel="설정"
          secondaryActionLabel="취소"
          onPrimaryAction={async () => {
            patchGroupMembersRole(
              {
                groupId,
                member: user,
                role: 'manager',
              },
              {
                onSuccess: () => {
                  bridge.openToast({
                    toast: {
                      body: `${user.nickname}님을 운영진으로 설정했어요.`,
                    },
                  });

                  refetchMemberList();
                },
              }
            );

            await closeDialog();
          }}
          onSecondaryAction={closeDialog}
        />
      ),
    });
  }, [groupId, hostMemberCount, user?.role, shownOnlyHost, selectedMemberOrderType]);

  const handleUnsetHost = useCallback(async () => {
    await closeDialog();

    return openDialog({
      element: (
        <Dialog
          title="운영진 제외"
          description={`${user.nickname}님을 운영진에서 제외할까요?`}
          primaryActionLabel="제외"
          secondaryActionLabel="취소"
          onPrimaryAction={async () => {
            patchGroupMembersRole(
              {
                groupId,
                member: user,
                role: 'member',
              },
              {
                onSuccess: () => {
                  bridge.openToast({
                    toast: {
                      body: `${user.nickname}님을 운영진에서 제외했어요.`,
                    },
                  });

                  refetchMemberList();
                },
              }
            );

            await closeDialog();
          }}
          onSecondaryAction={closeDialog}
        />
      ),
    });
  }, [groupId, hostMemberList, user?.role, shownOnlyHost, selectedMemberOrderType]);

  return {
    roleLabel: useMemo(() => roleLabel, [roleLabel]),
    handleManagerRoleProfile: useMemo(
      () => (isHigherManagerRole ? handleUnsetHost : handleSetHost),
      [isHigherManagerRole, handleSetHost, handleUnsetHost]
    ),
  };
};
