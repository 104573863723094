import { GroupRestrictedMemberPresentation } from '@community-group/api/lib/group/models';
import { useBottomSheet, withAsyncBoundary } from '@community-group/components';
import { Spacing } from '@community-group/components';
import { IconDot3VerticalLine } from '@daangn/react-monochrome-icon';
import dayjs from 'dayjs';

import { useGetRestrictedMembers } from '@/api/hooks/useGetRestrictedMembers';
import EmptySection from '@/components/common/Empty';
import { LoadMoreListContainer } from '@/components/common/LoadMoreContainer';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

import MemberProfileDetailRestrictedBottomSheet from './bottomSheet/MemberProfileDetailRestrictedBottomSheet';
import * as s from './MemberProfileDetailList.css';
import MemberProfileSection from './MemberProfileSection';

const MemberProfileDetailRestrictedList = () => {
  const { groupId } = usePathParams();
  const { data, hasNextPage, isFetchingNextPage, fetchNextPage } = useGetRestrictedMembers(groupId);

  const { open } = useBottomSheet();

  const restrictedList = data?.pages.map(({ data }) => data.restrictedMembers) ?? [];
  const flatten = restrictedList.flat(1);

  const handleLoadMoreImpression = () => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };

  const { push } = useFlow();
  const getMoveGroupUserProfileDetailPage = (userId: number) => () => {
    push('GroupUserProfileDetailPage', {
      groupId,
      userId: userId.toString(),
    });
  };

  const RightButton = (user: GroupRestrictedMemberPresentation) => {
    return (
      <button
        onClick={() => {
          open({
            element: <MemberProfileDetailRestrictedBottomSheet {...user} groupId={groupId} />,
          });
        }}
      >
        <IconDot3VerticalLine size={24} />
      </button>
    );
  };

  if (flatten.length < 1) {
    return (
      <>
        <Spacing size={4} />
        <EmptySection>내보낸 이웃이 없어요.</EmptySection>
      </>
    );
  }

  return (
    <div className={s.Wrapper}>
      <Spacing size={4} />
      {flatten.map((user) => (
        <MemberProfileSection
          key={user.id}
          user={{
            ...user,
            description:
              user.restrictedState === 'REMOVED'
                ? `${dayjs(user.restrictedAt).format('YY.MM.DD')} 내보냄`
                : `${dayjs(user.restrictedAt).format('YY.MM.DD')} 차단`,
          }}
          renderRightButton={<RightButton {...user} />}
          moveGroupUserProfileDetailPage={getMoveGroupUserProfileDetailPage(user.id)}
        />
      ))}
      {hasNextPage && <LoadMoreListContainer callback={handleLoadMoreImpression} />}
    </div>
  );
};

export default withAsyncBoundary(MemberProfileDetailRestrictedList, {});
