export type TabKey = 'joint' | 'pending' | 'restricted' | 'memberGradeApplication';

type Props = {
  hasApprovedMemberPermission: boolean;
  hasRestrictMemberPermission: boolean;
  hasMemberGradeApplicationPermission: boolean;
};

export const getMemberListForHostTabKeys = ({
  hasApprovedMemberPermission,
  hasRestrictMemberPermission,
  hasMemberGradeApplicationPermission,
}: Props): TabKey[] => {
  const tabKeys: TabKey[] = ['joint'];

  if (hasApprovedMemberPermission) {
    tabKeys.push('pending');
  }

  if (hasMemberGradeApplicationPermission) {
    tabKeys.push('memberGradeApplication');
  }

  if (hasRestrictMemberPermission) {
    tabKeys.push('restricted');
  }

  return tabKeys;
};
