import { groupClient } from '@community-group/api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { GROUP_CHAT_REQUEST_QUERY_KEY } from '@/domain/GroupDirectChat/queries';
import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

type Props = {
  groupId: string;
  userId: string;
};

export const useQueryGroupDirectChatState = ({ groupId, userId }: Props) => {
  const read = useAsyncRead(
    groupClient.api.GroupDirectChatApi.apiV1GroupsGroupIdMembersUserIdDirectChatStateGet
  );
  return {
    queryKey: GROUP_CHAT_REQUEST_QUERY_KEY.directChatState(groupId, userId),
    queryFn: () => read({ groupId: Number(groupId), userId: Number(userId) }),
  };
};

export const useReadGroupDirectChatState = ({ groupId, userId }: Props) => {
  const query = useQueryGroupDirectChatState({ groupId, userId });
  return useSuspenseQuery({ ...query });
};
