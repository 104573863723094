import { groupClient } from '@community-group/api';
import {
  ChallengeListResponse,
  ChallengeWithGroupSummaryPresentation,
} from '@community-group/api/lib/group/models';
import { InfiniteData, useSuspenseInfiniteQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useFetchInstance } from './instance/useFetchInstance';

export const getChallengeListPath = (cursor?: string, limit?: number) =>
  groupClient.api.GroupChallengeApi.getapiV1ChallengesGetPath(cursor, limit);

// 내가 가입가능한 모임의 챌린지 목록 조회
export const useGetChallengeList = ({
  initialCursor = undefined,
  limit = 30,
}: {
  initialCursor?: string;
  limit?: number;
}) => {
  const fetchInstance = useFetchInstance();
  const getChallengeList = groupClient.api.GroupChallengeApi.apiV1ChallengesGet({
    axios: fetchInstance,
  });

  const { fetchNextPage, hasNextPage, isFetchingNextPage, data, refetch } =
    useSuspenseInfiniteQuery({
      queryKey: [getChallengeListPath()],
      queryFn: ({ pageParam = initialCursor }) => {
        // return challengeListMockResponse;
        return getChallengeList({ cursor: pageParam, limit });
      },
      initialPageParam: initialCursor,
      getNextPageParam: ({ data }) =>
        data.pagination?.hasNext ? data?.pagination.endCursor : undefined,
      refetchInterval: 10000,
      select: selectPaginationFlatten,
    });

  return {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  };
};

function selectPaginationFlatten(
  data: InfiniteData<{ data: ChallengeListResponse }> | undefined
): ChallengeWithGroupSummaryPresentation[] {
  if (!data) return [];
  return data.pages.flatMap((page) => page.data.contents ?? []);
}

export const challengeListMockResponse: AxiosResponse<ChallengeListResponse> = {
  data: {
    contents: [
      {
        id: 12345,
        name: '매일 아침 운동하기',
        thumbnail: {
          id: '123',
          url: 'https://picsum.photos/200/300?random=1',
          small: 'https://picsum.photos/200/300?random=1',
          medium: 'https://picsum.photos/200/300?random=1',
          large: 'https://picsum.photos/200/300?random=1',
          width: 800,
          height: 600,
        },
        status: 'opened',
        certifyIntervalDays: 1,
        certifyIntervalTargetCount: 1,
        totalDays: 30,
        startedAt: '2024-03-15T00:00:00Z',
        endedAt: '2024-04-14T23:59:59Z',
        participantsCount: 128,
        group: {
          id: 12345,
          name: '매일 아침 운동하기',
          profileImage: {
            id: '123',
            url: 'https://picsum.photos/200/300?random=1',
            small: 'https://picsum.photos/200/300?random=1',
            medium: 'https://picsum.photos/200/300?random=1',
            large: 'https://picsum.photos/200/300?random=1',
            width: 800,
            height: 600,
          },
          region: {
            id: 123,
            name: '서울특별시',
            province: '서울특별시',
            city: '서울특별시',
            town: '서울특별시',
          },
        },
      },
      {
        id: 12346,
        name: '하루 3잔 물마시기',
        thumbnail: {
          id: '124',
          url: 'https://picsum.photos/200/300?random=2',
          small: 'https://picsum.photos/200/300?random=2',
          medium: 'https://picsum.photos/200/300?random=2',
          large: 'https://picsum.photos/200/300?random=2',
          width: 800,
          height: 600,
        },
        status: 'opened',
        certifyIntervalDays: 1,
        certifyIntervalTargetCount: 3,
        totalDays: 14,
        startedAt: '2024-03-20T00:00:00Z',
        endedAt: '2024-04-03T23:59:59Z',
        participantsCount: 256,
        group: {
          id: 12345,
          name: '매일 아침 운동하기',
          profileImage: {
            id: '123',
            url: 'https://picsum.photos/200/300?random=1',
            small: 'https://picsum.photos/200/300?random=1',
            medium: 'https://picsum.photos/200/300?random=1',
            large: 'https://picsum.photos/200/300?random=1',
            width: 800,
            height: 600,
          },
          region: {
            id: 123,
            name: '서울특별시',
            province: '서울특별시',
            city: '서울특별시',
            town: '서울특별시',
          },
        },
      },
      {
        id: 12347,
        name: '독서 30분',
        thumbnail: {
          id: '125',
          url: 'https://picsum.photos/200/300?random=3',
          small: 'https://picsum.photos/200/300?random=3',
          medium: 'https://picsum.photos/200/300?random=3',
          large: 'https://picsum.photos/200/300?random=3',
          width: 800,
          height: 600,
        },
        status: 'opened',
        certifyIntervalDays: 1,
        certifyIntervalTargetCount: 1,
        totalDays: 21,
        startedAt: '2024-03-18T00:00:00Z',
        endedAt: '2024-04-08T23:59:59Z',
        participantsCount: 89,
        group: {
          id: 12345,
          name: '매일 아침 운동하기',
          profileImage: {
            id: '123',
            url: 'https://picsum.photos/200/300?random=1',
            small: 'https://picsum.photos/200/300?random=1',
            medium: 'https://picsum.photos/200/300?random=1',
            large: 'https://picsum.photos/200/300?random=1',
            width: 800,
            height: 600,
          },
          region: {
            id: 123,
            name: '서울특별시',
            province: '서울특별시',
            city: '서울특별시',
            town: '서울특별시',
          },
        },
      },
      {
        id: 12348,
        name: '주 3회 헬스장 가기',
        thumbnail: {
          id: '126',
          url: 'https://picsum.photos/200/300?random=4',
          small: 'https://picsum.photos/200/300?random=4',
          medium: 'https://picsum.photos/200/300?random=4',
          large: 'https://picsum.photos/200/300?random=4',
          width: 800,
          height: 600,
        },
        status: 'opened',
        certifyIntervalDays: 7,
        certifyIntervalTargetCount: 3,
        totalDays: 28,
        startedAt: '2024-03-25T00:00:00Z',
        endedAt: '2024-04-22T23:59:59Z',
        participantsCount: 145,
        group: {
          id: 12345,
          name: '매일 아침 운동하기',
          profileImage: {
            id: '123',
            url: 'https://picsum.photos/200/300?random=1',
            small: 'https://picsum.photos/200/300?random=1',
            medium: 'https://picsum.photos/200/300?random=1',
            large: 'https://picsum.photos/200/300?random=1',
            width: 800,
            height: 600,
          },
          region: {
            id: 123,
            name: '서울특별시',
            province: '서울특별시',
            city: '서울특별시',
            town: '서울특별시',
          },
        },
      },
      {
        id: 12349,
        name: '영어 단어 50개 암기',
        thumbnail: {
          id: '127',
          url: 'https://picsum.photos/200/300?random=5',
          small: 'https://picsum.photos/200/300?random=5',
          medium: 'https://picsum.photos/200/300?random=5',
          large: 'https://picsum.photos/200/300?random=5',
          width: 800,
          height: 600,
        },
        status: 'opened',
        certifyIntervalDays: 1,
        certifyIntervalTargetCount: 1,
        totalDays: 30,
        startedAt: '2024-04-01T00:00:00Z',
        endedAt: '2024-04-30T23:59:59Z',
        participantsCount: 167,
        group: {
          id: 12345,
          name: '매일 아침 운동하기',
          profileImage: {
            id: '123',
            url: 'https://picsum.photos/200/300?random=1',
            small: 'https://picsum.photos/200/300?random=1',
            medium: 'https://picsum.photos/200/300?random=1',
            large: 'https://picsum.photos/200/300?random=1',
            width: 800,
            height: 600,
          },
          region: {
            id: 123,
            name: '서울특별시',
            province: '서울특별시',
            city: '서울특별시',
            town: '서울특별시',
          },
        },
      },
      {
        id: 12350,
        name: '아침 명상 10분',
        thumbnail: {
          id: '128',
          url: 'https://picsum.photos/200/300?random=6',
          small: 'https://picsum.photos/200/300?random=6',
          medium: 'https://picsum.photos/200/300?random=6',
          large: 'https://picsum.photos/200/300?random=6',
          width: 800,
          height: 600,
        },
        status: 'opened',
        certifyIntervalDays: 1,
        certifyIntervalTargetCount: 1,
        totalDays: 15,
        startedAt: '2024-03-22T00:00:00Z',
        endedAt: '2024-04-06T23:59:59Z',
        participantsCount: 78,
        group: {
          id: 12345,
          name: '매일 아침 운동하기',
          profileImage: {
            id: '123',
            url: 'https://picsum.photos/200/300?random=1',
            small: 'https://picsum.photos/200/300?random=1',
            medium: 'https://picsum.photos/200/300?random=1',
            large: 'https://picsum.photos/200/300?random=1',
            width: 800,
            height: 600,
          },
          region: {
            id: 123,
            name: '서울특별시',
            province: '서울특별시',
            city: '서울특별시',
            town: '서울특별시',
          },
        },
      },
      {
        id: 12351,
        name: '플랭크 1분 버티기',
        thumbnail: {
          id: '129',
          url: 'https://picsum.photos/200/300?random=7',
          small: 'https://picsum.photos/200/300?random=7',
          medium: 'https://picsum.photos/200/300?random=7',
          large: 'https://picsum.photos/200/300?random=7',
          width: 800,
          height: 600,
        },
        status: 'opened',
        certifyIntervalDays: 1,
        certifyIntervalTargetCount: 1,
        totalDays: 14,
        startedAt: '2024-03-28T00:00:00Z',
        endedAt: '2024-04-11T23:59:59Z',
        participantsCount: 234,
        group: {
          id: 12345,
          name: '매일 아침 운동하기',
          profileImage: {
            id: '123',
            url: 'https://picsum.photos/200/300?random=1',
            small: 'https://picsum.photos/200/300?random=1',
            medium: 'https://picsum.photos/200/300?random=1',
            large: 'https://picsum.photos/200/300?random=1',
            width: 800,
            height: 600,
          },
          region: {
            id: 123,
            name: '서울특별시',
            province: '서울특별시',
            city: '서울특별시',
            town: '서울특별시',
          },
        },
      },
      {
        id: 12352,
        name: '일기 쓰기',
        thumbnail: {
          id: '130',
          url: 'https://picsum.photos/200/300?random=8',
          small: 'https://picsum.photos/200/300?random=8',
          medium: 'https://picsum.photos/200/300?random=8',
          large: 'https://picsum.photos/200/300?random=8',
          width: 800,
          height: 600,
        },
        status: 'opened',
        certifyIntervalDays: 1,
        certifyIntervalTargetCount: 1,
        totalDays: 30,
        startedAt: '2024-04-01T00:00:00Z',
        endedAt: '2024-04-30T23:59:59Z',
        participantsCount: 156,
        group: {
          id: 12345,
          name: '매일 아침 운동하기',
          profileImage: {
            id: '123',
            url: 'https://picsum.photos/200/300?random=1',
            small: 'https://picsum.photos/200/300?random=1',
            medium: 'https://picsum.photos/200/300?random=1',
            large: 'https://picsum.photos/200/300?random=1',
            width: 800,
            height: 600,
          },
          region: {
            id: 123,
            name: '서울특별시',
            province: '서울특별시',
            city: '서울특별시',
            town: '서울특별시',
          },
        },
      },
      {
        id: 12353,
        name: '주말 등산',
        thumbnail: {
          id: '131',
          url: 'https://picsum.photos/200/300?random=9',
          small: 'https://picsum.photos/200/300?random=9',
          medium: 'https://picsum.photos/200/300?random=9',
          large: 'https://picsum.photos/200/300?random=9',
          width: 800,
          height: 600,
        },
        status: 'opened',
        certifyIntervalDays: 7,
        certifyIntervalTargetCount: 1,
        totalDays: 28,
        startedAt: '2024-04-06T00:00:00Z',
        endedAt: '2024-05-04T23:59:59Z',
        participantsCount: 92,
        group: {
          id: 12345,
          name: '매일 아침 운동하기',
          profileImage: {
            id: '123',
            url: 'https://picsum.photos/200/300?random=1',
            small: 'https://picsum.photos/200/300?random=1',
            medium: 'https://picsum.photos/200/300?random=1',
            large: 'https://picsum.photos/200/300?random=1',
            width: 800,
            height: 600,
          },
          region: {
            id: 123,
            name: '서울특별시',
            province: '서울특별시',
            city: '서울특별시',
            town: '서울특별시',
          },
        },
      },
      {
        id: 12354,
        name: '코딩 1시간',
        thumbnail: {
          id: '132',
          url: 'https://picsum.photos/200/300?random=10',
          small: 'https://picsum.photos/200/300?random=10',
          medium: 'https://picsum.photos/200/300?random=10',
          large: 'https://picsum.photos/200/300?random=10',
          width: 800,
          height: 600,
        },
        status: 'opened',
        certifyIntervalDays: 1,
        certifyIntervalTargetCount: 1,
        totalDays: 21,
        startedAt: '2024-03-25T00:00:00Z',
        endedAt: '2024-04-15T23:59:59Z',
        participantsCount: 134,
        group: {
          id: 12345,
          name: '매일 아침 운동하기',
          profileImage: {
            id: '123',
            url: 'https://picsum.photos/200/300?random=1',
            small: 'https://picsum.photos/200/300?random=1',
            medium: 'https://picsum.photos/200/300?random=1',
            large: 'https://picsum.photos/200/300?random=1',
            width: 800,
            height: 600,
          },
          region: {
            id: 123,
            name: '서울특별시',
            province: '서울특별시',
            city: '서울특별시',
            town: '서울특별시',
          },
        },
      },
    ],
    pagination: {
      endCursor: '1',
      hasNext: true,
    },
  },
  status: 200,
  statusText: 'OK',
  headers: {},
  config: {
    headers: {} as any,
  },
};
