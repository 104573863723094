import { useActivity } from '@stackflow/react';
import { useEffect } from 'react';

import { useBridge } from '@/contexts/Bridge';

const LEAVE_GROUP_CHAT_ROOM_EVENT_KEY = 'COMMUNITY_GROUP_LEAVE_CHAT';

type LEAVE_GROUP_CHAT_ROOM_TYPE = {
  EVENT_KEY: typeof LEAVE_GROUP_CHAT_ROOM_EVENT_KEY;
  callback: (data: { channelId: number; isAptDomain: boolean; groupId: number }) => void;
};

type Params =
  | LEAVE_GROUP_CHAT_ROOM_TYPE
  | {
      EVENT_KEY: string;
      callback: (data: any) => void;
    };

export const useSubscribedStream = ({ EVENT_KEY, callback }: Params) => {
  const { isActive, isTop } = useActivity();
  const { bridge } = useBridge();
  useEffect(() => {
    if (!isTop) return;
    if (!isActive) return;

    const disposeSubscribedPoiStream = bridge.dangerouslySubscribeStream({}, (error, response) => {
      if (error) throw error;

      if (!response) return;

      const { stream } = response;

      if (stream.eventName === EVENT_KEY) {
        const response = stream.data ? JSON.parse(stream.data) : {};

        console.log(stream.eventName, response);

        callback(response);
      }
    });

    return () => {
      disposeSubscribedPoiStream?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, isTop]);
};
