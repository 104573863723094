import './styles';

import { ViewError } from '@community-group/components';
import { ErrorBoundary } from '@sentry/react';
import cookie from 'cookie';
import { hydrateRoot } from 'react-dom/client';

import ClientSideQueryProvider from '@/_app/providers/ClientSideQueryProvider';

import App from './_app';
import { BASE_COOKIE } from './_app/streamingServer/constants';
import { getAppFoundationQuery } from './libs/app-foundation/utils';
import datadogRum from './utils/datadogRum';
import sentry from './utils/sentry';

const rootElement = document.getElementById('root')!;
const parsedCookie = cookie.parse(document.cookie);

const dehydratedState = window.__REACT_QUERY_STATE__;

(async () => {
  const { appEnv } = globalThis.appConfig;

  try {
    if (appEnv !== 'local') {
      datadogRum.init();
      sentry.init();
    }
  } catch (error) {
    console.log(`[Sentry & DataDogRum] Initialize Error: ${error}`);
  }

  hydrateRoot(
    rootElement,
    <ErrorBoundary
      fallback={() => {
        return <ViewError />;
      }}
    >
      <ClientSideQueryProvider queryState={dehydratedState}>
        {(queryClient) => {
          const safeAreaTop = parsedCookie?.[BASE_COOKIE.SAFE_AREA_TOP];
          const appFoundation = getAppFoundationQuery({ queryClient });

          const url = `${window.location.pathname}${
            window.location.search ? window.location.search : ''
          }`;

          return (
            <App
              url={url}
              theme={appFoundation?.app.os === 'IOS' ? 'cupertino' : 'android'}
              safeAreaTop={safeAreaTop}
              userConfig={{
                regionId: appFoundation?.region.id ?? 0,
                regionProvinceName: '',
                regionCityName: '',
                regionTownName: '',
                userId: appFoundation?.user.id ?? 0,
                userAuthToken: appFoundation?.user.authToken ?? '',
                userNickname: '',
                userProfileImageUrl: '',
                userHashId: '',
              }}
              deviceConfig={{
                theme: appFoundation?.app.os === 'IOS' ? 'cupertino' : 'android',
                userAgent: appFoundation?.app.userAgent ?? '',
                xUserAgent: '',
                isLegacyIOS: false,
              }}
            />
          );
        }}
      </ClientSideQueryProvider>
    </ErrorBoundary>
  );

  rootElement.style.display = '';
})();
