import { GroupFeedSummary, Image } from '@community-group/api/lib/group/models';
import { Typography } from '@community-group/components';
import { IconChevronRightFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { useMemo } from 'react';

import * as s from './ChallengeCertifyItem.css';

type Props = {
  certifyItem: GroupFeedSummary;
  onClick: () => void;
};

const ChallengeCertifyMoreItem = ({ certifyItem, onClick }: Props) => {
  const imageUrl = useMemo(() => {
    const images = certifyItem.medias.filter((media) => media.type === 'image');

    const imageObject = images[0].media as Image;

    return imageObject.medium;
  }, [certifyItem.medias]);

  return (
    <div className={s.Container} onClick={onClick}>
      <div
        className={s.ImageWrapper}
        style={{
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%), 
    url(${imageUrl}) lightgray 50% / cover no-repeat`,
        }}
      >
        <div className={s.ImageInnerContents}>
          <Typography typography="label3Regular" color="onPrimary">
            더보기
          </Typography>
          <IconChevronRightFill size={14} color={vars.$semantic.color.onPrimary} />
        </div>
      </div>
    </div>
  );
};

export default ChallengeCertifyMoreItem;
