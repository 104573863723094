import { groupClient } from '@community-group/api';
import { model } from '@community-group/api/lib/group';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Props = UseMutationOptions<
  AxiosResponse<model.GroupApplicationResponse>,
  Error,
  { id: number; groupMemberApplicationForm?: model.GroupMemberApplicationForm }
>;

/**
 * @deprecated domain/GroupApplication/hooks/usePostApplication 을 사용해주세요.
 */
export const usePostApplication = ({ onError, onSuccess, onMutate, onSettled }: Props) => {
  const fetchInstance = useFetchInstance();
  const postApplication = groupClient.api.GroupApplicationApi.apiV1GroupsIdApplicationsPost({
    axios: fetchInstance,
  });

  const postApplicationWrapperFp = ({
    id,
    groupMemberApplicationForm,
  }: {
    id: number;
    groupMemberApplicationForm: model.GroupMemberApplicationForm;
  }) => {
    return postApplication({
      id,
      groupMemberApplicationForm,
    });
  };

  return useThrottleMutation(postApplicationWrapperFp, {
    onError,
    onSuccess,
    onMutate,
    onSettled,
  });
};
