import { groupClient } from '@community-group/api';
import { PaginationQueryParams } from '@community-group/components/shared';
import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import { GROUP_FEED_QUERY_KEY } from '@/domain/GroupFeed/queries';
import { useRead } from '@/shared/api/hooks/useRead';
import { selectPaginationFlatten } from '@/shared/utils/paginationQuery';

type Props = {
  groupId: string;
  userId: string;
  initialCursor?: string | undefined;
  refetchInterval?: number;
} & PaginationQueryParams;

export const useReadGroupFeeds = ({
  groupId,
  userId,
  refetchInterval,
  initialCursor = undefined,
  limit = 20,
}: Props) => {
  const read = useRead(groupClient.api.GroupMemberApi.apiV1GroupsIdMembersUserIdFeedsGet);
  return useSuspenseInfiniteQuery({
    queryKey: GROUP_FEED_QUERY_KEY.feeds({
      groupId,
      userId,
      initialCursor,
      limit,
      refetchInterval,
    }),
    queryFn: async ({ pageParam = initialCursor }) => {
      const response = await read({
        id: Number(groupId),
        userId: Number(userId),
        cursor: pageParam,
        limit,
      });
      return {
        ...response,
        data: {
          items: response.data.items,
          hasNext: response.data.hasNext,
          endCursor: response.data.endCursor ?? '',
        },
      };
    },
    initialPageParam: initialCursor,
    getNextPageParam: (lastPage) => (lastPage.data.hasNext ? lastPage.data.endCursor : undefined),
    select: selectPaginationFlatten,
    refetchInterval,
  });
};
