import { GroupDetailPresentation } from '@community-group/api/lib/group/models';
import { PaginationEmpty, PaginationList } from '@community-group/components/shared';

import CommentItem from '@/components/group/profile/MemberProfileDetail/components/CommentItem';
import { useReadGroupProfileComments } from '@/domain/GroupProfile/hooks/useReadGroupProfileComments';

import * as s from './style.css';
type Props = {
  groupId: string;
  userId: string;
  groupDetail: GroupDetailPresentation;
};

const GroupProfileContentsTabComments = ({ groupId, userId, groupDetail }: Props) => {
  const {
    data: items,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useReadGroupProfileComments({ groupId, userId });

  if (items.length <= 0) {
    return <PaginationEmpty>작성한 댓글이 없어요.</PaginationEmpty>;
  }
  return (
    <div className={s.CommentListWrapper}>
      <PaginationList
        items={items}
        render={(item) => <CommentItem key={item.id} item={item} group={groupDetail} />}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
      />
    </div>
  );
};

export default GroupProfileContentsTabComments;
