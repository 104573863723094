import { BoxButton } from '@community-group/components';

import { useBridge } from '@/contexts/Bridge';

import * as s from './PayRejectAccessoryBar.css';

const PayRejectAccessoryBar = () => {
  const { bridge } = useBridge();

  const handleClickFAQ = () => {
    bridge.pushRouter({
      router: {
        remote: 'karrot://faqs',
      },
    });
  };

  return (
    <div className={s.ButtonContainer}>
      <BoxButton onClick={handleClickFAQ} width="100%" size="xlarge">
        문의하기
      </BoxButton>
    </div>
  );
};

export default PayRejectAccessoryBar;
