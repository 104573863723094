import { groupClient } from '@community-group/api';
import { GroupMemberGradeUpdateForm } from '@community-group/api/lib/group/models';

import { useRead } from '@/shared/api/hooks/useRead';
import {
  useThrottleMutation,
  UseThrottleMutationOption,
} from '@/shared/api/hooks/useThrottleMutation';

type Params = {
  groupId: string;
  userId: number;
} & GroupMemberGradeUpdateForm;

type Props = UseThrottleMutationOption;

// 멤버의 등급을 변경해요
export const usePutGroupMemberGrade = (props: Props) => {
  const put = useRead(groupClient.api.GroupMemberApi.apiV1GroupsIdMembersUserIdGradePut);
  const mutation = ({ groupId, userId, ...form }: Params) =>
    put({
      id: Number(groupId),
      userId: Number(userId),
      groupMemberGradeUpdateForm: form,
    });

  return useThrottleMutation(mutation, props);
};
