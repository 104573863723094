import {
  GroupCurrentUser,
  GroupMemberSummaryPresentation,
} from '@community-group/api/lib/group/models';
import {
  ActionItem,
  ActionListItem,
  isMember,
  isSuperHost,
  useBottomSheet,
} from '@community-group/components';
import { ActionList } from '@community-group/components';
import { ActionSheet } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { UseActionsOutputType } from '@stackflow/react';

import { MemberListOrderType } from '@/api/hooks/useGetMemberList';
import GroupPayChatActionListItem from '@/components/group/profile/GroupProfile/shared/GroupPayChatActionListItem';
import { useHandleMemberGradeProfile } from '@/domain/GroupProfile/utils/useHandleMemberGradeProfile';
import { TypeActivities } from '@/stackflow';
import { openGroupProfileReport } from '@/utils/link';

import { useHandleBanProfile } from '../../hooks/useHandleBanProfile';
import { useHandleManagerRoleProfile } from '../../hooks/useHandleManagerRoleProfile';

type Props = {
  groupId: string;
  currentUser: GroupCurrentUser;
  profileUser: GroupMemberSummaryPresentation;
  hostMemberList: GroupMemberSummaryPresentation[];
  isGroupUserProfileDetailPage: boolean;
  shownOnlyHost?: boolean;
  selectedMemberOrderType?: MemberListOrderType;
  push: UseActionsOutputType<TypeActivities>['push'];
};

const MemberProfileActionBottomSheet = ({
  groupId,
  currentUser,
  profileUser,
  hostMemberList,
  isGroupUserProfileDetailPage,
  shownOnlyHost,
  selectedMemberOrderType,
  push,
}: Props) => {
  const { closeAsync: closeBottomSheet } = useBottomSheet();

  const { banLabel, handleBanProfile } = useHandleBanProfile(
    groupId,
    profileUser,
    shownOnlyHost,
    selectedMemberOrderType
  );
  const { roleLabel, handleManagerRoleProfile } = useHandleManagerRoleProfile(
    groupId,
    profileUser,
    hostMemberList,
    shownOnlyHost,
    selectedMemberOrderType
  );
  const { memberGradeEnabled, memberGradeEditSections, handleMemberGradeProfile } =
    useHandleMemberGradeProfile({ groupId, currentUser, profileUser });

  const actionItemList: ActionItem[] = [];

  const isAbleChangeRole =
    isSuperHost(currentUser.role) && isMember(profileUser.role) && !profileUser.isAccountDeleted;
  const isAbleBan =
    isMember(profileUser.role) &&
    !isSuperHost(profileUser.role) &&
    currentUser.permissions.restrictMember;
  const showPayChatButton = isMember(currentUser.role) && isGroupUserProfileDetailPage;

  if (!memberGradeEnabled && isAbleChangeRole) {
    actionItemList.push({
      label: roleLabel,
      onClick: async () => {
        handleManagerRoleProfile();
      },
    });
  }

  if (memberGradeEnabled) {
    memberGradeEditSections.forEach((section) => {
      actionItemList.push({
        label: section.displaySectionText,
        onClick: async () => {
          await closeBottomSheet();
          handleMemberGradeProfile(section);
        },
      });
    });
  }

  actionItemList.push({
    label: '신고',
    onClick: async () => {
      await closeBottomSheet();
      openGroupProfileReport({
        groupId,
        userId: String(profileUser?.id),
      });
    },
  });

  if (isAbleBan) {
    actionItemList.push({
      label: banLabel,
      color: vars.$semantic.color.danger,
      onClick: async () => {
        handleBanProfile();
      },
    });
  }

  return (
    <ActionSheet
      bottomButton={{
        label: '닫기',
      }}
    >
      <ActionList>
        {showPayChatButton && (
          <GroupPayChatActionListItem
            groupId={parseInt(groupId)}
            targetUserId={profileUser.id}
            actionFlowPush={push}
          />
        )}
        {actionItemList.map((actionItem) => (
          <ActionListItem
            key={actionItem.label}
            onClick={actionItem.onClick}
            color={actionItem.color}
          >
            {actionItem.label}
          </ActionListItem>
        ))}
      </ActionList>
    </ActionSheet>
  );
};

export const MemberProfileActionBottomSheetFallback = ({
  groupId,
  currentUser,
  profileUser,
  isGroupUserProfileDetailPage,
  shownOnlyHost,
  selectedMemberOrderType,
  push,
}: Props) => {
  const { closeAsync: closeBottomSheet } = useBottomSheet();

  const { banLabel, handleBanProfile } = useHandleBanProfile(
    groupId,
    profileUser,
    shownOnlyHost,
    selectedMemberOrderType
  );

  const actionItemList: ActionItem[] = [];

  const isAbleBan =
    isMember(profileUser.role) &&
    !isSuperHost(profileUser.role) &&
    currentUser.permissions.restrictMember;
  const showPayChatButton = isMember(currentUser.role) && isGroupUserProfileDetailPage;

  actionItemList.push({
    label: '신고',
    onClick: async () => {
      await closeBottomSheet();
      openGroupProfileReport({
        groupId,
        userId: String(profileUser?.id),
      });
    },
  });

  if (isAbleBan) {
    actionItemList.push({
      label: banLabel,
      color: vars.$semantic.color.danger,
      onClick: async () => {
        handleBanProfile();
      },
    });
  }

  return (
    <ActionSheet
      bottomButton={{
        label: '닫기',
      }}
    >
      <ActionList>
        {showPayChatButton && (
          <GroupPayChatActionListItem
            groupId={parseInt(groupId)}
            targetUserId={profileUser.id}
            actionFlowPush={push}
          />
        )}
        {actionItemList.map((actionItem) => (
          <ActionListItem
            key={actionItem.label}
            onClick={actionItem.onClick}
            color={actionItem.color}
          >
            {actionItem.label}
          </ActionListItem>
        ))}
      </ActionList>
    </ActionSheet>
  );
};

export default MemberProfileActionBottomSheet;
