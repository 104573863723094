import { AccessStatusServiceEnum } from '@community-group/api/lib/group/models';
import { useEffect } from 'react';

import { useFlow } from '@/stackflow';

import useCurrentGroupMe from './useCurrentGroupMe';

export const useHandleRestrictedUser = ({
  serviceType,
  groupId,
  meetupId,
  postId,
  challengeId,
}: {
  serviceType?: AccessStatusServiceEnum;
  groupId: string;
  meetupId?: string;
  postId?: string;
  challengeId?: string;
}) => {
  const { replace } = useFlow();
  const currentUser = useCurrentGroupMe();

  const handleRestrictedUser = () => {
    replace(
      'AccessStatusServiceHandlingPage',
      {
        serviceType: serviceType,
        groupId: groupId ?? '',
        meetupId: meetupId ?? '',
        challengeId: challengeId ?? '',
        postId: postId ?? '',
        accessStatusOption: 'restricted',
      },
      { animate: false }
    );
  };

  useEffect(() => {
    if (currentUser.state === 'restricted') {
      handleRestrictedUser();
    }
  }, [currentUser]);

  return { handleRestrictedUser };
};
