import * as s from './style.css';

type Props = {
  children: React.ReactNode;
};

const HideScrollBar = ({ children }: Props) => {
  return <section className={s.HideScrollBarWrapper}>{children}</section>;
};

export default HideScrollBar;
