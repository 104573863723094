import {
  ChallengeInfoPostDetail,
  GroupCurrentUser,
  GroupDetailPresentation,
  GroupFeedMeetupInfoPresentation,
  GroupFeedSummary,
} from '@community-group/api/lib/group/models';
import { isSuperHost, useBottomSheet } from '@community-group/components';
import React, { MouseEvent, useMemo } from 'react';
import { match, P } from 'ts-pattern';

import { useHandleGroupOnly } from '@/components/group/JoinGroupState/hooks/useHandleGroupOnly';
import FeedItemV2 from '@/domain/GroupFeed/components/FeedItemV2';
import HigherManagerRoleOptionsBottomSheetV2 from '@/domain/GroupFeed/components/HigherManagerRoleOptionsBottomSheetV2';
import NormalUserRoleOptionsListV2 from '@/domain/GroupFeed/components/NormalUserRoleOptionsListV2';
import WriterRoleOptionsListV2 from '@/domain/GroupFeed/components/WriterRoleOptionsListV2';
import { overrideFeedTypeToPostType } from '@/domain/GroupFeed/utils/overrideFeedTypeToPostType';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';
import { GroupRoutes } from '@/utils/analytics/type';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';
import { refetchGroupMeetupDetail } from '@/utils/refetch/groupMeetupDetail';
import { refetchPostDetail } from '@/utils/refetch/groupPostDetail';
import { isHigherManager, isNotMember } from '@/utils/role';

type Props = {
  groupId: string;
  groupDetail: GroupDetailPresentation;
  feed: GroupFeedSummary;
  currentUser: GroupCurrentUser;
  meetup?: GroupFeedMeetupInfoPresentation;
  challenge?: ChallengeInfoPostDetail;
  shouldSetSubNickname?: boolean;
  onClickProfile?: (event: MouseEvent) => void;
};

const FeedItem = ({
  groupId,
  feed,
  groupDetail,
  currentUser,
  meetup,
  challenge,
  shouldSetSubNickname,
  onClickProfile,
}: Props) => {
  const { push } = useFlow();
  const { open: openBottomSheet, closeAsync: closeBottomSheet } = useBottomSheet();
  const postId = useMemo(() => feed.id.toString(), [feed.id]);

  const blockedGroupOnly = isNotMember(currentUser.role) && feed.publishType === 'group_only';
  const { groupInfo, handleGroupOnly } = useHandleGroupOnly({ groupId });

  const handleEditPostClick = async () => {
    await closeBottomSheet();
    match(feed)
      .with({ type: 'group_meetup', meetupInfo: P.not(P.nullish) }, ({ meetupInfo }) => {
        push('GroupMeetupEditPage', {
          meetupId: meetupInfo.id.toString(),
          groupId: groupId.toString(),
        });
      })
      .with({ type: 'challenge', challengeInfo: P.not(P.nullish) }, ({ challengeInfo }) => {
        push('ChallengeEditPage', {
          groupId: groupId.toString(),
          challengeId: challengeInfo.id.toString(),
        });
      })
      .otherwise(() => {
        push('GroupPostEditPage', {
          groupId,
          postId,
          challengeId: feed.challengeInfo?.id?.toString() ?? '',
          postType: overrideFeedTypeToPostType(feed.type),
        });
      });
  };

  const handleEditPostBoardCategoryClick = async () => {
    await closeBottomSheet();

    push('BottomSheet/GroupMoveBoardCategoryBottomSheet', {
      groupId,
      postId,
    });
  };

  const handleRefetchFeed = () => {
    refetchGroupDetail({ groupId });
    refetchPostDetail({ groupId, postId });
    refetchGroupMeetupDetail({ groupId, meetupId: feed.id.toString() });
  };

  const handleOnClickSheetList = async () => {
    const { id: userId, role } = currentUser;
    const { id: authorId } = feed.author;
    const isAuthor = userId === authorId;
    const isChallengePost = feed.type === 'challenge';

    // 챌린지 글이면서 일반 유저가 접근하는 경우 동작 없음
    if (isChallengePost && !isAuthor && !isSuperHost(role)) return;

    const bottomSheetElement = match({ isAuthor, role })
      .with({ role: P.when(isHigherManager) }, () => (
        // 모임장, 운영진
        <HigherManagerRoleOptionsBottomSheetV2
          feed={feed}
          groupId={groupId}
          currentUser={currentUser}
          isBoardManaged={groupDetail.isBoardManaged}
          onEditPost={handleEditPostClick}
          onEditPostBoardCategory={handleEditPostBoardCategoryClick}
          currentUserPermissions={currentUser.permissions}
        />
      ))
      // 일반 멤버
      .with({ isAuthor: false }, () => (
        <NormalUserRoleOptionsListV2 feed={feed} groupId={groupId} />
      ))
      // 글 작성자
      .with({ isAuthor: true }, () => (
        <WriterRoleOptionsListV2
          feed={feed}
          groupId={groupId}
          isBoardManaged={groupDetail.isBoardManaged}
          onEditPost={handleEditPostClick}
          onEditPostBoardCategory={handleEditPostBoardCategoryClick}
        />
      ))
      .exhaustive();

    openBottomSheet({
      element: bottomSheetElement,
      onDimClose: closeBottomSheet,
    });
  };

  const handleClick = () => {
    const baseParams = {
      groupId,
      groupName: groupInfo.name,
      isChatRequired: !groupInfo.isShowChatRoomSetting,
      isChatActivated: !groupInfo.isDeactivateChatRoom,
    };
    handleGroupOnly({
      isBlock: blockedGroupOnly,
      onSettled: () => {
        match({ type: feed.type, meetup, challenge })
          .with({ type: 'group_meetup', meetup: P.not(P.nullish) }, ({ meetup }) => {
            trackEvent({
              event: 'click_post_meetup_detail',
              params: baseParams,
              sample: true,
            });
            push('GroupMeetupDetailPage', {
              groupId,
              meetupId: meetup.id.toString(),
              from: 'groupDetailPage',
            });
          })
          .with({ type: 'challenge', challenge: P.not(P.nullish) }, ({ challenge }) => {
            trackEvent({
              event: 'click_post_detail',
              params: baseParams,
              sample: true,
            });
            push('GroupChallengeDetailPage', {
              groupId,
              challengeId: challenge.id.toString(),
            });
          })
          .otherwise(() => {
            push('GroupPostDetailPage', { groupId, postId });
          });
      },
      onSuccess({ joinApprovalRequireReason }) {
        if (joinApprovalRequireReason !== 'none') return;
        if (shouldSetSubNickname) {
          push('BottomSheet/GroupSetMemberProfileSubNicknameBottomSheet', {
            groupId,
          });
        }
      },
    });
  };

  const handleProfileClick = (event: MouseEvent) => {
    event.stopPropagation();

    if (feed.author.isAccountDeleted) return;

    trackEvent({
      event: 'click_profile',
      params: {
        referrer: GroupRoutes.GROUP_DETAIL,
        clickedUserId: feed.author?.id,
        clickedUserRole: feed.author?.role,
        clickedUserState: feed.author?.state,
        type: 'feed',
        groupId,
        groupName: groupInfo.name,
        isChatRequired: !groupInfo.isShowChatRoomSetting,
        isChatActivated: !groupInfo.isDeactivateChatRoom,
      },
    });

    push('GroupUserProfileDetailPage', {
      groupId,
      userId: feed.author.id.toString(),
    });
  };

  const handleProfileButtonClick = (e: MouseEvent) => {
    e.stopPropagation();
    handleGroupOnly({
      isBlock: blockedGroupOnly,
      onSettled() {
        handleOnClickSheetList();
      },
      onSuccess() {
        if (shouldSetSubNickname) {
          push('BottomSheet/GroupSetMemberProfileSubNicknameBottomSheet', {
            groupId,
          });
        }
      },
    });
  };

  const handleMeetupBannerClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (!meetup) return;

    push('GroupMeetupDetailPage', {
      groupId,
      meetupId: meetup.id.toString(),
    });
  };

  const handlePollBannerClick = (e: MouseEvent) => {
    e.stopPropagation();

    push('GroupPostDetailPage', {
      groupId,
      postId,
    });
  };

  return (
    <FeedItemV2
      groupId={groupId}
      feed={feed}
      currentUser={currentUser}
      meetup={meetup}
      challenge={challenge}
      onClick={handleClick}
      onProfileClick={onClickProfile || handleProfileClick}
      onProfileButtonClick={handleProfileButtonClick}
      onMeetupBannerClick={handleMeetupBannerClick}
      onPollBannerClick={handlePollBannerClick}
      onRefetchFeed={handleRefetchFeed}
    />
  );
};

export const MemoizedFeedItemV2 = React.memo(FeedItem);
