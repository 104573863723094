import { groupClient } from '@community-group/api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

import { GROUP_MEMBER_QUERY_KEY } from '../queries';

// 모임의 멤버 등업 요건을 조회해요
export const useQueryGroupMemberGradeRequirements = (groupId: string) => {
  const read = useAsyncRead(
    groupClient.api.GroupMemberApi.apiV1GroupsIdMembersGradeRequirementsGet
  );
  return {
    queryKey: GROUP_MEMBER_QUERY_KEY.memberGradeRequirements(groupId),
    queryFn: () => read({ id: Number(groupId) }).then((res) => res.requirements),
  };
};

export const useReadGroupMemberGradeRequirements = (groupId: string) => {
  const query = useQueryGroupMemberGradeRequirements(groupId);
  return useSuspenseQuery({ ...query });
};
