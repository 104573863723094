import { groupClient } from '@community-group/api';
import { GroupMemberGradeStatusUpdateForm } from '@community-group/api/lib/group/models';

import { useRead } from '@/shared/api/hooks/useRead';
import {
  useThrottleMutation,
  UseThrottleMutationOption,
} from '@/shared/api/hooks/useThrottleMutation';

type Params = {
  groupId: string;
} & GroupMemberGradeStatusUpdateForm;

type Props = UseThrottleMutationOption;

export const usePutGroupMemberGradeStatus = (props: Props) => {
  const put = useRead(groupClient.api.GroupMemberApi.apiV1GroupsIdMembersGradesStatusPut);
  const mutation = ({ groupId, ...form }: Params) =>
    put({
      id: Number(groupId),
      groupMemberGradeStatusUpdateForm: form,
    });

  return useThrottleMutation(mutation, props);
};
