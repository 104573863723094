import { GroupDetailPresentation, PostDetail } from '@community-group/api/lib/group/models';
import { Typography } from '@community-group/components';
import { useMemo } from 'react';

import { useGetChallengeDetail } from '@/api/hooks/useGetChallengeDetail';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';

import * as s from './AppBarTitle.css';

export type AppBarTitleProps = {
  group?: GroupDetailPresentation;
  post?: PostDetail;
};

const AppBarTitle = ({ group, post }: AppBarTitleProps) => {
  const { postType: queryParamsPostType, challengeId } = useQueryParams();
  const { data: challenge } = useGetChallengeDetail({
    groupId: group?.id.toString() ?? '',
    challengeId: challengeId ?? '',
  });

  const isChallengeCertifyPost = queryParamsPostType === 'challengeCertify';

  const displayText = useMemo(() => {
    if (isChallengeCertifyPost) {
      return {
        title: '인증글 쓰기',
        subTitle: post?.challengeInfo?.name ?? challenge?.name ?? '',
      };
    }
    return {
      title: '글쓰기',
      subTitle: group?.name ?? '',
    };
  }, [isChallengeCertifyPost, group?.name, post?.challengeInfo?.name, challenge?.name]);

  return (
    <div className={s.Container}>
      <Typography typography="title3Bold" ellipsisAfterLines={1}>
        {displayText.title}
      </Typography>
      <Typography typography="caption2Regular" color="gray600" ellipsisAfterLines={1}>
        {displayText.subTitle}
      </Typography>
    </div>
  );
};

export default AppBarTitle;
