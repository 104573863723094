import { PaginationQueryParams } from '@community-group/components/shared';

export const GROUP_FEED_QUERY_KEY = {
  base: (groupId: string, userId: string) => ['groupFeed', groupId, userId] as const,
  feeds: ({
    groupId,
    userId,
    initialCursor,
    limit,
    refetchInterval,
  }: {
    groupId: string;
    userId: string;
    refetchInterval?: number;
  } & PaginationQueryParams) =>
    [
      ...GROUP_FEED_QUERY_KEY.base(groupId, userId),
      'feeds',
      userId,
      initialCursor,
      limit,
      refetchInterval,
    ] as const,
};
