import { groupClient } from '@community-group/api';
import { PaginationQueryParams } from '@community-group/components/shared';
import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import { GROUP_PROFILE_QUERY_KEY } from '@/domain/GroupProfile/queries';
import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';
import { useRead } from '@/shared/api/hooks/useRead';
import { selectPaginationFlatten } from '@/shared/utils/paginationQuery';

type Props = {
  groupId: string;
  userId: string;
} & PaginationQueryParams;

export const useQueryGroupMemberJoinedMeetupThumbnail = ({
  groupId,
  userId,
  initialCursor = undefined,
  limit = 10,
}: Props) => {
  const read = useAsyncRead(
    groupClient.api.GroupMemberApi.apiV1GroupsIdMembersUserIdJoinedMeetupsGet
  );
  return {
    queryKey: GROUP_PROFILE_QUERY_KEY.joinedMeetupThumbnail(groupId, userId),
    queryFn: () =>
      read({
        id: Number(groupId),
        userId: Number(userId),
        cursor: initialCursor,
        limit,
      }).then((res) => res.joinedMeetups),
  };
};

export const useReadGroupMemberJoinedMeetups = ({
  groupId,
  userId,
  initialCursor = undefined,
  limit = 10,
}: Props) => {
  const read = useRead(groupClient.api.GroupMemberApi.apiV1GroupsIdMembersUserIdJoinedMeetupsGet);

  return useSuspenseInfiniteQuery({
    queryKey: GROUP_PROFILE_QUERY_KEY.joinedMeetups(groupId, userId, initialCursor),
    queryFn: async ({ pageParam = initialCursor }) => {
      const response = await read({
        id: Number(groupId),
        userId: Number(userId),
        cursor: pageParam,
        limit,
      });
      return {
        ...response,
        data: {
          ...response.data,
          items: response.data.joinedMeetups,
        },
      };
    },
    initialPageParam: initialCursor,
    getNextPageParam: ({ data }) => (data.hasNext ? data.endCursor : undefined),
    select: selectPaginationFlatten,
  });
};
