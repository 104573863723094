import { GroupMemberGrade } from '@community-group/api/lib/group/models';
import { Typography, VerticalSpacing } from '@community-group/components';
import { IconCheckmarkFlowerFill, IconCrownFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { useMemo } from 'react';

import { useReadGroupMemberGradeStatus } from '@/domain/GroupMember/hooks/useReadGroupMemberGradeStatus';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';

import * as s from './ProfileGradeBadge.css';

type Props = {
  groupId: string;
  grade: GroupMemberGrade;
};

const ProfileGradeBadge = ({ groupId, grade }: Props) => {
  const memberGradeEnabledFeatureFlag = useFeatureFlag('memberGradeEnabled');
  const memberGradeStatus = useReadGroupMemberGradeStatus(groupId);

  const memberGradeEnabled = useMemo(() => {
    return memberGradeEnabledFeatureFlag && memberGradeStatus;
  }, [memberGradeEnabledFeatureFlag, memberGradeStatus]);

  // 멤버 등급제가 꺼진 상태일 때 '정회원', '준회원'은 구분 짓지 않고 등급 노출하지 않음
  if (!memberGradeEnabled && grade.role === 'member') {
    return null;
  }

  if (grade.name === '준회원') {
    return null;
  }

  return (
    <div className={s.Container}>
      <GradeIcon grade={grade} memberGradeEnabled={Boolean(memberGradeEnabled)} />
      <VerticalSpacing size={4} />
      <Typography typography="subtitle2Regular" color="gray600">
        {grade.name}
      </Typography>
    </div>
  );
};

type IconProps = {
  grade: GroupMemberGrade;
  memberGradeEnabled: boolean;
};

const GradeIcon = ({ grade, memberGradeEnabled }: IconProps) => {
  switch (grade.role) {
    case 'superHost':
      return <IconCrownFill size={14} color="#f7be68" />;
    case 'manager':
      return <IconCrownFill size={14} color="#30c795" />;
    case 'member': {
      // TODO: 커스텀 등급(name을 모임별로 다르게 가능)이 적용되면 수정 필요
      if (memberGradeEnabled && grade.name === '정회원') {
        return <IconCheckmarkFlowerFill size={14} color={vars.$scale.color.gray600} />;
      }
      return null;
    }
    default:
      return null;
  }
};

export default ProfileGradeBadge;
