import {
  ChallengeStatusEnum,
  GroupMeetupProgressStatusPresentationStatus,
} from '@community-group/api/lib/group/models';
import { vars } from '@seed-design/design-token';
import { match } from 'ts-pattern';

export const getMeetupStatusTitleColor = (
  status: GroupMeetupProgressStatusPresentationStatus | ChallengeStatusEnum
) => {
  return match(status)
    .with('opened', () => vars.$semantic.color.primary)
    .with('fullyRecruited', () => vars.$scale.color.yellow400)
    .with('closed', 'deleted', 'canceled', 'blocked', () => vars.$scale.color.gray600)
    .otherwise(() => vars.$scale.color.gray600);
};
