import { ChallengeCertifyPointsResponse } from '@community-group/api/lib/group/models';
import { ViewError, ViewLoader, withAsyncBoundary } from '@community-group/components';
import { IconXmarkLine } from '@daangn/react-monochrome-icon';
import { ActivityComponentType } from '@stackflow/react';
import { useEffect, useState } from 'react';

import { useBridge } from '@/contexts/Bridge';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { ActivityQueryParams, useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { trackEvent } from '@/utils/analytics';

import GrowthColdStartReceivePrizeAccessoryBar from '../../components/AccessoryBar/GrowthColdStartReceivePrizeAccessoryBar';
import GrowthColdStartJoinSuccess from '../../components/GrowthColdStartJoinSuccess';
import { usePostGrowthColdStartSendPrize } from '../../hooks/usePostGrowthColdStartSendPrize';

/**
 * 2025.01 챌린지 프로모션 이벤트입니다.
 *  이후 관련 내용을 삭제해주세요.
 */

type Params = Pick<
  ActivityQueryParams,
  'challengeCertifyPromotionResult' | 'prizeEntryId' | 'point'
>;

const GrowthColdStartKarrotPaymentsJoinSuccessPage: ActivityComponentType<Params> = () => {
  const { prizeEntryId = '', point, challengeCertifyPromotionResult } = useQueryParams();
  const { pop, replace } = useFlow();
  const { bridge } = useBridge();

  const parsedChallengeCertifyPromotionResult = JSON.parse(
    challengeCertifyPromotionResult ?? '{}'
  ) as ChallengeCertifyPointsResponse;

  const [sentPrizeResult, setSentPrizeResult] = useState<boolean | null>(null);

  const openErrorSnackbar = () => {
    bridge.openToast({
      toast: {
        button: {
          action: {
            link: {
              path: 'karrot://faqs',
            },
          },
          body: '문의하기',
        },
        body: '당근머니 지급이 실패했어요. 고객센터 통해서 문의해주세요.',
        type: 'SNACKBAR',
      },
    });
  };

  const { mutate: postSendPrize } = usePostGrowthColdStartSendPrize({
    onSuccess: ({ data }) => {
      const result = data.success;

      if (result) {
        setSentPrizeResult(true);

        trackEvent({
          event: 'click_challenge_certify_not_member_pay_success',
          params: {
            ...parsedChallengeCertifyPromotionResult,
            point,
            prizeEntryId,
            state: result,
          },
        });
      } else if (result === 'failed') {
        trackEvent({
          event: 'click_challenge_certify_not_member_pay_failed',
          params: {
            ...parsedChallengeCertifyPromotionResult,
            point,
            prizeEntryId,
            state: result,
          },
        });
        replace('ChallengePayRejectPage', {});
      }
    },
    onError: () => {
      trackEvent({
        event: 'click_challenge_certify_not_member_pay_error',
        params: {
          ...parsedChallengeCertifyPromotionResult,
          point,
          prizeEntryId,
        },
      });
      pop();
      openErrorSnackbar();
    },
  });

  useEffect(() => {
    if (!prizeEntryId || prizeEntryId.length === 0) {
      openErrorSnackbar();
      return;
    }
    postSendPrize({
      prizeEntryId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppScreen
      appBar={{
        closeButton: {
          renderIcon: () => <IconXmarkLine size={24} />,
        },
        backButton: {
          renderIcon: () => <IconXmarkLine size={24} />,
        },
      }}
      accessoryBar={<GrowthColdStartReceivePrizeAccessoryBar />}
    >
      {!sentPrizeResult && <ViewLoader />}
      {sentPrizeResult && <GrowthColdStartJoinSuccess points={point ? Number(point) : undefined} />}
    </AppScreen>
  );
};

export default withAsyncBoundary(GrowthColdStartKarrotPaymentsJoinSuccessPage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
