import { groupClient } from '@community-group/api';
import { GroupMeetupJoinForm } from '@community-group/api/lib/group/models';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Params = {
  groupId: string;
  meetupId: string;
  groupMeetupJoinForm: GroupMeetupJoinForm;
};

type Props = UseMutationOptions<AxiosResponse<void>, Error, Params>;

export const usePutGroupMeetupJoin = ({ onError, onSuccess, onMutate, onSettled }: Props) => {
  const fetchInstance = useFetchInstance();
  const putGroupMeetupJoin = groupClient.api.MeetupApi.apiV1GroupsGroupIdMeetupsMeetupIdJoinPut({
    axios: fetchInstance,
  });

  const putGroupMeetupWrapper = ({ groupId, meetupId, groupMeetupJoinForm }: Params) => {
    return putGroupMeetupJoin({
      groupId: Number(groupId),
      meetupId: Number(meetupId),
      groupMeetupJoinForm,
    });
  };

  return useThrottleMutation(putGroupMeetupWrapper, {
    onError,
    onSuccess,
    onMutate,
    onSettled,
  });
};
