import { groupClient } from '@community-group/api';
import { GroupMemberGradeApplicationAcceptanceForm } from '@community-group/api/lib/group/models';

import { useRead } from '@/shared/api/hooks/useRead';
import {
  useThrottleMutation,
  UseThrottleMutationOption,
} from '@/shared/api/hooks/useThrottleMutation';

type Params = {
  groupId: string;
} & GroupMemberGradeApplicationAcceptanceForm;

type Props = UseThrottleMutationOption;

export const usePatchGroupMemberGradeApplication = (props: Props) => {
  const patch = useRead(
    groupClient.api.GroupMemberApi.apiV1GroupsIdMembersGradeApplicationAcceptancesPatch
  );
  const mutation = ({ groupId, ...form }: Params) =>
    patch({
      id: Number(groupId),
      groupMemberGradeApplicationAcceptanceForm: form,
    });

  return useThrottleMutation(mutation, props);
};
