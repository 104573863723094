import { Typography } from '@community-group/components';
import { IconCheckmarkBallotboxFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { MouseEvent } from 'react';

import * as s from './style.css';

type Props = {
  title: string;
  totalVoterCount: number;
  onClick?: (e: MouseEvent) => void;
};

export const PollBannerV2 = ({ title, totalVoterCount, onClick }: Props) => {
  return (
    <div className={s.Container} onClick={onClick} aria-hidden="true">
      <div className={s.ImageWrapper}>
        <IconCheckmarkBallotboxFill width={24} height={24} color={vars.$semantic.color.primary} />
      </div>
      <div className={s.ContentsWrapper}>
        <div className={s.Contents}>
          <Typography typography="bodyM2Regular" color="gray900" ellipsisAfterLines={1}>
            {title}
          </Typography>
          <Typography typography="caption1Regular" color="gray600" ellipsisAfterLines={1}>
            투표 ∙ {totalVoterCount}명 참여
          </Typography>
        </div>
      </div>
    </div>
  );
};
