import { isMember } from '@community-group/components';
import { PaginationLoader } from '@community-group/components/shared';
import { useSuspenseQueries } from '@tanstack/react-query';
import { Suspense } from 'react';

import { useQueryGroupDetail } from '@/domain/GroupDetail/hooks/useReadGroupDetail';
import { useQueryGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useQueryGroupProfileUserActivities } from '@/domain/GroupProfile/hooks/useReadGroupProfileUserActivities';
import MemberContents from '@/features/GroupProfile/ContentsTab/MemberContents';
import NotMemberContents from '@/features/GroupProfile/ContentsTab/NotMemberContents';

type Props = {
  groupId: string;
  userId: string;
};

const GroupProfileContentsTab = ({ groupId, userId }: Props) => {
  const [{ data: groupDetail }, { data: activitiesData }, { data: me }] = useSuspenseQueries({
    queries: [
      useQueryGroupDetail(groupId),
      useQueryGroupProfileUserActivities({ groupId, userId }),
      useQueryGroupMe({ groupId }),
    ],
  });

  const { count } = activitiesData;
  const totalContentsCount = count.createdPostAndMeetupCount;
  const commentCount = count.createdCommentCount;
  const meetupCount = count.joinedMeetupCount;
  if (!isMember(me.role)) {
    return (
      <NotMemberContents
        groupId={groupId}
        userId={userId}
        groupDetail={groupDetail}
        currentUser={me}
      />
    );
  }

  return (
    <Suspense fallback={<PaginationLoader />}>
      <MemberContents
        groupId={groupId}
        userId={userId}
        groupDetail={groupDetail}
        currentUser={me}
        totalContentsCount={totalContentsCount}
        commentCount={commentCount}
        meetupCount={meetupCount}
      />
    </Suspense>
  );
};

export default GroupProfileContentsTab;
