import { QueryClient } from '@tanstack/react-query';

import { APP_FOUNDATION_QUERY_KEY, AppFoundation } from '../../types';

type Props = {
  queryClient: QueryClient;
};

export const getAppFoundationQuery = ({ queryClient }: Props): AppFoundation | undefined => {
  return queryClient.getQueryData(APP_FOUNDATION_QUERY_KEY);
};
