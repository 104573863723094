import { groupClient } from '@community-group/api';

import { useRead } from '@/shared/api/hooks/useRead';
import {
  useThrottleMutation,
  UseThrottleMutationOption,
} from '@/shared/api/hooks/useThrottleMutation';

type Params = {
  groupId: string;
  userId: string;
};

type Props = UseThrottleMutationOption;

// 등업 신청을 해요
export const usePostGroupMemberGradeApplication = (props: Props) => {
  const post = useRead(
    groupClient.api.GroupMemberApi.apiV1GroupsIdMembersUserIdGradeApplicationPost
  );
  const mutation = ({ groupId, userId }: Params) =>
    post({
      id: Number(groupId),
      userId: Number(userId),
    });

  return useThrottleMutation(mutation, props);
};
