import {
  isHigherManager as checkIsHigherManager,
  withAsyncBoundary,
} from '@community-group/components';
import { useMemo } from 'react';

import { useReadGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';

import useGroupDetailGroupBotBannerClosed from '../hooks/useGroupDetailGroupBotBannerClosed';
import useGroupDetailGroupChallengeCertifyPromotionBannerClosed from '../hooks/useGroupDetailGroupChallengeCertifyPromotionBannerClosed';
import useGroupDetailHeroImageBannerClosed from '../hooks/useGroupDetailHeroImageBannerClosed';
import GroupDetailGroupChallengeCertifyPromotionBanner from './GroupDetailGroupChallengeCertifyPromotionBanner';
import GroupDetailHeroSliderBanner from './GroupDetailHeroSliderBanner';
import { GroupPhotoContestBanner } from './GroupPhotoContestBanner';

type Props = { groupId: number };

function GroupDetailBanner({ groupId }: Props) {
  const { data: groupMe } = useReadGroupMe({ groupId: groupId.toString() });

  const enableChallengePromotion = useFeatureFlag('enableChallengePromotion');
  const currentUserRole = useMemo(() => groupMe.role ?? 'none', [groupMe]);
  const isSuperHost = useMemo(() => currentUserRole === 'superHost', [currentUserRole]);
  const isHigherManager = useMemo(() => checkIsHigherManager(currentUserRole), [currentUserRole]);
  const isMember = useMemo(() => currentUserRole !== 'none', [currentUserRole]);

  const shownPhotoContest = useFeatureFlag('shownPhotoContest');

  const [groupBotBannerClosed] = useGroupDetailGroupBotBannerClosed(groupId.toString());
  const [groupChallengeCertifyPromotionBannerClosed] =
    useGroupDetailGroupChallengeCertifyPromotionBannerClosed(groupId.toString());

  const [heroImageBannerClosed] = useGroupDetailHeroImageBannerClosed(groupId.toString());

  // 히어로 슬라이더 관련 사진 첨부 or 게시글 작성 안내 배너
  if (isHigherManager && !heroImageBannerClosed) {
    return <GroupDetailHeroSliderBanner groupId={groupId} />;
  }

  // 2025.01 챌린지 인증 프로모션 이벤트 > 모임 상세 배너
  if (enableChallengePromotion && isMember && !groupChallengeCertifyPromotionBannerClosed) {
    return <GroupDetailGroupChallengeCertifyPromotionBanner groupId={groupId} />;
  }

  // TODO: 모임장봇 제거 필요
  // 모임장 봇 채팅 배너
  // if (isSuperHost && !groupBotBannerClosed) {
  //   return <GroupDetailGroupBotBanner groupId={groupId} />;
  // }

  // V2 사진전 배너 && 히어로 배너 노출 가능한 경우
  if (shownPhotoContest && groupMe.role !== 'none') {
    return <GroupPhotoContestBanner />;
  }

  return null;
}

export default withAsyncBoundary(GroupDetailBanner, {
  pendingFallback: null,
  rejectedFallback: <></>,
});
