import { UploadImageType } from '@community-group/api';

import CameraIcon from '@/assets/images/camera.png';
import ImageLoader from '@/components/common/Image/ImageLoader';
import { useUserConfig } from '@/contexts/UserConfig';

import * as s from './GroupCreateProfileImageUploader.css';

type Props = {
  profileImage?: UploadImageType;
  handleSetGroupProfile: () => Promise<void>;
  isLoading: boolean;
};

export const GroupCreateProfileImageUploader = ({
  profileImage,
  handleSetGroupProfile,
  isLoading,
}: Props) => {
  const { userConfig } = useUserConfig();

  return (
    <div className={s.imageContainer}>
      <div className={s.imageWrapper} onClick={handleSetGroupProfile}>
        <ImageLoader
          className={s.imageLoaderWrapper}
          src={
            profileImage?.medium ||
            userConfig?.userProfileImageUrl ||
            'https://dnvefa72aowie.cloudfront.net/origin/profile/profile_default.png'
          }
          isLoading={isLoading}
          isCircle
        />
        <div className={s.cameraImageWrapper}>
          <img className={s.cameraImage} src={CameraIcon} />
        </div>
      </div>
    </div>
  );
};
