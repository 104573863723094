// ! keyword는 띄어쓰기로만 구분자가 가능해요. 검색 api가 그렇게만 받음
const keywords = [
  {
    id: 14,
    name: '나이대',
    keyword: '나이대',
    description: '또래 친구는 여기서 만나요!',
    order: -2,
  },
  {
    id: 13,
    name: '여성',
    keyword: '여성',
    description: '여자끼리 모여봐요!',
    order: -1,
  },
  {
    id: 17,
    name: '뜨개질',
    keyword: '뜨개질',
    description: '뜨개질 함께하면 금방해요',
    order: -3,
  },
  {
    id: 12,
    name: '골프',
    keyword: '골프',
    description: '모임에서 골프 해요!',
    order: 0,
  },
  {
    id: 16,
    name: '당근 레이스',
    keyword: '당근 레이스',
    description: '2024 당근 레이스',
    order: 1,
  },
  {
    id: 15,
    name: '탁구',
    keyword: '탁구',
    description: '탁구는 복식이죠! 당근에서 모여요.',
    order: 2,
  },

  {
    id: 3,
    keyword: '러닝 달리기',
    name: '러닝',
    description: '이웃들과 함께 달려볼까요?',
    order: 3,
  },
  {
    id: 4,
    name: '등산',
    keyword: '등산 트래킹',
    description: '등산하기 딱 좋은 날씨!',
    order: 4,
  },
  {
    id: 5,
    name: '풋살',
    keyword: '풋살 축구 FC',
    description: '요즘 대세, 풋살할 사람 모여라!',
    order: 5,
  },
  {
    id: 7,
    name: '독서',
    keyword: '독서 책',
    description: '모임에서 책 읽어요!',
    order: 6,
  },
  {
    id: 8,
    name: '영어',
    keyword: '영어 토익 오픽',
    description: '모임에서 영어 스터디 해요!',
    order: 7,
  },
  {
    id: 10,
    name: '육아',
    keyword: '육아',
    description: '우리 동네 육아맘 만나요!',
    order: 8,
  },
  {
    id: 9,
    name: '반려동물',
    keyword: '반려동물 강아지 고양이',
    description: '냥집사 멍집사님들 모여요!',
    order: 9,
  },
  {
    id: 11,
    name: '클라이밍',
    keyword: '클라이밍',
    description: '모임에서 클라이밍 해요!',
    order: 10,
  },
  {
    id: 2,
    name: '배드민턴',
    keyword: '배드민턴 스쿼시 테니스',
    description: '배드민턴 한 판 치실 분?',
    order: 11,
  },
];

export const curationKeywords = keywords;

// 모임 홈에 노출되는 키워드 + 서버에서 받아오는 키워드
export const curationHomeSectionKeywords = [
  ...keywords.filter((keyword) => [17, 2, 3, 4, 9, 10, 12, 13, 14, 16].includes(keyword.id)),
];

// 큐레이션 탭 리스트에 노출되는 키워드 + 서버에서 받아오는 키워드
export const curationTabKeywords = keywords.filter((keyword) => ![13, 14, 16].includes(keyword.id));
