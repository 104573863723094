import { GROUP_DETAIL_QUERY_KEY } from '@/domain/GroupDetail/queries';
import { queryClient } from '@/shared/api/instance';
type Params = {
  groupId: string;
};

/*
 *  currentUser 상태관련 refetch
 */
export const refetchCurrentUser = ({ groupId }: Params) => {
  queryClient.refetchQueries({
    queryKey: GROUP_DETAIL_QUERY_KEY.me(groupId),
  });
};
