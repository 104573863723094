import { BoxButton } from '@community-group/components';
import AccountSdk from '@karrotpay/account-sdk/lib/sdk/v1/sdk';

import { useBridge } from '@/contexts/Bridge';
import { useFlow } from '@/stackflow';

import * as s from './style.css';

type Props = {
  prizeEntryId: string;
  point?: string;
  challengeCertifyPromotionResult: string;
};
const JoinKarrotPaymentsAccessoryBar = ({
  prizeEntryId,
  point,
  challengeCertifyPromotionResult,
}: Props) => {
  const { bridge } = useBridge();
  const { replace } = useFlow();

  const handleJoinKarrotPayments = () => {
    const accountSDK = new AccountSdk(bridge as any, globalThis.appConfig.appEnv, 'DAANGN_WEB');
    accountSDK.signup({
      queryParams: { where: 'group_challenge_certify_promotion_2501' },
      onSignupSuccess: () => {
        replace(
          'GrowthColdStartKarrotPaymentsJoinSuccessPage',
          { prizeEntryId, challengeCertifyPromotionResult, point },
          { animate: false }
        );
      },
    });
  };

  return (
    <div className={s.Wrapper}>
      <BoxButton width="100%" size="xlarge" onClick={handleJoinKarrotPayments}>
        당근페이 가입하기
      </BoxButton>
    </div>
  );
};

export default JoinKarrotPaymentsAccessoryBar;
