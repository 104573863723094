import { Pagination } from '@community-group/api/lib/apt-v2/models';
import { InfiniteData } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

type DataWithItems<ItemType> = {
  items?: ItemType[];
} & Pagination;

export const selectPaginationFlatten = <T>(
  data: InfiniteData<AxiosResponse<DataWithItems<T>, unknown>>
): T[] => {
  const list = data.pages.map(({ data }) => data.items) ?? [];
  const flattenList = list.flat(1) ?? [];

  return flattenList as T[];
};
