import { useReadGroupDetail } from '@/api/hooks/useGetGroupDetail';
import CheckAccessibleRole from '@/components/common/CheckAccessibleRole';
import useCurrentGroupMe from '@/hooks/useCurrentGroupMe';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { trackEvent } from '@/utils/analytics';
import { openGroupReport } from '@/utils/link';

import SettingGroupDeleteMenuItem from './SettingGroupDeleteMenuItem';
import SettingMenuItem from './SettingMenuItem';
import SettingSectionWrapper from './Wrapper';

const OtherSettingSection = () => {
  const { push } = useFlow();

  const { groupId } = usePathParams();
  const currentUser = useCurrentGroupMe();
  const { group } = useReadGroupDetail(groupId);

  const handleLeaveConfirm = () => {
    push('BottomSheet/LeaveGroupBottomSheet', { groupId: String(groupId) });
  };

  const handleDelegateSuperHost = () => {
    push('GroupSettingMemberDelegateSuperHostPage', { groupId: String(groupId) });
  };

  return (
    <SettingSectionWrapper title="기타">
      {/* 개설자만 */}
      <CheckAccessibleRole currentRole={currentUser.role} accessibleRoles={['superHost']}>
        <SettingMenuItem title="모임장 위임" onClick={handleDelegateSuperHost} />
        <SettingGroupDeleteMenuItem />
      </CheckAccessibleRole>

      {/* 개설자 외 나머지 */}
      <CheckAccessibleRole currentRole={currentUser.role} accessibleRoles={['manager', 'member']}>
        <SettingMenuItem
          title="신고하기"
          onClick={() => {
            trackEvent({
              event: 'click_report',
              params: {
                type: 'group',
              },
            });
            openGroupReport({
              groupId: String(group?.id),
            });
          }}
        />
        <SettingMenuItem warning title="모임 나가기" onClick={handleLeaveConfirm} />
      </CheckAccessibleRole>
    </SettingSectionWrapper>
  );
};

export default OtherSettingSection;
