import { GroupMeetupCreateForm } from '@community-group/api/lib/group/models';
import { BoxButton, Spacing } from '@community-group/components';
import {
  AsyncBoundary,
  Dialog,
  Divider,
  useDialog,
  useEffectOnce,
  useKeyboardSize,
  ViewError,
  ViewLoader,
} from '@community-group/components';
import { IconXmarkLine } from '@daangn/react-monochrome-icon';
import { zodResolver } from '@hookform/resolvers/zod';
import { ActivityComponentType } from '@stackflow/react';
import { useSuspenseQueries } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';

import RunningGrowthCallout from '@/components/group/Detail/components/RunningGrowth/RunningGrowthCallout';
import { getCurrentRunningGrowthStep } from '@/components/group/Detail/components/RunningGrowth/utils/curerntLevel';
import { useBridge } from '@/contexts/Bridge';
import { useUserConfig } from '@/contexts/UserConfig';
import { useQueryGroupDetail } from '@/domain/GroupDetail/hooks/useReadGroupDetail';
import { useQueryGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useQueryGroupLevel } from '@/domain/GroupLevel/hooks/useReadGroupLevel';
import { useQueryGroupMemberGrades } from '@/domain/GroupMember/hooks/useReadGroupMemberGrades';
import { useQueryGroupMemberGradeStatus } from '@/domain/GroupMember/hooks/useReadGroupMemberGradeStatus';
import { useQueryGroupPermissions } from '@/domain/GroupPermission/hooks/useReadGroupPermissions';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useUpdateStyleCurrentRouter } from '@/hooks/useUpdateStyleCurrentRouter';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { useBack } from '@/stackflow/hooks/useBack';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import useSetFixedLayoutSize from '@/stackflow/hooks/useSetFixedLayoutSize';
import { PageParams } from '@/stackflow/types/params';
import { getPermissionGradeName, isAccessibleGrade } from '@/utils/permission';
import { meetupCreateFormSchema, meetupFieldSchema } from '@/utils/validate/formSchema/meetup';
import { convertHookFormErrorObjectToArray } from '@/utils/validate/util';

import { GroupMeetupCreationCycleField } from '../../components/Field/CreationCycleField';
import { GroupMeetupFormDescriptionField } from '../../components/Field/Description';
import { GroupMeetupFormImagesField } from '../../components/Field/Images';
import { GroupMeetupFormMaximumParticipantsNumberField } from '../../components/Field/MaximumParticipantsNumber';
import { GroupMeetupFormMeetupDateTimeField } from '../../components/Field/MeetupDateTime';
import { GroupMeetupFormPoiField } from '../../components/Field/Poi';
import { GroupMeetupFormTitleField } from '../../components/Field/Title';
import {
  CloneGroupMeetupFormDataQueryParams,
  useInitGroupMeetupFormData,
} from '../../hooks/useInitGroupMeetupFormData';
import {
  closeListItemFormFieldQuerySelector,
  openListItemFormFieldQuerySelector,
} from '../../utils/selector';
import * as s from './index.css';

export type GroupMeetupNewPageParams = Pick<
  PageParams,
  'groupId' | keyof CloneGroupMeetupFormDataQueryParams | 'from'
>;

const GroupMeetupNewPage: ActivityComponentType<GroupMeetupNewPageParams> = () => {
  const { groupId } = usePathParams();
  const { cloneGroupMeetupFormData, from, calloutType } = useQueryParams();
  const { push, replace } = useFlow();
  const back = useBack();
  const { open: openDialog, close: closeDialog } = useDialog();

  useSetFixedLayoutSize();
  useUpdateStyleCurrentRouter({ IOS_ONLY_hideKeyboardAccessoryView: false }, true);

  const { bridge } = useBridge();
  const { isKeyboardOn } = useKeyboardSize(bridge);
  const {
    userConfig: { userId },
  } = useUserConfig();

  const initGroupMeetupFormData = useInitGroupMeetupFormData();

  const hookForm = useForm<GroupMeetupCreateForm>({
    defaultValues: initGroupMeetupFormData,
    resolver: zodResolver(meetupCreateFormSchema),
  });

  const [
    { data: me },
    { data: group },
    { data: level },
    { data: permissions },
    { data: memberGrades },
    { data: memberGradeStatus },
  ] = useSuspenseQueries({
    queries: [
      useQueryGroupMe({ groupId }),
      useQueryGroupDetail(groupId),
      useQueryGroupLevel(groupId),
      useQueryGroupPermissions(groupId),
      useQueryGroupMemberGrades(groupId),
      useQueryGroupMemberGradeStatus(groupId),
    ],
  });

  const memberGradeEnabledFeatureFlag = useFeatureFlag('memberGradeEnabled');
  const memberGradeEnabled = useMemo(() => {
    return memberGradeEnabledFeatureFlag && memberGradeStatus === 'system';
  }, [memberGradeEnabledFeatureFlag, memberGradeStatus]);

  const myCreateGroupMeetupPermission = useMemo(() => {
    return {
      permissionGradeName: getPermissionGradeName(
        memberGrades,
        permissions.createGroupMeetup.currentPriority
      ),
      hasAccessiblePermission: memberGradeEnabled
        ? isAccessibleGrade(me.grade, permissions.createGroupMeetup.currentPriority)
        : true /** @note 멤버 등급제가 꺼진 상태라면 누구나 일정을 만들 수 있음 */,
    };
  }, [me.grade, memberGradeEnabled, memberGrades, permissions.createGroupMeetup.currentPriority]);

  useEffect(() => {
    if (myCreateGroupMeetupPermission.hasAccessiblePermission) return;

    if (myCreateGroupMeetupPermission.permissionGradeName === '정회원') {
      openDialog({
        element: (
          <Dialog
            description="정회원만 일정을 만들 수 있어요. 멤버 등급을 확인해주세요."
            secondaryActionIntent="nonpreferred"
            primaryActionLabel="내 멤버 등급 보기"
            secondaryActionLabel="닫기"
            onPrimaryAction={async () => {
              await closeDialog();
              replace('GroupMemberGradeDetailPage', {
                groupId,
                userId: me.id.toString(),
              });
            }}
            onSecondaryAction={async () => {
              await closeDialog();
              back();
            }}
          />
        ),
      });
      return;
    }

    openDialog({
      element: (
        <Dialog
          description={`${myCreateGroupMeetupPermission.permissionGradeName}만 일정을 만들 수 있어요.`}
          secondaryActionIntent="nonpreferred"
          primaryActionLabel="확인"
          onPrimaryAction={async () => {
            await closeDialog();
            back();
          }}
        />
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myCreateGroupMeetupPermission]);

  useEnterTrackEvent({
    event: 'enter_create_meetup',
    params: {
      groupId,
      calloutType,
      currentRunningGrowthMission: getCurrentRunningGrowthStep(calloutType),
      currentLevel: level.currentLevel,
      currentProgressPercentage: level.forLevelUp.percentage,
      from,
      userId: (userId ?? 0).toString(),
      categoryId: group.category.id,
      categoryName: group.category.name,
    },
    loggerType: ['APPSFLYER', 'AMPLITUDE', 'KARROT'],
  });

  useEffect(() => {
    if (cloneGroupMeetupFormData) {
      openListItemFormFieldQuerySelector('meetupTimeAt');
    }
  }, []);

  useEffectOnce(() => {
    if (from === 'individual_chat') {
      openDialog({
        element: (
          <Dialog
            description="개별 채팅방에서는 일정을 사용할 수 없어요."
            primaryActionLabel="닫기"
            onPrimaryAction={async () => {
              bridge.closeRouter({});
            }}
          />
        ),
      });
    }
  });

  const { handleSubmit: handleFormSubmit, watch } = hookForm;

  const isDisabledButton = useMemo(() => {
    if (isKeyboardOn) return false;

    if (watch('title').length < (meetupFieldSchema.title.minLength ?? 1)) return true;

    if (
      (watch('images') ?? [])?.filter(
        (imageId) => imageId.includes('temp') || imageId.includes('error')
      ).length > 0
    )
      return true;

    return false;
  }, [isKeyboardOn, watch('title'), watch('images')]);

  const handleSubmit = () => {
    if (isKeyboardOn) return;

    handleFormSubmit(
      (data) => {
        push('GroupMeetupNewOptionsPage', {
          groupId,
          meetupCreateForm: JSON.stringify(data),
          calloutType,
          from,
        });
      },
      (errors) => {
        const errorList = convertHookFormErrorObjectToArray(errors);

        const firstError = errorList[0];

        bridge.openToast({
          toast: {
            body: firstError.value.message ?? '',
          },
        });
      }
    )();
  };

  return (
    <AppScreen
      appBar={{
        backButton: {
          render: () => (
            <div className={s.closeButtonWrapper} onClick={back}>
              <IconXmarkLine />
            </div>
          ),
        },
        closeButton: {
          render: () => (
            <div className={s.closeButtonWrapper} onClick={back}>
              <IconXmarkLine />
            </div>
          ),
        },
      }}
      accessoryBar={
        <div
          className={s.accessoryBarWrapper}
          style={{
            padding: isKeyboardOn
              ? '0'
              : '0.75rem 1rem calc(0.5rem + env(safe-area-inset-bottom)) 1rem',
          }}
        >
          <BoxButton
            width="100%"
            variant="primary"
            size="xlarge"
            isDisabled={isDisabledButton}
            onClick={handleSubmit}
            UNSAFE_style={isKeyboardOn ? { borderRadius: 0 } : undefined}
          >
            다음
          </BoxButton>
        </div>
      }
    >
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
        <form>
          <GroupMeetupNewView hookForm={hookForm} />
        </form>
      </AsyncBoundary>
    </AppScreen>
  );
};

export default GroupMeetupNewPage;

type Props = {
  hookForm: UseFormReturn<GroupMeetupCreateForm>;
};
const GroupMeetupNewView = ({ hookForm: { watch, setValue } }: Props) => {
  const { poiName } = useQueryParams();

  return (
    <div className={s.wrapper}>
      <GroupMeetupFormTitleField
        name="title"
        watch={watch}
        setValue={setValue}
        placeholder="멤버들과 어떤 활동을 할까요?"
        maxLength={meetupFieldSchema.title.maxLength ?? 100}
        onClick={() => {
          closeListItemFormFieldQuerySelector([
            'meetupTimeAt',
            'hasMeetupTimeAtOnlyDate',
            'creationCycle',
            'maximumParticipantsNumber',
          ]);
        }}
      />
      <GroupMeetupFormMeetupDateTimeField
        dateName="meetupTimeAt"
        onlyDateName="hasMeetupTimeAtOnlyDate"
        watch={watch}
        setValue={setValue}
        onClick={() => {
          closeListItemFormFieldQuerySelector([
            'meetupTimeAt',
            'hasMeetupTimeAtOnlyDate',
            'creationCycle',
            'maximumParticipantsNumber',
          ]);
        }}
      />
      <Divider />
      <GroupMeetupCreationCycleField
        creationCycleName="creationCycle"
        dateName="meetupTimeAt"
        watch={watch}
        setValue={setValue}
        onClick={() => {
          closeListItemFormFieldQuerySelector([
            'meetupTimeAt',
            'hasMeetupTimeAtOnlyDate',
            'creationCycle',
            'maximumParticipantsNumber',
          ]);
        }}
      />
      <Divider />
      <GroupMeetupFormPoiField
        name="poiItems.0"
        setValue={setValue}
        defaultValue={poiName}
        placeholder="미정"
        onClick={() => {
          closeListItemFormFieldQuerySelector([
            'meetupTimeAt',
            'hasMeetupTimeAtOnlyDate',
            'creationCycle',
            'maximumParticipantsNumber',
          ]);
        }}
      />
      <Divider />
      <GroupMeetupFormMaximumParticipantsNumberField
        name="maximumParticipantsNumber"
        watch={watch}
        setValue={setValue}
        onClick={() => {
          closeListItemFormFieldQuerySelector([
            'meetupTimeAt',
            'hasMeetupTimeAtOnlyDate',
            'creationCycle',
            'maximumParticipantsNumber',
          ]);
        }}
      />
      <Divider />
      <GroupMeetupFormDescriptionField
        name="description"
        watch={watch}
        setValue={setValue}
        placeholder="활동에 대한 설명을 추가해주세요."
        onClick={() => {
          closeListItemFormFieldQuerySelector([
            'meetupTimeAt',
            'hasMeetupTimeAtOnlyDate',
            'creationCycle',
            'maximumParticipantsNumber',
          ]);
        }}
      />
      <Divider />
      <GroupMeetupFormImagesField name="images" setValue={setValue} />
      <Spacing size={58} />
      <RunningGrowthCallout />
      <Spacing size={4} />
    </div>
  );
};
