import type VConsole from 'vconsole';

const INTERNAL_TOOL_PAGE_KEY = 'internal-tool-page';

const AB_TEST_SELECT_KEY = 'ab-test-select';
const ABC_TEST_SELECT_KEY = 'abc-test-select';
const AB_TEST_BUTTON_KEY = 'ab-test-submit-button';
const AB_TEST_RESET_BUTTON_KEY = 'ab-test-reset-button';

export const AB_TEST_LOCAL_STORAGE_KEY = 'ExperimentABTest';
export const ABC_TEST_LOCAL_STORAGE_KEY = 'ExperimentABCTest';

export const INTERNAL_TOOL_PLUGIN_ID = 'internal_tool';
export const INTERNAL_TOOL_PLUGIN_NAME = 'Internal Tool';

export const initInternalToolVConsolePlugin = (vConsoleModule: typeof VConsole) => {
  const abTestPlugin = new vConsoleModule.VConsolePlugin(
    INTERNAL_TOOL_PLUGIN_ID,
    INTERNAL_TOOL_PLUGIN_NAME
  );

  abTestPlugin.on('renderTab', (callback) => {
    const view = internalToolTemplate();

    callback(view);

    const submitButton = document.getElementById(AB_TEST_BUTTON_KEY);
    submitButton?.addEventListener('click', () => {
      const abSelect = document.getElementById(AB_TEST_SELECT_KEY) as HTMLSelectElement;
      const abcSelect = document.getElementById(ABC_TEST_SELECT_KEY) as HTMLSelectElement;

      const abTestValue = abSelect.value;
      const abcTestValue = abcSelect.value;

      window.localStorage.setItem(AB_TEST_LOCAL_STORAGE_KEY, abTestValue);
      window.localStorage.setItem(ABC_TEST_LOCAL_STORAGE_KEY, abcTestValue);
    });

    const resetButton = document.getElementById(AB_TEST_RESET_BUTTON_KEY);
    resetButton?.addEventListener('click', () => {
      window.localStorage.removeItem(AB_TEST_LOCAL_STORAGE_KEY);
      window.localStorage.removeItem(ABC_TEST_LOCAL_STORAGE_KEY);
    });

    const internalPageButton = document.getElementById(INTERNAL_TOOL_PAGE_KEY);
    internalPageButton?.addEventListener('click', () => {
      window.location.href = '/internal-tool';
    });
  });

  return abTestPlugin;
};

const internalToolTemplate = () => {
  const currentABTestVariant = window.localStorage.getItem(AB_TEST_LOCAL_STORAGE_KEY) ?? 'A';
  const currentABCTestVariant = window.localStorage.getItem(ABC_TEST_LOCAL_STORAGE_KEY) ?? 'A';

  return `
    <div style="width: 100%; padding: 8px;">
      <h1 style="padding-bottom: 8px; font-size: 20px;">Internal Tool</h1>
      <div style="margin-bottom: 24px;">
      <button id=${INTERNAL_TOOL_PAGE_KEY} type="button" style="font-weight: 700; font-size: 14px; padding: 8px 12px; border-radius: 4px; border: 1px solid #ff8e4d; background: #ff8e4d; color:white;">Internal Tool 페이지 이동</button>
      
      <div style="margin-bottom: 24px;"></div>

      <h1 style="padding-bottom: 8px; font-size: 20px;">A/B Test Settings</h1>
      <div style="margin-bottom: 24px;">
        <label for=${AB_TEST_SELECT_KEY} style="font-size: 16px; font-weight: 700; margin-right: 8px;">AB Test Variant:</label>
        <select id=${AB_TEST_SELECT_KEY} style="font-size: 16px; width: 120px; padding: 8px 12px; border-radius: 4px; margin-right: 8px;">
          <option value="A" ${currentABTestVariant === 'A' ? 'selected' : ''}>A</option>
          <option value="B" ${currentABTestVariant === 'B' ? 'selected' : ''}>B</option>
        </select>
      </div>
      <div style="margin-bottom: 24px;">
        <label for=${ABC_TEST_SELECT_KEY} style="font-size: 16px; font-weight: 700; margin-right: 8px;">ABC Test Variant:</label>
        <select id=${ABC_TEST_SELECT_KEY} style="font-size: 16px; width: 120px; padding: 8px 12px; border-radius: 4px; margin-right: 8px;">
          <option value="A" ${currentABCTestVariant === 'A' ? 'selected' : ''}>A</option>
          <option value="B" ${currentABCTestVariant === 'B' ? 'selected' : ''}>B</option>
          <option value="C" ${currentABCTestVariant === 'C' ? 'selected' : ''}>C</option>
        </select>
      </div>
      <button id=${AB_TEST_BUTTON_KEY} type="button" style="font-weight: 700; font-size: 14px; padding: 8px 12px; border-radius: 4px; border: 1px solid #777; background: #777">Apply</button>
      <button id=${AB_TEST_RESET_BUTTON_KEY} type="button" style="font-weight: 700; font-size: 14px; padding: 8px 12px; border-radius: 4px; border: 1px solid #777; background: #777">초기화</button>
    </div>
  `;
};
