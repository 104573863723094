import { USER_AGENT_REGEX } from '@/constants/regex';

export const parseUserAgent = (userAgent: string, regex?: RegExp) => {
  try {
    const result = userAgent.match(regex || USER_AGENT_REGEX);

    if (!result || !result.groups) {
      return null;
    }

    const {
      versionCode: appVersionCode,
      versionName: appVersion,
      osType,
      osVersion,
      appType,
      buildType,
    } = result.groups;

    const isLegacyIOS = osType === 'iOS' && osVersion?.startsWith('14');
    return {
      appVersionCode,
      appVersion,
      osType,
      osVersion,
      appType,
      buildType,
      isLegacyIOS,
    };
  } catch (e) {
    // TODO: 에러를 보여주거나 default UA를 내려주거나 하기
    console.log('ua parse error', e);
  }
};
