import { AsyncBoundary, Typography } from '@community-group/components';
import { Spacing } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useMemo } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

import { useReadGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { usePutGroup } from '@/api/hooks/usePutGroup';
import { FormInput } from '@/components/common/base/Input/Text';
import { FixedButton } from '@/components/common/Form/components/FixedButton';
import { useBridge } from '@/contexts/Bridge';
import { useConnectedFlow } from '@/stackflow';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';
import { refetchCurrentUser } from '@/utils/refetch/currentUser';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';
import { refetchGroupProfile } from '@/utils/refetch/groupGroupProfile';
import { refetchGroupSetting } from '@/utils/refetch/groupSetting';
import { groupBoardCategoryFieldSchema } from '@/utils/validate/formSchema/boardCategory';
import { groupSubNicknameQuestionFieldSchema } from '@/utils/validate/formSchema/subNickname';
import { validateSchemaWithHandleToast } from '@/utils/validate/util';

export type EditGroupSubnicknameQuestionBottomSheetParams = Pick<PageParams, 'groupId'>;

const EditGroupSubnicknameQuestionBottomSheet: ActivityComponentType<
  EditGroupSubnicknameQuestionBottomSheetParams
> = () => {
  const { pop } = useConnectedFlow();
  return (
    <BottomSheet
      onOutsideClick={(e) => {
        e.preventDefault();
        pop().send({ isSettingOn: false });
      }}
    >
      <AsyncBoundary pendingFallback={<></>} rejectedFallback={<></>}>
        <EditGroupSubnicknameQuestionBottomSheetWrapper />
      </AsyncBoundary>
    </BottomSheet>
  );
};

const EditGroupSubnicknameQuestionBottomSheetWrapper = () => {
  const { groupId } = usePathParams();
  const { from } = useQueryParams();
  const { pop } = useConnectedFlow();
  const { group } = useReadGroupDetail(groupId);
  const { bridge } = useBridge();
  const { mutate } = usePutGroup({
    onSuccess: () => {
      bridge.openToast({
        toast: {
          body: '별명이 잠시 후 반영돼요.',
        },
      });
      trackEvent({
        event: 'click_setting_on_subnickname',
        params: {
          groupId: groupId,
          groupName: group?.name,
          groupCategory: group?.category.name,
          isSettingOnSubnickname: false,
          subnicknameRequestText: '',
          from,
        },
      });
      refetchGroupDetail({ groupId });
      refetchCurrentUser({ groupId });
      refetchGroupProfile();
      refetchGroupSetting({ groupId });
    },
  });

  const { watch, register } = useForm<FieldValues>({
    defaultValues: {
      name: group?.subNicknameSetting.requestText ?? '',
    },
  });

  const nameFieldValue = watch('name');

  const isButtonDisabled = useMemo(() => {
    if (nameFieldValue.trim().length === 0) return true;

    return false;
  }, [nameFieldValue]);

  const validateForm = () => {
    if (!validateSchemaWithHandleToast(groupSubNicknameQuestionFieldSchema, nameFieldValue))
      return false;

    return true;
  };

  const handleSubmit = () => {
    if (!validateForm()) return;

    mutate(
      {
        id: groupId,
        groupModifyForm: {
          subNicknameSetting: {
            isSettingOn: true,
            requestText: nameFieldValue,
          },
        },
      },
      { onSuccess: () => pop().send({ isSettingOn: true }) }
    );
  };

  return (
    <>
      <Spacing size={16} />
      <Typography typography="title3Bold" color="gray900">
        별명 입력 요청 문구
      </Typography>
      <Spacing size={6} />
      <Typography typography="subtitle1Regular" color="gray700">
        새로운 멤버가 가입했을 때 어떻게 별명을 작성하면 되는지 안내하는 문구를 적어주세요.
      </Typography>
      <Spacing size={16} />
      <FormInput
        register={register}
        name="name"
        placeholder="별명 입력 요청 문구를 적어주세요."
        minLength={groupBoardCategoryFieldSchema.name.minLength ?? 0}
        maxLength={groupBoardCategoryFieldSchema.name.maxLength ?? 0}
        value={nameFieldValue}
      />
      <Spacing size={16} />
      <FixedButton disabled={isButtonDisabled} onClick={handleSubmit}>
        저장
      </FixedButton>
    </>
  );
};

export default EditGroupSubnicknameQuestionBottomSheet;
