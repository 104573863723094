import { GroupMemberGrade } from '@community-group/api/lib/group/models';
import { useBottomSheet } from '@community-group/components';
import { Spacing } from '@community-group/components';
import { IconCheckmarkLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';

import * as s from './MemberGradeBottomSheet.css';

type Props = {
  memberGrades: GroupMemberGrade[];
  selectedMemberGradeId?: string;
  setSelectedMemberGradeId?: (memberGradeId?: string) => void;
};

export const MemberGradeBottomSheet = ({
  memberGrades,
  selectedMemberGradeId,
  setSelectedMemberGradeId,
}: Props) => {
  const { closeAsync: closeBottomSheet } = useBottomSheet();

  const handleClick = (value?: GroupMemberGrade) => {
    setSelectedMemberGradeId?.(value?.id);
    closeBottomSheet();
  };

  return (
    <div className={s.Wrapper}>
      <h1 className={s.Title}>멤버 등급</h1>
      <Spacing size={16} />
      <ul className={s.FilterList}>
        <li
          className={s.FilterItem({ selected: !selectedMemberGradeId })}
          onClick={() => handleClick()}
        >
          전체
          {!selectedMemberGradeId && (
            <div className={s.IconWrapper}>
              <IconCheckmarkLine size={24} color={vars.$scale.color.carrot500} />
            </div>
          )}
        </li>
        {memberGrades.map((grade) => (
          <li
            className={s.FilterItem({ selected: grade.id === selectedMemberGradeId })}
            key={grade.id}
            onClick={() => {
              handleClick(grade);
            }}
          >
            {grade.name}
            {grade.id === selectedMemberGradeId && (
              <div className={s.IconWrapper}>
                <IconCheckmarkLine size={24} color={vars.$scale.color.carrot500} />
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};
