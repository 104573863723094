import { Category, CategoryItemType } from '@community-group/api/lib/group/models';
import { ChipRadio, ChipRadioGroup, Spacing } from '@community-group/components';
import { useMemo } from 'react';
import { $Values } from 'utility-types';

import { ORDER_HOME, ORDER_HOME_KR_TYPO } from '@/api/base/group';
import { useGetCategories } from '@/api/hooks/useGetCategories';
import GroupIcon from '@/assets/images/group_icon.png';
import { Container } from '@/components/common/Container';

import CategoryFilterList from '../../common/Filter/CategoryFilterList';
import { FilterType } from '.';
import * as s from './GroupListFilter.css';

type Props = {
  filter: FilterType;
  setFilter: React.Dispatch<React.SetStateAction<FilterType>>;
};

const GroupListFilter = ({ filter, setFilter }: Props) => {
  const { categories } = useGetCategories();

  const categoryList = useMemo(
    () => [{ id: 0, name: '전체', icon: GroupIcon, createdAt: '' }, ...categories],
    [categories]
  );
  return (
    <div className={s.wrapper}>
      <Container paddingY={0} paddingX={0}>
        <CategoryFilterList
          categories={categoryList}
          onClickItem={(item: Category | CategoryItemType) => {
            setFilter((prevFilter) => ({
              ...prevFilter,
              category: item.id !== 0 ? item.id : undefined,
            }));
          }}
        />

        <Spacing size={8} />

        <div className={s.switchOrderWrapper}>
          <ChipRadioGroup
            value={filter.order}
            size="small"
            onChange={(value) => {
              setFilter((prevFilter) => ({
                ...prevFilter,
                order: value as $Values<typeof ORDER_HOME>,
              }));
            }}
          >
            <ChipRadio value={ORDER_HOME.RECOMMEND_SCORE_DESC}>
              {ORDER_HOME_KR_TYPO[ORDER_HOME.RECOMMEND_SCORE_DESC]}
            </ChipRadio>
            <ChipRadio value={ORDER_HOME.CREATED_AT_DESC}>
              {ORDER_HOME_KR_TYPO[ORDER_HOME.CREATED_AT_DESC]}
            </ChipRadio>
          </ChipRadioGroup>
        </div>
      </Container>
    </div>
  );
};

export default GroupListFilter;
