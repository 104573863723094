import { groupClient } from '@community-group/api';
import { model } from '@community-group/api/lib/group';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/shared/api/hooks/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Request = { id: number; challengeId: number; form: model.GroupChallengeUpdateForm };

type Props = UseMutationOptions<AxiosResponse, Error, Request>;

// 챌린지 수정 API
const usePatchChallenge = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const patchChallenge =
    groupClient.api.GroupChallengeApi.apiV1GroupsGroupIdChallengesChallengeIdPatch({
      axios: fetchInstance,
    });

  const patchChallengeWrapperFp = (req: Request) => {
    return patchChallenge({
      groupId: req.id,
      challengeId: req.challengeId,
      groupChallengeUpdateForm: req.form,
    });
  };

  return useThrottleMutation(patchChallengeWrapperFp, {
    onError,
    onSuccess,
  });
};

export default usePatchChallenge;
