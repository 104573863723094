import { GroupCurrentUser, GroupFeedSummary } from '@community-group/api/lib/group/models';
import { Typography } from '@community-group/components';
import { VerticalSpacing } from '@community-group/components';
import {
  IconHorizline2VerticalChatbubbleRectangularRightLine,
  IconThumbUpFill,
  IconThumbUpLine,
} from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { useActivity } from '@stackflow/react';
import { useCallback } from 'react';

import { useDeleteMeetupEmotion } from '@/api/hooks/useDeleteMeetupEmotion';
import { getGroupMeetupEmotionListPath } from '@/api/hooks/useGetGroupMeetupEmotionList';
import { usePatchMeetupEmotion } from '@/api/hooks/usePatchMeetupEmotion';
import { IconShareRegularExperiment } from '@/components/common/Icons/shareRegular';
import { useBridge } from '@/contexts/Bridge';
import { updateGroupMemberFeeds } from '@/domain/GroupFeed/utils/updateGroupMemberFeeds';
import useHandlePostEmotion from '@/hooks/useHandlePostEmotion';
import { useShareMeetupContents } from '@/hooks/useShareMeetupContents';
import { queryClient } from '@/shared/api/instance';
import { useShareContents } from '@/shared/utils/useShareContents';
import { trackEvent } from '@/utils/analytics';
import { isNotMember } from '@/utils/role';

import * as s from './style.css';

type Props = {
  post: GroupFeedSummary;
  groupId: string;
  currentUser: GroupCurrentUser;
  refetchFeed: () => void;
};

const FeedItemFeedbackBoxV2 = ({ post, ...props }: Props) => {
  const feedType = post.type;

  if (feedType === 'group_meetup') {
    return <MeetupFeedItemFeedbackBox post={post} {...props} />;
  }

  if (feedType === 'challenge') {
    // TODO: 챌린지 공유 기능 개발 완료시 오픈
    return null;
    // return <ChallengeFeedItemFeedbackBox post={post} {...props} />;
  }

  return <PostFeedItemFeedbackBox post={post} {...props} />;
};

const MeetupFeedItemFeedbackBox = ({ post, groupId, refetchFeed, currentUser }: Props) => {
  const isPrivatePost = post.publishType === 'group_only';
  const isNotMemberUser = isNotMember(currentUser.role);

  const { bridge } = useBridge();
  const { name: activityName } = useActivity();

  const refetchMeetupEmotionList = () => {
    const queryKey = getGroupMeetupEmotionListPath(Number(groupId), post.id);
    queryClient.refetchQueries({
      queryKey: [queryKey],
    });
    refetchFeed();
  };
  const { mutate: deleteMeetupEmotion } = useDeleteMeetupEmotion({
    onSuccess: () => {
      refetchMeetupEmotionList();
      updateGroupMemberFeeds({
        type: 'dislike',
        groupId,
        userId: post.author.id.toString(),
        targetId: post.id.toString(),
      });
    },
  });
  const { mutate: patchMeetupEmotion } = usePatchMeetupEmotion({
    onSuccess: () => {
      refetchMeetupEmotionList();
      updateGroupMemberFeeds({
        type: 'like',
        groupId,
        userId: post.author.id.toString(),
        targetId: post.id.toString(),
      });
    },
  });

  const handleLikeClick = (event) => {
    if (isPrivatePost && isNotMemberUser) return;

    event.stopPropagation();
    trackEvent({
      event: 'click_like',
      params: {
        contentType: 'meetup',
        from: activityName ?? '',
      },
      sample: true,
    });

    bridge.setHapticLightFeedback({});
    if (post.emotion?.myEmotion) {
      deleteMeetupEmotion({ groupId, meetupId: post.id.toString() });
      return;
    }
    patchMeetupEmotion({ groupId, meetupId: post.id.toString() });
  };

  const handleShareMeetupContents = useShareMeetupContents();
  const handleShareClick = (e) => {
    if (!currentUser) return;

    e.stopPropagation();
    handleShareMeetupContents({
      groupId,
      meetupId: post.id.toString(),
      permalinkId: post.permalinkId,
      currentUserRole: currentUser.role,
      from: 'feedItem',
    });
  };

  return (
    <div className={s.Container}>
      <div className={s.LeftBox}>
        <div className={s.IconContainer}>
          <button className={s.LikeButton} onClick={handleLikeClick}>
            {post.emotion.myEmotion ? (
              <IconThumbUpFill width={22} height={22} color={vars.$semantic.color.primary} />
            ) : (
              <IconThumbUpLine width={22} height={22} color={vars.$scale.color.gray800} />
            )}
          </button>
          {post.emotion.count > 0 && (
            <>
              <VerticalSpacing size={4} />
              <Typography
                typography="caption1Regular"
                color={post.emotion?.myEmotion ? 'primary' : 'gray900'}
              >
                {post.emotion.count}
              </Typography>
            </>
          )}
        </div>
        <VerticalSpacing size={20} />
        <div className={s.IconContainer}>
          <IconHorizline2VerticalChatbubbleRectangularRightLine
            width={22}
            height={22}
            color={vars.$scale.color.gray800}
          />
          {post.commentCount > 0 && (
            <>
              <VerticalSpacing size={4} />
              <Typography typography="caption1Regular" color="gray900">
                {post.commentCount}
              </Typography>
            </>
          )}
        </div>
        <VerticalSpacing size={20} />
        <div className={s.IconContainer} onClick={handleShareClick}>
          <IconShareRegularExperiment
            currentUserRole={currentUser.role}
            width={22}
            height={22}
            color={vars.$scale.color.gray800}
          />
        </div>
      </div>
      {post.readCount > 0 && (
        <Typography typography="caption1Regular" color="gray600">
          조회 {post.readCount}
        </Typography>
      )}
    </div>
  );
};

const PostFeedItemFeedbackBox = ({ post, groupId, refetchFeed, currentUser }: Props) => {
  const isPrivatePost = post.publishType === 'group_only';
  const isNotMemberUser = isNotMember(currentUser.role);

  const { mutateDislikePost, mutateLikePost } = useHandlePostEmotion({
    onLikeClick: refetchFeed,
    onDislikeClick: refetchFeed,
  });
  const { bridge } = useBridge();
  const { name: activityName } = useActivity();

  const handleLikeClick = useCallback(
    (liked: boolean, id: string) => {
      bridge.setHapticLightFeedback({});

      const requestBody = {
        groupId,
        postId: id,
        authorId: post.author.id.toString(),
        boardCategories: post.boardCategories?.map((categories) => categories.id),
      };

      if (liked) {
        mutateDislikePost(requestBody);
        return;
      }
      mutateLikePost(requestBody, {
        onSuccess: () => {
          trackEvent({
            event: 'click_like',
            params: {
              contentType: 'post',
              from: activityName ?? '',
            },
            sample: true,
          });
        },
      });
      return;
    },
    [bridge, groupId, post.author.id, post.boardCategories, mutateLikePost, mutateDislikePost]
  );

  const handleSharePostContents = useShareContents();
  const handleShareClick = (e) => {
    if (!post.type) return;
    if (isPrivatePost && isNotMemberUser) return;

    e.stopPropagation();
    handleSharePostContents({
      groupId,
      postId: post.id.toString(),
      postType: post.type,
      permalinkId: post.permalinkId,
      currentUserRole: currentUser.role ?? 'none',
      from: 'feedItem',
    });
  };

  const isMyEmotion = Boolean(post.emotion.myEmotion);

  return (
    <div className={s.Container}>
      <div className={s.LeftBox}>
        <div className={s.IconContainer}>
          <button
            className={s.LikeButton}
            onClick={(e) => {
              if (isPrivatePost && isNotMemberUser) return;

              e.stopPropagation();
              handleLikeClick(isMyEmotion, String(post.id));
            }}
          >
            {isMyEmotion ? (
              <IconThumbUpFill width={22} height={22} color={vars.$semantic.color.primary} />
            ) : (
              <IconThumbUpLine width={22} height={22} color={vars.$scale.color.gray800} />
            )}
          </button>
          {post.emotion.count > 0 && (
            <>
              <VerticalSpacing size={4} />
              <Typography typography="caption1Regular" color={isMyEmotion ? 'primary' : 'gray900'}>
                {post.emotion.count}
              </Typography>
            </>
          )}
        </div>
        <VerticalSpacing size={20} />
        <div className={s.IconContainer}>
          <IconHorizline2VerticalChatbubbleRectangularRightLine
            width={22}
            height={22}
            color={vars.$scale.color.gray800}
          />
          {post.commentCount > 0 && (
            <>
              <VerticalSpacing size={4} />
              <Typography typography="caption1Regular" color="gray900">
                {post.commentCount}
              </Typography>
            </>
          )}
        </div>
        <VerticalSpacing size={20} />
        <div className={s.IconContainer} onClick={handleShareClick}>
          <IconShareRegularExperiment
            currentUserRole={currentUser.role}
            width={22}
            height={22}
            color={vars.$scale.color.gray800}
          />
        </div>
      </div>
      {post.readCount > 0 && (
        <Typography typography="caption1Regular" color="gray600">
          조회 {post.readCount}
        </Typography>
      )}
    </div>
  );
};

export default FeedItemFeedbackBoxV2;

const ChallengeFeedItemFeedbackBox = ({ post, groupId, refetchFeed, currentUser }: Props) => {
  const handleShareClick = (e) => {
    e.stopPropagation();
    if (!post.type) return;
    // shareGroupChallengePermalink(post.challengeInfo.publicId);
  };

  return (
    <div className={s.Container}>
      <div className={s.LeftBox}>
        <div className={s.IconContainer} onClick={handleShareClick}>
          <IconShareRegularExperiment
            currentUserRole={currentUser.role}
            width={22}
            height={22}
            color={vars.$scale.color.gray800}
          />
        </div>
      </div>
      {post.readCount > 0 && (
        <Typography typography="caption1Regular" color="gray600">
          조회 {post.readCount}
        </Typography>
      )}
    </div>
  );
};
