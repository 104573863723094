import { groupClient } from '@community-group/api';
import { model } from '@community-group/api/lib/group';
import { PostDetail } from '@community-group/api/lib/group/models';
import { useQuery, UseQueryOptions, useSuspenseQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { GROUP_URL } from '../base/post';
import { useFetchInstance } from './instance/useFetchInstance';

type OptionType = UseQueryOptions<
  AxiosResponse<model.PostResponse, any> | undefined,
  unknown,
  AxiosResponse<model.PostResponse, any> | undefined,
  string[]
>;

export const getPostDetailPath = (groupId?: string, postId?: string) =>
  `${GROUP_URL}/${groupId}/posts/${postId}`;

export const useGetPostDetail = (groupId?: string, postId?: string, options?: OptionType) => {
  const fetchInstance = useFetchInstance();
  const getPostDetail = groupClient.api.PostApi.apiV1GroupsIdPostsPostIdGet({
    axios: fetchInstance,
  });

  const { data, refetch, error, isLoading } = useSuspenseQuery({
    queryKey: [getPostDetailPath(groupId, postId)],
    queryFn: () => getPostDetail({ id: Number(groupId), postId: Number(postId) }),
    ...options,
    retry: 1,
    staleTime: 1000 * 30,
    refetchOnWindowFocus: ({ state }) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (state?.error?.response?.status === 404) {
        return false;
      }

      return true;
    },
  });

  const currentError = error as AxiosError;

  return { post: data?.data.post as PostDetail, refetch, error: currentError, isLoading };
};
