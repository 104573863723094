import { groupClient } from '@community-group/api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

import { GROUP_PERMISSION_QUERY_KEY } from '../queries';

export const useQueryGroupPermissions = (groupId: string) => {
  const read = useAsyncRead(groupClient.api.PermissionsApi.apiV1GroupsIdPermissionsGet);
  return {
    queryKey: GROUP_PERMISSION_QUERY_KEY.base(groupId),
    queryFn: () =>
      read({
        id: Number(groupId),
      }).then((res) => res.permissions),
    refetchOnMount: 'always' as const, // NOTE: 늘 최신의 권한 데이터를 조회해야 해요 T-95131
  };
};

export const useReadGroupPermissions = (groupId: string) => {
  const query = useQueryGroupPermissions(groupId);
  return useSuspenseQuery({ ...query });
};
