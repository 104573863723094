import {
  GroupMemberRoleEnum,
  GroupMemberSummaryPresentation,
} from '@community-group/api/lib/group/models';
import { IconChevronRightFill } from '@daangn/react-monochrome-icon';
import { useCallback } from 'react';

import ProfileListItem, { ProfileListItemProps } from '@/components/common/Profile/ListItem';
import { useBridge } from '@/contexts/Bridge';
import { useReadGroupHostMembers } from '@/domain/GroupMember/hooks/useReadGroupHostMembers';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { trackEvent } from '@/utils/analytics';

import { useHandleProfileMoreButton } from '../hooks/useHandleProfileMoreButton';
import * as s from './MemberProfileSection.css';

interface Props extends ProfileListItemProps {
  approvePage?: boolean;
  renderRightButton?: React.ReactNode;
  currentUserRole?: GroupMemberRoleEnum;
  moveGroupUserProfileDetailPage: () => void;
}

const MemberProfileSection = ({
  user,
  approvePage,
  renderRightButton,
  appendDescription,
  currentUserRole,
  moveGroupUserProfileDetailPage,
}: Props) => {
  const { groupId } = usePathParams();

  const {
    data: { members: hostMemberList },
  } = useReadGroupHostMembers({ groupId, shownOnlyHost: true });

  const { renderProfileMoreButton } = useHandleProfileMoreButton({
    groupId,
    profileUser: user as GroupMemberSummaryPresentation,
    hostMemberList,
  });
  const { bridge } = useBridge();
  const RightSectionContainer = useCallback(() => {
    if (approvePage) {
      return (
        <button
          onClick={() => {
            return moveGroupUserProfileDetailPage();
          }}
        >
          <IconChevronRightFill size={20} />
        </button>
      );
    }

    return renderProfileMoreButton();
  }, [approvePage, moveGroupUserProfileDetailPage, renderProfileMoreButton]);

  return (
    <li className={s.listItem}>
      <div
        className={s.leftSection}
        onClick={() => {
          if (user.state === 'notMember') {
            bridge.openToast({ toast: { body: '모임 멤버가 아니에요.' } });
            return;
          }

          moveGroupUserProfileDetailPage();

          trackEvent({
            event: 'click_profile',
            params: {
              clickedUserId: user.id,
              clickedUserRole: user.role,
              clickedUserState: user.state,
              currentUserRole: currentUserRole,
            },
          });
          return;
        }}
      >
        <ProfileListItem user={user} appendDescription={appendDescription} />
      </div>

      <div className={s.rightSection}>{renderRightButton ?? <RightSectionContainer />}</div>
    </li>
  );
};

export default MemberProfileSection;
