import { groupClient } from '@community-group/api';
import { useInfiniteQuery, useSuspenseInfiniteQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

export const getUserProfileCommentsPath = (groupId: number, userId: number) =>
  groupClient.api.GroupMemberApi.getapiV1GroupsIdMembersUserIdCommentsGetPath(groupId, userId);

export const useGetUserProfileComments = (
  id: number,
  userId: number,
  initialCursor?: string | undefined
) => {
  const fetchInstance = useFetchInstance();
  const getProfileComments = groupClient.api.GroupMemberApi.apiV1GroupsIdMembersUserIdCommentsGet({
    axios: fetchInstance,
  });

  const { fetchNextPage, hasNextPage, isFetchingNextPage, data, refetch } =
    useSuspenseInfiniteQuery({
      queryKey: [getUserProfileCommentsPath(id, userId)],
      queryFn: ({ pageParam = initialCursor }) =>
        getProfileComments({ id, userId, cursor: pageParam, limit: 100 }),
      initialPageParam: initialCursor,
      getNextPageParam: ({ data }) => (data.hasNext ? data?.endCursor ?? undefined : undefined),
      refetchInterval: 10000,
    });

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
  };
};
