import { GroupMemberRoleEnum } from '@community-group/api/lib/group/models';
import {
  ActionList,
  ActionListItem,
  ActionSheet,
  BoxButton,
  useBottomSheet,
  useSnackbarAdapter,
} from '@community-group/components';
import { IconDot3VerticalFill } from '@daangn/react-monochrome-icon';

import { useDeleteUserChatRequestGroupDirectChats } from '@/api/hooks/useDeleteUserChatRequestGroupDirectChats';
import { getGroupMainProfileQueryKey } from '@/api/hooks/useGetGroupMainProfile';
import { GROUP_CHAT_REQUEST_QUERY_KEY } from '@/domain/GroupDirectChat/queries';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { queryClient } from '@/shared/api/instance';
import { trackEvent } from '@/utils/analytics';

import * as s from './GroupDirectChatStatusOnAwaitingApprovalButton.css';

type Props = {
  groupId: string;
  targetUserId: string;
  requestGroupDirectChatId: number;
  requestUserRole: GroupMemberRoleEnum;
};

export const GroupDirectChatStatusOnAwaitingApprovalButton = ({
  requestGroupDirectChatId,
  targetUserId,
  groupId,
  requestUserRole,
}: Props) => {
  const { open: openBottomSheet, closeAsync: closeBottomSheet } = useBottomSheet();

  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate } = useDeleteUserChatRequestGroupDirectChats({
    onError: handleErrorWithToast,
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: getGroupMainProfileQueryKey(Number(targetUserId), Number(groupId)),
      });
      queryClient.invalidateQueries({
        queryKey: GROUP_CHAT_REQUEST_QUERY_KEY.directChatState(groupId, targetUserId),
      });
    },
  });

  const snackbarAdapter = useSnackbarAdapter();
  const handleDeleteClick = () => {
    closeBottomSheet();
    trackEvent({
      event: 'click_cancel_1on1_chat_request_button',
      params: {
        groupId,
        domain: 'group',
        role: requestUserRole,
      },
      sample: true,
    });

    mutate(
      { requestGroupDirectChatId },
      {
        onSuccess: () => {
          snackbarAdapter.create({
            message: '채팅 신청을 취소했어요.',
            type: 'default',
            timeout: 3000,
            onClick: () => {
              snackbarAdapter.dismiss();
            },
          });
        },
      }
    );
  };

  const handleMoreButtonClick = () => {
    openBottomSheet({
      element: (
        <ActionSheet
          bottomButton={{
            label: '닫기',
          }}
        >
          <ActionList>
            <ActionListItem onClick={handleDeleteClick}>채팅 신청 취소</ActionListItem>
          </ActionList>
        </ActionSheet>
      ),
    });
  };

  return (
    <>
      <BoxButton
        size="medium"
        variant="secondary"
        UNSAFE_style={{
          width: '100%',
        }}
        isDisabled={true}
      >
        채팅 수락 대기 중
      </BoxButton>
      <BoxButton
        onClick={handleMoreButtonClick}
        size="medium"
        variant="secondary"
        UNSAFE_className={s.IconButton}
      >
        <IconDot3VerticalFill />
      </BoxButton>
    </>
  );
};
