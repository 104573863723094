import { groupClient } from '@community-group/api';

import { queryClient } from '@/shared/api/instance';

/*
 *  현재 프로필 상태를 refetch하는 함수
 */
export const refetchGroupProfile = () => {
  queryClient.refetchQueries({
    queryKey: ['MyMainProfile'],
  });
  queryClient.refetchQueries({
    queryKey: [
      'GroupProfleCheck',
      groupClient.api.GroupProfileApi.getapiV1GroupsProfileCheckGetPath(),
    ],
  });
  queryClient.refetchQueries({
    queryKey: ['GroupProfile', groupClient.api.GroupProfileApi.getapiV1GroupsProfileGetPath()],
  });
  queryClient.refetchQueries({ queryKey: ['groupProfile'] });
};
