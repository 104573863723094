import {
  GroupMemberRoleEnum,
  GroupMemberSummaryWithActivityPresentation,
} from '@community-group/api/lib/group/models';
import { isHigherManager, isSuperHost, Typography } from '@community-group/components';
import { Spacing, VerticalSpacing } from '@community-group/components';
import { IconClockFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { useCallback } from 'react';

import { MemberListOrderType } from '@/api/hooks/useGetMemberList';
import ProfileListItem from '@/components/common/Profile/ListItem';
import { useReadGroupHostMembers } from '@/domain/GroupMember/hooks/useReadGroupHostMembers';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { trackEvent } from '@/utils/analytics';
import { formatDate, getDateDiffFromNow } from '@/utils/date';
import { numberToLocaleString } from '@/utils/number';

import { useHandleProfileMoreButton } from '../hooks/useHandleProfileMoreButton';
import { MemberListFilter } from '../pages/MemberListForHost';
import * as s from './MemberProfileDetailSection.css';

type Props = {
  user: GroupMemberSummaryWithActivityPresentation;
  memberListFilter?: MemberListFilter;
  selectedMemberOrderType?: MemberListOrderType;
  currentUserRole: GroupMemberRoleEnum;
  canViewVerifiedUserInfo: boolean;
  needVerification: boolean;
  memberGradeEnabled: boolean;
};

export const MemberProfileDetailSection = ({
  user,
  memberListFilter,
  selectedMemberOrderType,
  currentUserRole,
  canViewVerifiedUserInfo,
  needVerification,
  memberGradeEnabled,
}: Props) => {
  const { id, activities, joinedAt, application } = user;
  const { groupId } = usePathParams();
  const shownOnlyHost = memberListFilter?.shownOnlyHost;

  const {
    data: { members: hostMemberList },
  } = useReadGroupHostMembers({ groupId, shownOnlyHost: true });
  const { renderProfileMoreButton } = useHandleProfileMoreButton({
    groupId,
    profileUser: user,
    selectedMemberOrderType,
    shownOnlyHost,
    hostMemberList,
  });

  const ProfileMoreButton = useCallback(renderProfileMoreButton, [
    user.role,
    shownOnlyHost,
    selectedMemberOrderType,
  ]);

  const { push } = useFlow();

  const handleProfileClick = () => {
    push('GroupUserProfileDetailPage', {
      groupId,
      userId: String(id),
    });

    trackEvent({
      event: 'click_profile',
      params: {
        clickedUserId: user.id,
        clickedUserRole: user.role,
        clickedUserState: user.state,
        currentUserRole: currentUserRole,
      },
    });
    return;
  };

  const getAppendDescription = () => {
    const formattedDate = formatDate(new Date(Date.parse(String(joinedAt))), 'yy.MM.dd 가입');

    if (
      !isSuperHost(currentUserRole) ||
      !user.verifiedUserInfoText ||
      !canViewVerifiedUserInfo ||
      !needVerification
    ) {
      return formattedDate;
    }

    if (needVerification && !canViewVerifiedUserInfo) {
      return user.verified ? `${formattedDate} ⸱ 본인인증 완료` : formattedDate;
    }

    if (user.verifiedUserInfoText) {
      return `${formattedDate} ⸱ ${user.verifiedUserInfoText}`;
    }

    return formattedDate;
  };

  return (
    <li className={s.ListItem}>
      <div className={s.ProfileContainer}>
        <div className={s.LeftSection} onClick={handleProfileClick}>
          <ProfileListItem
            user={user}
            appendDescription={getAppendDescription()}
            config={{ memberGradeEnabled }}
          />
        </div>
        <div className={s.RightSection}>
          <ProfileMoreButton />
        </div>
      </div>
      {activities && (
        <>
          <Spacing size={8} />
          <div className={s.VisitContentItem}>
            <IconClockFill size={16} color={vars.$scale.color.gray500} />
            <VerticalSpacing size={8} />
            <Typography typography="label3Regular" color="gray900">
              {getDateDiffFromNow(new Date(Date.parse(String(activities.lastVisitedAt)))).text +
                ' 전 방문'}
            </Typography>
          </div>
          <Spacing size={8} />
          <div className={s.ContentsContainer} onClick={handleProfileClick}>
            <Contents label="게시글" contents={numberToLocaleString(activities.entirePostCount)} />
            <Contents label="댓글" contents={numberToLocaleString(activities.entireCommentCount)} />
            <Contents
              label="일정 참여"
              contents={numberToLocaleString(activities.entireMeetupCount)}
            />
            {/* 
              TODO: 결석 체크관련해서 고도화 이후 다시 사용자에게 공개
              https://daangn.slack.com/archives/C05354CCEBA/p1714044965336309
            */}
            {isHigherManager(currentUserRole) && (
              <Contents
                label="일정 결석"
                contents={numberToLocaleString(activities.noAttendanceMeetupCount)}
              />
            )}
          </div>
        </>
      )}
      {application && (
        <>
          <Spacing size={8} />
          <div className={s.ApplicationContent}>
            <Typography color="gray600" typography="bodyL2Regular">
              Q. {application.question}
            </Typography>
            <Spacing size={4} />
            <Typography color="gray900" typography="bodyL2Regular">
              {application.answer}
            </Typography>
          </div>
        </>
      )}
    </li>
  );
};

const Contents = ({ label, contents }: { label: string; contents: string }) => (
  <div className={s.ContentsItem}>
    <Typography typography="caption2Regular" color="gray600">
      {label}
    </Typography>
    <Spacing size={2} />
    <Typography typography="label3Regular" color="gray900">
      {contents}
    </Typography>
  </div>
);
