import { groupClient } from '@community-group/api';

export const isAccessibleGrade = (
  currentUserMemberGrade: groupClient.model.GroupMemberGrade,
  permissionPriority: number
) => {
  return currentUserMemberGrade.priority <= permissionPriority;
};

export const filterMemberGradesByPriority = (
  memberGrades: groupClient.model.GroupMemberGrade[],
  priority: number
) => {
  return memberGrades.filter((grade) => grade.priority <= priority);
};

export const parsePermissionDescription = (
  memberGrades: groupClient.model.GroupMemberGrade[],
  priority: number
) => {
  const grades = [...memberGrades].sort((a, b) => b.priority - a.priority);
  if (
    grades[0].role === groupClient.model.GroupPermissionRoleEnum.member &&
    priority === grades[0].priority
  ) {
    return '모든 멤버';
  }

  return filterMemberGradesByPriority(memberGrades, priority)
    .map((grade) => grade.name)
    .join(', ');
};

export const getPermissionGradeName = (
  memberGrades: groupClient.model.GroupMemberGrade[],
  priority: number
) => {
  const grades = [...memberGrades].sort((a, b) => b.priority - a.priority);
  if (
    grades[0].role === groupClient.model.GroupPermissionRoleEnum.member &&
    priority >= grades[0].priority
  ) {
    const sameRoles = grades.filter((grade) => grade.role === grades[0].role);
    if (sameRoles.length > 1) {
      return '모든 멤버';
    }

    return grades[0].name;
  }

  if (
    grades[1].role === groupClient.model.GroupPermissionRoleEnum.member &&
    priority === grades[1].priority
  ) {
    return grades[1].name;
  }

  return filterMemberGradesByPriority(memberGrades, priority)
    .map((grade) => grade.name)
    .join(', ');
};
