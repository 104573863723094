import { Typography } from '@community-group/components';
import { IconXmarkLine } from '@daangn/react-monochrome-icon';

import { useFlow } from '@/stackflow';
import { useBack } from '@/stackflow/hooks/useBack';

import * as s from './TransparentNavBar.css';

const TransparentNavBar = () => {
  const { push } = useFlow();
  const handleBack = useBack();

  const handleClick = () => {
    push('BottomSheet/ChallengeCertifyPromotionGuideBottomSheet', {});
  };

  return (
    <div className={s.Container}>
      <IconXmarkLine size={24} onClick={handleBack} />
      <div className={s.RightSide}>
        <Typography typography="label2Regular" onClick={handleClick}>
          안내
        </Typography>
      </div>
    </div>
  );
};

export default TransparentNavBar;
