import { groupClient } from '@community-group/api';
import { useQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

type CategoryProps = {
  text?: string;
  options?: {
    enabled?: boolean;
    onError: (error: Error) => void;
  };
};

export const useGetRecommendCategory = ({ text = '', options }: CategoryProps) => {
  const fetchInstance = useFetchInstance();
  const getRecommendCategory = groupClient.api.CategoryApi.apiV1GroupCategoriesRecommendGet({
    axios: fetchInstance,
  });

  const { data, refetch, isPending, isRefetching } = useQuery({
    queryKey: [groupClient.api.CategoryApi.getapiV1GroupCategoriesRecommendGetPath(text ?? '')],
    queryFn: () => {
      if (text.length > 2) {
        return getRecommendCategory({ text });
      }
      return getRecommendCategory({ text: '' });
    },
    enabled: options?.enabled ?? true,
  });

  const response = data?.data;

  return { data: response, refetch, isLoading: isPending, isRefetching };
};
