import { groupClient } from '@community-group/api';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import { useRead } from '@/shared/api/hooks/useRead';

import { GROUP_URL } from '../base/group';
import { useFetchInstance } from './instance/useFetchInstance';

export const getGroupMePath = (groupId?: string) => `${GROUP_URL}/${groupId}/me`;

type Options = {
  refetchOnWindowFocus?: boolean;
};

export const useReadGroupMeNoSuspense = (groupId?: string) => {
  const read = useRead(groupClient.api.GroupApi.apiV1GroupsIdMeGet);

  return useQuery({
    queryKey: [getGroupMePath(groupId), 'group-me'],
    queryFn: async () => {
      return read({ id: Number(groupId) });
    },
    select: (data) => data?.data,
    enabled: !!groupId,
  });
};

export const useGetGroupMe = (id?: string, options?: Options) => {
  const fetchInstance = useFetchInstance();
  const getGroupMe = groupClient.api.GroupApi.apiV1GroupsIdMeGet({ axios: fetchInstance });

  const { refetchOnWindowFocus = false } = options ?? {};

  const { data, refetch, isPending, isRefetching } = useSuspenseQuery({
    queryKey: [getGroupMePath(id)],
    queryFn: () => {
      if (!id) return null;
      return getGroupMe({ id: Number(id) });
    },
    refetchOnWindowFocus,
  });

  const response = data?.data;

  return { data: response, refetch, isLoading: isPending, isRefetching };
};
