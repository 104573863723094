import {
  ActionableCallout,
  Dialog,
  Divider,
  Render,
  Spacing,
  Switch,
  Typography,
  useDialog,
  useSnackbarAdapter,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { SettingList } from '@community-group/components/features';
import { IconQuestionmarkCircleLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { ActivityComponentType } from '@stackflow/react';
import { useMemo } from 'react';

import FormGuideCallout from '@/components/common/FormGuideCallout';
import { usePutGroupMemberGradeStatus } from '@/domain/GroupMember/hooks/usePutGroupMemberGradeStatus';
import { useReadGroupMemberGradeStatus } from '@/domain/GroupMember/hooks/useReadGroupMemberGradeStatus';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';

import SettingMemberGradeRequirements from '../components/SettingMemberGrade/SettingMemberGradeRequirements';
import * as s from './GroupSettingMemberGradePage.css';

type Params = Pick<PageParams, 'groupId'>;

const GroupSettingMemberGradePage: ActivityComponentType<Params> = () => {
  const { groupId } = usePathParams();
  const { push } = useFlow();
  const { open: openDialog, close: closeDialog } = useDialog();

  const { data: memberGradeStatus, refetch: refetchMemberGradeStatus } =
    useReadGroupMemberGradeStatus(groupId);
  const memberGradeEnabled = useMemo(() => memberGradeStatus === 'system', [memberGradeStatus]);

  const { create, dismiss } = useSnackbarAdapter();
  const { mutateAsync: putGroupMemberGradeStatus } = usePutGroupMemberGradeStatus({
    onSuccess: () => refetchMemberGradeStatus(),
  });

  const handleMemberGradeChange = async () => {
    if (memberGradeEnabled) {
      openDialog({
        element: (
          <Dialog
            title="정말 멤버 등급을 그만 사용할까요?"
            description="멤버 등급을 없애면 기존 멤버들의 등급 기록이 모두 사라져요."
            primaryActionLabel="확인"
            secondaryActionLabel="취소"
            onPrimaryAction={async () => {
              closeDialog();

              await putGroupMemberGradeStatus({
                groupId,
                status: 'inactive',
              });
              create({
                message: '멤버 등급을 더 이상 사용하지 않아요.',
                timeout: 3000,
                type: 'default',
                onClick: dismiss,
              });
            }}
            onSecondaryAction={closeDialog}
          />
        ),
      });
      return;
    }

    await putGroupMemberGradeStatus({
      groupId,
      status: 'system',
    });
  };

  return (
    <AppScreen
      appBar={{
        title: '멤버 등급 관리',
        borderColor: vars.$semantic.color.divider3,
        borderSize: '0.5px',
        renderRight: () => (
          <IconQuestionmarkCircleLine size={24} color={vars.$scale.color.gray900} />
        ),
      }}
    >
      <SettingList.Wrapper>
        <SettingList.Item
          title="멤버 등급 사용"
          renderRight={
            <Switch onChange={handleMemberGradeChange} isSelected={memberGradeEnabled} />
          }
        />
        <SettingList.Item
          title="멤버 권한 관리"
          onClick={() => {
            push('GroupSettingPermissionPage', {
              groupId,
            });
          }}
        />
      </SettingList.Wrapper>
      <Render condition={memberGradeEnabled}>
        <Divider />
        <SettingMemberGradeRequirements groupId={groupId} />
      </Render>
      <Divider />
      <Spacing size={16} />
      <div className={s.CalloutWrapper}>
        <FormGuideCallout calloutType="member-grade-setting" />
      </div>
      <Spacing size={4} />
      <div className={s.CalloutWrapper}>
        <ActionableCallout variant="outline" width="100%">
          <Typography typography="bodyM2Regular" color="gray900">
            <b>설문</b> 멤버 등급에 대한 의견을 들려주세요.
          </Typography>
        </ActionableCallout>
      </div>
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupSettingMemberGradePage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
