import { GroupCurrentUser, GroupDetailPresentation } from '@community-group/api/lib/group/models';
import { PaginationEmpty, PaginationList } from '@community-group/components/shared';

import { MemoizedFeedItemV2 } from '@/domain/GroupFeed/components/MemoizedFeedItemV2';
import { useReadGroupFeeds } from '@/domain/GroupFeed/hooks/useReadGroupFeeds';

import * as s from './style.css';

type Props = {
  groupId: string;
  userId: string;
  groupDetail: GroupDetailPresentation;
  currentUser: GroupCurrentUser;
};

const GroupProfileContentsTabFeeds = ({ groupId, userId, groupDetail, currentUser }: Props) => {
  const {
    data: items,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useReadGroupFeeds({
    groupId,
    userId,
  });

  if (items.length <= 0) {
    return <PaginationEmpty>작성한 게시글이 없어요.</PaginationEmpty>;
  }

  return (
    <div className={s.ListWrapper}>
      <PaginationList
        items={items}
        render={(item) => (
          <MemoizedFeedItemV2
            key={`${item.id}-${item.type}`}
            groupId={groupId}
            groupDetail={groupDetail}
            currentUser={currentUser}
            challenge={item.challengeInfo}
            meetup={item.meetupInfo}
            shouldSetSubNickname={groupDetail.subNicknameSetting.isSettingOn}
            onClickProfile={() => {}}
            feed={item}
          />
        )}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
      />
    </div>
  );
};

export default GroupProfileContentsTabFeeds;
