import { TextButton } from '@community-group/components';
import { IconFacebook, IconInstagram, IconTwitter } from '@community-group/components';
import { IconPlusLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { match } from 'ts-pattern';

export type SnsTagItemProps = {
  name?: string;
  value?: string;
  iconType?: 'facebook' | 'instagram' | 'x';
  tagType?: 'new';
  onClick?: () => void;
};

const SnsTextButton = ({ name, iconType, tagType, onClick }: SnsTagItemProps) => {
  if (tagType === 'new') {
    return (
      <TextButton
        onClick={onClick}
        prefix={<IconPlusLine size={16} />}
        variant="secondaryLow"
        size="small"
      >
        소셜 계정 추가
      </TextButton>
    );
  }

  const getIcon = () => {
    return match(iconType)
      .with('facebook', () => <IconFacebook fill="gray" />)
      .with('instagram', () => <IconInstagram fill="gray" />)
      .otherwise(() => <IconTwitter fill="gray" />);
  };

  return (
    <TextButton
      onClick={onClick}
      prefix={getIcon()}
      variant="secondaryLow"
      size="small"
      UNSAFE_style={{ borderBottom: `1px solid ${vars.$scale.color.gray600}` }}
    >
      {name}
    </TextButton>
  );
};

export default SnsTextButton;
