import { groupClient } from '@community-group/api';
import { PaginationQueryParams } from '@community-group/components/shared';
import { useInfiniteQuery, useSuspenseInfiniteQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

type Params = {
  topic: string;
} & PaginationQueryParams;

export const useGetCurationList = ({ topic, initialCursor }: Params) => {
  const fetchInstance = useFetchInstance();

  const getCurationList = groupClient.api.GroupCurationApi.apiV1GroupsCurationGet({
    axios: fetchInstance,
  });

  const { fetchNextPage, hasNextPage, isFetchingNextPage, data, refetch } =
    useSuspenseInfiniteQuery({
      queryKey: [groupClient.api.GroupCurationApi.getapiV1GroupsCurationGetPath(topic), topic],
      queryFn: ({ pageParam = initialCursor }) =>
        getCurationList({ topic, cursor: pageParam, limit: 30 }),
      initialPageParam: initialCursor,
      getNextPageParam: ({ data }) => (data.hasNext ? data?.endCursor ?? undefined : undefined),
      refetchInterval: 10000,
    });

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
  };
};
