import { z } from 'zod';

const images = z.array(z.string()).max(10, '이미지는 10개까지만 올릴 수 있어요.');

export const commentFormSchema = z.object({
  images,
  content: z.string().refine((value) => value.trim().length > 0, {
    message: '댓글이 너무 짧아요. 1자 이상 작성해주세요.',
  }),
});
