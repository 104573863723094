import { groupClient } from '@community-group/api';
import { CommentRelatedContentTypeEnum } from '@community-group/api/lib/group/models';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

type Params = {
  groupId: string;
  relatedId: string;
  relatedContentType: CommentRelatedContentTypeEnum;
};

export const getCommentTotalCountPath = (
  groupId: string,
  relatedId: string,
  relatedContentType: CommentRelatedContentTypeEnum
) => {
  return groupClient.api.CommentApi.getapiV1GroupsIdRelatedContentRelateIdCommentsTotalCountGetPath(
    Number(groupId),
    Number(relatedId),
    relatedContentType
  );
};

export const useGetCommentTotalCount = ({ groupId, relatedId, relatedContentType }: Params) => {
  const fetchInstance = useFetchInstance();
  const getCommentCount =
    groupClient.api.CommentApi.apiV1GroupsIdRelatedContentRelateIdCommentsTotalCountGet({
      axios: fetchInstance,
    });

  const path = getCommentTotalCountPath(groupId, relatedId, relatedContentType);
  const { data, refetch } = useSuspenseQuery({
    queryKey: [path],
    queryFn: () =>
      getCommentCount({
        id: Number(groupId),
        relateId: Number(relatedId),
        relatedContentType,
      }),
  });

  return {
    data: data?.data,
    refetch,
  };
};
