import { ChallengeBannerPresentationChallenges } from '@community-group/api/lib/group/models';
import { Spacing } from '@community-group/components';
import { motion } from 'framer-motion';
import { useLayoutEffect, useMemo, useState } from 'react';

import { useReadGroupDetail } from '@/api/hooks/useGetGroupDetail';
import useMainBannerFolded from '@/components/group/DetailV2/hooks/useMainBannerFolded';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { trackEvent } from '@/utils/analytics';

import BannerHeader from '../BannerHeader';
import ChallengeBannerItem from '../ChallengeBannerItem';
import FooterButton from '../FooterButton';
import * as s from './style.css';

type Props = {
  totalChallengesCount: number;
  challenge: ChallengeBannerPresentationChallenges[];
};

const MultiAttendedChallenge = ({ challenge, totalChallengesCount }: Props) => {
  const { push } = useFlow();
  const { groupId } = usePathParams();
  const title = '이번 주 인증글 남기기';
  const subTitle = useMemo(() => {
    return `진행 중인 챌린지가 ${totalChallengesCount}개 있어요.`;
  }, [totalChallengesCount]);

  const { foldedMainBanner, handleToggle } = useMainBannerFolded();
  const { group } = useReadGroupDetail(groupId);

  const handleClickFooterButton = () => {
    trackEvent({
      event: 'click_challenge_banner_more',
      params: {
        groupId,
        groupName: group?.name,
        categoryId: group?.category.id,
      },
    });
    push('GroupChallengeListPage', {
      groupId,
    });
  };

  const animateConfig = foldedMainBanner
    ? { opacity: 0, height: 0, transitionEnd: { display: 'none' } }
    : {
        opacity: 1,
        height: 'auto',
        transitionEnd: {
          display: 'block',
        },
      };

  const handleChangeCloseChallengeBanner = () => {
    handleToggle();
  };

  return (
    <>
      <BannerHeader
        title={title}
        subTitle={subTitle}
        isOpened={!foldedMainBanner}
        onToggle={handleChangeCloseChallengeBanner}
      />

      <motion.div
        style={{ width: '100%' }}
        animate={animateConfig}
        initial={false}
        transition={{
          opacity: {
            duration: 0.3,
            type: 'just',
            bounce: 0.26,
          },
          height: {
            duration: 0.3,
            type: 'just',
            bounce: 0.26,
          },
        }}
      >
        <div className={s.ChallengeListContainer}>
          <Spacing size={16} />
          {challenge.map((item, index) => (
            <div key={item.id}>
              <ChallengeBannerItem
                key={item.id}
                group={group}
                isSuccess={item.todayCertified ?? false}
                {...item}
              />
              {index !== challenge.length - 1 && <Spacing size={8} />}
            </div>
          ))}
          <Spacing size={16} />
          <FooterButton text="챌린지 전체 보기" onClick={handleClickFooterButton} />
        </div>
      </motion.div>
    </>
  );
};

export default MultiAttendedChallenge;
