import { GroupMemberSummaryPresentation } from '@community-group/api/lib/group/models';
import { useDialog } from '@community-group/components';
import { useCallback } from 'react';

import { getGroupPath } from '@/api/base/group';
import { getGroupMainProfileQueryKey } from '@/api/hooks/useGetGroupMainProfile';
import { MemberListOrderType } from '@/api/hooks/useGetMemberList';
import { queryClient } from '@/shared/api/instance';
import { refetchGroupMemberList } from '@/utils/refetch/memberList';

import { DeleteMemberDialog } from '../../Setting/components/DeleteMemberDialog';

export const useHandleBanProfile = (
  groupId: string,
  user: GroupMemberSummaryPresentation,
  shownOnlyHost?: boolean,
  selectedMemberOrderType?: MemberListOrderType
) => {
  const { open: openDialog, close: closeDialog } = useDialog();

  const refetchMemberList = useCallback(() => {
    setTimeout(() => {
      queryClient.refetchQueries({
        queryKey: [`${getGroupPath(groupId)}/members`, selectedMemberOrderType, shownOnlyHost],
      });

      queryClient.refetchQueries({
        queryKey: [`${getGroupPath(groupId)}/members`, undefined, true],
      });
      queryClient.refetchQueries({
        queryKey: getGroupMainProfileQueryKey(user.id, parseInt(groupId)),
      });
      refetchGroupMemberList({
        groupId,
        selectedMemberOrderType,
        shownOnlyHost,
      });
    }, 200);
  }, [groupId, selectedMemberOrderType, shownOnlyHost, user?.id]);

  const handleBanProfile = async () => {
    await closeDialog();
    openDialog({
      element: (
        <DeleteMemberDialog
          groupId={groupId}
          userId={user.id.toString()}
          nickname={user.nickname}
          subNickname={user.subNickname}
          confirmCallback={() => {
            refetchMemberList();
          }}
        />
      ),
      onOutsideClick: closeDialog,
    });
  };

  return {
    banLabel: '모임에서 내보내기',
    handleBanProfile,
  };
};
