import { groupClient } from '@community-group/api';
import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';

import { useFetchInstance } from './instance/useFetchInstance';

export const useGetMoneyTransferTicket = () => {
  const fetchInstance = useFetchInstance();
  const getMoneyTransferTicketFp =
    groupClient.api.GroupPayApi.apiV1MoneyTransfersTicketsTicketIdGet({
      axios: fetchInstance,
    });

  const fetchGetMoneyTransferTicket = async (ticketId: string) => {
    try {
      const { data } = await getMoneyTransferTicketFp({ ticketId });

      return data;
    } catch (error: any) {
      Sentry.captureException(error as AxiosError);

      throw new Error(error);
    }
  };

  return {
    fetch: fetchGetMoneyTransferTicket,
  };
};
