import { ViewError, ViewLoader, withAsyncBoundary } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';

import { AppScreen } from '@/stackflow/components/AppScreen';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';

import GroupCreateProfileAndInstantCreateChallenge from '../components/GroupCreateProfileAndInstantCreateChallenge';
import GroupCreateProfileAndJoinGroupPage from '../components/GroupCreateProfileAndJoinGroup';
import GroupProfileCreateComponent from '../components/GroupProfileCreate';

const GroupProfileCreatePage: ActivityComponentType = () => {
  return <ProfileWrapper />;
};

const ProfileWrapper = () => {
  const { groupId, from } = useQueryParams();

  // 모임 없이 챌린지를 즉시 생성하는 플로우에서, 프로필 생성 후 챌린지 생성
  if (from === 'GroupChallengeNewPage') {
    return <GroupCreateProfileAndInstantCreateChallenge />;
  }

  if (groupId && from !== 'MemberGroupDetail') {
    return <GroupCreateProfileAndJoinGroupPage />;
  }

  return <GroupProfileCreateComponent />;
};

export default withAsyncBoundary(GroupProfileCreatePage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
