import { useCallback, useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { useStorage } from '@/hooks/useStorage';

import { defaultGroupChallengeData } from '../pages/GroupChallengeNewPage';
import { getTemplatesByCategory } from '../templates/challengeTemplates';
import { GroupChallengeBaseForm } from '../types';

// 임시 저장 데이터 load
export const useTempCreateGroupChallengeFormData = ({ groupId }: { groupId?: string }) => {
  const [createChallengeForm, setCreateChallengeForm] = useStorage(
    'createGroupChallengeForm',
    {} as {
      [groupId: string]: GroupChallengeBaseForm;
    }
  );

  const tempStoredData = useMemo(() => {
    if (!groupId) return undefined;

    const storedData = createChallengeForm?.[groupId];
    if (!storedData?.name) return null;

    // 템플릿 데이터와 비교
    const matchingTemplate = getTemplatesByCategory('').find((template) => {
      const templateData = { ...defaultGroupChallengeData, ...template.defaultData };
      return JSON.stringify(templateData) === JSON.stringify(storedData);
    });

    // 템플릿과 완전히 동일한 데이터라면 보여주지 않음
    if (matchingTemplate) {
      return null;
    }

    return storedData;
  }, [createChallengeForm, groupId]);

  return {
    createChallengeForm,
    setCreateChallengeForm,
    tempStoredData,
  };
};

// 임시 저장 데이터를 저장하는 훅
export const useTempCreateGroupChallengeForm = ({
  groupId,
  formHandler,
}: {
  groupId?: string;
  formHandler: UseFormReturn<GroupChallengeBaseForm>;
}) => {
  const { createChallengeForm, setCreateChallengeForm, tempStoredData } =
    useTempCreateGroupChallengeFormData({ groupId });

  const watchedData = useWatch({
    control: formHandler?.control,
  }) as GroupChallengeBaseForm;

  const handleSaveTemp = useCallback(
    (newValue: GroupChallengeBaseForm) => {
      if (!groupId) return;
      setCreateChallengeForm({ ...createChallengeForm, [groupId]: newValue });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createChallengeForm]
  );

  // 데이터 변경 시 data 업데이트
  useDeepCompareEffect(() => {
    handleSaveTemp(watchedData);
  }, [watchedData]);

  return {
    tempStoredData,
    handleSaveTemp,
  };
};
